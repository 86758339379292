'use strict';

var curryCallAsync = require('./curry_call_async.js');
var logging = require('./logging.js');
var OTHelpers = require('@opentok/ot-helpers');

module.exports = function RumorSocket(plugin, server) {
  var Proto = function RumorSocket() {},
      api = new Proto(),
      connected = false,
      rumorID,
      _onOpen,
      _onClose;

  try {
    rumorID = plugin._.RumorInit(server, '');
  } catch (e) {
    logging.error('Error creating the Rumor Socket: ', e.message);
  }

  if (!rumorID) {
    throw new Error('Could not initialise OTPlugin rumor connection');
  }

  api.open = function() {
    connected = true;
    plugin._.RumorOpen(rumorID);
  };

  api.close = function(code, reason) {
    if (connected) {
      connected = false;
      plugin._.RumorClose(rumorID, code, reason);
    }

    plugin.removeRef(api);
  };

  api.destroy = function() {
    this.close();
  };

  api.send = function(msg) {
    plugin._.RumorSend(rumorID, msg.type, msg.toAddress,
      JSON.parse(JSON.stringify(msg.headers)), msg.data);
  };

  api.onOpen = function(callback) {
    _onOpen = callback;
  };

  api.onClose = function(callback) {
    _onClose = callback;
  };

  api.onError = function(callback) {
    plugin._.SetOnRumorError(rumorID, curryCallAsync(callback));
  };

  api.onMessage = function(callback) {
    plugin._.SetOnRumorMessage(rumorID, curryCallAsync(callback));
  };

  plugin.addRef(api);

  plugin._.SetOnRumorOpen(rumorID, curryCallAsync(function() {
    if (_onOpen && OTHelpers.isFunction(_onOpen)) {
      _onOpen.call(null);
    }
  }));

  plugin._.SetOnRumorClose(rumorID, curryCallAsync(function(code) {
    _onClose(code);

    // We're done. Clean up ourselves
    plugin.removeRef(api);
  }));

  return api;
};
