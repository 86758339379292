'use strict';

// OTHelpers.env
//
// Contains information about the current environment.
// * **env.name** The name of the Environment (Chrome, FF, Node, etc)
// * **env.version** Usually a Float, except in Node which uses a String
// * **env.userAgent** The raw user agent
// * **env.versionGreaterThan** A helper method that returns true if the
// current version is greater than the argument
//
// Example
//     if (OTHelpers.env.versionGreaterThan('0.10.30')) {
//       // do something
//     }
//

var env = {};
module.exports = env;

env._attachToOTHelpers = {};

// @todo make exposing userAgent unnecessary
env.version = -1;

// Returns true if otherVersion is greater than the current environment
// version.
env.versionGreaterThan = function versionGreaterThan(otherVersion) {
  if (otherVersion === env.version) {
    return true;
  }

  if (typeof otherVersion === 'number' && typeof env.version === 'number') {
    return otherVersion > env.version;
  }

  // The versions have multiple components (i.e. 0.10.30) and
  // must be compared piecewise.
  // Note: I'm ignoring the case where one version has multiple
  // components and the other doesn't.
  var v1 = otherVersion.split('.'),
      v2 = env.version.split('.'),
      versionLength = (v1.length > v2.length ? v2 : v1).length;

  for (var i = 0; i < versionLength; ++i) {
    if (parseInt(v1[i], 10) > parseInt(v2[i], 10)) {
      return true;
    }
  }

  // Special case, v1 has extra components but the initial components
  // were identical, we assume this means newer but it might also mean
  // that someone changed versioning systems.
  if (i < v1.length) {
    return true;
  }

  return false;
};

if (typeof(process) !== 'undefined' &&
    typeof(process.versions) !== 'undefined' &&
    typeof(process.versions.node) === 'string') {

  env.version = process.versions.node;
  if (env.version.substr(1) === 'v') env.version = env.version.substr(1);

  // Special casing node to avoid gating global.navigator.
  // Version will be a string rather than a float.
  env.name = 'Node';
  env.version = env.version;
  env.userAgent = 'Node ' + env.version;
  env.iframeNeedsLoad = false;
}
else {
  env.userAgent = global.navigator.userAgent.toLowerCase();
  env.appName = global.navigator.appName;
  env.navigatorVendor = undefined;
  env.name = 'unknown';

  if (env.userAgent.indexOf('opera') > -1 || env.userAgent.indexOf('opr') > -1) {
    env.name = 'Opera';

    if (/opr\/([0-9]{1,}[\.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat( RegExp.$1 );
    }

  } else if (env.userAgent.indexOf('firefox') > -1)   {
    env.name = 'Firefox';

    if (/firefox\/([0-9]{1,}[\.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat( RegExp.$1 );
    }

  } else if (env.appName === 'Microsoft Internet Explorer') {
    // IE 10 and below
    env.name = 'IE';

    if (/msie ([0-9]{1,}[\.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat( RegExp.$1 );
    }

  } else if (env.userAgent.search(/edge\/\d+(\.\d+)?$/) > -1) {
    env.name = 'Edge';

    if (/Edge\/(\d+(\.\d+)?)$/.exec(env.userAgent) !== null) {
      env.version = parseFloat( RegExp.$1 );
    }

  } else if (env.appName === 'Netscape' && env.userAgent.indexOf('trident') > -1) {
    // IE 11+

    env.name = 'IE';

    if (/trident\/.*rv:([0-9]{1,}[\.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat( RegExp.$1 );
    }

  } else if (env.userAgent.indexOf('chrome') > -1) {
    env.name = 'Chrome';

    if (/chrome\/([0-9]{1,}[\.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat( RegExp.$1 );
    }

  } else if (global.navigator.vendor &&
    global.navigator.vendor.toLowerCase().indexOf('apple') > -1) {
    env.name = 'Safari';

    if (/version\/([0-9]{1,}[\.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat( RegExp.$1 );
    }
  }

  env.iframeNeedsLoad = env.userAgent.indexOf('webkit') < 0;
  env.userAgent = global.navigator.userAgent;
}

env._attachToOTHelpers.browser = function() {
  return env.name;
};

env._attachToOTHelpers.browserVersion = function() {
  return env;
};
