'use strict';

module.exports = function versionGreaterThan(version1, version2) {
  if (version1 === version2) return false;
  if (version1 === -1) return version2;
  if (version2 === -1) return version1;

  if (version1.indexOf('.') === -1 && version2.indexOf('.') === -1) {
    return version1 > version2;
  }

  // The versions have multiple components (i.e. 0.10.30) and
  // must be compared piecewise.
  // Note: I'm ignoring the case where one version has multiple
  // components and the other doesn't.
  var v1 = version1.split('.'),
      v2 = version2.split('.'),
      versionLength = (v1.length > v2.length ? v2 : v1).length;

  for (var i = 0; i < versionLength; ++i) {
    if (parseInt(v1[i], 10) > parseInt(v2[i], 10)) {
      return true;
    }
  }

  // Special case, v1 has extra components but the initial components
  // were identical, we assume this means newer but it might also mean
  // that someone changed versioning systems.
  if (i < v1.length) {
    return true;
  }

  return false;
};
