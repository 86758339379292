'use strict';

var env = require('../../env');
var callbacks = require('../../callbacks');

if(env.name !== 'Node') {
  module.exports = function browserEventing(self, syncronous) {
    var api = {
      events: {}
    };

    // Call the defaultAction, passing args
    function executeDefaultAction(defaultAction, args) {
      if (!defaultAction) return;

      defaultAction.apply(null, args.slice());
    }

    // Execute each handler in +listeners+ with +args+.
    //
    // Each handler will be executed async. On completion the defaultAction
    // handler will be executed with the args.
    //
    // @param [Array] listeners
    //    An array of functions to execute. Each will be passed args.
    //
    // @param [Array] args
    //    An array of arguments to execute each function in  +listeners+ with.
    //
    // @param [String] name
    //    The name of this event.
    //
    // @param [Function, Null, Undefined] defaultAction
    //    An optional function to execute after every other handler. This will execute even
    //    if +listeners+ is empty. +defaultAction+ will be passed args as a normal
    //    handler would.
    //
    // @return Undefined
    //
    function executeListenersAsyncronously(name, args, defaultAction) {
      var listeners = api.events[name];
      if (!listeners || listeners.length === 0) return;

      var listenerAcks = listeners.length;

      listeners.forEach(function(listener) { // , index
        function filterHandlers(_listener) {
          return _listener.handler === listener.handler;
        }

        // We run this asynchronously so that it doesn't interfere with execution if an
        // error happens
        callbacks.callAsync(function() {
          try {
            // have to check if the listener has not been removed
            if (api.events[name] && api.events[name].some(filterHandlers)) {
              (listener.closure || listener.handler).apply(listener.context || null, args);
            }
          }
          finally {
            listenerAcks--;

            if (listenerAcks === 0) {
              executeDefaultAction(defaultAction, args);
            }
          }
        });
      });
    }


    // This is identical to executeListenersAsyncronously except that handlers will
    // be executed syncronously.
    //
    // On completion the defaultAction handler will be executed with the args.
    //
    // @param [Array] listeners
    //    An array of functions to execute. Each will be passed args.
    //
    // @param [Array] args
    //    An array of arguments to execute each function in  +listeners+ with.
    //
    // @param [String] name
    //    The name of this event.
    //
    // @param [Function, Null, Undefined] defaultAction
    //    An optional function to execute after every other handler. This will execute even
    //    if +listeners+ is empty. +defaultAction+ will be passed args as a normal
    //    handler would.
    //
    // @return Undefined
    //
    function executeListenersSyncronously(name, args) { // defaultAction is not used
      var listeners = api.events[name];
      if (!listeners || listeners.length === 0) return;

      listeners.forEach(function(listener) { // index
        (listener.closure || listener.handler).apply(listener.context || null, args);
      });
    }

    var executeListeners = syncronous === true ?
      executeListenersSyncronously : executeListenersAsyncronously;


    api.addListeners = function (eventNames, handler, context, closure) {
      var listener = {handler: handler};
      if (context) listener.context = context;
      if (closure) listener.closure = closure;

      eventNames.forEach(function(name) {
        if (!api.events[name]) api.events[name] = [];
        api.events[name].push(listener);

        var addedListener = name + ':added';
        if (api.events[addedListener]) {
          executeListeners(addedListener, [api.events[name].length]);
        }
      });
    };

    api.removeListeners = function(eventNames, handler, context) {
      function filterListeners(listener) {
        var isCorrectHandler = (
          listener.handler.originalHandler === handler ||
          listener.handler === handler
        );

        return !(isCorrectHandler && listener.context === context);
      }

      eventNames.forEach(function(name) {
        if (api.events[name]) {
          api.events[name] = api.events[name].filter(filterListeners);
          if (api.events[name].length === 0) delete api.events[name];

          var removedListener = name + ':removed';
          if (api.events[ removedListener]) {
            executeListeners(removedListener, [api.events[name] ? api.events[name].length : 0]);
          }
        }
      });
    };

    api.removeAllListenersNamed = function (eventNames) {
      eventNames.forEach(function(name) {
        if (api.events[name]) {
          delete api.events[name];
        }
      });
    };

    api.removeAllListeners = function () {
      api.events = {};
    };

    api.dispatchEvent = function(event, defaultAction) {
      if (!api.events[event.type] || api.events[event.type].length === 0) {
        executeDefaultAction(defaultAction, [event]);
        return;
      }

      executeListeners(event.type, [event], defaultAction);
    };

    api.trigger = function(/* eventName [, arg1, arg2, ...argN] */) {
      var args = Array.prototype.slice.call(arguments);
      var eventName = args.shift();

      if (!api.events[eventName] || api.events[eventName].length === 0) {
        return;
      }

      executeListeners(eventName, args);
    };


    /* test-code */

    // When this is being used inside Jasmime we create and expose spies for
    // several private methods. These are used for verification in our test
    // suites.

    /*global jasmine:true */
    if (global.jasmine && global.jasmine.getEnv()) {
      api.addListeners = jasmine.createSpy('addListeners')
                          .and.callFake(api.addListeners);

      api.removeListeners = jasmine.createSpy('removeListeners')
                            .and.callFake(api.removeListeners);

      api.removeAllListenersNamed = jasmine.createSpy('removeAllListenersNamed')
                                      .and.callFake(api.removeAllListenersNamed);
    }
    /* end-test-code */

    return api;
  };
}
