'use strict';

var uuid = require('uuid');
var OTHelpers = require('@opentok/ot-helpers');
var pluginEventingBehaviour = require('./plugin_eventing_behaviour.js');
var refCountBehaviour = require('./ref_count_behaviour.js');

var PROXY_LOAD_TIMEOUT = 5000;

var objectTimeouts = {};

var clearGlobalCallback = function clearGlobalCallback(callbackId) {
  if (!callbackId) return;

  if (objectTimeouts[callbackId]) {
    clearTimeout(objectTimeouts[callbackId]);
    objectTimeouts[callbackId] = null;
  }

  if (global[callbackId]) {
    try {
      delete global[callbackId];
    } catch (err) {
      global[callbackId] = void 0;
    }
  }
};

var waitOnGlobalCallback = function waitOnGlobalCallback(callbackId, completion) {
  objectTimeouts[callbackId] = setTimeout(function() {
    clearGlobalCallback(callbackId);
    completion('The object timed out while loading.');
  }, PROXY_LOAD_TIMEOUT);

  global[callbackId] = function() {
    clearGlobalCallback(callbackId);

    var args = Array.prototype.slice.call(arguments);
    args.unshift(null);
    completion.apply(null, args);
  };
};

var generateCallbackID = function generateCallbackID() {
  return 'OTPlugin_loaded_' + uuid().replace(/\-+/g, '');
};

var generateObjectHtml = function generateObjectHtml(callbackId, options) {
  options = options || {};

  var objBits = [],
    attrs = [
      'type="' + options.mimeType + '"',
      'id="' + callbackId + '_obj"',
      'tb_callback_id="' + callbackId + '"',
      'width="0" height="0"'
    ],
    params = {
      userAgent: OTHelpers.env.userAgent.toLowerCase(),
      windowless: options.windowless,
      onload: callbackId
    };

  if (options.isVisible !== true) {
    attrs.push('visibility="hidden"');
  }

  objBits.push('<object ' + attrs.join(' ') + '>');

  for (var name in params) {
    if (params.hasOwnProperty(name)) {
      objBits.push('<param name="' + name + '" value="' + params[name] + '" />');
    }
  }

  objBits.push('</object>');
  return objBits.join('');
};

var createObject = function createObject(callbackId, options, completion) {
  options = options || {};

  var html = generateObjectHtml(callbackId, options),
      doc = options.doc || global.document;

  doc.body.insertAdjacentHTML('beforeend', html);
  var object = doc.body.querySelector('#' + callbackId + '_obj');

  completion(void 0, object);
};

// Reference counted wrapper for a plugin object
var createPluginProxy = function(options, completion) {
  var Proto = function PluginProxy() {},
      api = new Proto(),
      waitForReadySignal = pluginEventingBehaviour(api);

  refCountBehaviour(api);

  // Assign +plugin+ to this object and setup all the public
  // accessors that relate to the DOM Object.
  //
  var setPlugin = function setPlugin(plugin) {
        if (plugin) {
          api._ = plugin;
          api.parentElement = plugin.parentElement;
          api.OTHelpers = OTHelpers(plugin);
        } else {
          api._ = null;
          api.parentElement = null;
          api.OTHelpers = OTHelpers();
        }
      };

  api.uuid = generateCallbackID();

  api.isValid = function() {
    return api._.valid;
  };

  api.destroy = function() {
    api.removeAllRefs();
    setPlugin(null);

    // Let listeners know that they should do any final book keeping
    // that relates to us.
    api.emit('destroy');
  };

  api.enumerateDevices = function(completion) {
    api._.enumerateDevices(completion);
  };

  /// Initialise

  // The next statement creates the raw plugin object accessor on the Proxy.
  // This is null until we actually have created the Object.
  setPlugin(null);

  waitOnGlobalCallback(api.uuid, function(err) {
    if (err) {
      completion('The plugin with the mimeType of ' +
                      options.mimeType + ' timed out while loading: ' + err);

      api.destroy();
      return;
    }

    api._.setAttribute('id', 'tb_plugin_' + api._.uuid);
    api._.removeAttribute('tb_callback_id');
    api.uuid = api._.uuid;
    api.id = api._.id;

    waitForReadySignal(function(err) {
      if (err) {
        completion('Error while starting up plugin ' + api.uuid + ': ' + err);
        api.destroy();
        return;
      }

      completion(void 0, api);
    });
  });

  createObject(api.uuid, options, function(err, plugin) {
    setPlugin(plugin);
  });

  return api;
};

// Specialisation for the MediaCapturer API surface
var makeMediaCapturerProxy = function makeMediaCapturerProxy(api) {
  api.selectSources = function() {
    return api._.selectSources.apply(api._, arguments);
  };

  api.listenForDeviceChanges();
  return api;
};

// Specialisation for the MediaPeer API surface
var makeMediaPeerProxy = function makeMediaPeerProxy(api) {
  api.setStream = function(stream, completion) {
    api._.setStream(stream);

    var renderingStarted = false;

    if (completion) {
      if (stream.hasVideo()) {
        api._.registerXCallback(
          'renderingStarted',
          function() {
            if (renderingStarted) {
              logging.error('Expected renderingStarted to happen only once.');
              return;
            }

            renderingStarted = true;
            completion();
          }
        );
      } else {
        // TODO Investigate whether there is a good way to detect
        // when the audio is ready. Does it even matter?

        // This fires a little too soon.
        setTimeout(completion, 200);
      }
    }

    return api;
  };

  return api;
};

module.exports = {
  createPluginProxy: createPluginProxy,
  makeMediaPeerProxy: makeMediaPeerProxy,
  makeMediaCapturerProxy: makeMediaCapturerProxy
};
