'use strict';

var curryCallAsync = require('./curry_call_async.js');
var logging = require('./logging.js');
var meta = require('./meta.js');

// TODO: fix this super duper evil circular dependency between this project and webrtc-js via global
// scope
var OT = global.OT;

var OTHelpers = require('@opentok/ot-helpers');
var PluginProxies = require('./plugin_proxies.js');
var versionGreaterThan = require('./version_greater_than.js');

var autoUpdaterController;

var AutoUpdater = function() {
  var plugin;

  var getControllerCurry = function getControllerFirstCurry(fn) {
    return function() {
      if (plugin) {
        return fn(void 0, arguments);
      }

      PluginProxies.create({
        mimeType: meta.getInstallerMimeType(),
        isVisible: false,
        windowless: false
      }, function(err, p) {
        plugin = p;

        if (err) {
          logging.error('Error while loading the AutoUpdater: ' + err);
          return;
        }

        return fn.apply(void 0, arguments);
      });
    };
  };

  // Returns true if the version of the plugin installed on this computer
  // does not match the one expected by this version of OTPlugin.
  this.isOutOfDate = function() {
    return versionGreaterThan(meta.version(), meta.installedVersion());
  };

  this.autoUpdate = getControllerCurry(function() {
    var modal = OT.Dialogs.Plugin.updateInProgress(),
        analytics = new OT.Analytics(),
      payload = {
        ieVersion: OTHelpers.env.version,
        pluginOldVersion: meta.installedVersion(),
        pluginNewVersion: meta.version
      };

    var success = curryCallAsync(function() {
          analytics.logEvent({
            action: 'OTPluginAutoUpdate',
            variation: 'Success',
            partnerId: OT.APIKEY,
            payload: JSON.stringify(payload)
          });

          plugin.destroy();

          modal.close();
          OT.Dialogs.Plugin.updateComplete().on({
            reload: function() {
              window.location.reload();
            }
          });
        }),

        error = curryCallAsync(function(errorCode, errorMessage, systemErrorCode) {
          payload.errorCode = errorCode;
          payload.systemErrorCode = systemErrorCode;

          analytics.logEvent({
            action: 'OTPluginAutoUpdate',
            variation: 'Failure',
            partnerId: OT.APIKEY,
            payload: JSON.stringify(payload)
          });

          plugin.destroy();

          modal.close();
          var updateMessage = errorMessage + ' (' + errorCode +
                                    '). Please restart your browser and try again.';

          modal = OT.Dialogs.Plugin.updateComplete(updateMessage).on({
            reload: function() {
              modal.close();
            }
          });

          logging.error('autoUpdate failed: ' + errorMessage + ' (' + errorCode +
                                    '). Please restart your browser and try again.');
          // TODO log client event
        }),

        progress = curryCallAsync(function(progress) {
          modal.setUpdateProgress(progress.toFixed());
          // modalBody.innerHTML = 'Updating...' + progress.toFixed() + '%';
        });

    meta.pathToInstaller(function(pathToInstaller) {
      plugin._.updatePlugin(pathToInstaller, success, error, progress);
    });
  });

  this.destroy = function() {
    if (plugin) plugin.destroy();
  };

  // Refresh the plugin list so that we'll hopefully detect newer versions
  if (navigator.plugins) {
    navigator.plugins.refresh(false);
  }
};

AutoUpdater.get = function(completion) {
  if (!autoUpdaterController) {
    if (!this.isinstalled()) {
      completion.call(null, 'Plugin was not installed');
      return;
    }

    autoUpdaterController = new AutoUpdater();
  }

  completion.call(null, void 0, autoUpdaterController);
};

AutoUpdater.isinstalled = function() {
  return meta.getInstallerMimeType() !== null && !meta.hasBrokenUpdater();
};

AutoUpdater.installedVersion = function() {
  return meta.installedVersion();
};

module.exports = AutoUpdater;
