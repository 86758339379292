'use strict';

var OTHelpers = require('@opentok/ot-helpers');
var PeerConnections = require('./peer_connections.js');
var RaptorConstants = require('../messaging/raptor/raptor_constants.js');
var setCertificates = require('./set_certificates.js');

/*
 * Abstracts PeerConnection related stuff away from Publisher.
 *
 * Responsible for:
 * * setting up the underlying PeerConnection (delegates to PeerConnections)
 * * triggering a connected event when the Peer connection is opened
 * * triggering a disconnected event when the Peer connection is closed
 * * providing a destroy method
 * * providing a processMessage method
 *
 * Once the PeerConnection is connected and the video element playing it triggers
 * the connected event
 *
 * Triggers the following events
 * * connected
 * * disconnected
 */
module.exports = function PublisherPeerConnection(
  remoteConnection,
  session,
  streamId,
  webRTCStream,
  channels,
  numberOfSimulcastStreams,
  subscriberUri
) {
  var _publisherPeerConnection = this;
  var _peerConnection;
  var _hasRelayCandidates = false;
  var _subscriberId = session._.subscriberMap[remoteConnection.id + '_' + streamId];
  var _awaitingIceRestart = false;

  // Private
  var _onPeerClosed = function() {
    this.destroy();
    if (_awaitingIceRestart) {
      this.trigger('iceRestartFailure', this);
    }
    this.trigger('disconnected', this);
  };

  // Note: All Peer errors are fatal right now.
  var _onPeerError = function(errorReason, prefix) {
    this.trigger('error', null, errorReason, this, prefix);
    this.destroy();
  };

  var _onIceConnectionStateChange = function() {
    if (_awaitingIceRestart && _peerConnection.iceConnectionStateIsConnected()) {
      _awaitingIceRestart = false;
      this.trigger('iceRestartSuccess');
    }
  };

  var _relayMessageToPeer = function(type, payload, uri) {
    if (!_hasRelayCandidates) {
      var extractCandidates = type === RaptorConstants.Actions.CANDIDATE ||
                              type === RaptorConstants.Actions.OFFER ||
                              type === RaptorConstants.Actions.ANSWER ||
                              type === RaptorConstants.Actions.PRANSWER;

      if (extractCandidates) {
        var message = (
          type === RaptorConstants.Actions.CANDIDATE ?
          payload.candidate :
          payload.sdp
        );

        _hasRelayCandidates = message.indexOf('typ relay') !== -1;
      }
    }

    switch (type) {
      case RaptorConstants.Actions.ANSWER:
      case RaptorConstants.Actions.PRANSWER:
        if (session.sessionInfo.p2pEnabled) {
          session._.jsepAnswerP2p(streamId, _subscriberId, payload.sdp);
        } else {
          session._.jsepAnswer(streamId, payload.sdp);
        }

        break;

      case RaptorConstants.Actions.OFFER:
        this.trigger('connected');
        session._.jsepOffer(uri, payload.sdp);

        break;

      case RaptorConstants.Actions.CANDIDATE:
        if (session.sessionInfo.p2pEnabled) {
          session._.jsepCandidateP2p(streamId, _subscriberId, payload);

        } else {
          session._.jsepCandidate(streamId, payload);
        }
        break;

      default:
    }
  }.bind(this);

  var _onQOS = function _onQOS(parsedStats, prevStats) {
    this.trigger('qos', remoteConnection, parsedStats, prevStats);
  }.bind(this);

  OTHelpers.eventing(this);

  /// Public API

  this.getDataChannel = function(label, options, completion) {
    _peerConnection.getDataChannel(label, options, completion);
  };

  this.destroy = function() {
    // Clean up our PeerConnection
    if (_peerConnection) {
      _peerConnection.off();
      PeerConnections.remove(remoteConnection, streamId);
    }

    _peerConnection = null;
  };

  this.processMessage = function(type, message) {
    _peerConnection.processMessage(type, message);
  };

  // Init
  this.init = function(iceServers, completion) {
    var pcConfig = {
      iceServers: iceServers,
      channels: channels,
      numberOfSimulcastStreams: numberOfSimulcastStreams
    };

    setCertificates(pcConfig, function(err, pcConfigWithCerts) {
      if (err) {
        completion(err);
        return;
      }

      _peerConnection = PeerConnections.add(remoteConnection, streamId, pcConfigWithCerts);

      _peerConnection.on({
        close: _onPeerClosed,
        error: _onPeerError,
        qos: _onQOS,
        iceConnectionStateChange: _onIceConnectionStateChange
      }, _publisherPeerConnection);

      _peerConnection.registerMessageDelegate(_relayMessageToPeer);
      _peerConnection.addLocalStream(webRTCStream);

      completion(undefined, _publisherPeerConnection);
    });

    this.remoteConnection = function() {
      return remoteConnection;
    };

    this.hasRelayCandidates = function() {
      return _hasRelayCandidates;
    };
  };

  this.getSenders = function() {
    return _peerConnection.getSenders();
  };

  this.createOfferWithIceRestart = function() {
    _awaitingIceRestart = true;
    return _peerConnection.createOfferWithIceRestart(subscriberUri);
  };

  this.iceConnectionStateIsConnected = function() {
    return _peerConnection.iceConnectionStateIsConnected();
  };
};
