'use strict';

var analytics = require('./analytics.js');
var APIKEY = require('./api_key.js');
var Events = require('./events.js');
var OTHelpers = require('@opentok/ot-helpers');
var logging = require('./logging.js');

/**
 * The Error class is used to define the error object passed into completion handlers.
 * Each of the following methods, which execute asynchronously, includes a
 * <code>completionHandler</code> parameter:
 *
 * <ul>
 *   <li><a href="Session.html#connect">Session.connect()</a></li>
 *   <li><a href="Session.html#forceDisconnect">Session.forceDisconnect()</a></li>
 *   <li><a href="Session.html#forceUnpublish">Session.forceUnpublish()</a></li>
 *   <li><a href="Session.html#publish">Session.publish()</a></li>
 *   <li><a href="Session.html#subscribe">Session.subscribe()</a></li>
 *   <li><a href="OT.html#initPublisher">OT.initPublisher()</a></li>
 *   <li><a href="OT.html#reportIssue">OT.reportIssue()</a></li>
 * </ul>
 *
 * <p>
 * The <code>completionHandler</code> parameter is a function that is called when the call to
 * the asynchronous method succeeds or fails. If the asynchronous call fails, the completion
 * handler function is passes an error object (defined by the Error class). The <code>code</code>
 * and <code>message</code> properties of the error object provide details about the error.
 *
 * @property {Number} code The error code, defining the error.
 *
 *   <p>
 *   In the event of an error, the <code>code</code> value of the <code>error</code> parameter can
 *   have one of the following values:
 * </p>
 *
 * <p>Errors when calling <code>Session.connect()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1004</td>
 *   <td>Authentication error. Check the error message for details. This error can result if you
 *     in an expired token when trying to connect to a session. It can also occur if you pass
 *     in an invalid token or API key. Make sure that you are generating the token using the
 *     current version of one of the
 *     <a href="http://tokbox.com/opentok/libraries/server">OpenTok server SDKs</a>.</td>
 * </tr>
 * <tr>
 *   <td>1005</td>
 *   <td>Invalid Session ID. Make sure you generate the session ID using the current version of
 *     one of the <a href="http://tokbox.com/opentok/libraries/server">OpenTok server
 *     SDKs</a>.</td>
 * </tr>
 * <tr>
 *   <td>1006</td>
 *   <td>Connect Failed. Unable to connect to the session. You may want to have the client check
 *     the network connection.</td>
 * </tr>
 * <tr>
 *  <td>1026</td>
 *  <td>Terms of service violation: export compliance. See the
 *    <a href="https://tokbox.com/support/tos">Terms of Service</a>.</td>
 * </tr>
 * <tr>
 *   <td>2001</td>
 *   <td>Connect Failed. Unexpected response from the OpenTok server. Try connecting again
 *     later.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>Session.forceDisconnect()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b>
 *       <code>code</code>
 *     </b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1010</td>
 *   <td>The client is not connected to the OpenTok session. Check that client connects
 *     successfully and has not disconnected before calling forceDisconnect().</td>
 * </tr>
 * <tr>
 *   <td>1520</td>
 *   <td>Unable to force disconnect. The client's token does not have the role set to moderator.
 *     Once the client has connected to the session, the <code>capabilities</code> property of
 *     the Session object lists the client's capabilities.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>Session.forceUnpublish()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1010</td>
 *   <td>The client is not connected to the OpenTok session. Check that client connects
 *     successfully and has not disconnected before calling forceUnpublish().</td>
 * </tr>
 * <tr>
 *   <td>1530</td>
 *   <td>Unable to force unpublish. The client's token does not have the role set to moderator.
 *     Once the client has connected to the session, the <code>capabilities</code> property of
 *     the Session object lists the client's capabilities.</td>
 * </tr>
 * <tr>
 *   <td>1535</td>
 *   <td>Force Unpublish on an invalid stream. Make sure that the stream has not left the
 *     session before you call the <code>forceUnpublish()</code> method.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>Session.publish()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1010</td>
 *   <td>The client is not connected to the OpenTok session. Check that the client connects
 *     successfully before trying to publish. And check that the client has not disconnected
 *     before trying to publish.</td>
 * </tr>
 * <tr>
 *   <td>1500</td>
 *   <td>Unable to Publish. The client's token does not have the role set to to publish or
 *     moderator. Once the client has connected to the session, the <code>capabilities</code>
 *     property of the Session object lists the client's capabilities.</td>
 * </tr>
 * <tr>
 *   <td>1553</td>
 *   <td>WebRTC ICE workflow error. Try publishing again or reconnecting to the session.</td>
 * </tr>
 * <tr>
 *   <td>1601</td>
 *   <td>Internal error -- WebRTC publisher error. Try republishing or reconnecting to the
 *     session.</td>
 * </tr>
 * <tr>
 *   <td>2001</td>
 *   <td>Publish Failed. Unexpected response from the OpenTok server. Try publishing again
 *     later.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>Session.signal()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *     <td>400</td>
 *     <td>One of the signal properties &mdash; data, type, or to &mdash;
 *                 is invalid. Or the data cannot be parsed as JSON.</td>
 * </tr>
 * <tr>
 *   <td>404</td> <td>The to connection does not exist.</td>
 * </tr>
 * <tr>
 *   <td>413</td> <td>The type string exceeds the maximum length (128 bytes),
 *     or the data string exceeds the maximum size (8 kB).</td>
 * </tr>
 * <tr>
 *   <td>500</td>
 *   <td>The client is not connected to the OpenTok session. Check that the client connects
 *     successfully before trying to signal. And check that the client has not disconnected before
 *     trying to publish.</td>
 * </tr>
 * <tr>
 *   <td>2001</td>
 *   <td>Signal Failed. Unexpected response from the OpenTok server. Try sending the signal again
 *     later.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>Session.subscribe()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b>
 *       <code>code</code>
 *     </b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1013</td>
 *   <td>WebRTC PeerConnection error. Try resubscribing to the stream or
 *     reconnecting to the session.</td>
 * </tr>
 * <tr>
 *   <td>1554</td>
 *   <td>WebRTC ICE workflow error. Try resubscribing to the stream or
 *     reconnecting to the session.</td>
 * </tr>
 * <tr>
 *   <td>1600</td>
 *   <td>Internal error -- WebRTC subscriber error. Try resubscribing to the stream or
 *     reconnecting to the session.</td>
 * </tr>
 * <tr>
 *   <td>2001</td>
 *   <td>Subscribe Failed. Unexpected response from the OpenTok server. Try subscribing again
 *     later.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>OT.initPublisher()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1004</td>
 *   <td>Authentication error. Check the error message for details. This error can result if you
 *     pass in an expired token when trying to connect to a session. It can also occur if you
 *     pass in an invalid token or API key. Make sure that you are generating the token using
 *     the current version of one of the
 *     <a href="http://tokbox.com/opentok/libraries/server">OpenTok server SDKs</a>.</td>
 * </tr>
 * <tr>
 *   <td>1550</td>
 *   <td>Screen sharing is not supported (and you set the <code>videoSource</code> property
 *     of the <code>options</code> parameter of <code>OT.initPublisher()</code> to
 *     <code>"application"</code>, <code>"screen"</code>, or <code>"window"</code>).
 *     Before calling <code>OT.initPublisher()</code>, you can call
 *     <a href="OT.html#checkScreenSharingCapability">OT.checkScreenSharingCapability()</a>
 *     to check if screen sharing is supported.</td>
 * </tr>
 * <tr>
 *   <td>1551</td>
 *   <td>A screen sharing extension needs to be registered but it is not. This error can occur
 *     when you set the <code>videoSource</code> property of the <code>options</code> parameter
 *     of <code>OT.initPublisher()</code> to <code>"application"</code>, <code>"screen"</code>,
 *     or <code>"window"</code>. Before calling <code>OT.initPublisher()</code>, you can call
 *     <a href="OT.html#checkScreenSharingCapability">OT.checkScreenSharingCapability()</a>
 *     to check if screen sharing requires an extension to be registered.</td>
 * </tr>
 * <tr>
 *   <td>1552</td>
 *   <td>A screen sharing extension is required, but it is not installed. This error can occur
 *     when you set the <code>videoSource</code> property of the <code>options</code> parameter
 *     of <code>OT.initPublisher()</code> to <code>"screen"</code>. Before calling
 *     <code>OT.initPublisher()</code>, you can call
 *     <a href="OT.html#checkScreenSharingCapability">OT.checkScreenSharingCapability()</a>
 *     to check if screen sharing requires an extension to be installed.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>OT.initPublisher()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>2011</td>
 *   <td>Error calling OT.reportIssue(). Check the client's network connection.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>General errors that can occur when calling any method:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1011</td>
 *   <td>Invalid Parameter. Check that you have passed valid parameter values into the method
 *     call.</td>
 * </tr>
 * <tr>
 *   <td>2000</td>
 *   <td>Internal Error. Try reconnecting to the OpenTok session and trying the action again.</td>
 * </tr>
 * </tbody></table>
 *
 * @property {String} message The message string provides details about the error.
 *
 * @class Error
 * @augments Event
 */

var OTError = function(code, message) {
  this.code = code;
  this.message = message;
};

module.exports = OTError;

OTHelpers.eventing(OTError);

var errorsCodesToTitle = {
  1004: 'Authentication error',
  1005: 'Invalid Session ID',
  1006: 'Connect Failed',
  1007: 'Connect Rejected',
  1008: 'Connect Time-out',
  1009: 'Security Error',
  1010: 'Not Connected',
  1011: 'Invalid Parameter',
  1012: 'Peer-to-peer Stream Play Failed',
  1013: 'Connection Failed',
  1014: 'API Response Failure',
  1015: 'PeerConnection not connected, cannot call this method',
  1021: 'Request Timeout',
  1026: 'Terms of Service Violation: Export Compliance',
  1500: 'Unable to Publish',
  1503: 'No TURN server found',
  1520: 'Unable to Force Disconnect',
  1530: 'Unable to Force Unpublish',
  1553: 'ICEWorkflow failed',
  1600: 'createOffer, createAnswer, setLocalDescription, setRemoteDescription',
  2000: 'Internal Error',
  2001: 'Unexpected Server Response',
  4000: 'WebSocket Connection Failed',
  4001: 'WebSocket Network Disconnected'
};

function _exceptionHandler(component, msg, errorCode, context) {
  var title = OTError.getTitleByCode(errorCode);
  var contextCopy = context ? OTHelpers.clone(context) : {};

  logging.error('OT.exception :: title: ' + title + ' (' + errorCode + ') msg: ' + msg);

  if (!contextCopy.partnerId) { contextCopy.partnerId = APIKEY.value; }

  try {
    analytics.logError(errorCode, 'tb.exception', title, { details: msg }, contextCopy);

    OTError.dispatchEvent(
      new Events.ExceptionEvent(
        Events.Event.names.EXCEPTION,
        msg,
        title,
        errorCode,
        component,
        component
      )
    );
  } catch (err) {
    logging.error('OT.exception :: Failed to dispatch exception - ' + err.toString());
    // Don't throw an error because this is asynchronous
    // don't do an exceptionHandler because that would be recursive
  }
}

/**
 * Get the title of an error by error code
 *
 * @property {Number|String} code The error code to lookup
 * @return {String} The title of the message with that code
 *
 * @example
 *
 * OTError.getTitleByCode(1006) === 'Connect Failed'
 */
OTError.getTitleByCode = function(code) {
  return errorsCodesToTitle[+code];
};

// @todo redo this when we have time to tidy up
//
// @example
//
//  OTError.handleJsException("Descriptive error message", 2000, {
//      session: session,
//      target: stream|publisher|subscriber|session|etc
//  });
//
OTError.handleJsException = function(errorMsg, code, options) {
  options = options || {};

  var session = options.session;
  var context;

  if (session) {
    context = {
      sessionId: session.sessionId
    };

    if (session.isConnected()) { context.connectionId = session.connection.connectionId; }
    if (!options.target) { options.target = session; }

  } else if (options.sessionId) {
    context = {
      sessionId: options.sessionId
    };

    if (!options.target) { options.target = null; }
  }

  _exceptionHandler(options.target, errorMsg, code, context);
};
