'use strict';

/*
 * A <code>RTCPeerConnection.getStats</code> based audio level sampler.
 *
 * It uses the the <code>getStats</code> method to get the <code>audioOutputLevel</code>.
 * This implementation expects the single parameter version of the <code>getStats</code> method.
 *
 * Currently the <code>audioOutputLevel</code> stats is only supported in Chrome.
 *
 * @param {function} getStatsFn the "getStats" function
 * @constructor
 */
module.exports = function GetstatsAudioOutputLevelSampler(getStatsFn) {

  /*
   * Acquires the audio level.
   *
   * @param {function(?number)} done a callback to be called with the acquired value in the
   * [0, 1] range when available or <code>null</code> if no value could be acquired
   */
  this.sample = function(done) {
    getStatsFn(function(error, stats) {
      if (!error) {
        for (var idx = 0; idx < stats.length; idx++) {
          var stat = stats[idx];
          var audioOutputLevel = parseFloat(stat.audioOutputLevel);
          if (!isNaN(audioOutputLevel)) {
            // the mex value delivered by getStats for audio levels is 2^15
            done(audioOutputLevel / 32768);
            return;
          }
        }
      }

      done(null);
    });
  };
};
