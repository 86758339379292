'use strict';

var Bluebird = require('bluebird');
require('./web_rtc_polyfills.js');

// Web OT Helpers
//
var OTPlugin = require('@opentok/otplugin.js');
var OTHelpers = require('@opentok/ot-helpers');
var logging = require('../ot/logging.js');
var throttleUntilComplete = require('./throttle_until_complete.js');

var nativeGetUserMedia,
    getUserMedia,
    throttledGetUserMedia,
    vendorToW3CErrors,
    gumNamesToMessages,
    mapVendorErrorName,
    parseErrorEvent;

// Handy cross-browser getUserMedia shim. Inspired by some code from Adam Barth
nativeGetUserMedia = (function() {
  if (global.navigator.getUserMedia) {
    return global.navigator.getUserMedia.bind(global.navigator);
  } else if (global.navigator.mediaDevices && global.navigator.mediaDevices.getUserMedia) {
    return function(constraints, onStream, onError) {
      global.navigator.mediaDevices.getUserMedia(constraints).then(onStream, onError);
    };
  } else if (global.navigator.mozGetUserMedia) {
    return global.navigator.mozGetUserMedia.bind(global.navigator);
  } else if (global.navigator.webkitGetUserMedia) {
    return global.navigator.webkitGetUserMedia.bind(global.navigator);
  } else if (OTPlugin.isInstalled()) {
    return OTPlugin.getUserMedia.bind(OTPlugin);
  }
})();

// Convert nativeGetUserMedia to use promises
var promisifiedGetUserMedia = function(constraints) {
  return new Bluebird.Promise(function(resolve, reject) {
    nativeGetUserMedia(constraints, resolve, reject);
  });
};

// This waits to call getUserMedia() until after the access allowed db is closed
throttledGetUserMedia = throttleUntilComplete(promisifiedGetUserMedia);

// Mozilla error strings and the equivalent W3C names. NOT_SUPPORTED_ERROR does not
// exist in the spec right now, so we'll include Mozilla's error description.
// Chrome TrackStartError is triggered when the camera is already used by another app (Windows)
vendorToW3CErrors = {
  PERMISSION_DENIED: 'PermissionDeniedError',
  SecurityError: 'PermissionDeniedError',
  NOT_SUPPORTED_ERROR: 'NotSupportedError',
  MANDATORY_UNSATISFIED_ERROR: ' ConstraintNotSatisfiedError',
  NO_DEVICES_FOUND: 'NoDevicesFoundError',
  HARDWARE_UNAVAILABLE: 'HardwareUnavailableError',
  TrackStartError: 'HardwareUnavailableError'
};

gumNamesToMessages = {
  PermissionDeniedError: 'End-user denied permission to hardware devices',
  PermissionDismissedError: 'End-user dismissed permission to hardware devices',
  NotSupportedError: 'A constraint specified is not supported by the browser.',
  ConstraintNotSatisfiedError: 'It\'s not possible to satisfy one or more constraints ' +
    'passed into the getUserMedia function',
  OverconstrainedError: 'Due to changes in the environment, one or more mandatory ' +
    'constraints can no longer be satisfied.',
  NoDevicesFoundError: 'No voice or video input devices are available on this machine.',
  HardwareUnavailableError: 'The selected voice or video devices are unavailable. Verify ' +
    'that the chosen devices are not in use by another application.'
};

// Map vendor error strings to names and messages if possible
mapVendorErrorName = function mapVendorErrorName(vendorErrorName, vendorErrors) {
  var errorName, errorMessage;

  if (vendorErrors.hasOwnProperty(vendorErrorName)) {
    errorName = vendorErrors[vendorErrorName];
  } else {
    // This doesn't map to a known error from the Media Capture spec, it's
    // probably a custom vendor error message.
    errorName = vendorErrorName;
  }

  if (gumNamesToMessages.hasOwnProperty(errorName)) {
    errorMessage = gumNamesToMessages[errorName];
  } else {
    errorMessage = 'Unknown Error while getting user media';
  }

  return {
    name: errorName,
    message: errorMessage
  };
};

// Parse and normalise a getUserMedia error event from Chrome or Mozilla
// @ref http://dev.w3.org/2011/webrtc/editor/getusermedia.html#idl-def-NavigatorUserMediaError
parseErrorEvent = function parseErrorObject(event) {
  var error;

  if (OTHelpers.isObject(event) && event.name) {
    error = mapVendorErrorName(event.name, vendorToW3CErrors);
    error.constraintName = event.constraintName;
  } else if (typeof event === 'string') {
    error = mapVendorErrorName(event, vendorToW3CErrors);
  } else {
    error = {
      message: 'Unknown Error type while getting media'
    };
  }

  return error;
};

// Validates a Hash of getUserMedia constraints. Currently we only
// check to see if there is at least one non-false constraint.
var areInvalidConstraints = function(constraints) {
  if (!constraints || !OTHelpers.isObject(constraints)) { return true; }

  for (var key in constraints) {
    if (!constraints.hasOwnProperty(key)) {
      continue;
    }
    if (constraints[key]) { return false; }
  }

  return true;
};

// A wrapper for the builtin navigator.getUserMedia. In addition to the usual
// getUserMedia behaviour, this helper method also accepts a accessDialogOpened
// and accessDialogClosed callback.
//
// @memberof OTHelpers
// @private
//
// @param {Object} constraints
//      A dictionary of constraints to pass to getUserMedia. See
//      http://dev.w3.org/2011/webrtc/editor/getusermedia.html#idl-def-MediaStreamConstraints
//      in the Media Capture and Streams spec for more info.
//
// @param {function} success
//      Called when getUserMedia completes successfully. The callback will be passed a WebRTC
//      Stream object.
//
// @param {function} failure
//      Called when getUserMedia fails to access a user stream. It will be passed an object
//      with a code property representing the error that occurred.
//
// @param {function} accessDialogOpened
//      Called when the access allow/deny dialog is opened.
//
// @param {function} accessDialogClosed
//      Called when the access allow/deny dialog is closed.
//
// @param {function} accessDenied
//      Called when access is denied to the camera/mic. This will be either because
//      the user has clicked deny or because a particular origin is permanently denied.
//
module.exports = function(constraints, success, failure, accessDialogOpened,
  accessDialogClosed, accessDenied, customGetUserMedia) {

  getUserMedia = throttledGetUserMedia;

  if (OTHelpers.isFunction(customGetUserMedia)) {
    getUserMedia = customGetUserMedia;
  }

  // All constraints are false, we don't allow this. This may be valid later
  // depending on how/if we integrate data channels.
  if (areInvalidConstraints(constraints)) {
    logging.error('Couldn\'t get UserMedia: All constraints were false');
    // Using a ugly dummy-code for now.
    failure.call(null, {
      name: 'NO_VALID_CONSTRAINTS',
      message: 'Video and Audio was disabled, you need to enabled at least one'
    });

    return;
  }

  var triggerOpenedTimer = null;
  var displayedPermissionDialog = false;

  var finaliseAccessDialog = function() {
    if (triggerOpenedTimer) {
      clearTimeout(triggerOpenedTimer);
    }

    if (displayedPermissionDialog && accessDialogClosed) { accessDialogClosed(); }
  };

  var triggerOpened = function() {
    triggerOpenedTimer = null;
    displayedPermissionDialog = true;

    if (accessDialogOpened) { accessDialogOpened(); }
  };

  var onStream = function(stream) {
    finaliseAccessDialog();
    success.call(null, stream);
  };

  var onError = function(event) {
    finaliseAccessDialog();
    var error = parseErrorEvent(event);

    // The error name 'PERMISSION_DENIED' is from an earlier version of the spec
    if (error.name === 'PermissionDeniedError' || error.name === 'PermissionDismissedError') {
      accessDenied.call(null, error);
    } else {
      failure.call(null, error);
    }
  };

  if (customGetUserMedia) {
    getUserMedia(constraints, onStream, onError);
  } else {
    getUserMedia(constraints).then(onStream)['catch'](onError);
  }

  // The 'remember me' functionality of WebRTC only functions over HTTPS, if
  // we aren't on HTTPS then we should definitely be displaying the access
  // dialog.
  //
  // If we are on HTTPS, we'll wait 500ms to see if we get a stream
  // immediately. If we do then the user had clicked 'remember me'. Otherwise
  // we assume that the accessAllowed dialog is visible.
  //
  // @todo benchmark and see if 500ms is a reasonable number. It seems like
  // we should know a lot quicker.
  //
  if (location.protocol.indexOf('https') === -1) {
    // Execute after, this gives the client a chance to bind to the
    // accessDialogOpened event.
    triggerOpenedTimer = setTimeout(triggerOpened, 100);

  } else {
    // wait a second and then trigger accessDialogOpened
    triggerOpenedTimer = setTimeout(triggerOpened, 500);
  }
};
