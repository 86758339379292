'use strict';

var util = require('./util');

var reqAnimationFrame = global.requestAnimationFrame ||
                        global.mozRequestAnimationFrame ||
                        global.webkitRequestAnimationFrame ||
                        global.msRequestAnimationFrame;

if (reqAnimationFrame) {
  reqAnimationFrame = reqAnimationFrame.bind(global);
} else {
  var lastTime = 0;
  var startTime = util.now();

  reqAnimationFrame = function(callback){
    var currTime = util.now();
    var timeToCall = Math.max(0, 16 - (currTime - lastTime));
    var id = global.setTimeout(function() { callback(currTime - startTime); }, timeToCall);
    lastTime = currTime + timeToCall;
    return id;
  };
}

module.exports = reqAnimationFrame;
