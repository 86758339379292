'use strict';

var $ = require('./elementCollection/shorthandSelector');
var env = require('./env');
var eventing = require('./behaviours/eventing');
var util = require('./util');
var uuid = require('uuid');

module.exports = function Modal(options) {
  eventing(this, true);

  var callback = arguments[arguments.length - 1];

  if(!util.isFunction(callback)) {
    throw new Error('OTHelpers.Modal2 must be given a callback');
  }

  if(arguments.length < 2) {
    options = {};
  }

  var domElement = document.createElement('iframe');

  domElement.id = options.id || uuid();
  domElement.style.position = 'absolute';
  domElement.style.position = 'fixed';
  domElement.style.height = '100%';
  domElement.style.width = '100%';
  domElement.style.top = '0px';
  domElement.style.left = '0px';
  domElement.style.right = '0px';
  domElement.style.bottom = '0px';
  domElement.style.zIndex = 1000;
  domElement.style.border = '0';

  try {
    domElement.style.backgroundColor = 'rgba(0,0,0,0.2)';
  } catch (err) {
    // Old IE browsers don't support rgba and we still want to show the upgrade message
    // but we just make the background of the iframe completely transparent.
    domElement.style.backgroundColor = 'transparent';
    domElement.setAttribute('allowTransparency', 'true');
  }

  domElement.scrolling = 'no';
  domElement.setAttribute('scrolling', 'no');

  // This is necessary for IE, as it will not inherit it's doctype from
  // the parent frame.
  var frameContent = '<!DOCTYPE html><html><head>' +
                    '<meta http-equiv="x-ua-compatible" content="IE=Edge">' +
                    '<meta http-equiv="Content-type" content="text/html; charset=utf-8">' +
                    '<title></title></head><body></body></html>';

  var wrappedCallback = function() {
    var doc = domElement.contentDocument || domElement.contentWindow.document;

    if (env.iframeNeedsLoad) {
      doc.body.style.backgroundColor = 'transparent';
      doc.body.style.border = 'none';

      if (env.name !== 'IE') {
        // Skip this for IE as we use the bookmarklet workaround
        // for THAT browser.
        doc.open();
        doc.write(frameContent);
        doc.close();
      }
    }

    callback(
      domElement.contentWindow,
      doc
    );
  };

  document.body.appendChild(domElement);

  if (env.iframeNeedsLoad) {
    if (env.name === 'IE') {
      // This works around some issues with IE and document.write.
      // Basically this works by slightly abusing the bookmarklet/scriptlet
      // functionality that all browsers support.
      domElement.contentWindow.contents = frameContent;
      /*jshint scripturl:true*/
      domElement.src = 'javascript:window["contents"]';
      /*jshint scripturl:false*/
    }

    $(domElement).on('load', wrappedCallback);
  } else {
    setTimeout(wrappedCallback, 0);
  }

  this.close = function() {
    $(domElement).remove();
    this.trigger('closed');
    this.element = domElement = null;
    return this;
  };

  this.element = domElement;
};
