'use strict';

var PeerConnection = require('./peer_connection.js');

var connections = {};

var PeerConnections = {};
module.exports = PeerConnections;

PeerConnections.add = function(remoteConnection, streamId, config) {
  var key = remoteConnection.id + '_' + streamId;
  var ref = connections[key];

  if (!ref) {
    ref = connections[key] = {
      count: 0,
      pc: new PeerConnection(config)
    };
  }

  // increase the PCs ref count by 1
  ref.count += 1;

  return ref.pc;
};

PeerConnections.remove = function(remoteConnection, streamId) {
  var key = remoteConnection.id + '_' + streamId;
  var ref = connections[key];

  if (ref) {
    ref.count -= 1;

    if (ref.count === 0) {
      ref.pc.disconnect();
      delete connections[key];
    }
  }
};
