'use strict';

var OTHelpers = require('@opentok/ot-helpers');

/*
 * An <code>AudioContext</code> based audio level sampler. It returns the maximum value in the
 * last 1024 samples.
 *
 * It is worth noting that the remote <code>MediaStream</code> audio analysis is currently only
 * available in FF.
 *
 * This implementation gracefully handles the case where the <code>MediaStream</code> has not
 * been set yet by returning a <code>null</code> value until the stream is set. It is up to the
 * call site to decide what to do with this value (most likely ignore it and retry later).
 *
 * @constructor
 * @param {AudioContext} audioContext an audio context instance to get an analyser node
 */
module.exports = function WebaudioAudioLevelSampler(audioContext) {

  var _sampler = this;
  var _analyser = null;
  var _timeDomainData = null;
  var _webRTCStream = null;

  var buildAnalyzer = function(stream) {
    var sourceNode = audioContext.createMediaStreamSource(stream);
    var analyser = audioContext.createAnalyser();
    sourceNode.connect(analyser);
    return analyser;
  };

  OTHelpers.defineProperties(_sampler, {
    webRTCStream: {
      get: function() {
        return _webRTCStream;
      },
      set: function(webRTCStream) {
        // when the stream is updated we need to create a new analyzer
        _webRTCStream = webRTCStream;
        _analyser = buildAnalyzer(_webRTCStream);
        _timeDomainData = new Uint8Array(_analyser.frequencyBinCount);
      }
    }
  });

  this.sample = function(done) {
    if (_analyser) {
      _analyser.getByteTimeDomainData(_timeDomainData);

      // varies from 0 to 255
      var max = 0;
      for (var idx = 0; idx < _timeDomainData.length; idx++) {
        max = Math.max(max, Math.abs(_timeDomainData[idx] - 128));
      }

      // normalize the collected level to match the range delivered by
      // the getStats' audioOutputLevel
      done(max / 128);
    } else {
      done(null);
    }
  };
};
