'use strict';

var makeEverythingAttachToOTHelpers = require('./makeEverythingAttachToOTHelpers');

var callbacks = {};
module.exports = callbacks;

// Calls the function +fn+ asynchronously with the current execution.
// This is most commonly used to execute something straight after
// the current function.
//
// Any arguments in addition to +fn+ will be passed to +fn+ when it's
// called.
//
// You would use this inplace of setTimeout(fn, 0) type constructs. callAsync
// is preferable as it executes in a much more predictable time global,
// unlike setTimeout which could execute anywhere from 2ms to several thousand
// depending on the browser/context.
//
// It does this using global.postMessage, although if postMessage won't
// work it will fallback to setTimeout.
//
callbacks.callAsync = function (/* fn, [arg1, arg2, ..., argN] */) {
  var args = Array.prototype.slice.call(arguments);
  var fn = args.shift();

  setTimeout(function() {
    fn.apply(null, args);
  }, 0);
};

// Wraps +handler+ in a function that will execute it asynchronously
// so that it doesn't interfere with it's exceution context if it raises
// an exception.
callbacks.createAsyncHandler = function(handler) {
  return function() {
    var args = Array.prototype.slice.call(arguments);

    callbacks.callAsync(function() {
      handler.apply(null, args);
    });
  };
};

makeEverythingAttachToOTHelpers(callbacks);
