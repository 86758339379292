'use strict';

var Capabilities = require('./capabilities.js');
var Events = require('./events.js');
var OTHelpers = require('@opentok/ot-helpers');

/**
 * The Connection object represents a connection to an OpenTok session. Each client that connects
 * to a session has a unique connection, with a unique connection ID (represented by the
 * <code>id</code> property of the Connection object for the client).
 * <p>
 * The Session object has a <code>connection</code> property that is a Connection object.
 * It represents the local client's connection. (A client only has a connection once the
 * client has successfully called the <code>connect()</code> method of the {@link Session}
 * object.)
 * <p>
 * The Session object dispatches a <code>connectionCreated</code> event when each client (including
 * your own) connects to a session (and for clients that are present in the session when you
 * connect). The <code>connectionCreated</code> event object has a <code>connection</code>
 * property, which is a Connection object corresponding to the client the event pertains to.
 * <p>
 * The Stream object has a <code>connection</code> property that is a Connection object.
 * It represents the connection of the client that is publishing the stream.
 *
 * @class Connection
 * @property {String} connectionId The ID of this connection.
 * @property {Number} creationTime The timestamp for the creation of the connection. This
 * value is calculated in milliseconds.
 * You can convert this value to a Date object by calling <code>new Date(creationTime)</code>,
 * where <code>creationTime</code>
 * is the <code>creationTime</code> property of the Connection object.
 * @property {String} data A string containing metadata describing the
 * connection. When you generate a user token string pass the connection data string to the
 * <code>generate_token()</code> method of our
 * <a href="https://tokbox.com/opentok/libraries/server/">server-side libraries</a>. You can
 * also generate a token and define connection data on the
 * <a href="https://dashboard.tokbox.com/projects">Dashboard</a> page.
 */
var Connection = function(id, creationTime, data, capabilitiesHash, permissionsHash) {
  var destroyedReason;

  this.id = this.connectionId = id;
  this.creationTime = creationTime ? Number(creationTime) : null;
  this.data = data;
  this.capabilities = new Connection.Capabilities(capabilitiesHash);
  this.permissions = new Capabilities(permissionsHash);
  this.quality = null;

  OTHelpers.eventing(this);

  this.destroy = function(reason, quiet) {
    destroyedReason = reason || 'clientDisconnected';

    if (quiet !== true) {
      this.dispatchEvent(
        new Events.DestroyedEvent(
          // This should be Events.Event.names.CONNECTION_DESTROYED, but
          // the value of that is currently shared with Session
          'destroyed',
          this,
          destroyedReason
        )
      );
    }
  }.bind(this);

  this.destroyed = function() {
    return destroyedReason !== void 0;
  };

  this.destroyedReason = function() {
    return destroyedReason;
  };

};

Connection.fromHash = function(hash) {
  return new Connection(
    hash.id,
    hash.creationTime,
    hash.data,
    OTHelpers.extend(hash.capablities || {}, { supportsWebRTC: true }),
    hash.permissions || []
  );
};

// TODO: This is weird and feels like it shouldn't exist.
Connection.Capabilities = function(capabilitiesHash) {
  // Private helper methods
  var castCapabilities = function(capabilitiesHash) {
    capabilitiesHash.supportsWebRTC = OTHelpers.castToBoolean(capabilitiesHash.supportsWebRTC);
    return capabilitiesHash;
  };

  // Private data
  var _caps = castCapabilities(capabilitiesHash);
  this.supportsWebRTC = _caps.supportsWebRTC;
};

module.exports = Connection;
