'use strict';

var OTHelpers = require('@opentok/ot-helpers');
var logging = require('../logging.js');

// @meta: ping Mike/Eric to let them know that this data is coming and what format it will be
// @meta: what reports would I like around this, what question am I trying to answer?
//
// log the sequence of iceconnectionstates, icegatheringstates, signalingstates
// log until we reach a terminal iceconnectionstate or signalingstate
// send a client event once we have a full sequence
//
// Format of the states:
//  [
//    { delta: 1234, iceConnection: 'new', signalingstate: 'stable', iceGathering: 'new'},
//    { delta: 1234, iceConnection: 'new', signalingstate: 'stable', iceGathering: 'new'},
//    { delta: 1234, iceConnection: 'new', signalingstate: 'stable', iceGathering: 'new'},
//  ]
//
// Format of the logged event:
//  {
//    startTime: 1234,
//    finishTime: 5678,
//    states: [
//      { delta: 1234, iceConnection: 'new', signalingstate: 'stable', iceGathering: 'new'},
//      { delta: 1234, iceConnection: 'new', signalingstate: 'stable', iceGathering: 'new'},
//      { delta: 1234, iceConnection: 'new', signalingstate: 'stable', iceGathering: 'new'},
//    ]
//  }
//
//
module.exports = function connectionStateLogger(pc) {
  var finishTime, suceeded;
  var startTime = OTHelpers.now();
  var states = [];

  var trackState = function() {
    var now = OTHelpers.now();
    var lastState = states[states.length - 1];
    var state = { delta: finishTime ? now - finishTime : 0 };

    if (!lastState || lastState.iceConnection !== pc.iceConnectionState) {
      state.iceConnectionState = pc.iceConnectionState;
    }

    if (!lastState || lastState.signalingState !== pc.signalingState) {
      state.signalingState = pc.signalingState;
    }

    if (!lastState || lastState.iceGatheringState !== pc.iceGatheringState) {
      state.iceGathering = pc.iceGatheringState;
    }
    logging.debug(state);
    states.push(state);
    finishTime = now;
  };

  pc.addEventListener('iceconnectionstatechange', trackState, false);
  pc.addEventListener('signalingstatechange', trackState, false);
  pc.addEventListener('icegatheringstatechange', trackState, false);

  return {
    stop: function() {
      pc.removeEventListener('iceconnectionstatechange', trackState, false);
      pc.removeEventListener('signalingstatechange', trackState, false);
      pc.removeEventListener('icegatheringstatechange', trackState, false);

      // @todo The client logging of these states is not currently used, so it's left todo.

      // @todo analyse final state and decide whether the connection was successful
      suceeded = true;

      var payload = {
        type: 'PeerConnectionWorkflow',
        success: suceeded,
        startTime: startTime,
        finishTime: finishTime,
        states: states
      };

      // @todo send client event
      logging.debug(payload);
    }
  };
};
