'use strict';

var OTHelpers = require('@opentok/ot-helpers');
var logging = require('./logging.js');

var Events = {};

/**
 * The Event object defines the basic OpenTok event object that is passed to
 * event listeners. Other OpenTok event classes implement the properties and methods of
 * the Event object.</p>
 *
 * <p>For example, the Stream object dispatches a <code>streamPropertyChanged</code> event when
 * the stream's properties are updated. You add a callback for an event using the
 * <code>on()</code> method of the Stream object:</p>
 *
 * <pre>
 * stream.on("streamPropertyChanged", function (event) {
 *     alert("Properties changed for stream " + event.target.streamId);
 * });</pre>
 *
 * @class Event
 * @property {Boolean} cancelable Whether the event has a default behavior that is cancelable
 * (<code>true</code>) or not (<code>false</code>). You can cancel the default behavior by
 * calling the <code>preventDefault()</code> method of the Event object in the callback
 * function. (See <a href="#preventDefault">preventDefault()</a>.)
 *
 * @property {Object} target The object that dispatched the event.
 *
 * @property {String} type  The type of event.
 */
Events.Event = OTHelpers.Event();
/**
* Prevents the default behavior associated with the event from taking place.
*
* <p>To see whether an event has a default behavior, check the <code>cancelable</code> property
* of the event object. </p>
*
* <p>Call the <code>preventDefault()</code> method in the callback function for the event.</p>
*
* <p>The following events have default behaviors:</p>
*
* <ul>
*
*   <li><code>sessionDisconnect</code> &#151; See
*   <a href="SessionDisconnectEvent.html#preventDefault">
*   SessionDisconnectEvent.preventDefault()</a>.</li>
*
*   <li><code>streamDestroyed</code> &#151; See <a href="StreamEvent.html#preventDefault">
*   StreamEvent.preventDefault()</a>.</li>
*
*   <li><code>accessDialogOpened</code> &#151; See the
*   <a href="Publisher.html#event:accessDialogOpened">accessDialogOpened event</a>.</li>
*
*   <li><code>accessDenied</code> &#151; See the <a href="Publisher.html#event:accessDenied">
*   accessDenied event</a>.</li>
*
* </ul>
*
* @method #preventDefault
* @memberof Event
*/
/**
* Whether the default event behavior has been prevented via a call to
* <code>preventDefault()</code> (<code>true</code>) or not (<code>false</code>).
* See <a href="#preventDefault">preventDefault()</a>.
* @method #isDefaultPrevented
* @return {Boolean}
* @memberof Event
*/

// Event names lookup
Events.Event.names = {
  // Activity Status for cams/mics
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  UNKNOWN: 'unknown',

  // Archive types
  PER_SESSION: 'perSession',
  PER_STREAM: 'perStream',

  // Events
  EXCEPTION: 'exception',
  ISSUE_REPORTED: 'issueReported',

  // Session Events
  SESSION_CONNECTED: 'sessionConnected',
  SESSION_RECONNECTING: 'sessionReconnecting',
  SESSION_RECONNECTED: 'sessionReconnected',
  SESSION_DISCONNECTED: 'sessionDisconnected',
  STREAM_CREATED: 'streamCreated',
  STREAM_DESTROYED: 'streamDestroyed',
  CONNECTION_CREATED: 'connectionCreated',
  CONNECTION_DESTROYED: 'connectionDestroyed',
  SIGNAL: 'signal',
  STREAM_PROPERTY_CHANGED: 'streamPropertyChanged',
  MICROPHONE_LEVEL_CHANGED: 'microphoneLevelChanged',

  // Publisher Events
  RESIZE: 'resize',
  SETTINGS_BUTTON_CLICK: 'settingsButtonClick',
  DEVICE_INACTIVE: 'deviceInactive',
  INVALID_DEVICE_NAME: 'invalidDeviceName',
  ACCESS_ALLOWED: 'accessAllowed',
  ACCESS_DENIED: 'accessDenied',
  ACCESS_DIALOG_OPENED: 'accessDialogOpened',
  ACCESS_DIALOG_CLOSED: 'accessDialogClosed',
  ECHO_CANCELLATION_MODE_CHANGED: 'echoCancellationModeChanged',
  MEDIA_STOPPED: 'mediaStopped',
  PUBLISHER_DESTROYED: 'destroyed',

  // Subscriber Events
  SUBSCRIBER_DESTROYED: 'destroyed',
  SUBSCRIBER_CONNECTED: 'connected',
  SUBSCRIBER_DISCONNECTED: 'disconnected',

  // DeviceManager Events
  DEVICES_DETECTED: 'devicesDetected',

  // DevicePanel Events
  DEVICES_SELECTED: 'devicesSelected',
  CLOSE_BUTTON_CLICK: 'closeButtonClick',

  MICLEVEL: 'microphoneActivityLevel',
  MICGAINCHANGED: 'microphoneGainChanged',

  // Environment Loader
  ENV_LOADED: 'envLoaded',
  ENV_UNLOADED: 'envUnloaded',

  // Audio activity Events
  AUDIO_LEVEL_UPDATED: 'audioLevelUpdated'
};

/**
* The {@link OT} class dispatches <code>exception</code> events when the OpenTok API encounters
* an exception (error). The ExceptionEvent object defines the properties of the event
* object that is dispatched.
*
* <p>Note that you set up a callback for the <code>exception</code> event by calling the
* <code>OT.on()</code> method.</p>
*
* @class ExceptionEvent
* @property {Number} code The error code. The following is a list of error codes:</p>
*
* <table class="docs_table">
*  <tbody><tr>
*   <td>
*   <b>code</b>
*
*   </td>
*   <td>
*   <b>title</b>
*   </td>
*  </tr>
*
*  <tr>
*   <td>
*   1004
*
*   </td>
*   <td>
*   Authentication error
*   </td>
*  </tr>
*
*  <tr>
*   <td>
*   1005
*
*   </td>
*   <td>
*   Invalid Session ID
*   </td>
*  </tr>
*  <tr>
*   <td>
*   1006
*
*   </td>
*   <td>
*   Connect Failed
*   </td>
*  </tr>
*  <tr>
*   <td>
*   1007
*
*   </td>
*   <td>
*   Connect Rejected
*   </td>
*  </tr>
*  <tr>
*   <td>
*   1008
*
*   </td>
*   <td>
*   Connect Time-out
*   </td>
*  </tr>
*  <tr>
*   <td>
*   1009
*
*   </td>
*   <td>
*   Security Error
*   </td>
*  </tr>
*   <tr>
*    <td>
*    1010
*
*    </td>
*    <td>
*    Not Connected
*    </td>
*   </tr>
*   <tr>
*    <td>
*    1011
*
*    </td>
*    <td>
*    Invalid Parameter
*    </td>
*   </tr>
*   <tr>
*    <td>
*    1013
*    </td>
*    <td>
*    Connection Failed
*    </td>
*   </tr>
*   <tr>
*    <td>
*    1014
*    </td>
*    <td>
*    API Response Failure
*    </td>
*   </tr>
*   <tr>
*    <td>
*    1026
*    </td>
*    <td>
*    Terms of Service Violation: Export Compliance
*    </td>
*   </tr>
*  <tr>
*    <td>
*    1500
*    </td>
*    <td>
*    Unable to Publish
*    </td>
*   </tr>
*
*  <tr>
*    <td>
*    1520
*    </td>
*    <td>
*    Unable to Force Disconnect
*    </td>
*   </tr>
*
*  <tr>
*    <td>
*    1530
*    </td>
*    <td>
*    Unable to Force Unpublish
*    </td>
*   </tr>
*  <tr>
*    <td>
*    1535
*    </td>
*    <td>
*    Force Unpublish on Invalid Stream
*    </td>
*   </tr>
*
*  <tr>
*    <td>
*    2000
*
*    </td>
*    <td>
*    Internal Error
*    </td>
*  </tr>
*
*  <tr>
*    <td>
*    2010
*
*    </td>
*    <td>
*    Report Issue Failure
*    </td>
*  </tr>
*
*
*  </tbody></table>
*
*  <p>Check the <code>message</code> property for more details about the error.</p>
*
* @property {String} message The error message.
*
* @property {Object} target The object that the event pertains to. For an
* <code>exception</code> event, this will be an object other than the OT object
* (such as a Session object or a Publisher object).
*
* @property {String} title The error title.
* @augments Event
*/
Events.ExceptionEvent = function(type, message, title, code, component, target) {
  Events.Event.call(this, type);

  this.message = message;
  this.title = title;
  this.code = code;
  this.component = component;
  this.target = target;
};

Events.IssueReportedEvent = function(type, issueId) {
  Events.Event.call(this, type);
  this.issueId = issueId;
};

// Triggered when the JS dynamic config and the DOM have loaded.
Events.EnvLoadedEvent = function(type) {
  Events.Event.call(this, type);
};

/**
 * Defines <code>connectionCreated</code> and <code>connectionDestroyed</code> events dispatched
 * by the {@link Session} object.
 * <p>
 * The Session object dispatches a <code>connectionCreated</code> event when a client (including
 * your own) connects to a Session. It also dispatches a <code>connectionCreated</code> event for
 * every client in the session when you first connect. (when your local client connects, the
 * Session object also dispatches a <code>sessionConnected</code> event, defined by the
 * {@link SessionConnectEvent} class.)
 * <p>
 * While you are connected to the session, the Session object dispatches a
 * <code>connectionDestroyed</code> event when another client disconnects from the Session.
 * (When you disconnect, the Session object also dispatches a <code>sessionDisconnected</code>
 * event, defined by the {@link SessionDisconnectEvent} class.)
 *
 * <h5><a href="example"></a>Example</h5>
 *
 * <p>The following code keeps a running total of the number of connections to a session
 * by monitoring the <code>connections</code> property of the <code>sessionConnect</code>,
 * <code>connectionCreated</code> and <code>connectionDestroyed</code> events:</p>
 *
 * <pre>var apiKey = ""; // Replace with your API key. See https://dashboard.tokbox.com/projects
 * var sessionID = ""; // Replace with your own session ID.
 *                     // See https://dashboard.tokbox.com/projects
 * var token = ""; // Replace with a generated token that has been assigned the moderator role.
 *                 // See https://dashboard.tokbox.com/projects
 * var connectionCount = 0;
 *
 * var session = OT.initSession(apiKey, sessionID);
 * session.on("connectionCreated", function(event) {
 *    connectionCount++;
 *    displayConnectionCount();
 * });
 * session.on("connectionDestroyed", function(event) {
 *    connectionCount--;
 *    displayConnectionCount();
 * });
 * session.connect(token);
 *
 * function displayConnectionCount() {
 *     document.getElementById("connectionCountField").value = connectionCount.toString();
 * }</pre>
 *
 * <p>This example assumes that there is an input text field in the HTML DOM
 * with the <code>id</code> set to <code>"connectionCountField"</code>:</p>
 *
 * <pre>&lt;input type="text" id="connectionCountField" value="0"&gt;&lt;/input&gt;</pre>
 *
 *
 * @property {Connection} connection A Connection objects for the connections that was
 * created or deleted.
 *
 * @property {Array} connections Deprecated. Use the <code>connection</code> property. A
 * <code>connectionCreated</code> or <code>connectionDestroyed</code> event is dispatched
 * for each connection created and destroyed in the session.
 *
 * @property {String} reason For a <code>connectionDestroyed</code> event,
 *  a description of why the connection ended. This property can have two values:
 * </p>
 * <ul>
 *  <li><code>"clientDisconnected"</code> &#151; A client disconnected from the session by calling
 *     the <code>disconnect()</code> method of the Session object or by closing the browser.
 *     (See <a href="Session.html#disconnect">Session.disconnect()</a>.)</li>
 *
 *  <li><code>"forceDisconnected"</code> &#151; A moderator has disconnected the publisher
 *      from the session, by calling the <code>forceDisconnect()</code> method of the Session
 *      object. (See <a href="Session.html#forceDisconnect">Session.forceDisconnect()</a>.)</li>
 *
 *  <li><code>"networkDisconnected"</code> &#151; The network connection terminated abruptly
 *      (for example, the client lost their internet connection).</li>
 * </ul>
 *
 * <p>Depending on the context, this description may allow the developer to refine
 * the course of action they take in response to an event.</p>
 *
 * <p>For a <code>connectionCreated</code> event, this string is undefined.</p>
 *
 * @class ConnectionEvent
 * @augments Event
 */
var connectionEventPluralDeprecationWarningShown = false;
Events.ConnectionEvent = function(type, connection, reason) {
  Events.Event.call(this, type, false);

  Object.defineProperty(this, 'connections', {
    get: function() {
      if (!connectionEventPluralDeprecationWarningShown) {
        logging.warn('OT.ConnectionEvent connections property is deprecated, ' +
          'use connection instead.');
        connectionEventPluralDeprecationWarningShown = true;
      }
      return [connection];
    }
  });

  this.connection = connection;
  this.reason = reason;
};

/**
 * StreamEvent is an event that can have the type "streamCreated" or "streamDestroyed".
 * These events are dispatched by the Session object when another client starts or
 * stops publishing a stream to a {@link Session}. For a local client's stream, the
 * Publisher object dispatches the event.
 *
 * <h4><a href="example_streamCreated"></a>Example &#151; streamCreated event dispatched
 * by the Session object</h4>
 *  <p>The following code initializes a session and sets up an event listener for when
 *    a stream published by another client is created:</p>
 *
 * <pre>
 * session.on("streamCreated", function(event) {
 *   // streamContainer is a DOM element
 *   subscriber = session.subscribe(event.stream, targetElement);
 * }).connect(token);
 * </pre>
 *
 *  <h4><a href="example_streamDestroyed"></a>Example &#151; streamDestroyed event dispatched
 * by the Session object</h4>
 *
 *    <p>The following code initializes a session and sets up an event listener for when
 *       other clients' streams end:</p>
 *
 * <pre>
 * session.on("streamDestroyed", function(event) {
 *     console.log("Stream " + event.stream.name + " ended. " + event.reason);
 * }).connect(token);
 * </pre>
 *
 * <h4><a href="example_streamCreated_publisher"></a>Example &#151; streamCreated event dispatched
 * by a Publisher object</h4>
 *  <p>The following code publishes a stream and adds an event listener for when the streaming
 * starts</p>
 *
 * <pre>
 * var publisher = session.publish(targetElement)
 *   .on("streamCreated", function(event) {
 *     console.log("Publisher started streaming.");
 *   );
 * </pre>
 *
 *  <h4><a href="example_streamDestroyed_publisher"></a>Example &#151; streamDestroyed event
 * dispatched by a Publisher object</h4>
 *
 *  <p>The following code publishes a stream, and leaves the Publisher in the HTML DOM
 * when the streaming stops:</p>
 *
 * <pre>
 * var publisher = session.publish(targetElement)
 *   .on("streamDestroyed", function(event) {
 *     event.preventDefault();
 *     console.log("Publisher stopped streaming.");
 *   );
 * </pre>
 *
 * @class StreamEvent
 *
 * @property {Boolean} cancelable   Whether the event has a default behavior that is cancelable
 *  (<code>true</code>) or not (<code>false</code>). You can cancel the default behavior by
 * calling the <code>preventDefault()</code> method of the StreamEvent object in the event
 * listener function. The <code>streamDestroyed</code> event is cancelable.
 * (See <a href="#preventDefault">preventDefault()</a>.)
 *
 * @property {String} reason For a <code>streamDestroyed</code> event,
 *  a description of why the session disconnected. This property can have one of the following
 *  values:
 * </p>
 * <ul>
 *  <li><code>"clientDisconnected"</code> &#151; A client disconnected from the session by calling
 *     the <code>disconnect()</code> method of the Session object or by closing the browser.
 *     (See <a href="Session.html#disconnect">Session.disconnect()</a>.)</li>
 *
 *  <li><code>"forceDisconnected"</code> &#151; A moderator has disconnected the publisher of the
 *    stream from the session, by calling the <code>forceDisconnect()</code> method of the Session
 *     object. (See <a href="Session.html#forceDisconnect">Session.forceDisconnect()</a>.)</li>
 *
 *  <li><code>"forceUnpublished"</code> &#151; A moderator has forced the publisher of the stream
 *    to stop publishing the stream, by calling the <code>forceUnpublish()</code> method of the
 *    Session object.
 *    (See <a href="Session.html#forceUnpublish">Session.forceUnpublish()</a>.)</li>
 *
 *  <li><code>"mediaStopped"</code> &#151; The user publishing the stream has stopped sharing the
 *    screen. This value is only used in screen-sharing video streams.</li>
 *
 *  <li><code>"networkDisconnected"</code> &#151; The network connection terminated abruptly (for
 *      example, the client lost their internet connection).</li>
 *
 * </ul>
 *
 * <p>Depending on the context, this description may allow the developer to refine
 * the course of action they take in response to an event.</p>
 *
 * <p>For a <code>streamCreated</code> event, this string is undefined.</p>
 *
 * @property {Stream} stream A Stream object corresponding to the stream that was added (in the
 * case of a <code>streamCreated</code> event) or deleted (in the case of a
 * <code>streamDestroyed</code> event).
 *
 * @property {Array} streams Deprecated. Use the <code>stream</code> property. A
 * <code>streamCreated</code> or <code>streamDestroyed</code> event is dispatched for
 * each stream added or destroyed.
 *
 * @augments Event
 */

var streamEventPluralDeprecationWarningShown = false;
Events.StreamEvent = function(type, stream, reason, cancelable) {
  Events.Event.call(this, type, cancelable);

  Object.defineProperty(this, 'streams', {
    get: function() {
      if (!streamEventPluralDeprecationWarningShown) {
        logging.warn('OT.StreamEvent streams property is deprecated, use stream instead.');
        streamEventPluralDeprecationWarningShown = true;
      }
      return [stream];
    }
  });

  this.stream = stream;
  this.reason = reason;
};

/**
* Prevents the default behavior associated with the event from taking place.
*
* <p>For the <code>streamDestroyed</code> event dispatched by the Session object,
* the default behavior is that all Subscriber objects that are subscribed to the stream are
* unsubscribed and removed from the HTML DOM. Each Subscriber object dispatches a
* <code>destroyed</code> event when the element is removed from the HTML DOM. If you call the
* <code>preventDefault()</code> method in the event listener for the <code>streamDestroyed</code>
* event, the default behavior is prevented and you can clean up Subscriber objects using your
* own code. See
* <a href="Session.html#getSubscribersForStream">Session.getSubscribersForStream()</a>.</p>
* <p>
* For the <code>streamDestroyed</code> event dispatched by a Publisher object, the default
* behavior is that the Publisher object is removed from the HTML DOM. The Publisher object
* dispatches a <code>destroyed</code> event when the element is removed from the HTML DOM.
* If you call the <code>preventDefault()</code> method in the event listener for the
* <code>streamDestroyed</code> event, the default behavior is prevented, and you can
* retain the Publisher for reuse or clean it up using your own code.
*</p>
* <p>To see whether an event has a default behavior, check the <code>cancelable</code> property of
* the event object. </p>
*
* <p>
*   Call the <code>preventDefault()</code> method in the event listener function for the event.
* </p>
*
* @method #preventDefault
* @memberof StreamEvent
*/

/**
 * The Session object dispatches SessionConnectEvent object when a session has successfully
 * connected in response to a call to the <code>connect()</code> method of the Session object.
 * <p>
 * In version 2.2, the completionHandler of the <code>Session.connect()</code> method
 * indicates success or failure in connecting to the session.
 *
 * @class SessionConnectEvent
 * @property {Array} connections Deprecated in version 2.2 (and set to an empty array). In
 * version 2.2, listen for the <code>connectionCreated</code> event dispatched by the Session
 * object. In version 2.2, the Session object dispatches a <code>connectionCreated</code> event
 * for each connection (including your own). This includes connections present when you first
 * connect to the session.
 *
 * @property {Array} streams Deprecated in version 2.2 (and set to an empty array). In version
 * 2.2, listen for the <code>streamCreated</code> event dispatched by the Session object. In
 * version 2.2, the Session object dispatches a <code>streamCreated</code> event for each stream
 * other than those published by your client. This includes streams
 * present when you first connect to the session.
 *
 * @see <a href="Session.html#connect">Session.connect()</a></p>
 * @augments Event
 */

var sessionConnectedConnectionsDeprecationWarningShown = false;
var sessionConnectedStreamsDeprecationWarningShown = false;
var sessionConnectedArchivesDeprecationWarningShown = false;

Events.SessionConnectEvent = function(type) {
  Events.Event.call(this, type, false);

  Object.defineProperties(this, {
    connections: {
      get: function() {
        if (!sessionConnectedConnectionsDeprecationWarningShown) {
          logging.warn('OT.SessionConnectedEvent no longer includes connections. Listen ' +
            'for connectionCreated events instead.');
          sessionConnectedConnectionsDeprecationWarningShown = true;
        }
        return [];
      }
    },
    streams: {
      get: function() {
        if (!sessionConnectedStreamsDeprecationWarningShown) {
          logging.warn('OT.SessionConnectedEvent no longer includes streams. Listen for ' +
            'streamCreated events instead.');
          sessionConnectedConnectionsDeprecationWarningShown = true;
        }
        return [];
      }
    },
    archives: {
      get: function() {
        if (!sessionConnectedArchivesDeprecationWarningShown) {
          logging.warn('OT.SessionConnectedEvent no longer includes archives. Listen for ' +
            'archiveStarted events instead.');
          sessionConnectedArchivesDeprecationWarningShown = true;
        }
        return [];
      }
    }
  });
};

Events.SessionReconnectingEvent = function() {
  Events.Event.call(this, Events.Event.names.SESSION_RECONNECTING);
};

Events.SessionReconnectedEvent = function() {
  Events.Event.call(this, Events.Event.names.SESSION_RECONNECTED);
};

/**
 * The Session object dispatches SessionDisconnectEvent object when a session has disconnected.
 * This event may be dispatched asynchronously in response to a successful call to the
 * <code>disconnect()</code> method of the session object.
 *
 *  <h4>
 *    <a href="example"></a>Example
 *  </h4>
 *  <p>
 *    The following code initializes a session and sets up an event listener for when a session is
 * disconnected.
 *  </p>
 * <pre>var apiKey = ""; // Replace with your API key. See https://dashboard.tokbox.com/projects
 *  var sessionID = ""; // Replace with your own session ID.
 *                      // See https://dashboard.tokbox.com/projects
 *  var token = ""; // Replace with a generated token that has been assigned the moderator role.
 *                  // See https://dashboard.tokbox.com/projects
 *
 *  var session = OT.initSession(apiKey, sessionID);
 *  session.on("sessionDisconnected", function(event) {
 *      alert("The session disconnected. " + event.reason);
 *  });
 *  session.connect(token);
 *  </pre>
 *
 * @property {String} reason A description of why the session disconnected.
 *   This property can have two values:
 *  </p>
 *  <ul>
 *    <li>
 *      <code>"clientDisconnected"</code> &mdash; A client disconnected from the
 *      session by calling the <code>disconnect()</code> method of the Session
 *      object or by closing the browser. ( See <a href=
 *      "Session.html#disconnect">Session.disconnect()</a>.)
 *    </li>
 *
 *    <li>
 *      <code>"forceDisconnected"</code> &mdash; A moderator has disconnected you from
 *      the session by calling the <code>forceDisconnect()</code> method of the
 *      Session object. (See <a href=
 *      "Session.html#forceDisconnect">Session.forceDisconnect()</a>.)
 *    </li>
 *
 *    <li><code>"networkDisconnected"</code> &mdash; The network connection terminated
 *    abruptly (for example, the client lost its internet connection). Prior to dispatching a
 *    <code>sessionDisconnected</code> event for this reason, the Session object dispatches a
 *    <code>reconnecting</code> event, and the client attempts to reconnect to the OpenTok session.
 *    If the reconnection fails, the Session object dispatches a <code>sessionDisconnected</code>
 *    event with the <code>reason</code> property set to <code>"networkDisconnected"</code>.</li>
 *  </ul>
 *  <ul>
 *
 * @class SessionDisconnectEvent
 * @augments Event
 */
Events.SessionDisconnectEvent = function(type, reason, cancelable) {
  Events.Event.call(this, type, cancelable);
  this.reason = reason;
};

/**
* Prevents the default behavior associated with the event from taking place.
*
* <p>
*   For the <code>sessionDisconnectEvent</code>, the default behavior is that all
*   Subscriber objects are unsubscribed and removed from the HTML DOM. Each
*   Subscriber object dispatches a <code>destroyed</code> event when the element
*   is removed from the HTML DOM. If you call the <code>preventDefault()</code>
*   method in the event listener for the <code>sessionDisconnect</code> event,
*   the default behavior is prevented, and you can, optionally, clean up
*   Subscriber objects using your own code). *
* </p>
* <p>
*   To see whether an event has a default behavior, check the
*   <code>cancelable</code> property of the event object.
* </p>*
* <p>
*   Call the <code>preventDefault()</code> method in the event listener function
*   for the event.
* </p>
*
* @method #preventDefault
* @memberof SessionDisconnectEvent
*/

/**
 * The Session object dispatches a <code>streamPropertyChanged</code> event in the
 * following circumstances:
 *
 * <ul>
 *   <li> A stream has started or stopped publishing audio or video (see
 *     <a href="Publisher.html#publishAudio">Publisher.publishAudio()</a> and
 *     <a href="Publisher.html#publishVideo">Publisher.publishVideo()</a>).
 *     This change results from a call to the <code>publishAudio()</code> or
 *     <code>publishVideo()</code> methods of the Publish object. Note that a
 *     subscriber's video can be disabled or enabled for reasons other than the
 *     publisher disabling or enabling it. A Subscriber object dispatches
 *     <code>videoDisabled</code> and <code>videoEnabled</code> events in all
 *     conditions that cause the subscriber's stream to be disabled or enabled.
 *   </li>
 *   <li> The <code>videoDimensions</code> property of the Stream object has
 *     changed (see <a href="Stream.html#properties">Stream.videoDimensions</a>).
 *   </li>
 *   <li> The <code>videoType</code> property of the Stream object has changed.
 *     This can happen in a stream published by a mobile device. (See
 *     <a href="Stream.html#properties">Stream.videoType</a>.)
 *   </li>
 * </ul>
 *
 * @class StreamPropertyChangedEvent
 * @property {String} changedProperty The property of the stream that changed. This value
 * is either <code>"hasAudio"</code>, <code>"hasVideo"</code>, or <code>"videoDimensions"</code>.
 * @property {Object} newValue The new value of the property (after the change).
 * @property {Object} oldValue The old value of the property (before the change).
 * @property {Stream} stream The Stream object for which a property has changed.
 *
 * @see <a href="Publisher.html#publishAudio">Publisher.publishAudio()</a></p>
 * @see <a href="Publisher.html#publishVideo">Publisher.publishVideo()</a></p>
 * @see <a href="Stream.html#properties">Stream.videoDimensions</a></p>
 * @augments Event
 */
Events.StreamPropertyChangedEvent = function(type, stream, changedProperty, oldValue, newValue) {
  Events.Event.call(this, type, false);
  this.type = type;
  this.stream = stream;
  this.changedProperty = changedProperty;
  this.oldValue = oldValue;
  this.newValue = newValue;
};

/**
 * Dispatched when the video dimensions of the video change for a screen-sharing
 * video stream (when the user resizes the window being captured).
 *
 * @class VideoDimensionsChangedEvent
 * @property {Object} newValue The new video dimensions (after the change). This object has two
 * properties: <code>height</code> (the height, in pixels) and <code>width</code> (the width,
 * in pixels).
 * @property {Object} oldValue The old video dimensions (before the change). This object has two
 * properties: <code>height</code> (the old height, in pixels) and <code>width</code> (the old
 * width, in pixels).
 *
 * @see <a href="Publisher.html#event:videoDimensionsChanged">Publisher videoDimensionsChanged
 *   event</a>
 * @see <a href="Subscriber.html#event:videoDimensionsChanged">Subscriber videoDimensionsChanged
 *   event</a>
 * @augments Event
 */
Events.VideoDimensionsChangedEvent = function(target, oldValue, newValue) {
  Events.Event.call(this, 'videoDimensionsChanged', false);
  this.type = 'videoDimensionsChanged';
  this.target = target;
  this.oldValue = oldValue;
  this.newValue = newValue;
};

/**
 * Defines event objects for the <code>archiveStarted</code> and <code>archiveStopped</code>
 * events. The Session object dispatches these events when an archive recording of the session
 * starts and stops.
 *
 * @property {String} id The archive ID.
 * @property {String} name The name of the archive. You can assign an archive a name when you
 * create it, using the <a href="http://www.tokbox.com/opentok/api">OpenTok REST API</a> or one
 * of the <a href="http://www.tokbox.com/opentok/libraries/server">OpenTok server SDKs</a>.
 *
 * @class ArchiveEvent
 * @augments Event
 */
Events.ArchiveEvent = function(type, archive) {
  Events.Event.call(this, type, false);
  this.type = type;
  this.id = archive.id;
  this.name = archive.name;
  this.status = archive.status;
  this.archive = archive;
};

Events.ArchiveUpdatedEvent = function(stream, key, oldValue, newValue) {
  Events.Event.call(this, 'updated', false);
  this.target = stream;
  this.changedProperty = key;
  this.oldValue = oldValue;
  this.newValue = newValue;
};

/**
 * The Session object dispatches a signal event when the client receives a signal from the
 * session.
 *
 * @class SignalEvent
 * @property {String} type The type assigned to the signal (if there is one). Use the type to
 * filter signals received (by adding an event handler for signal:type1 or signal:type2, etc.)
 * @property {String} data The data string sent with the signal (if there is one).
 * @property {Connection} from The Connection corresponding to the client that sent with the
 * signal.
 *
 * @see <a href="Session.html#signal">Session.signal()</a></p>
 * @see <a href="Session.html#events">Session events (signal and signal:type)</a></p>
 * @augments Event
 */
Events.SignalEvent = function(type, data, from) {
  Events.Event.call(this, type ? 'signal:' + type : Events.Event.names.SIGNAL, false);
  this.data = data;
  this.from = from;
};

Events.StreamUpdatedEvent = function(stream, key, oldValue, newValue) {
  Events.Event.call(this, 'updated', false);
  this.target = stream;
  this.changedProperty = key;
  this.oldValue = oldValue;
  this.newValue = newValue;
};

Events.DestroyedEvent = function(type, target, reason) {
  Events.Event.call(this, type, false);
  this.target = target;
  this.reason = reason;
};

Events.ConnectionStateChangedEvent = function(type, target) {
  Events.Event.call(this, type, false);
  this.target = target;
};

/**
 * Defines the event object for the <code>videoDisabled</code> and <code>videoEnabled</code>
 * events dispatched by the Subscriber.
 *
 * @class VideoEnabledChangedEvent
 *
 * @property {Boolean} cancelable Whether the event has a default behavior that is cancelable
 * (<code>true</code>) or not (<code>false</code>). You can cancel the default behavior by
 * calling the <code>preventDefault()</code> method of the event object in the callback
 * function. (See <a href="#preventDefault">preventDefault()</a>.)
 *
 * @property {String} reason The reason the video was disabled or enabled. This can be set to one
 * of the following values:
 *
 * <ul>
 *
 *   <li><code>"publishVideo"</code> &mdash; The publisher started or stopped publishing video,
 *   by calling <code>publishVideo(true)</code> or <code>publishVideo(false)</code>.</li>
 *
 *   <li><code>"quality"</code> &mdash; The OpenTok Media Router starts or stops sending video
 *   to the subscriber based on stream quality changes. This feature of the OpenTok Media
 *   Router has a subscriber drop the video stream when connectivity degrades. (The subscriber
 *   continues to receive the audio stream, if there is one.)
 *   <p>
 *   If connectivity improves to support video again, the Subscriber object dispatches
 *   a <code>videoEnabled</code> event, and the Subscriber resumes receiving video.
 *   <p>
 *   By default, the Subscriber displays a video disabled indicator when a
 *   <code>videoDisabled</code> event with this reason is dispatched and removes the indicator
 *   when the <code>videoDisabled</code> event with this reason is dispatched. You can control
 *   the display of this icon by calling the <code>setStyle()</code> method of the Subscriber,
 *   setting the <code>videoDisabledDisplayMode</code> property(or you can set the style when
 *   calling the <code>Session.subscribe()</code> method, setting the <code>style</code> property
 *   of the <code>properties</code> parameter).
 *   <p>
 *   This feature is only available in sessions that use the OpenTok Media Router (sessions with
 *   the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">media mode</a>
 *   set to routed), not in sessions with the media mode set to relayed.
 *   </li>
 *
 *   <li><code>"subscribeToVideo"</code> &mdash; The subscriber started or stopped subscribing to
 *   video, by calling <code>subscribeToVideo(true)</code> or
 *   <code>subscribeToVideo(false)</code>.</li>
 *
 * </ul>
 *
 * @property {Object} target The object that dispatched the event.
 *
 * @property {String} type  The type of event: <code>"videoDisabled"</code> or
 * <code>"videoEnabled"</code>.
 *
 * @see <a href="Subscriber.html#event:videoDisabled">Subscriber videoDisabled event</a></p>
 * @see <a href="Subscriber.html#event:videoEnabled">Subscriber videoEnabled event</a></p>
 * @augments Event
 */
Events.VideoEnabledChangedEvent = function(type, properties) {
  Events.Event.call(this, type, false);
  this.reason = properties.reason;
};

Events.VideoDisableWarningEvent = function(type/*, properties*/) {
  Events.Event.call(this, type, false);
};

/**
 * Dispatched periodically by a Subscriber or Publisher object to indicate the audio
 * level. This event is dispatched up to 60 times per second, depending on the browser.
 *
 * @property {Number} audioLevel The audio level, from 0 to 1.0. Adjust this value logarithmically
 * for use in adjusting a user interface element, such as a volume meter. Use a moving average
 * to smooth the data.
 *
 * @class AudioLevelUpdatedEvent
 * @augments Event
 */
Events.AudioLevelUpdatedEvent = function(audioLevel) {
  Events.Event.call(this, Events.Event.names.AUDIO_LEVEL_UPDATED, false);
  this.audioLevel = audioLevel;
};

Events.MediaStoppedEvent = function(target) {
  Events.Event.call(this, Events.Event.names.MEDIA_STOPPED, true);
  this.target = target;
};

module.exports = Events;
