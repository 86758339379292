'use strict';

var makeEverythingAttachToOTHelpers = require('./makeEverythingAttachToOTHelpers');

var domLoad = {};
module.exports = domLoad;

var _domReady = typeof(document) === 'undefined' ||
                  document.readyState === 'complete' ||
                 (document.readyState === 'interactive' && document.body),

    _loadCallbacks = [],
    _unloadCallbacks = [],
    _domUnloaded = false,

    onDomUnload = function() {
      _domUnloaded = true;

      _unloadCallbacks.forEach(function(listener) {
        listener[0].call(listener[1]);
      });

      _unloadCallbacks = [];
    },

    onDomReady = function() {
      _domReady = true;

      if (typeof(document) !== 'undefined') {
        document.removeEventListener('DOMContentLoaded', onDomReady, false);
        global.removeEventListener('load', onDomReady, false);

        // This is making an assumption about there being only one 'global'
        // that we care about.
        var globalonunload = global.onunload;
        global.onunload = function() {
          if (typeof globalonunload === 'function') {
            globalonunload.apply(undefined, arguments);
          }
          onDomUnload();
        }
      }

      // @TODO consider how to trigger/handle clean up Node. This is
      // obviously not the Object to do it in, as there is no DOM in
      // Node. But this is where the equivilant browser code is so
      // it's a good place to leave a todo.

      _loadCallbacks.forEach(function(listener) {
        listener[0].call(listener[1]);
      });

      _loadCallbacks = [];
    };

domLoad.isReady = function() {
  return !_domUnloaded && _domReady;
};

domLoad.onDOMLoad = function(cb, context) {
  if (domLoad.isReady()) {
    cb.call(context);
    return;
  }

  _loadCallbacks.push([cb, context]);
};

domLoad.onDOMUnload = function(cb, context) {
  if (this.isDOMUnloaded()) {
    cb.call(context);
    return;
  }

  _unloadCallbacks.push([cb, context]);
};

domLoad.isDOMUnloaded = function() {
  return _domUnloaded;
};

if (_domReady) {
  onDomReady();
} else if(typeof(document) !== 'undefined') {
  document.addEventListener('DOMContentLoaded', onDomReady, false);

  // fallback
  global.addEventListener('load', onDomReady, false );
}

makeEverythingAttachToOTHelpers(domLoad);
