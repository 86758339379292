'use strict';

// The top-level namespace, also performs basic DOMElement selecting.
//
// @example Get the DOM element with the id of 'domId'
//   OTHelpers('#domId')
//
// @example Get all video elements
//   OTHelpers('video')
//
// @example Get all elements with the class name of 'foo'
//   OTHelpers('.foo')
//
// @example Get all elements with the class name of 'foo',
// and do something with the first.
//   var collection = OTHelpers('.foo');
//   console.log(collection.first);
//
//
// The second argument is the context, that is document or parent Element, to
// select from.
//
// @example Get a video element within the element with the id of 'domId'
//   OTHelpers('video', OTHelpers('#domId'))
//
//
//
// OTHelpers will accept any of the following and return a collection:
//   OTHelpers()
//   OTHelpers('css selector', optionalParentNode)
//   OTHelpers(DomNode)
//   OTHelpers([array of DomNode])
//
// The collection is a ElementCollection object, see the ElementCollection docs for usage info.
//
var OTHelpers = require('./elementCollection/shorthandSelector');

OTHelpers.ElementCollection = require('./elementCollection/index');
OTHelpers.util = require('./util');
OTHelpers.env = require('./env');
OTHelpers.logging = require('./logging');
OTHelpers.ajax = require('./ajax');
OTHelpers.callbacks = require('./callbacks');
OTHelpers.Event = require('./behaviours/eventing/event');
OTHelpers.eventing = require('./behaviours/eventing');
OTHelpers.statable = require('./behaviours/statable');
OTHelpers.Analytics = require('./analytics');
OTHelpers.requestAnimationFrame = require('./requestAnimationFrame');
OTHelpers.async = require('../vendor/async');
OTHelpers.capabilities = require('./capabilities');
OTHelpers.casting = require('./casting');
OTHelpers.Collection = require('./collection');
OTHelpers.cookies = require('./cookies');
OTHelpers.domExtras = require('./domExtras');
OTHelpers.domLoad = require('./domLoad');
OTHelpers.Error = require('./error');
OTHelpers.Modal = require('./modal');
OTHelpers.isWebSocketSupported = require('./isWebSocketSupported');
OTHelpers.useLogHelpers = require('./logging/mixin');

// TODO: Remove the need for this kind of bazzadry.
for (var key in OTHelpers) {
  var component = OTHelpers[key];
  var attachments = component._attachToOTHelpers;

  if (attachments) {
    for (var attachmentName in attachments) {
      if (Object.hasOwnProperty.call(OTHelpers, attachmentName) && !(key === 'util' && attachmentName === 'bind')) {
        console.warn(
          'More than one module is trying to define ' + attachmentName + ' on OTHelpers.'
        );
      }
      OTHelpers[attachmentName] = attachments[attachmentName];
    }
  }
}

var previousOTHelpers = global.OTHelpers;

OTHelpers.noConflict = function() {
  OTHelpers.noConflict = function() {
    return OTHelpers;
  };
  global.OTHelpers = previousOTHelpers;
  return OTHelpers;
};

global.OTHelpers = OTHelpers;

// A guard to detect when IE has performed cleans on unload
global.___othelpers = true;

module.exports = OTHelpers;
