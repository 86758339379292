'use strict';

var bluebird = require('bluebird');

var uuid = require('uuid');
var curryCallAsync = require('../curry_call_async.js');
var createGetStatsAdaptor = require('./create_get_stats_adaptor/create_get_stats_adaptor.js');
var empiricDelay = require('../empiric_delay.js');
var logging = require('../logging.js');
var MediaStream = require('../media_stream');
var OTHelpers = require('@opentok/ot-helpers');
var RTCIceCandidate = require('../rtc_ice_candidate.js');
var RTCSessionDescription = require('../rtc_session_description.js');

// Our RTCPeerConnection shim, it should look like a normal PeerConection
// from the outside, but it actually delegates to our plugin.
//
var PeerConnection = function(iceServers, options, plugin, ready) {
  var Proto = function PeerConnection() {},
      api = new Proto(),
      id = uuid(),
      hasLocalDescription = false,
      hasRemoteDescription = false,
      candidates = [],
      inited = false,
      deferMethods = [],
      events,
      streamAdded;

  plugin.addRef(api);

  events = {
    addstream: [],
    removestream: [],
    icecandidate: [],
    signalingstatechange: [],
    iceconnectionstatechange: []
  };

  var onAddIceCandidate = function onAddIceCandidate() {/* success */},

      onAddIceCandidateFailed = function onAddIceCandidateFailed(err) {
        logging.error('Failed to process candidate');
        logging.error(err);
      },

      processPendingCandidates = function processPendingCandidates() {
        for (var i = 0; i < candidates.length; ++i) {
          plugin._.addIceCandidate(id, candidates[i], onAddIceCandidate, onAddIceCandidateFailed);
        }
      },

      deferMethod = function deferMethod(method) {
        return function() {
          if (inited === true) {
            return method.apply(api, arguments);
          }

          deferMethods.push([method, arguments]);
        };
      },

      processDeferredMethods = function processDeferredMethods() {
        var m;
        while ((m = deferMethods.shift())) {
          m[0].apply(api, m[1]);
        }
      },

      triggerEvent = function triggerEvent(/* eventName [, arg1, arg2, ..., argN] */) {
        var args = Array.prototype.slice.call(arguments),
            eventName = args.shift();

        if (!events.hasOwnProperty(eventName)) {
          logging.error('PeerConnection does not have an event called: ' + eventName);
          return;
        }

        events[eventName].forEach(function(listener) {
          listener.apply(null, args);
        });
      },

      bindAndDelegateEvents = function bindAndDelegateEvents(events) {
        for (var name in events) {
          if (events.hasOwnProperty(name)) {
            events[name] = curryCallAsync(events[name]);
          }
        }

        plugin._.on(id, events);
      },

      addStream = function addStream(streamJson) {
        setTimeout(function() {
          var stream = MediaStream.fromJson(streamJson, plugin),
              event = {stream: stream, target: api};

          if (api.onaddstream && OTHelpers.isFunction(api.onaddstream)) {
            OTHelpers.callAsync(api.onaddstream, event);
          }

          triggerEvent('addstream', event);
        }, empiricDelay);
      },

      removeStream = function removeStream(streamJson) {
        var stream = MediaStream.fromJson(streamJson, plugin),
            event = {stream: stream, target: api};

        if (api.onremovestream && OTHelpers.isFunction(api.onremovestream)) {
          OTHelpers.callAsync(api.onremovestream, event);
        }

        triggerEvent('removestream', event);
      },

      iceCandidate = function iceCandidate(candidateSdp, sdpMid, sdpMLineIndex) {
        var candidate = new RTCIceCandidate({
          candidate: candidateSdp,
          sdpMid: sdpMid,
          sdpMLineIndex: sdpMLineIndex
        });

        var event = {candidate: candidate, target: api};

        if (api.onicecandidate && OTHelpers.isFunction(api.onicecandidate)) {
          OTHelpers.callAsync(api.onicecandidate, event);
        }

        triggerEvent('icecandidate', event);
      },

      signalingStateChange = function signalingStateChange(state) {
        api.signalingState = state;
        var event = {state: state, target: api};

        if (api.onsignalingstatechange &&
                OTHelpers.isFunction(api.onsignalingstatechange)) {
          OTHelpers.callAsync(api.onsignalingstatechange, event);
        }

        triggerEvent('signalingstate', event);
      },

      iceConnectionChange = function iceConnectionChange(state) {
        api.iceConnectionState = state;
        var event = {state: state, target: api};

        if (api.oniceconnectionstatechange &&
                OTHelpers.isFunction(api.oniceconnectionstatechange)) {
          OTHelpers.callAsync(api.oniceconnectionstatechange, event);
        }

        triggerEvent('iceconnectionstatechange', event);
      };

  api.createOffer = deferMethod(function(success, error, constraints) {
    logging.debug('createOffer', constraints);
    plugin._.createOffer(id, function(type, sdp) {
      success(new RTCSessionDescription({
        type: type,
        sdp: sdp
      }));
    }, error, constraints || {});
  });

  api.createAnswer = deferMethod(function(success, error, constraints) {
    logging.debug('createAnswer', constraints);
    plugin._.createAnswer(id, function(type, sdp) {
      success(new RTCSessionDescription({
        type: type,
        sdp: sdp
      }));
    }, error, constraints || {});
  });

  api.setLocalDescription = deferMethod(function(description, success, error) {
    logging.debug('setLocalDescription');

    plugin._.setLocalDescription(id, description, function() {
      hasLocalDescription = true;

      if (hasRemoteDescription) processPendingCandidates();
      if (success) success.call(null);
    }, error);
  });

  api.setRemoteDescription = deferMethod(function(description, success, error) {
    logging.debug('setRemoteDescription');

    plugin._.setRemoteDescription(id, description, function() {
      hasRemoteDescription = true;

      if (hasLocalDescription) processPendingCandidates();
      if (success) success.call(null);
    }, error);
  });

  api.addIceCandidate = deferMethod(function(candidate) {
    logging.debug('addIceCandidate');

    if (hasLocalDescription && hasRemoteDescription) {
      plugin._.addIceCandidate(id, candidate, onAddIceCandidate, onAddIceCandidateFailed);
    } else {
      candidates.push(candidate);
    }
  });

  api.addStream = deferMethod(function(stream) {
    var constraints = {};
    plugin._.addStream(id, stream, constraints);
  });

  api.removeStream = deferMethod(function(stream) {
    plugin._.removeStream(id, stream);
  });

  api.getRemoteStreams = function() {
    return plugin._.getRemoteStreams(id).map(function(stream) {
      return MediaStream.fromJson(stream, plugin);
    });
  };

  api.getLocalStreams = function() {
    return plugin._.getLocalStreams(id).map(function(stream) {
      return MediaStream.fromJson(stream, plugin);
    });
  };

  api.getStreamById = function(streamId) {
    return MediaStream.fromJson(plugin._.getStreamById(id, streamId), plugin);
  };

  var adaptedGetStats = createGetStatsAdaptor(plugin._, id);

  api.getStats = deferMethod(function(mediaStreamTrack, success, error) {
    streamAdded.promise.then(function() {
      var getStatsPromise = adaptedGetStats(mediaStreamTrack);

      getStatsPromise.then(success);
      getStatsPromise.catch(error);
    });
  });

  api.close = function() {
    plugin._.destroyPeerConnection(id);
    plugin.removeRef(this);
  };

  api.destroy = function() {
    api.close();
  };

  api.addEventListener = function(event, handler /* [, useCapture] we ignore this */) {
    if (events[event] === void 0) {
      return;
    }

    events[event].push(handler);
  };

  api.removeEventListener = function(event, handler /* [, useCapture] we ignore this */) {
    if (events[event] === void 0) {
      return;
    }

    events[event] = events[event].filter(function(fn) {
      return fn !== handler;
    });
  };

  // These should appear to be null, instead of undefined, if no
  // callbacks are assigned. This more closely matches how the native
  // objects appear and allows 'if (pc.onsignalingstatechange)' type
  // feature detection to work.
  api.onaddstream = null;
  api.onremovestream = null;
  api.onicecandidate = null;
  api.onsignalingstatechange = null;
  api.oniceconnectionstatechange = null;

  // Both username and credential must exist, otherwise the plugin throws an error
  iceServers.iceServers.forEach(function(iceServer) {
    if (!iceServer.username) iceServer.username = '';
    if (!iceServer.credential) iceServer.credential = '';
  });

  if (!plugin._.initPeerConnection(id, iceServers, options)) {
    ready(new OTHelpers.error('Failed to initialise PeerConnection'));
    return;
  }

  // This will make sense once init becomes async
  bindAndDelegateEvents({
    addStream: addStream,
    removeStream: removeStream,
    iceCandidate: iceCandidate,
    signalingStateChange: signalingStateChange,
    iceConnectionChange: iceConnectionChange
  });

  streamAdded = bluebird.defer();

  api.addEventListener('addstream', function() {
    setTimeout(function() {
      streamAdded.resolve();
    }, 200);
  });

  inited = true;
  processDeferredMethods();

  ready(void 0, api);

  return api;
};

PeerConnection.create = function(iceServers, options, plugin, ready) {
  new PeerConnection(iceServers, options, plugin, ready);
};

module.exports = PeerConnection;
