'use strict';

// TODO: We should be exporting fixed versions of these objects rather than messing with them
// globally. Currently this file is not actually modularized and require('./web_rtc_polyfills.js')
// is only used as an error-prone inclusion mechanism.

var OTHelpers = require('@opentok/ot-helpers');

// Web OT Helpers
// guard for Node.js // TODO: use OTHelpers.env instead
if (global && typeof navigator !== 'undefined') {
  if (navigator.webkitGetUserMedia) {
    // Stub for getVideoTracks for Chrome < 26
    if (!global.webkitMediaStream.prototype.getVideoTracks) {
      global.webkitMediaStream.prototype.getVideoTracks = function() {
        return this.videoTracks;
      };
    }

    // Stubs for getAudioTracks for Chrome < 26
    if (!global.webkitMediaStream.prototype.getAudioTracks) {
      global.webkitMediaStream.prototype.getAudioTracks = function() {
        return this.audioTracks;
      };
    }

    if (!global.webkitRTCPeerConnection.prototype.getLocalStreams) {
      global.webkitRTCPeerConnection.prototype.getLocalStreams = function() {
        return this.localStreams;
      };
    }

    if (!global.webkitRTCPeerConnection.prototype.getRemoteStreams) {
      global.webkitRTCPeerConnection.prototype.getRemoteStreams = function() {
        return this.remoteStreams;
      };
    }

  } else if (navigator.mozGetUserMedia) {
    // Firefox < 23 doesn't support get Video/Audio tracks, we'll just stub them out for now.
    if (!global.MediaStream.prototype.getVideoTracks) {
      global.MediaStream.prototype.getVideoTracks = function() {
        return [];
      };
    }

    if (!global.MediaStream.prototype.getAudioTracks) {
      global.MediaStream.prototype.getAudioTracks = function() {
        return [];
      };
    }

    // This won't work as mozRTCPeerConnection is a weird internal Firefox
    // object (a wrapped native object I think).
    // if (!global.mozRTCPeerConnection.prototype.getLocalStreams) {
    //     global.mozRTCPeerConnection.prototype.getLocalStreams = function() {
    //         return this.localStreams;
    //     };
    // }

    // This won't work as mozRTCPeerConnection is a weird internal Firefox
    // object (a wrapped native object I think).
    // if (!global.mozRTCPeerConnection.prototype.getRemoteStreams) {
    //     global.mozRTCPeerConnection.prototype.getRemoteStreams = function() {
    //         return this.remoteStreams;
    //     };
    // }
  }

  // The setEnabled method on MediaStreamTracks is a OTPlugin
  // construct. In this particular instance it's easier to bring
  // all the good browsers down to IE's level than bootstrap it up.
  if (typeof global.MediaStreamTrack !== 'undefined') {
    if (!global.MediaStreamTrack.prototype.setEnabled) {
      global.MediaStreamTrack.prototype.setEnabled = function(enabled) {
        this.enabled = OTHelpers.castToBoolean(enabled);
      };
    }
  }

  if (!global.URL && global.webkitURL) {
    global.URL = global.webkitURL;
  }
}
