'use strict';

var analytics = require('../analytics.js');
var APIKEY = require('../api_key.js');
var Archiving = require('../chrome/archiving.js');
var audioContext = require('../../helpers/audio_context.js');
var AudioLevelMeter = require('../chrome/audio_level_meter.js');
var AudioLevelTransformer = require('../audio_level_transformer');
var BackingBar = require('../chrome/backing_bar.js');
var Bluebird = require('bluebird');
var Chrome = require('../chrome/chrome.js');
var ConnectivityAttemptPinger = require('../../helpers/connectivity_attempt_pinger.js');
var deviceHelpers = require('../../helpers/device_helpers.js');
var EnvironmentLoader = require('../environment_loader.js');
var Events = require('../events.js');
var ExceptionCodes = require('../exception_codes.js');
var getUserMedia = require('../../helpers/get_user_media.js');
var IntervalRunner = require('../interval_runner.js');
var logging = require('../logging.js');
var Microphone = require('./microphone.js');
var MuteButton = require('../chrome/mute_button.js');
var NamePanel = require('../chrome/name_panel.js');
var OTError = require('../ot_error.js');
var OTHelpers = require('@opentok/ot-helpers');
var OTPlugin = require('@opentok/otplugin.js');
var parseIceServers = require('../messaging/raptor/parse_ice_servers.js');
var properties = require('../../helpers/properties.js');
var PublisherPeerConnection = require('../peer_connection/publisher_peer_connection.js');
var PublishingState = require('./state.js');
var screenSharing = require('../screensharing/screen_sharing.js');
var StreamChannel = require('../stream_channel.js');
var StylableComponent = require('../stylable_component.js');
var systemRequirements = require('../system_requirements.js');
var uuid = require('uuid');
var VideoOrientation = require('../../helpers/video_orientation.js');
var WidgetView = require('../../helpers/widget_view.js');

// The default constraints
var defaultConstraints = {
  audio: true,
  video: true
};

var PUBLISH_MAX_DELAY = require('./max_delay.js');

/**
 * The Publisher object  provides the mechanism through which control of the
 * published stream is accomplished. Calling the <code>OT.initPublisher()</code> method
 * creates a Publisher object. </p>
 *
 *  <p>The following code instantiates a session, and publishes an audio-video stream
 *  upon connection to the session: </p>
 *
 *  <pre>
 *  var apiKey = ''; // Replace with your API key. See https://dashboard.tokbox.com/projects
 *  var sessionID = ''; // Replace with your own session ID.
 *                      // See https://dashboard.tokbox.com/projects
 *  var token = ''; // Replace with a generated token that has been assigned the moderator role.
 *                  // See https://dashboard.tokbox.com/projects
 *
 *  var session = OT.initSession(apiKey, sessionID);
 *  session.connect(token, function(error) {
 *    if (error) {
 *      console.log(error.message);
 *    } else {
 *      // This example assumes that a DOM element with the ID 'publisherElement' exists
 *      var publisherProperties = {width: 400, height:300, name:"Bob's stream"};
 *      publisher = OT.initPublisher('publisherElement', publisherProperties);
 *      session.publish(publisher);
 *    }
 *  });
 *  </pre>
 *
 *      <p>This example creates a Publisher object and adds its video to a DOM element
 *      with the ID <code>publisherElement</code> by calling the <code>OT.initPublisher()</code>
 *      method. It then publishes a stream to the session by calling
 *      the <code>publish()</code> method of the Session object.</p>
 *
 * @property {Boolean} accessAllowed Whether the user has granted access to the camera
 * and microphone. The Publisher object dispatches an <code>accessAllowed</code> event when
 * the user grants access. The Publisher object dispatches an <code>accessDenied</code> event
 * when the user denies access.
 * @property {Element} element The HTML DOM element containing the Publisher.
 * @property {String} id The DOM ID of the Publisher.
 * @property {Stream} stream The {@link Stream} object corresponding the the stream of
 * the Publisher.
 * @property {Session} session The {@link Session} to which the Publisher belongs.
 *
 * @see <a href="OT.html#initPublisher">OT.initPublisher</a>
 * @see <a href="Session.html#publish">Session.publish()</a>
 *
 * @class Publisher
 * @augments EventDispatcher
 */
var Publisher = function(options) {
  // Check that the client meets the minimum requirements, if they don't the upgrade
  // flow will be triggered.
  if (!systemRequirements.check()) {
    systemRequirements.upgrade();
    return;
  }

  var _widgetView,
      _videoElementFacade,
      _stream,
      _streamId,
      _webRTCStream,
      _session,
      _publishStartTime,
      _microphone,
      _chrome,
      _audioLevelMeter,
      _properties,
      _validResolutions,
      _state,
      _iceServers,
      _connectivityAttemptPinger,
      _enableSimulcast;

  var _guid = Publisher.nextId();
  var _peerConnections = {};
  var _loaded = false;
  var _validFrameRates = [1, 7, 15, 30];
  var _isScreenSharing = options && (
    options.videoSource === 'screen' ||
    options.videoSource === 'window' ||
    options.videoSource === 'tab' ||
    options.videoSource === 'browser' ||
    options.videoSource === 'application'
  );
  var self = this;

  _properties = OTHelpers.defaults(options || {}, {
    publishAudio: _isScreenSharing ? false : true,
    publishVideo: true,
    mirror: _isScreenSharing ? false : true,
    showControls: true,
    fitMode: _isScreenSharing ? 'contain' : 'cover',
    audioFallbackEnabled: _isScreenSharing ? false : true,
    maxResolution: _isScreenSharing ? { width: 1920, height: 1920 } : undefined
  });

  _validResolutions = {
    '320x240': { width: 320, height: 240 },
    '640x480': { width: 640, height: 480 },
    '1280x720': { width: 1280, height: 720 }
  };

  OTHelpers.eventing(this);

  if (!_isScreenSharing) {
    var audioLevelRunner = new IntervalRunner(function() {
      if (_videoElementFacade) {
        _videoElementFacade.getAudioInputLevel()
          .then(function(audioInputLevel) {
            OTHelpers.requestAnimationFrame(function() {
              self.dispatchEvent(
                new Events.AudioLevelUpdatedEvent(audioInputLevel));
            });
          });
      }
    }, 60);

    this.on({
      'audioLevelUpdated:added': function(count) {
        if (count === 1) {
          audioLevelRunner.start();
        }
      },
      'audioLevelUpdated:removed': function(count) {
        if (count === 0) {
          audioLevelRunner.stop();
        }
      }
    });
  }

  /// Private Methods
  var logAnalyticsEvent = function(action, variation, payload, throttle) {
    analytics.logEvent({
      action: action,
      variation: variation,
      payload: payload,
      sessionId: _session ? _session.sessionId : null,
      connectionId: _session &&
        _session.isConnected() ? _session.connection.connectionId : null,
      partnerId: _session ? _session.apiKey : APIKEY.value,
      streamId: _streamId
    }, throttle);
  };

  var logConnectivityEvent = function(variation, payload) {
    if (variation === 'Attempt' || !_connectivityAttemptPinger) {
      _connectivityAttemptPinger = new ConnectivityAttemptPinger({
        action: 'Publish',
        sessionId: _session ? _session.sessionId : null,
        connectionId: _session &&
          _session.isConnected() ? _session.connection.connectionId : null,
        partnerId: _session ? _session.apiKey : APIKEY.value,
        streamId: _streamId
      });
    }
    if (variation === 'Failure' && payload.reason !== 'Non-fatal') {
      // We don't want to log an invalid sequence in this case because it was a
      // non-fatal failure
      _connectivityAttemptPinger.setVariation(variation);
    }
    logAnalyticsEvent('Publish', variation, payload);
  };

  var logRepublish = function(variation, payload) {
    logAnalyticsEvent('ICERestart', variation, payload);
  };

  var recordQOS = function(connection, parsedStats) {
    var QoSBlob = {
      streamType: 'WebRTC',
      sessionId: _session ? _session.sessionId : null,
      connectionId: _session && _session.isConnected() ?
        _session.connection.connectionId : null,
      partnerId: _session ? _session.apiKey : APIKEY.value,
      streamId: _streamId,
      width: _widgetView ? Number(OTHelpers.width(_widgetView.domElement).replace('px', ''))
        : undefined,
      height: _widgetView ? Number(OTHelpers.height(_widgetView.domElement).replace('px', ''))
        : undefined,
      version: properties.version,
      mediaServerName: _session ? _session.sessionInfo.messagingServer : null,
      p2pFlag: _session ? _session.sessionInfo.p2pEnabled : false,
      duration: _publishStartTime ? new Date().getTime() - _publishStartTime.getTime() : 0,
      remoteConnectionId: connection.id,
      scalableVideo: !!_enableSimulcast
    };
    analytics.logQOS(OTHelpers.extend(QoSBlob, parsedStats));
    self.trigger('qos', parsedStats);
  };

  // Returns the video dimensions. Which could either be the ones that
  // the developer specific in the videoDimensions property, or just
  // whatever the video element reports.
  //
  // If all else fails then we'll just default to 640x480
  //
  var getVideoDimensions = function() {
    var streamWidth, streamHeight;

    // We set the streamWidth and streamHeight to be the minimum of the requested
    // resolution and the actual resolution.
    if (_properties.videoDimensions) {
      streamWidth = Math.min(_properties.videoDimensions.width,
        (_videoElementFacade && _videoElementFacade.videoWidth()) || 640);
      streamHeight = Math.min(_properties.videoDimensions.height,
        (_videoElementFacade && _videoElementFacade.videoHeight()) || 480);
    } else {
      streamWidth = (_videoElementFacade && _videoElementFacade.videoWidth()) || 640;
      streamHeight = (_videoElementFacade && _videoElementFacade.videoHeight()) || 480;
    }

    return {
      width: streamWidth,
      height: streamHeight
    };
  };

  /// Private Events

  var stateChangeFailed = function(changeFailed) {
    logging.error('OT.Publisher State Change Failed: ', changeFailed.message);
    logging.debug(changeFailed);
  };

  var onLoaded = function() {
    if (_state.isDestroyed()) {
      // The publisher was destroyed before loading finished
      return;
    }

    logging.debug('OT.Publisher.onLoaded');

    _state.set('MediaBound');

    // If we have a session and we haven't created the stream yet then
    // wait until that is complete before hiding the loading spinner
    _widgetView.loading(self.session ? !_stream : false);

    _loaded = true;

    createChrome.call(self);

    self.trigger('initSuccess');
    self.trigger('loaded', self);
  };

  var onLoadFailure = function(reason) {
    var errorCode = ExceptionCodes.P2P_CONNECTION_FAILED;
    var payload = {
      reason: 'OT.Publisher PeerConnection Error: ',
      code: errorCode,
      message: reason
    };
    logConnectivityEvent('Failure', payload);

    _state.set('Failed');
    self.trigger('publishComplete', new OTError(errorCode,
      'OT.Publisher PeerConnection Error: ' + reason));

    OTError.handleJsException(
      'OT.Publisher PeerConnection Error: ' + reason,
      ExceptionCodes.P2P_CONNECTION_FAILED,
      {
        session: _session,
        target: self
      }
    );
  };

  // Clean up our LocalMediaStream
  var cleanupLocalStream = function() {
    if (_webRTCStream) {
      // Stop revokes our access cam and mic access for this instance
      // of localMediaStream.
      if (global.MediaStreamTrack && global.MediaStreamTrack.prototype.stop) {
        // Newer spec
        _webRTCStream.getAudioTracks()
                      .concat(_webRTCStream.getVideoTracks())
                      .forEach(function(track) { track.stop(); });
      } else {
        // Older spec
        _webRTCStream.stop();
      }

      _webRTCStream = null;
    }
  };

  var onStreamAvailable = function(webOTStream) {
    logging.debug('OT.Publisher.onStreamAvailable');

    _state.set('BindingMedia');

    cleanupLocalStream();
    _webRTCStream = webOTStream;

    _microphone = new Publisher.Microphone(_webRTCStream, !_properties.publishAudio);
    self.publishVideo(_properties.publishVideo &&
      _webRTCStream.getVideoTracks().length > 0);

    self.accessAllowed = true;
    self.dispatchEvent(new Events.Event(Events.Event.names.ACCESS_ALLOWED, false));

    var videoContainerOptions = {
      muted: true,
      error: onVideoError
    };

    _videoElementFacade = _widgetView.bindVideo(_webRTCStream,
                                      videoContainerOptions,
                                      function(err) {
      if (err) {
        onLoadFailure(err);
        return;
      }

      onLoaded();
    });
  };

  var onPublishingTimeout = function(session) {
    logging.error('OT.Publisher.onPublishingTimeout');

    _state.set('Failed');
    self.trigger('publishComplete', new OTError(ExceptionCodes.UNABLE_TO_PUBLISH,
      'Could not publish in a reasonable amount of time'));

    if (session.isConnected() && self.stream) {
      session._.streamDestroy(self.stream.id);
    }

    // Disconnect immediately, rather than wait for the WebSocket to
    // reply to our destroyStream message.
    self.disconnect();

    self.session = _session = null;

    // We're back to being a stand-alone publisher again.
    if (!_state.isDestroyed()) { _state.set('MediaBound'); }

    if (_connectivityAttemptPinger) {
      _connectivityAttemptPinger.stop();
      _connectivityAttemptPinger = null;
    }

    self._.streamDestroyed('networkDisconnected');

    var payload = {
      reason: 'publish',
      code: ExceptionCodes.UNABLE_TO_PUBLISH,
      message: 'OT.Publisher failed to publish in a reasonable amount of time (timeout)'
    };
    logConnectivityEvent('Failure', payload);

    OTError.handleJsException(
      payload.reason,
      payload.code,
      {
        session: _session,
        target: self
      }
    );
  };

  var onStreamAvailableError = function(error) {
    logging.error('OT.Publisher.onStreamAvailableError ' + error.name + ': ' + error.message);

    _state.set('Failed');
    self.trigger('publishComplete', new OTError(ExceptionCodes.UNABLE_TO_PUBLISH,
        error.message));

    if (_widgetView) { _widgetView.destroy(); }

    var payload = {
      reason: 'GetUserMedia',
      code: ExceptionCodes.UNABLE_TO_PUBLISH,
      message: 'OT.Publisher failed to access camera/mic: ' + error.message
    };
    logConnectivityEvent('Failure', payload);

    OTError.handleJsException(
      payload.reason,
      payload.code,
      {
        session: _session,
        target: self
      }
    );
  };

  var onScreenSharingError = function(error) {
    logging.error('OT.Publisher.onScreenSharingError ' + error.message);
    _state.set('Failed');

    self.trigger('publishComplete', new OTError(ExceptionCodes.UNABLE_TO_PUBLISH,
      'Screensharing: ' + error.message));

    var payload = {
      reason: 'ScreenSharing',
      message: error.message
    };
    logConnectivityEvent('Failure', payload);
  };

  // The user has clicked the 'deny' button the the allow access dialog
  // (or it's set to always deny)
  var onAccessDenied = function(error) {
    if (_isScreenSharing) {
      if (global.location.protocol !== 'https:') {
        /**
         * in http:// the browser will deny permission without asking the
         * user. There is also no way to tell if it was denied by the
         * user, or prevented from the browser.
         */
        error.message += ' Note: https:// is required for screen sharing.';
      }
    }

    logging.error('OT.Publisher.onStreamAvailableError Permission Denied');

    _state.set('Failed');
    var errorMessage = 'OT.Publisher Access Denied: Permission Denied' +
        (error.message ? ': ' + error.message : '');
    var errorCode = ExceptionCodes.UNABLE_TO_PUBLISH;
    self.trigger('publishComplete', new OTError(errorCode, errorMessage));

    var payload = {
      reason: 'GetUserMedia',
      code: errorCode,
      message: errorMessage
    };
    logConnectivityEvent('Failure', payload);

    self.dispatchEvent(new Events.Event(Events.Event.names.ACCESS_DENIED));
  };

  var onAccessDialogOpened = function() {
    logAnalyticsEvent('accessDialog', 'Opened');

    self.dispatchEvent(new Events.Event(Events.Event.names.ACCESS_DIALOG_OPENED, true));
  };

  var onAccessDialogClosed = function() {
    logAnalyticsEvent('accessDialog', 'Closed');

    self.dispatchEvent(new Events.Event(Events.Event.names.ACCESS_DIALOG_CLOSED, false));
  };

  var onVideoError = function(errorCode, errorReason) {
    logging.error('OT.Publisher.onVideoError');

    var message = errorReason + (errorCode ? ' (' + errorCode + ')' : '');
    logAnalyticsEvent('stream', null, { reason: 'OT.Publisher while playing stream: ' + message });

    _state.set('Failed');

    if (_state.isAttemptingToPublish()) {
      self.trigger('publishComplete', new OTError(ExceptionCodes.UNABLE_TO_PUBLISH,
          message));
    } else {
      self.trigger('error', message);
    }

    OTError.handleJsException('OT.Publisher error playing stream: ' + message,
    ExceptionCodes.UNABLE_TO_PUBLISH, {
      session: _session,
      target: self
    });
  };

  var onPeerDisconnected = function(peerConnection) {
    logging.debug('Subscriber has been disconnected from the Publisher\'s PeerConnection');

    self.cleanupSubscriber(peerConnection.remoteConnection().id);
  };

  var onPeerConnectionFailure = function(code, reason, peerConnection, prefix) {
    var errorCode;
    if (prefix === 'ICEWorkflow') {
      errorCode = ExceptionCodes.PUBLISHER_ICE_WORKFLOW_FAILED;
    } else {
      errorCode = ExceptionCodes.UNABLE_TO_PUBLISH;
    }
    var payload = {
      reason: prefix ? prefix : 'PeerConnectionError',
      code: errorCode,
      message: (prefix ? prefix : '') + ':Publisher PeerConnection with connection ' +
        (peerConnection && peerConnection.remoteConnection &&
        peerConnection.remoteConnection().id) + ' failed: ' + reason,
      hasRelayCandidates: peerConnection.hasRelayCandidates()
    };
    if (_state.isPublishing()) {
      // We're already publishing so this is a Non-fatal failure, must be p2p and one of our
      // peerconnections failed
      payload.reason = 'Non-fatal';
    } else {
      self.trigger('publishComplete', new OTError(ExceptionCodes.UNABLE_TO_PUBLISH,
          payload.message));
    }
    logConnectivityEvent('Failure', payload);

    OTError.handleJsException('OT.Publisher PeerConnection Error: ' + reason, errorCode, {
      session: _session,
      target: self
    });

    // We don't call cleanupSubscriber as it also logs a
    // disconnected analytics event, which we don't want in this
    // instance. The duplication is crufty though and should
    // be tidied up.

    delete _peerConnections[peerConnection.remoteConnection().id];
  };

  var onIceRestartSuccess = function(connectionId) {
    logRepublish('Success', { remoteConnectionId: connectionId });
  };

  var onIceRestartFailure = function(connectionId) {
    logRepublish('Failure', {
      reason: 'ICEWorkflow',
      message: 'OT.Publisher PeerConnection Error: ' +
        'The stream was unable to connect due to a network error.' +
        ' Make sure your connection isn\'t blocked by a firewall.',
      remoteConnectionId: connectionId
    });
  };

  /// Private Helpers

  // Assigns +stream+ to this publisher. The publisher listens
  // for a bunch of events on the stream so it can respond to
  // changes.
  var assignStream = function(stream) {
    self.stream = _stream = stream;
    _stream.on('destroyed', self.disconnect, self);

    _state.set('Publishing');
    _widgetView.loading(!_loaded);
    _publishStartTime = new Date();

    self.trigger('publishComplete', null, self);

    self.dispatchEvent(new Events.StreamEvent('streamCreated', stream, null, false));

    var payload = {
      streamType: 'WebRTC'
    };
    logConnectivityEvent('Success', payload);
  };

  var createPeerConnectionForRemote = function(remoteConnection, uri, completion) {
    var peerConnection = _peerConnections[remoteConnection.id];

    if (!peerConnection) {
      var startConnectingTime = OTHelpers.now();

      logAnalyticsEvent('createPeerConnection', 'Attempt');

      // Cleanup our subscriber when they disconnect
      remoteConnection.on('destroyed',
        self.cleanupSubscriber.bind(self, remoteConnection.id));

      // Calculate the number of streams to use. 1 for normal, >1 for Simulcast
      var numberOfSimulcastStreams = 1;

      _enableSimulcast = false;
      if (OTHelpers.env.name === 'Chrome' && !_isScreenSharing &&
        !_session.sessionInfo.p2pEnabled &&
        _properties.constraints.video) {
        // We only support simulcast on Chrome, and when not using
        // screensharing.
        _enableSimulcast = _session.sessionInfo.simulcast;

        if (_enableSimulcast === void 0) {
          // If there is no session wide preference then allow the
          // developer to choose.
          _enableSimulcast = options && options._enableSimulcast;
        }
      }

      if (_enableSimulcast) {
        var streamDimensions = getVideoDimensions();
        // HD and above gets three streams. Otherwise they get 2.
        if (streamDimensions.width > 640 &&
            streamDimensions.height > 480) {
          numberOfSimulcastStreams = 3;
        } else {
          numberOfSimulcastStreams = 2;
        }
      }

      peerConnection = _peerConnections[remoteConnection.id] = (
        new Publisher.PublisherPeerConnection(
          remoteConnection,
          _session,
          _streamId,
          _webRTCStream,
          _properties.channels,
          numberOfSimulcastStreams,
          uri
        )
      );

      peerConnection.on({
        connected: function() {
          var payload = {
            pcc: parseInt(OTHelpers.now() - startConnectingTime, 10),
            hasRelayCandidates: peerConnection.hasRelayCandidates()
          };
          logAnalyticsEvent('createPeerConnection', 'Success', payload);
        },
        disconnected: onPeerDisconnected,
        error: onPeerConnectionFailure,
        qos: recordQOS,
        iceRestartSuccess: onIceRestartSuccess.bind(undefined, remoteConnection.id),
        iceRestartFailure: onIceRestartFailure.bind(undefined, remoteConnection.id)
      });

      peerConnection.init(_iceServers, completion);
    } else {
      OTHelpers.callAsync(function() {
        completion(undefined, peerConnection);
      });
    }
  };

  /// Chrome

  // If mode is false, then that is the mode. If mode is true then we'll
  // definitely display  the button, but we'll defer the model to the
  // Publishers buttonDisplayMode style property.
  var chromeButtonMode = function(mode) {
    if (mode === false) { return 'off'; }

    var defaultMode = self.getStyle('buttonDisplayMode');

    // The default model is false, but it's overridden by +mode+ being true
    if (defaultMode === false) { return 'on'; }

    // defaultMode is either true or auto.
    return defaultMode;
  };

  var updateChromeForStyleChange = function(key, value) {
    if (!_chrome) { return; }

    switch (key) {
      case 'nameDisplayMode':
        _chrome.name.setDisplayMode(value);
        _chrome.backingBar.setNameMode(value);
        break;

      case 'showArchiveStatus':
        logAnalyticsEvent('showArchiveStatus', 'styleChange', { mode: value ? 'on' : 'off' });
        _chrome.archive.setShowArchiveStatus(value);
        break;

      case 'buttonDisplayMode':
        _chrome.muteButton.setDisplayMode(value);
        _chrome.backingBar.setMuteMode(value);
        break;

      case 'audioLevelDisplayMode':
        _chrome.audioLevel.setDisplayMode(value);
        break;

      case 'backgroundImageURI':
        _widgetView.setBackgroundImageURI(value);
        break;

      default:
    }
  };

  var createChrome = function() {

    if (!self.getStyle('showArchiveStatus')) {
      logAnalyticsEvent('showArchiveStatus', 'createChrome', { mode: 'off' });
    }

    var widgets = {
      backingBar: new BackingBar({
        nameMode: !_properties.name ? 'off' : self.getStyle('nameDisplayMode'),
        muteMode: chromeButtonMode.call(self, self.getStyle('buttonDisplayMode'))
      }),

      name: new NamePanel({
        name: _properties.name,
        mode: self.getStyle('nameDisplayMode')
      }),

      archive: new Archiving({
        show: self.getStyle('showArchiveStatus'),
        archiving: false
      })
    };

    if (!(_properties.audioSource === null || _properties.audioSource === false)) {
      widgets.muteButton = new MuteButton({
        muted: _properties.publishAudio === false,
        mode: chromeButtonMode.call(self, self.getStyle('buttonDisplayMode'))
      });
    }

    var audioLevelTransformer = new AudioLevelTransformer();

    var audioLevelUpdatedHandler = function(evt) {
      _audioLevelMeter.setValue(audioLevelTransformer.transform(evt.audioLevel));
    };

    _audioLevelMeter = new AudioLevelMeter({
      mode: self.getStyle('audioLevelDisplayMode'),
      onActivate: function() {
        self.on('audioLevelUpdated', audioLevelUpdatedHandler);
      },
      onPassivate: function() {
        self.off('audioLevelUpdated', audioLevelUpdatedHandler);
      }
    });

    widgets.audioLevel = _audioLevelMeter;

    _chrome = new Chrome({
      parent: _widgetView.domElement
    }).set(widgets).on({
      muted: self.publishAudio.bind(self, false),
      unmuted: self.publishAudio.bind(self, true)
    });

    updateAudioLevelMeterDisplay();
  };

  var reset = function() {
    if (_chrome) {
      _chrome.destroy();
      _chrome = null;
    }

    self.disconnect();

    _microphone = null;

    if (_videoElementFacade) {
      _videoElementFacade.destroy();
      _videoElementFacade = null;
    }

    cleanupLocalStream();

    if (_widgetView) {
      _widgetView.destroy();
      _widgetView = null;
    }

    if (_session) {
      self._.unpublishFromSession(_session, 'reset');
    }

    self.id = null;
    self.stream = _stream = null;
    _loaded = false;

    self.session = _session = null;

    if (!_state.isDestroyed()) { _state.set('NotPublishing'); }
  };

  StylableComponent(self, {
    showArchiveStatus: true,
    nameDisplayMode: 'auto',
    buttonDisplayMode: 'auto',
    audioLevelDisplayMode: _isScreenSharing ? 'off' : 'auto',
    backgroundImageURI: null
  }, _properties.showControls, function(payload) {
    logAnalyticsEvent('SetStyle', 'Publisher', payload, 0.1);
  });

  var updateAudioLevelMeterDisplay = function() {
    if (_audioLevelMeter && self.getStyle('audioLevelDisplayMode') === 'auto') {
      if (!_properties.publishVideo && _properties.publishAudio) {
        _audioLevelMeter.show();
      } else {
        _audioLevelMeter.hide();
      }
    }
  };

  var setAudioOnly = function(audioOnly) {
    if (_widgetView) {
      _widgetView.audioOnly(audioOnly);
      _widgetView.showPoster(audioOnly);
    }

    updateAudioLevelMeterDisplay();
  };

  this.publish = function(targetElement) {
    logging.debug('OT.Publisher: publish');

    if (_state.isAttemptingToPublish() || _state.isPublishing()) {
      reset();
    }
    _state.set('GetUserMedia');

    if (!_properties.constraints) {
      _properties.constraints = OTHelpers.clone(defaultConstraints);

      if (_isScreenSharing) {
        if (_properties.audioSource != null) {
          logging.warn('Invalid audioSource passed to Publisher - when using screen sharing no ' +
            'audioSource may be used');
        }
        _properties.audioSource = null;
      }

      if (_properties.audioSource === null || _properties.audioSource === false) {
        _properties.constraints.audio = false;
        _properties.publishAudio = false;
      } else {
        if (typeof _properties.audioSource === 'object') {
          if (_properties.audioSource.deviceId != null) {
            _properties.audioSource = _properties.audioSource.deviceId;
          } else {
            logging.warn('Invalid audioSource passed to Publisher. Expected either a device ID');
          }
        }

        if (_properties.audioSource) {
          if (typeof _properties.constraints.audio !== 'object') {
            _properties.constraints.audio = {};
          }
          if (!_properties.constraints.audio.mandatory) {
            _properties.constraints.audio.mandatory = {};
          }
          if (!_properties.constraints.audio.optional) {
            _properties.constraints.audio.optional = [];
          }
          _properties.constraints.audio.mandatory.sourceId =
            _properties.audioSource;
        }
      }

      if (_properties.videoSource === null || _properties.videoSource === false) {
        _properties.constraints.video = false;
        _properties.publishVideo = false;
      } else {

        if (typeof _properties.videoSource === 'object' &&
          _properties.videoSource.deviceId == null) {
          logging.warn('Invalid videoSource passed to Publisher. Expected either a device ' +
            'ID or device.');
          _properties.videoSource = null;
        }

        var _setupVideoDefaults = function() {
          if (typeof _properties.constraints.video !== 'object') {
            _properties.constraints.video = {};
          }
          if (!_properties.constraints.video.mandatory) {
            _properties.constraints.video.mandatory = {};
          }
          if (!_properties.constraints.video.optional) {
            _properties.constraints.video.optional = [];
          }

          if (OTHelpers.env.name === 'Firefox') {
            _properties.constraints.video.width = {};
            _properties.constraints.video.height = {};
            _properties.constraints.video.frameRate = {};
          }
        };

        if (_properties.videoSource) {
          // _isScreenSharing is handled by the extension helpers
          if (!_isScreenSharing) {
            _setupVideoDefaults();
            var mandatory = _properties.constraints.video.mandatory;
            if (_properties.videoSource.deviceId != null) {
              mandatory.sourceId = _properties.videoSource.deviceId;
            } else {
              mandatory.sourceId = _properties.videoSource;
            }
          }
        }

        if (_properties.resolution) {
          if (!_validResolutions.hasOwnProperty(_properties.resolution)) {
            logging.warn('Invalid resolution passed to the Publisher. Got: ' +
              _properties.resolution + ' expecting one of "' +
              Object.keys(_validResolutions).join('","') + '"');
          } else {
            _properties.videoDimensions = _validResolutions[_properties.resolution];
            if (OTHelpers.env.name === 'Chrome' || OTPlugin.isInstalled()) {
              _setupVideoDefaults();
              _properties.constraints.video.optional =
                _properties.constraints.video.optional.concat([
                  { minWidth: _properties.videoDimensions.width },
                  { maxWidth: _properties.videoDimensions.width },
                  { minHeight: _properties.videoDimensions.height },
                  { maxHeight: _properties.videoDimensions.height }
                ]);
            } else if (OTHelpers.env.name === 'Firefox') {
              _setupVideoDefaults();
              _properties.constraints.video.width.ideal =
                _properties.videoDimensions.width;
              _properties.constraints.video.height.ideal =
                _properties.videoDimensions.height;
            }
          }
        }

        if (_properties.maxResolution) {
          _setupVideoDefaults();
          if (_properties.maxResolution.width > 1920) {
            logging.warn(
              'Invalid maxResolution passed to the Publisher. maxResolution.width must ' +
              'be less than or equal to 1920'
            );

            _properties.maxResolution.width = 1920;
          }
          if (_properties.maxResolution.height > 1920) {
            logging.warn(
              'Invalid maxResolution passed to the Publisher. maxResolution.height must ' +
              'be less than or equal to 1920'
            );

            _properties.maxResolution.height = 1920;
          }

          _properties.videoDimensions = _properties.maxResolution;

          if (OTHelpers.env.name === 'Chrome') {
            _setupVideoDefaults();
            _properties.constraints.video.mandatory.maxWidth =
              _properties.videoDimensions.width;
            _properties.constraints.video.mandatory.maxHeight =
              _properties.videoDimensions.height;
          } else if (OTHelpers.env.name === 'Firefox') {
            _setupVideoDefaults();
            _properties.constraints.video.width.max =
              _properties.videoDimensions.width;
            _properties.constraints.video.height.max =
              _properties.videoDimensions.height;
          }
          // We do not support this in Firefox yet
        }

        if (_properties.frameRate !== void 0 &&
          _validFrameRates.indexOf(_properties.frameRate) === -1) {
          logging.warn('Invalid frameRate passed to the publisher got: ' +
          _properties.frameRate + ' expecting one of ' + _validFrameRates.join(','));
          delete _properties.frameRate;
        } else if (_properties.frameRate) {
          _setupVideoDefaults();
          if (OTHelpers.env.name === 'Chrome' || OTPlugin.isInstalled()) {
            _properties.constraints.video.optional =
              _properties.constraints.video.optional.concat([
                { minFrameRate: _properties.frameRate },
                { maxFrameRate: _properties.frameRate }
              ]);
          } else if (OTHelpers.env.name === 'Firefox') {
            _properties.constraints.video.frameRate.ideal = _properties.frameRate;
          }
        }

      }

    } else {
      logging.warn('You have passed your own constraints not using ours');
    }

    if (_properties.style) {
      self.setStyle(_properties.style, null, true);
    }

    if (_properties.name) {
      _properties.name = _properties.name.toString();
    }

    _properties.classNames = 'OT_root OT_publisher';

    // Defer actually creating the publisher DOM nodes until we know
    // the DOM is actually loaded.
    EnvironmentLoader.onLoad(function() {
      _widgetView = new Publisher.WidgetView(targetElement, _properties);
      self.id = _widgetView.domId();
      self.element = _widgetView.domElement;

      _widgetView.on('videoDimensionsChanged', function(oldValue, newValue) {
        if (_stream) {
          _stream.setVideoDimensions(newValue.width, newValue.height);
        }
        self.dispatchEvent(
          new Events.VideoDimensionsChangedEvent(self, oldValue, newValue)
        );
      });

      _widgetView.on('mediaStopped', function() {
        var event = new Events.MediaStoppedEvent(self);

        self.dispatchEvent(event, function() {
          if (!event.isDefaultPrevented()) {
            if (_session) {
              self._.unpublishFromSession(_session, 'mediaStopped');
            } else {
              self.destroy('mediaStopped');
            }
          }
        });
      });

      OTHelpers.waterfall([
        function(cb) {
          if (_isScreenSharing) {
            screenSharing.checkCapability(function(response) {
              if (!response.supported) {
                onScreenSharingError(
                  new Error('Screen Sharing is not supported in this browser')
                );
              } else if (response.extensionRegistered === false) {
                onScreenSharingError(
                  new Error('Screen Sharing support in this browser requires an extension, but ' +
                    'one has not been registered.')
                );
              } else if (response.extensionInstalled === false) {
                onScreenSharingError(
                  new Error('Screen Sharing support in this browser requires an extension, but ' +
                    'the extension is not installed.')
                );
              } else {

                var helper = screenSharing.pickHelper();

                if (helper.proto.getConstraintsShowsPermissionUI) {
                  onAccessDialogOpened();
                }

                helper.instance.getConstraints(options.videoSource, _properties.constraints,
                  function(err, constraints) {
                  if (helper.proto.getConstraintsShowsPermissionUI) {
                    onAccessDialogClosed();
                  }
                  if (err) {
                    if (err.message === 'PermissionDeniedError') {
                      onAccessDenied(err);
                    } else {
                      onScreenSharingError(err);
                    }
                  } else {
                    _properties.constraints = constraints;
                    cb();
                  }
                });
              }
            });
          } else {
            deviceHelpers.shouldAskForDevices(function(devices) {
              if (!devices.video) {
                logging.warn('Setting video constraint to false, there are no video sources');
                _properties.constraints.video = false;
              }
              if (!devices.audio) {
                logging.warn('Setting audio constraint to false, there are no audio sources');
                _properties.constraints.audio = false;
              }
              cb();
            });
          }
        },

        function() {

          if (_state.isDestroyed()) {
            return;
          }

          Publisher.getUserMedia(
            _properties.constraints,
            onStreamAvailable,
            onStreamAvailableError,
            onAccessDialogOpened,
            onAccessDialogClosed,
            onAccessDenied
          );
        }

      ]);

    }, self);

    return self;
  };

  /**
  * Starts publishing audio (if it is currently not being published)
  * when the <code>value</code> is <code>true</code>; stops publishing audio
  * (if it is currently being published) when the <code>value</code> is <code>false</code>.
  *
  * @param {Boolean} value Whether to start publishing audio (<code>true</code>)
  * or not (<code>false</code>).
  *
  * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
  * @see <a href="Stream.html#hasAudio">Stream.hasAudio</a>
  * @see StreamPropertyChangedEvent
  * @method #publishAudio
  * @memberOf Publisher
*/
  this.publishAudio = function(value) {
    _properties.publishAudio = value;

    if (_microphone) {
      _microphone.muted(!value);
    }

    if (_chrome) {
      _chrome.muteButton.muted(!value);
    }

    if (_session && _stream) {
      _stream.setChannelActiveState('audio', value);
    }

    updateAudioLevelMeterDisplay();

    return self;
  };

  /**
  * Starts publishing video (if it is currently not being published)
  * when the <code>value</code> is <code>true</code>; stops publishing video
  * (if it is currently being published) when the <code>value</code> is <code>false</code>.
  *
  * @param {Boolean} value Whether to start publishing video (<code>true</code>)
  * or not (<code>false</code>).
  *
  * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
  * @see <a href="Stream.html#hasVideo">Stream.hasVideo</a>
  * @see StreamPropertyChangedEvent
  * @method #publishVideo
  * @memberOf Publisher
*/
  this.publishVideo = function(value) {
    var oldValue = _properties.publishVideo;
    _properties.publishVideo = value;

    if (_session && _stream && _properties.publishVideo !== oldValue) {
      _stream.setChannelActiveState('video', value);
    }

    // We currently do this event if the value of publishVideo has not changed
    // This is because the state of the video tracks enabled flag may not match
    // the value of publishVideo at this point. This will be tidied up shortly.
    if (_webRTCStream) {
      var videoTracks = _webRTCStream.getVideoTracks();
      for (var i = 0, num = videoTracks.length; i < num; ++i) {
        videoTracks[i].setEnabled(value);
      }
    }

    setAudioOnly(!value);

    return self;
  };

  /**
  * Deletes the Publisher object and removes it from the HTML DOM.
  * <p>
  * The Publisher object dispatches a <code>destroyed</code> event when the DOM
  * element is removed.
  * </p>
  * @method #destroy
  * @memberOf Publisher
  * @return {Publisher} The Publisher.
  */
  this.destroy = function(/* unused */ reason, quiet) {
    if (_state.isAttemptingToPublish()) {
      if (_connectivityAttemptPinger) {
        _connectivityAttemptPinger.stop();
        _connectivityAttemptPinger = null;
      }
      if (_session) {
        logConnectivityEvent('Canceled');
      }
    }

    if (_state.isDestroyed()) { return self; }
    _state.set('Destroyed');

    reset();

    if (quiet !== true) {
      self.dispatchEvent(
        new Events.DestroyedEvent(
          Events.Event.names.PUBLISHER_DESTROYED,
          self,
          reason
        ),
        self.off.bind(self)
      );
    }

    return self;
  };

  /**
  * @methodOf Publisher
  * @private
  */
  this.disconnect = function() {
    // Close the connection to each of our subscribers
    for (var fromConnectionId in _peerConnections) {
      if (_peerConnections.hasOwnProperty(fromConnectionId)) {
        self.cleanupSubscriber(fromConnectionId);
      }
    }
  };

  this.cleanupSubscriber = function(fromConnectionId) {
    var pc = _peerConnections[fromConnectionId];

    if (pc) {
      pc.destroy();
      delete _peerConnections[fromConnectionId];

      logAnalyticsEvent('disconnect', 'PeerConnection',
        { subscriberConnection: fromConnectionId });
    }
  };

  this.processMessage = function(type, fromConnection, message) {
    logging.debug('OT.Publisher.processMessage: Received ' + type + ' from ' + fromConnection.id);
    logging.debug(message);

    switch (type) {
      case 'unsubscribe':
        self.cleanupSubscriber(message.content.connection.id);
        break;

      default:
        createPeerConnectionForRemote(fromConnection, message.uri, function(err, peerConnection) {
          if (err) {
            throw err;
          }

          peerConnection.processMessage(type, message);
        });
    }
  };

  /**
  * Returns the base-64-encoded string of PNG data representing the Publisher video.
  *
  *   <p>You can use the string as the value for a data URL scheme passed to the src parameter of
  *   an image file, as in the following:</p>
  *
  * <pre>
  *  var imgData = publisher.getImgData();
  *
  *  var img = document.createElement("img");
  *  img.setAttribute("src", "data:image/png;base64," + imgData);
  *  var imgWin = window.open("about:blank", "Screenshot");
  *  imgWin.document.write("&lt;body&gt;&lt;/body&gt;");
  *  imgWin.document.body.appendChild(img);
  * </pre>
  *
  * @method #getImgData
  * @memberOf Publisher
  * @return {String} The base-64 encoded string. Returns an empty string if there is no video.
  */

  this.getImgData = function() {
    if (!_loaded) {
      logging.error(
        'OT.Publisher.getImgData: Cannot getImgData before the Publisher is publishing.'
      );

      return null;
    }

    return _videoElementFacade.imgData();
  };

  // API Compatibility layer for Flash Publisher, this could do with some tidyup.
  this._ = {
    publishToSession: function(session) {
      // Add session property to Publisher
      self.session = _session = session;

      _streamId = uuid();
      var createStream = function() {

        // Bail if this.session is gone, it means we were unpublished
        // before createStream could finish.
        if (!_session) { return; }

        // make sure we trigger an error if we are not getting any "ack" after a reasonable
        // amount of time
        var publishGuardingTo = setTimeout(function() {
          onPublishingTimeout(session);
        }, PUBLISH_MAX_DELAY);

        self.on('publishComplete', function() {
          clearTimeout(publishGuardingTo);
        });


        _state.set('PublishingToSession');

        var onStreamRegistered = function(err, streamId, message) {
          if (err) {
            // @todo we should respect err.code here and translate it to the local
            // client equivalent.
            var errorCode, errorMessage;
            var knownErrorCodes = [403, 404, 409];
            if (err.code && knownErrorCodes.indexOf(err.code) > -1) {
              errorCode = ExceptionCodes.UNABLE_TO_PUBLISH;
              errorMessage = err.message;
            } else {
              errorCode = ExceptionCodes.UNEXPECTED_SERVER_RESPONSE;
              errorMessage = 'Unexpected server response. Try this operation again later.';
            }

            var payload = {
              reason: 'Publish',
              code: errorCode,
              message: errorMessage
            };
            logConnectivityEvent('Failure', payload);
            if (_state.isAttemptingToPublish()) {
              self.trigger('publishComplete', new OTError(errorCode, errorMessage));
            }

            OTError.handleJsException(
              err.message,
              errorCode,
              {
                session: _session,
                target: self
              }
            );

            return;
          }

          self.streamId = _streamId = streamId;
          _iceServers = parseIceServers(message);
        };

        var streamDimensions = getVideoDimensions();
        var streamChannels = [];

        if (!(_properties.videoSource === null || _properties.videoSource === false)) {
          streamChannels.push(new StreamChannel({
            id: 'video1',
            type: 'video',
            active: _properties.publishVideo,
            orientation: VideoOrientation.ROTATED_NORMAL,
            frameRate: _properties.frameRate,
            width: streamDimensions.width,
            height: streamDimensions.height,
            source: _isScreenSharing ? 'screen' : 'camera',
            fitMode: _properties.fitMode
          }));
        }

        if (!(_properties.audioSource === null || _properties.audioSource === false)) {
          streamChannels.push(new StreamChannel({
            id: 'audio1',
            type: 'audio',
            active: _properties.publishAudio
          }));
        }

        session._.streamCreate(_properties.name || '', _streamId,
          _properties.audioFallbackEnabled, streamChannels, onStreamRegistered);
      };

      if (_loaded) {
        createStream.call(self);
      } else {
        self.on('initSuccess', createStream, self);
      }

      logConnectivityEvent('Attempt', {
        streamType: 'WebRTC',
        dataChannels: _properties.channels
      });

      return self;
    },

    unpublishFromSession: function(session, reason) {
      if (!_session || session.id !== _session.id) {
        logging.warn('The publisher ' + _guid + ' is trying to unpublish from a session ' +
          session.id + ' it is not attached to (it is attached to ' +
          (_session && _session.id || 'no session') + ')');
        return self;
      }

      if (session.isConnected() && self.stream) {
        session._.streamDestroy(self.stream.id);
      }

      // Disconnect immediately, rather than wait for the WebSocket to
      // reply to our destroyStream message.
      self.disconnect();
      if (_state.isAttemptingToPublish()) {
        logConnectivityEvent('Canceled');
      }
      self.session = _session = null;

      // We're back to being a stand-alone publisher again.
      if (!_state.isDestroyed()) { _state.set('MediaBound'); }

      if (_connectivityAttemptPinger) {
        _connectivityAttemptPinger.stop();
        _connectivityAttemptPinger = null;
      }
      logAnalyticsEvent('unpublish', 'Success', { sessionId: session.id });

      self._.streamDestroyed(reason);

      return self;
    },

    streamDestroyed: function(reason) {
      if (['reset'].indexOf(reason) < 0) {
        var event = new Events.StreamEvent('streamDestroyed', _stream, reason, true);
        var defaultAction = function() {
          if (!event.isDefaultPrevented()) {
            self.destroy();
          }
        };
        self.dispatchEvent(event, defaultAction);
      }
    },

    archivingStatus: function(status) {
      if (_chrome) {
        _chrome.archive.setArchiving(status);
      }

      return status;
    },

    webRtcStream: function() {
      return _webRTCStream;
    },

    switchTracks: function() {
      return new Bluebird.Promise(function(resolve, reject) {
        Publisher.getUserMedia(
          _properties.constraints,
          function(newStream) {

            cleanupLocalStream();
            _webRTCStream = newStream;

            _microphone = new Publisher.Microphone(_webRTCStream, !_properties.publishAudio);

            var videoContainerOptions = {
              muted: true,
              error: onVideoError
            };

            _videoElementFacade = _widgetView.bindVideo(_webRTCStream, videoContainerOptions,
              function(err) {
                if (err) {
                  onLoadFailure(err);
                  reject(err);
                }
              });

            var replacePromises = [];

            Object.keys(_peerConnections).forEach(function(connectionId) {
              var peerConnection = _peerConnections[connectionId];
              peerConnection.getSenders().forEach(function(sender) {
                if (sender.track.kind === 'audio' && newStream.getAudioTracks().length) {
                  replacePromises.push(sender.replaceTrack(newStream.getAudioTracks()[0]));
                } else if (sender.track.kind === 'video' && newStream.getVideoTracks().length) {
                  replacePromises.push(sender.replaceTrack(newStream.getVideoTracks()[0]));
                }
              });
            });

            Bluebird.all(replacePromises).then(resolve, reject);
          },
          function(error) {
            onStreamAvailableError(error);
            reject(error);
          },
          onAccessDialogOpened,
          onAccessDialogClosed,
          function(error) {
            onAccessDenied(error);
            reject(error);
          });
      });
    },

    /**
     * @param {string=} windowId
     */
    switchAcquiredWindow: function(windowId) {

      if (OTHelpers.env.name !== 'Firefox' || OTHelpers.env.version < 38) {
        throw new Error('switchAcquiredWindow is an experimental method and is not supported by' +
        'the current platform');
      }

      if (typeof windowId !== 'undefined') {
        _properties.constraints.video.browserWindow = windowId;
      }

      logAnalyticsEvent('SwitchAcquiredWindow', 'Attempt', {
        constraints: _properties.constraints
      });

      var switchTracksPromise = self._.switchTracks();

      // "listening" promise completion just for analytics
      switchTracksPromise.then(function() {
        logAnalyticsEvent('SwitchAcquiredWindow', 'Success', {
          constraints: _properties.constraints
        });
      }, function(error) {
        logAnalyticsEvent('SwitchAcquiredWindow', 'Failure', {
          error: error,
          constraints: _properties.constraints
        });
      });

      return switchTracksPromise;
    },

    getDataChannel: function(label, options, completion) {
      var pc = _peerConnections[Object.keys(_peerConnections)[0]];

      // @fixme this will fail if it's called before we have a PublisherPeerConnection.
      // I.e. before we have a subscriber.
      if (!pc) {
        completion(new OTHelpers.Error(
          'Cannot create a DataChannel before there is a subscriber.'
        ));

        return;
      }

      pc.getDataChannel(label, options, completion);
    },

    iceRestart: function(force) {
      var peerConnection, fromConnectionId;
      for (fromConnectionId in _peerConnections) {
        if (_peerConnections.hasOwnProperty(fromConnectionId)) {
          peerConnection = _peerConnections[fromConnectionId];
          if (force || !peerConnection.iceConnectionStateIsConnected()) {
            logRepublish('Attempt', { remoteConnectionId: fromConnectionId });
            _peerConnections[fromConnectionId].createOfferWithIceRestart();
          } else {
            logging.debug('Publisher: Skipping ice restart for ' + fromConnectionId +
              ', we are connected.');
          }
        }
      }
    }
  };

  this.detectDevices = function() {
    logging.warn('Fixme: Haven\'t implemented detectDevices');
  };

  this.detectMicActivity = function() {
    logging.warn('Fixme: Haven\'t implemented detectMicActivity');
  };

  this.getEchoCancellationMode = function() {
    logging.warn('Fixme: Haven\'t implemented getEchoCancellationMode');
    return 'fullDuplex';
  };

  this.setMicrophoneGain = function() {
    logging.warn('Fixme: Haven\'t implemented setMicrophoneGain');
  };

  this.getMicrophoneGain = function() {
    logging.warn('Fixme: Haven\'t implemented getMicrophoneGain');
    return 0.5;
  };

  this.setCamera = function() {
    logging.warn('Fixme: Haven\'t implemented setCamera');
  };

  this.setMicrophone = function() {
    logging.warn('Fixme: Haven\'t implemented setMicrophone');
  };

  // Platform methods:

  this.guid = function() {
    return _guid;
  };

  this.videoElement = function() {
    return _videoElementFacade.domElement();
  };

  this.setStream = assignStream;

  this.isWebRTC = true;

  this.isLoading = function() {
    return _widgetView && _widgetView.loading();
  };

  /**
  * Returns the width, in pixels, of the Publisher video. This may differ from the
  * <code>resolution</code> property passed in as the <code>properties</code> property
  * the options passed into the <code>OT.initPublisher()</code> method, if the browser
  * does not support the requested resolution.
  *
  * @method #videoWidth
  * @memberOf Publisher
  * @return {Number} the width, in pixels, of the Publisher video.
  */
  this.videoWidth = function() {
    return _videoElementFacade.videoWidth();
  };

  /**
  * Returns the height, in pixels, of the Publisher video. This may differ from the
  * <code>resolution</code> property passed in as the <code>properties</code> property
  * the options passed into the <code>OT.initPublisher()</code> method, if the browser
  * does not support the requested resolution.
  *
  * @method #videoHeight
  * @memberOf Publisher
  * @return {Number} the height, in pixels, of the Publisher video.
  */
  this.videoHeight = function() {
    return _videoElementFacade.videoHeight();
  };

  // Make read-only: element, guid, _.webRtcStream

  this.on('styleValueChanged', updateChromeForStyleChange, this);
  _state = new PublishingState(stateChangeFailed);

  this.accessAllowed = false;

  /**
  * Dispatched when the user has clicked the Allow button, granting the
  * app access to the camera and microphone. The Publisher object has an
  * <code>accessAllowed</code> property which indicates whether the user
  * has granted access to the camera and microphone.
  * @see Event
  * @name accessAllowed
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the user has clicked the Deny button, preventing the
  * app from having access to the camera and microphone.
  * @see Event
  * @name accessDenied
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the Allow/Deny dialog box is opened. (This is the dialog box in which
  * the user can grant the app access to the camera and microphone.)
  * @see Event
  * @name accessDialogOpened
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the Allow/Deny box is closed. (This is the dialog box in which the
  * user can grant the app access to the camera and microphone.)
  * @see Event
  * @name accessDialogClosed
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched periodically to indicate the publisher's audio level. The event is dispatched
  * up to 60 times per second, depending on the browser. The <code>audioLevel</code> property
  * of the event is audio level, from 0 to 1.0. See {@link AudioLevelUpdatedEvent} for more
  * information.
  * <p>
  * The following example adjusts the value of a meter element that shows volume of the
  * publisher. Note that the audio level is adjusted logarithmically and a moving average
  * is applied:
  * <p>
  * <pre>
  * var movingAvg = null;
  * publisher.on('audioLevelUpdated', function(event) {
  *   if (movingAvg === null || movingAvg <= event.audioLevel) {
  *     movingAvg = event.audioLevel;
  *   } else {
  *     movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
  *   }
  *
  *   // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
  *   var logLevel = (Math.log(movingAvg) / Math.LN10) / 1.5 + 1;
  *   logLevel = Math.min(Math.max(logLevel, 0), 1);
  *   document.getElementById('publisherMeter').value = logLevel;
  * });
  * </pre>
  * <p>This example shows the algorithm used by the default audio level indicator displayed
  * in an audio-only Publisher.
  *
  * @name audioLevelUpdated
  * @event
  * @memberof Publisher
  * @see AudioLevelUpdatedEvent
  */

  /**
   * The publisher has started streaming to the session.
   * @name streamCreated
   * @event
   * @memberof Publisher
   * @see StreamEvent
   * @see <a href="Session.html#publish">Session.publish()</a>
   */

  /**
   * The publisher has stopped streaming to the session. The default behavior is that
   * the Publisher object is removed from the HTML DOM. The Publisher object dispatches a
   * <code>destroyed</code> event when the element is removed from the HTML DOM. If you call the
   * <code>preventDefault()</code> method of the event object in the event listener, the default
   * behavior is prevented, and you can, optionally, retain the Publisher for reuse or clean it up
   * using your own code.
   * @name streamDestroyed
   * @event
   * @memberof Publisher
   * @see StreamEvent
   */

  /**
  * Dispatched when the Publisher element is removed from the HTML DOM. When this event
  * is dispatched, you may choose to adjust or remove HTML DOM elements related to the publisher.
  * @name destroyed
  * @event
  * @memberof Publisher
*/

  /**
  * Dispatched when the video dimensions of the video change. This can only occur in when the
  * <code>stream.videoType</code> property is set to <code>"screen"</code> (for a screen-sharing
  * video stream), when the user resizes the window being captured. This event object has a
  * <code>newValue</code> property and an <code>oldValue</code> property, representing the new and
  * old dimensions of the video. Each of these has a <code>height</code> property and a
  * <code>width</code> property, representing the height and width, in pixels.
  * @name videoDimensionsChanged
  * @event
  * @memberof Publisher
  * @see VideoDimensionsChangedEvent
*/

  /**
   * The user has stopped screen-sharing for the published stream. This event is only dispatched
   * for screen-sharing video streams.
   * @name mediaStopped
   * @event
   * @memberof Publisher
   * @see StreamEvent
   */
};

// Helper function to generate unique publisher ids
Publisher.nextId = uuid;

Publisher.audioContext = audioContext;
Publisher.getUserMedia = getUserMedia;
Publisher.Microphone = Microphone;
Publisher.PublisherPeerConnection = PublisherPeerConnection;
Publisher.WidgetView = WidgetView;

module.exports = Publisher;
