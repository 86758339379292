'use strict';

var OTPlugin = require('@opentok/otplugin.js');
var OTHelpers = require('@opentok/ot-helpers');
var logging = require('../logging.js');

//
// There are three implementations of stats parsing in this file.
// 1. For Chrome: Chrome is currently using an older version of the API
// 2. For OTPlugin: The plugin is using a newer version of the API that
//    exists in the latest WebRTC codebase
// 3. For Firefox: FF is using a version that looks a lot closer to the
//    current spec.
//
// I've attempted to keep the three implementations from sharing any code,
// accordingly you'll notice a bunch of duplication between the three.
//
// This is acceptable as the goal is to be able to remove each implementation
// as it's no longer needed without any risk of affecting the others. If there
// was shared code between them then each removal would require an audit of
// all the others.
//
//

///
// Get Stats using the older API. Used by all current versions
// of Chrome.
//
var parseStatsOldAPI = function parseStatsOldAPI(peerConnection,
                                                 prevStats,
                                                 currentStats,
                                                 completion) {

  /* this parses a result if there it contains the video bitrate */
  var parseVideoStats = function(result) {
    if (result.stat('googFrameRateSent')) {
      currentStats.videoSentBytes = Number(result.stat('bytesSent'));
      currentStats.videoSentPackets = Number(result.stat('packetsSent'));
      currentStats.videoSentPacketsLost = Number(result.stat('packetsLost'));
      currentStats.videoRtt = Number(result.stat('googRtt'));
      currentStats.videoFrameRate = Number(result.stat('googFrameRateInput'));
      currentStats.videoWidth = Number(result.stat('googFrameWidthSent'));
      currentStats.videoHeight = Number(result.stat('googFrameHeightSent'));
      currentStats.videoFrameRateSent = Number(result.stat('googFrameRateSent'));
      currentStats.videoWidthInput = Number(result.stat('googFrameWidthInput'));
      currentStats.videoHeightInput = Number(result.stat('googFrameHeightInput'));
      currentStats.videoCodec = result.stat('googCodecName');
    } else if (result.stat('googFrameRateReceived')) {
      currentStats.videoRecvBytes = Number(result.stat('bytesReceived'));
      currentStats.videoRecvPackets = Number(result.stat('packetsReceived'));
      currentStats.videoRecvPacketsLost = Number(result.stat('packetsLost'));
      currentStats.videoFrameRate = Number(result.stat('googFrameRateOutput'));
      currentStats.videoFrameRateReceived = Number(result.stat('googFrameRateReceived'));
      currentStats.videoFrameRateDecoded = Number(result.stat('googFrameRateDecoded'));
      currentStats.videoWidth = Number(result.stat('googFrameWidthReceived'));
      currentStats.videoHeight = Number(result.stat('googFrameHeightReceived'));
      currentStats.videoCodec = result.stat('googCodecName');
    }

    return null;
  };

  var parseAudioStats = function(result) {
    if (result.stat('audioInputLevel')) {
      currentStats.audioSentPackets = Number(result.stat('packetsSent'));
      currentStats.audioSentPacketsLost = Number(result.stat('packetsLost'));
      currentStats.audioSentBytes =  Number(result.stat('bytesSent'));
      currentStats.audioCodec = result.stat('googCodecName');
      currentStats.audioRtt = Number(result.stat('googRtt'));
    } else if (result.stat('audioOutputLevel')) {
      currentStats.audioRecvPackets = Number(result.stat('packetsReceived'));
      currentStats.audioRecvPacketsLost = Number(result.stat('packetsLost'));
      currentStats.audioRecvBytes =  Number(result.stat('bytesReceived'));
      currentStats.audioCodec = result.stat('googCodecName');
    }
  };

  var parseStatsReports = function(stats) {
    if (stats.result) {
      var resultList = stats.result();
      for (var resultIndex = 0; resultIndex < resultList.length; resultIndex++) {
        var result = resultList[resultIndex];

        if (result.stat) {
          if (result.stat('googActiveConnection') === 'true') {
            currentStats.localCandidateType = result.stat('googLocalCandidateType');
            currentStats.remoteCandidateType = result.stat('googRemoteCandidateType');
            currentStats.transportType = result.stat('googTransportType');
          }

          parseAudioStats(result);
          parseVideoStats(result);
        }
      }
    }

    completion(null, currentStats);
  };

  peerConnection.getStats(parseStatsReports);
};

///
// Get Stats for the OT Plugin, newer than Chromes version, but
// still not in sync with the spec.
//
var parseStatsOTPlugin = function parseStatsOTPlugin(peerConnection,
                                                  prevStats,
                                                  currentStats,
                                                  completion) {

  var onStatsError = function onStatsError(error) {
    completion(error);
  };

  ///
  // From the Audio Tracks
  // * avgAudioBitrate
  // * audioBytesTransferred
  //
  var parseAudioStats = function(statsReport) {
    var transferDelta;
    var lastBytesSent = prevStats.audioBytesTransferred || 0;

    if (statsReport.audioInputLevel) {
      currentStats.audioSentBytes = Number(statsReport.bytesSent);
      currentStats.audioSentPackets = Number(statsReport.packetsSent);
      currentStats.audioSentPacketsLost = Number(statsReport.packetsLost);
      currentStats.audioRtt = Number(statsReport.googRtt);
      currentStats.audioCodec = statsReport.googCodecName;
    } else if (statsReport.audioOutputLevel) {
      currentStats.audioBytesTransferred = Number(statsReport.bytesReceived);
      currentStats.audioCodec = statsReport.googCodecName;
    }

    if (currentStats.audioBytesTransferred) {
      transferDelta = currentStats.audioBytesTransferred - lastBytesSent;
      currentStats.avgAudioBitrate = Math.round(transferDelta * 8 /
        (currentStats.period / 1000));
    }
  };

  ///
  // From the Video Tracks
  // * frameRate
  // * avgVideoBitrate
  // * videoBytesTransferred
  //
  var parseVideoStats = function(statsReport) {
    var transferDelta;
    var lastBytesSent = prevStats.videoBytesTransferred || 0;

    if (statsReport.googFrameHeightSent) {
      currentStats.videoSentBytes = Number(statsReport.bytesSent);
      currentStats.videoSentPackets = Number(statsReport.packetsSent);
      currentStats.videoSentPacketsLost = Number(statsReport.packetsLost);
      currentStats.videoRtt = Number(statsReport.googRtt);
      currentStats.videoCodec = statsReport.googCodecName;
      currentStats.videoWidth = Number(statsReport.googFrameWidthSent);
      currentStats.videoHeight = Number(statsReport.googFrameHeightSent);
      currentStats.videoFrameRateSent = Number(statsReport.googFrameRateSent);
      currentStats.videoWidthInput = Number(statsReport.googFrameWidthInput);
      currentStats.videoHeightInput = Number(statsReport.googFrameHeightInput);
    } else if (statsReport.googFrameHeightReceived) {
      currentStats.videoRecvBytes =   Number(statsReport.bytesReceived);
      currentStats.videoRecvPackets = Number(statsReport.packetsReceived);
      currentStats.videoRecvPacketsLost = Number(statsReport.packetsLost);
      currentStats.videoRtt = Number(statsReport.googRtt);
      currentStats.videoCodec = statsReport.googCodecName;
      currentStats.videoFrameRateReceived = Number(statsReport.googFrameRateReceived);
      currentStats.videoFrameRateDecoded = Number(statsReport.googFrameRateDecoded);
      currentStats.videoWidth = Number(statsReport.googFrameWidthReceived);
      currentStats.videoHeight = Number(statsReport.googFrameHeightReceived);
    }

    if (currentStats.videoBytesTransferred) {
      transferDelta = currentStats.videoBytesTransferred - lastBytesSent;
      currentStats.avgVideoBitrate = Math.round(transferDelta * 8 /
       (currentStats.period / 1000));
    }

    if (statsReport.googFrameRateInput) {
      currentStats.videoFrameRate = Number(statsReport.googFrameRateInput);
    } else if (statsReport.googFrameRateOutput) {
      currentStats.videoFrameRate = Number(statsReport.googFrameRateOutput);
    }
  };

  var isStatsForVideoTrack = function(statsReport) {
    return statsReport.googFrameHeightSent !== void 0 ||
            statsReport.googFrameHeightReceived !== void 0 ||
            currentStats.videoBytesTransferred !== void 0 ||
            statsReport.googFrameRateSent !== void 0;
  };

  var isStatsForIceCandidate = function(statsReport) {
    return statsReport.googActiveConnection === 'true';
  };

  peerConnection.getStats(null, function(statsReports) {
    statsReports.forEach(function(statsReport) {
      if (isStatsForIceCandidate(statsReport)) {
        currentStats.localCandidateType = statsReport.googLocalCandidateType;
        currentStats.remoteCandidateType = statsReport.googRemoteCandidateType;
        currentStats.transportType = statsReport.googTransportType;
      } else if (isStatsForVideoTrack(statsReport)) {
        parseVideoStats(statsReport);
      } else {
        parseAudioStats(statsReport);
      }
    });

    completion(null, currentStats);
  }, onStatsError);
};

///
// Get Stats using the newer API.
//
var parseStatsNewAPI = function parseStatsNewAPI(peerConnection,
                                                 prevStats,
                                                 currentStats,
                                                 completion) {

  var onStatsError = function onStatsError(error) {
    completion(error);
  };

  var parseAudioStats = function(result) {
    if (result.type === 'outboundrtp') {
      currentStats.audioSentPackets = result.packetsSent;
      currentStats.audioSentPacketsLost = result.packetsLost;
      currentStats.audioSentBytes =  result.bytesSent;
    } else if (result.type === 'inboundrtp') {
      currentStats.audioRecvPackets = result.packetsReceived;
      currentStats.audioRecvPacketsLost = result.packetsLost;
      currentStats.audioRecvBytes =  result.bytesReceived;
    }
  };

  var parseVideoStats = function(result) {
    if (result.type === 'outboundrtp') {
      currentStats.videoSentPackets = result.packetsSent;
      currentStats.videoSentPacketsLost = result.packetsLost;
      currentStats.videoSentBytes =  result.bytesSent;
    } else if (result.type === 'inboundrtp') {
      currentStats.videoRecvPackets = result.packetsReceived;
      currentStats.videoRecvPacketsLost = result.packetsLost;
      currentStats.videoRecvBytes =  result.bytesReceived;
    }
  };

  peerConnection.getStats(null, function(stats) {

    for (var key in stats) {
      if (stats.hasOwnProperty(key) &&
        (stats[key].type === 'outboundrtp' || stats[key].type === 'inboundrtp')) {
        var res = stats[key];

        if (res.id.indexOf('rtp') !== -1) {
          if (res.id.indexOf('audio') !== -1) {
            parseAudioStats(res);
          } else if (res.id.indexOf('video') !== -1) {
            parseVideoStats(res);
          }
        }
      }
    }

    completion(null, currentStats);
  }, onStatsError);
};

var parseQOS = function(peerConnection, prevStats, currentStats, completion) {
  if (OTPlugin.isInstalled()) {
    parseQOS = parseStatsOTPlugin;
    return parseStatsOTPlugin(peerConnection, prevStats, currentStats, completion);
  } else if (OTHelpers.env.name === 'Firefox' && OTHelpers.env.version >= 27) {
    parseQOS = parseStatsNewAPI;
    return parseStatsNewAPI(peerConnection, prevStats, currentStats, completion);
  }

  parseQOS = parseStatsOldAPI;
  return parseStatsOldAPI(peerConnection, prevStats, currentStats, completion);
};

var Qos = function(qosCallback) {
  var _peerConnection;
  var _creationTime = OTHelpers.now();

  var calculateQOS = function calculateQOS(prevStats, interval) {
    if (!_peerConnection) {
      // We don't have a PeerConnection yet, or we did and
      // it's been closed. Either way we're done.
      return;
    }

    var now = OTHelpers.now();

    var currentStats = {
      timeStamp: now,
      duration: Math.round(now - _creationTime),
      period: Math.round(now - prevStats.timeStamp)
    };

    var onParsedStats = function(err, parsedStats) {
      if (err) {
        logging.error('Failed to Parse QOS Stats: ' + JSON.stringify(err));
        return;
      }

      qosCallback(parsedStats, prevStats);

      var nextInterval = interval === Qos.INITIAL_INTERVAL ?
        Qos.INTERVAL - Qos.INITIAL_INTERVAL :
        Qos.INTERVAL;

      // Recalculate the stats
      setTimeout(calculateQOS.bind(null, parsedStats, nextInterval),
        interval);
    };

    parseQOS(_peerConnection, prevStats, currentStats, onParsedStats);
  };

  this.startCollecting = function(peerConnection) {
    if (!peerConnection || !peerConnection.getStats) {
      // It looks like this browser doesn't support getStats
      // Bail.
      return;
    }

    _peerConnection = peerConnection;

    calculateQOS({ timeStamp: OTHelpers.now() }, Qos.INITIAL_INTERVAL);
  };

  this.stopCollecting = function() {
    _peerConnection = null;
  };
};

// Send stats after 1 sec
Qos.INITIAL_INTERVAL = 1000;
// Recalculate the stats every 30 sec
Qos.INTERVAL = 30000;

module.exports = Qos;
