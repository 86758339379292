'use strict';

var OTPlugin = require('@opentok/otplugin.js');
var OTHelpers = require('@opentok/ot-helpers');

/**
 *
 * @returns {function(RTCPeerConnection,
 * function(DOMError, Array.<{id: string=, type: string=, timestamp: number}>))}
 */
module.exports = function getStatsAdapter() {

  ///
  // Get Stats using the older API. Used by all current versions
  // of Chrome.
  //
  function getStatsOldAPI(peerConnection, completion) {

    peerConnection.getStats(function(rtcStatsReport) {

      var stats = [];
      rtcStatsReport.result().forEach(function(rtcStat) {

        var stat = {};

        rtcStat.names().forEach(function(name) {
          stat[name] = rtcStat.stat(name);
        });

        // fake the structure of the "new" RTC stat object
        stat.id = rtcStat.id;
        stat.type = rtcStat.type;
        stat.timestamp = rtcStat.timestamp;
        stats.push(stat);
      });

      completion(null, stats);
    });
  }

  ///
  // Get Stats using the newer API.
  //
  function getStatsNewAPI(peerConnection, completion) {

    peerConnection.getStats(null, function(rtcStatsReport) {

      var stats = [];
      rtcStatsReport.forEach(function(rtcStats) {
        stats.push(rtcStats);
      });

      completion(null, stats);
    }, completion);
  }

  if (OTHelpers.browserVersion().name === 'Firefox' || OTPlugin.isInstalled()) {
    return getStatsNewAPI;
  }

  return getStatsOldAPI;
};
