'use strict';

var capabilities = require('../../capabilities');

function isClassListSupported() {
  return (typeof document !== 'undefined') && ('classList' in document.createElement('a'));
}

var classListSupported = isClassListSupported();

// Returns true if the client supports element.classList
capabilities.registerCapability('classList', isClassListSupported);

function hasClass(element, className) {
  if (!className) {
    return false;
  }

  if (classListSupported) {
    return element.classList.contains(className);
  }

  return element.className.indexOf(className) > -1;
}

function toggleClasses(element, classNames) {
  if (!classNames || classNames.length === 0) return;

  // Only bother targeting Element nodes, ignore Text Nodes, CDATA, etc
  if (element.nodeType !== 1) {
    return;
  }

  var numClasses = classNames.length,
      i = 0;

  if (classListSupported) {
    for (; i<numClasses; ++i) {
      element.classList.toggle(classNames[i]);
    }

    return;
  }

  var className = (' ' + element.className + ' ').replace(/[\s+]/, ' ');


  for (; i<numClasses; ++i) {
    if (hasClass(element, classNames[i])) {
      className = className.replace(' ' + classNames[i] + ' ', ' ');
    }
    else {
      className += classNames[i] + ' ';
    }
  }

  element.className = className.trim();
}

function addClass(element, classNames) {
  if (!classNames || classNames.length === 0) return;

  // Only bother targeting Element nodes, ignore Text Nodes, CDATA, etc
  if (element.nodeType !== 1) {
    return;
  }

  var numClasses = classNames.length,
      i = 0;

  if (classListSupported) {
    for (; i<numClasses; ++i) {
      element.classList.add(classNames[i]);
    }

    return;
  }

  // Here's our fallback to browsers that don't support element.classList

  if (!element.className && classNames.length === 1) {
    element.className = classNames.join(' ');
  }
  else {
    var setClass = ' ' + element.className + ' ';

    for (; i<numClasses; ++i) {
      if ( !~setClass.indexOf( ' ' + classNames[i] + ' ')) {
        setClass += classNames[i] + ' ';
      }
    }

    element.className = setClass.trim();
  }
}

function removeClass(element, classNames) {
  if (!classNames || classNames.length === 0) return;

  // Only bother targeting Element nodes, ignore Text Nodes, CDATA, etc
  if (element.nodeType !== 1) {
    return;
  }

  var numClasses = classNames.length,
      i = 0;

  if (classListSupported) {
    for (; i<numClasses; ++i) {
      element.classList.remove(classNames[i]);
    }

    return;
  }

  var className = (' ' + element.className + ' ').replace(/[\s+]/, ' ');

  for (; i<numClasses; ++i) {
    className = className.replace(' ' + classNames[i] + ' ', ' ');
  }

  element.className = className.trim();
}

module.exports = function(ElementCollection) {
  ElementCollection.prototype.addClass = function (value) {
    if (value) {
      var classNames = value.trim().split(/\s+/);

      this.forEach(function(element) {
        addClass(element, classNames);
      });
    }

    return this;
  };

  ElementCollection.prototype.removeClass = function (value) {
    if (value) {
      var classNames = value.trim().split(/\s+/);

      this.forEach(function(element) {
        removeClass(element, classNames);
      });
    }

    return this;
  };

  ElementCollection.prototype.toggleClass = function (value) {
    if (value) {
      var classNames = value.trim().split(/\s+/);

      this.forEach(function(element) {
        toggleClasses(element, classNames);
      });
    }

    return this;
  };

  ElementCollection.prototype.hasClass = function (value) {
    return this.some(function(element) {
      return hasClass(element, value);
    });
  };
};
