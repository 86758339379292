'use strict';

var OTHelpers = require('@opentok/ot-helpers');

var sessionObjects = {};

// TODO: Eliminate the need for this module, which is globally tracking these objects.

// Publishers are id'd by their guid
sessionObjects.publishers = new OTHelpers.Collection('guid');

// Subscribers are id'd by their widgetId
sessionObjects.subscribers = new OTHelpers.Collection('widgetId');

sessionObjects.sessions = new OTHelpers.Collection();

module.exports = sessionObjects;
