'use strict';

module.exports = function TaskQueue() {
  var Proto = function TaskQueue() {},
      api = new Proto(),
      tasks = [];

  api.add = function(fn, context) {
    if (context) {
      tasks.push(fn.bind(context));
    } else {
      tasks.push(fn);
    }
  };

  api.runAll = function() {
    var task;
    while ((task = tasks.shift())) {
      task();
    }
  };

  return api;
};
