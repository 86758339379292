'use strict';

var eventing = require('./behaviours/eventing');
var logging = require('./logging');
var util = require('./util');

module.exports = function Collection(idField) {
  var _models = [],
      _byId = {},
      _idField = idField || 'id';

  eventing(this, true);

  var modelProperty = function(model, property) {
    if (util.isFunction(model[property])) {
      return model[property]();
    } else {
      return model[property];
    }
  };

  var onModelUpdate = function onModelUpdate(event) {
        this.trigger('update', event);
        this.trigger('update:'+event.target.id, event);
      }.bind(this),

      onModelDestroy = function onModelDestroyed(event) {
        this.remove(event.target, event.reason);
      }.bind(this);


  this.reset = function() {
    // Stop listening on the models, they are no longer our problem
    _models.forEach(function(model) {
      model.off('updated', onModelUpdate, this);
      model.off('destroyed', onModelDestroy, this);
    }, this);

    _models = [];
    _byId = {};
  };

  this.destroy = function(reason) {
    _models.forEach(function(model) {
      if(model && typeof model.destroy === 'function') {
        model.destroy(reason, true);
      }
    });

    this.reset();
    this.off();
  };

  this.get = function(id) { return id && _byId[id] !== void 0 ? _models[_byId[id]] : void 0; };
  this.has = function(id) { return id && _byId[id] !== void 0; };

  this.toString = function() { return _models.toString(); };

  // Return only models filtered by either a dict of properties
  // or a filter function.
  //
  // @example Return all publishers with a streamId of 1
  //   OT.publishers.where({streamId: 1})
  //
  // @example The same thing but filtering using a filter function
  //   OT.publishers.where(function(publisher) {
  //     return publisher.stream.id === 4;
  //   });
  //
  // @example The same thing but filtering using a filter function
  //          executed with a specific this
  //   OT.publishers.where(function(publisher) {
  //     return publisher.stream.id === 4;
  //   }, self);
  //
  this.where = function(attrsOrFilterFn, context) {
    if (util.isFunction(attrsOrFilterFn)) {
      return _models.filter(attrsOrFilterFn, context);
    }

    return _models.filter(function(model) {
      for (var key in attrsOrFilterFn) {
        if(!attrsOrFilterFn.hasOwnProperty(key)) {
          continue;
        }
        if (modelProperty(model, key) !== attrsOrFilterFn[key]) return false;
      }

      return true;
    });
  };

  // Similar to where in behaviour, except that it only returns
  // the first match.
  this.find = function(attrsOrFilterFn, context) {
    var filterFn;

    if (util.isFunction(attrsOrFilterFn)) {
      filterFn = attrsOrFilterFn;
    }
    else {
      filterFn = function(model) {
        for (var key in attrsOrFilterFn) {
          if(!attrsOrFilterFn.hasOwnProperty(key)) {
            continue;
          }
          if (modelProperty(model, key) !== attrsOrFilterFn[key]) return false;
        }

        return true;
      };
    }

    filterFn = filterFn.bind(context);

    for (var i=0; i<_models.length; ++i) {
      if (filterFn(_models[i]) === true) return _models[i];
    }

    return null;
  };

  this.forEach = function(fn, context) {
    _models.forEach(fn, context);
    return this;
  };

  this.add = function(model) {
    var id = modelProperty(model, _idField);

    if (this.has(id)) {
      logging.warn('Model ' + id + ' is already in the collection', _models);
      return this;
    }

    _byId[id] = _models.push(model) - 1;

    model.on('updated', onModelUpdate, this);
    model.on('destroyed', onModelDestroy, this);

    this.trigger('add', model);
    this.trigger('add:'+id, model);

    return this;
  };

  this.remove = function(model, reason) {
    var id = modelProperty(model, _idField);

    _models.splice(_byId[id], 1);

    // Shuffle everyone down one
    for (var i=_byId[id]; i<_models.length; ++i) {
      _byId[_models[i][_idField]] = i;
    }

    delete _byId[id];

    model.off('updated', onModelUpdate, this);
    model.off('destroyed', onModelDestroy, this);

    this.trigger('remove', model, reason);
    this.trigger('remove:'+id, model, reason);

    return this;
  };

  // Retrigger the add event behaviour for each model. You can also
  // select a subset of models to trigger using the same arguments
  // as the #where method.
  this._triggerAddEvents = function() {
    this.where.apply(this, arguments).forEach(function(model) {
      this.trigger('add', model);
      this.trigger('add:' + modelProperty(model, _idField), model);
    }, this);
  };

  this.length = function() {
    return _models.length;
  };
};
