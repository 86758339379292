'use strict';

var OTPlugin = require('@opentok/otplugin.js');

// Normalise these
var NativeRTCIceCandidate;

if (!OTPlugin.isInstalled()) {
  NativeRTCIceCandidate = (global.RTCIceCandidate || global.mozRTCIceCandidate);
} else {
  NativeRTCIceCandidate = OTPlugin.RTCIceCandidate;
}

// Process incoming Ice Candidates from a remote connection (which have been
// forwarded via iceCandidateForwarder). The Ice Candidates cannot be processed
// until a PeerConnection is available. Once a PeerConnection becomes available
// the pending PeerConnections can be processed by calling processPending.
//
// @example
//
//  var iceProcessor = new IceCandidateProcessor();
//  iceProcessor.process(iceMessage1);
//  iceProcessor.process(iceMessage2);
//  iceProcessor.process(iceMessage3);
//
//  iceProcessor.setPeerConnection(peerConnection);
//  iceProcessor.processPending();
//
module.exports = function IceCandidateProcessor() {
  var _pendingIceCandidates = [];
  var _peerConnection = null;

  this.setPeerConnection = function(peerConnection) {
    _peerConnection = peerConnection;
  };

  this.process = function(message) {
    var iceCandidate = new NativeRTCIceCandidate(message.content);

    if (_peerConnection) {
      _peerConnection.addIceCandidate(iceCandidate);
    } else {
      _pendingIceCandidates.push(iceCandidate);
    }
  };

  this.processPending = function() {
    while (_pendingIceCandidates.length) {
      _peerConnection.addIceCandidate(_pendingIceCandidates.shift());
    }
  };
};
