'use strict';

module.exports = function(mod) {
  var attachments = {};

  for (var key in mod) {
    attachments[key] = mod[key];
  }

  mod._attachToOTHelpers = attachments;
};
