'use strict';

/* jshint browser:true, smarttabs:true */

var env = require('../env');
var toDebugStringIE9 = require('./debug_helper_ie9');

var LEVELS_DESCRIPTORS = [
  {name: 'NONE', value: 0, method: null},
  {name: 'ERROR', value: 1, method: 'error'},
  {name: 'WARN', value: 2, method: 'warn'},
  {name: 'INFO', value: 3, method: 'info'},
  {name: 'LOG', value: 4, method: 'log'},
  {name: 'DEBUG', value: 5, method: 'debug'}
];

var SLICE_PROTO = [].slice;

// There is a single global log level for every component that uses the logs.
var _logLevel;

function noop() {}

function bindMethod(obj, methodName) {
  var method = obj[methodName];
  if (typeof method.bind === 'function') {
    return method.bind(obj);
  } else {
    // functions from the console object in IE9 don't inherit from Function hence don't have bind
    return Function.prototype.bind.call(method, obj);
  }
}

/**
 * Creates a logging method that retains as much context as possible.
 *
 * In IE it creates a function that formats data better. In other browsers it returns a function
 * bound to the console to keep the file/line debugging info.
 */
function createLoggingMethod(obj, methodName) {
  if (env.name === 'IE' && env.version <= 9) {
    // IE9 doesn't show file/line info anyway so we can "break" the rule here and use this
    // opportunity to format data better
    return function() {
      obj[methodName](toDebugStringIE9(SLICE_PROTO.apply(arguments)));
    };
  } else {
    return bindMethod(obj, methodName);
  }
}

function getLoggingMethod(methodName) {
  if (typeof console === 'undefined') {
    return noop;
  }

  if (console[methodName] !== undefined) {
    return createLoggingMethod(console, methodName);
  } else if (console.log !== undefined) {
    return createLoggingMethod(console, 'log');
  } else {
    return noop;
  }
}

// This logging mixin is based on the idea that to get correct file / line info in log trace we
// need direct console method call. To ensure that we need to implement logging level filtering by
// rebinding method every time it changes: we can't wrap logging methods to filter otherwise we
// would lose the file/line info
module.exports = function applyLoggerMixin(logging) {

  LEVELS_DESCRIPTORS.forEach(function(levelDescriptor) {
    logging[levelDescriptor.name] = levelDescriptor.value;
  });

  // Determine if the level is visible given the current logLevel.
  logging.shouldLog = function(level) {
    return _logLevel >= level;
  };

  logging.setLogLevel = function setLogLevel(level) {

    // make sure the level value is a valid one
    level = typeof level === 'number' ? level : 0;

    LEVELS_DESCRIPTORS.forEach(function(levelDescriptor) {
      if (levelDescriptor.method !== null) {
        if (levelDescriptor.value <= level) {
          logging[levelDescriptor.method] = getLoggingMethod(levelDescriptor.method);
        } else {
          logging[levelDescriptor.method] = noop;
        }
      }
    });

    logging.debug('Setting the debug level to ' + level);

    return level;
  };

  logging.setLogLevel(logging.ERROR);
};
