'use strict';

var logging = require('../logging.js');
var SDPHelpers = require('./sdp_helpers.js');

// Attempt to completely process +offer+. This will:
// * set the offer as the remote description
// * create an answer and
// * set the new answer as the location description
//
// If there are no issues, the +success+ callback will be executed on completion.
// Errors during any step will result in the +failure+ callback being executed.
//
module.exports = function offerProcessor(
  peerConnection,
  numberOfSimulcastStreams,
  offer,
  success,
  failure
) {
  var generateErrorCallback, setLocalDescription, createAnswer;

  generateErrorCallback = function(message, prefix) {
    return function(errorReason) {
      logging.error(message);
      logging.error(errorReason);

      if (failure) { failure(message, errorReason, prefix); }
    };
  };

  setLocalDescription = function(answer) {
    answer.sdp = SDPHelpers.removeComfortNoise(answer.sdp);
    answer.sdp = SDPHelpers.removeVideoCodec(answer.sdp, 'ulpfec');
    answer.sdp = SDPHelpers.removeVideoCodec(answer.sdp, 'red');


    if (numberOfSimulcastStreams > 1) {
      offer.sdp = SDPHelpers.enableSimulcast(offer.sdp, numberOfSimulcastStreams);
    }

    peerConnection.setLocalDescription(
      answer,

      // Success
      function() {
        success(answer);
      },

      // Failure
      generateErrorCallback('Error while setting LocalDescription', 'SetLocalDescription')
    );
  };

  createAnswer = function() {
    peerConnection.createAnswer(
      // Success
      setLocalDescription,

      // Failure
      generateErrorCallback('Error while setting createAnswer', 'CreateAnswer'),

      null, // MediaConstraints
      false // createProvisionalAnswer
    );
  };

  if (offer.sdp.indexOf('a=rtcp-fb') === -1) {
    var rtcpFbLine = 'a=rtcp-fb:* ccm fir\r\na=rtcp-fb:* nack ';

    offer.sdp = offer.sdp.replace(/^m=video(.*)$/gmi, 'm=video$1\r\n' + rtcpFbLine);
  }

  peerConnection.setRemoteDescription(
    offer,

    // Success
    createAnswer,

    // Failure
    generateErrorCallback('Error while setting RemoteDescription', 'SetRemoteDescription')
  );
};
