'use strict';

var logging = require('./logging.js');
var OTHelpers = require('@opentok/ot-helpers');

module.exports = function Style(initalStyles, onStyleChange) {
  var _style = {};

  var _COMPONENT_STYLES = [
    'showMicButton',
    'showSpeakerButton',
    'nameDisplayMode',
    'buttonDisplayMode',
    'backgroundImageURI',
    'audioLevelDisplayMode'
  ];

  var _validStyleValues = {
    buttonDisplayMode: ['auto', 'mini', 'mini-auto', 'off', 'on'],
    nameDisplayMode: ['auto', 'off', 'on'],
    audioLevelDisplayMode: ['auto', 'off', 'on'],
    showSettingsButton: [true, false],
    showMicButton: [true, false],
    backgroundImageURI: null,
    showControlBar: [true, false],
    showArchiveStatus: [true, false],
    videoDisabledDisplayMode: ['auto', 'off', 'on']
  };

  // Validates the style +key+ and also whether +value+ is valid for +key+
  var isValidStyle = function(key, value) {
    return key === 'backgroundImageURI' ||
      (_validStyleValues.hasOwnProperty(key) &&
        _validStyleValues[key].indexOf(value) !== -1);
  };

  var castValue = function(value) {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return value;
    }
  };

  // Returns a shallow copy of the styles.
  this.getAll = function() {
    var style = OTHelpers.clone(_style);

    for (var key in style) {    //eslint-disable-line one-var
      if (!style.hasOwnProperty(key)) {
        continue;
      }
      if (_COMPONENT_STYLES.indexOf(key) < 0) {

        // Strip unnecessary properties out, should this happen on Set?
        delete style[key];
      }
    }

    return style;
  };

  this.get = function(key) {
    if (key) {
      return _style[key];
    }

    // We haven't been asked for any specific key, just return the lot
    return this.getAll();
  };

  // *note:* this will not trigger onStyleChange if +silent+ is truthy
  this.setAll = function(newStyles, silent) {
    var oldValue, newValue;

    for (var key in newStyles) {    //eslint-disable-line one-var
      if (!newStyles.hasOwnProperty(key)) {
        continue;
      }
      newValue = castValue(newStyles[key]);

      if (isValidStyle(key, newValue)) {
        oldValue = _style[key];

        if (newValue !== oldValue) {
          _style[key] = newValue;
          if (!silent) { onStyleChange(key, newValue, oldValue); }
        }

      } else {
        logging.warn('Style.setAll::Invalid style property passed ' + key + ' : ' + newValue);
      }
    }

    return this;
  };

  this.set = function(key, value) {
    logging.debug('setStyle: ' + key.toString());

    var oldValue;
    var newValue = castValue(value);

    if (!isValidStyle(key, newValue)) {
      logging.warn('Style.set::Invalid style property passed ' + key + ' : ' + newValue);
      return this;
    }

    oldValue = _style[key];
    if (newValue !== oldValue) {
      _style[key] = newValue;

      onStyleChange(key, value, oldValue);
    }

    return this;
  };

  if (initalStyles) { this.setAll(initalStyles, true); }
};
