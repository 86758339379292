'use strict';

var Widget = require('./behaviour/widget.js');
var OTHelpers = require('@opentok/ot-helpers');

module.exports = function AudioLevelMeter(options) {
  var _meterBarElement, _voiceOnlyIconElement, _meterValueElement, _value;
  var widget = this;
  var _maxValue = options.maxValue || 1;
  var _minValue = options.minValue || 0;

  function onCreate() {
    _meterBarElement = OTHelpers.createElement('div', {
      className: 'OT_audio-level-meter__bar'
    }, '');
    _meterValueElement = OTHelpers.createElement('div', {
      className: 'OT_audio-level-meter__value'
    }, '');
    _voiceOnlyIconElement = OTHelpers.createElement('div', {
      className: 'OT_audio-level-meter__audio-only-img'
    }, '');

    widget.domElement.appendChild(_meterBarElement);
    widget.domElement.appendChild(_voiceOnlyIconElement);
    widget.domElement.appendChild(_meterValueElement);
  }

  function updateView() {
    var percentSize = _value * 100 / (_maxValue - _minValue);
    _meterValueElement.style.width = _meterValueElement.style.height = 2 * percentSize + '%';
    _meterValueElement.style.top = _meterValueElement.style.right = -percentSize + '%';
  }

  // Mixin common widget behaviour
  var widgetOptions = {
    mode: options ? options.mode : 'auto',
    nodeName: 'div',
    htmlAttributes: {
      className: 'OT_audio-level-meter'
    },
    onCreate: onCreate
  };

  Widget(this, widgetOptions);

  // override
  var _setDisplayMode = widget.setDisplayMode.bind(widget);
  widget.setDisplayMode = function(mode) {
    _setDisplayMode(mode);
    if (mode === 'off') {
      if (options.onPassivate) { options.onPassivate(); }
    } else if (options.onActivate) {
      options.onActivate();
    }
  };

  widget.setValue = function(value) {
    _value = value;
    updateView();
  };
};
