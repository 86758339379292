'use strict';

require('../../helpers/web_rtc_polyfills.js');

var analytics = require('../analytics.js');
var connectionStateLogger = require('./connection_state_logger.js');
var createPeerConnection = require('../../helpers/create_peer_connection.js');
var getStatsAdpater = require('./get_stats_adapter.js');
var IceCandidateProcessor = require('./ice_candidate_processor');
var logging = require('../logging.js');
var offerProcessor = require('./offer_processor.js');
var OTHelpers = require('@opentok/ot-helpers');
var OTPlugin = require('@opentok/otplugin.js');
var PeerConnectionChannels = require('./peer_connection_channels.js');
var RaptorConstants = require('../messaging/raptor/raptor_constants.js');
var subscribeProcessor = require('./subscribe_processor.js');
var Qos = require('./qos.js');

// Normalise these
var NativeRTCSessionDescription;

if (!OTPlugin.isInstalled()) {
  NativeRTCSessionDescription = (global.RTCSessionDescription ||
                                 global.mozRTCSessionDescription);
} else {
  NativeRTCSessionDescription = OTPlugin.RTCSessionDescription;
}

// Helper function to forward Ice Candidates via +messageDelegate+
var iceCandidateForwarder = function(messageDelegate) {
  return function(event) {
    if (event.candidate) {
      messageDelegate(RaptorConstants.Actions.CANDIDATE, {
        candidate: event.candidate.candidate,
        sdpMid: event.candidate.sdpMid || '',
        sdpMLineIndex: event.candidate.sdpMLineIndex || 0
      });
    } else {
      logging.debug('IceCandidateForwarder: No more ICE candidates.');
    }
  };
};

/*
 * Negotiates a WebRTC PeerConnection.
 *
 * Responsible for:
 * * offer-answer exchange
 * * iceCandidates
 * * notification of remote streams being added/removed
 *
 */
var PeerConnection = function(config) {
  var _peerConnection, _channels, _offer, _answer, _stateLogger;
  var _peerConnectionCompletionHandlers = [];
  var _iceProcessor = new IceCandidateProcessor();
  var _getStatsAdapter = getStatsAdpater();
  var _state = 'new';
  var _messageDelegates = [];
  var api = {};

  OTHelpers.eventing(api);

  // if ice servers doesn't exist Firefox will throw an exception. Chrome
  // interprets this as 'Use my default STUN servers' whereas FF reads it
  // as 'Don't use STUN at all'. *Grumble*
  if (!config.iceServers) { config.iceServers = []; }

  // Private methods
  var delegateMessage = function(type, messagePayload, uri) {
    if (_messageDelegates.length) {
      // We actually only ever send to the first delegate. This is because
      // each delegate actually represents a Publisher/Subscriber that
      // shares a single PeerConnection. If we sent to all delegates it
      // would result in each message being processed multiple times by
      // each PeerConnection.
      _messageDelegates[0](type, messagePayload, uri);
    }
  };

  // Create and initialise the PeerConnection object. This deals with
  // any differences between the various browser implementations and
  // our own OTPlugin version.
  //
  // +completion+ is the function is call once we've either successfully
  // created the PeerConnection or on failure.
  //
  // +localWebRtcStream+ will be null unless the callee is representing
  // a publisher. This is an unfortunate implementation limitation
  // of OTPlugin, it's not used for vanilla WebRTC. Hopefully this can
  // be tidied up later.
  //
  var internalCreatePeerConnection = function(completion, localWebRtcStream) {
    if (_peerConnection) {
      completion.call(null, null, _peerConnection);
      return;
    }

    _peerConnectionCompletionHandlers.push(completion);

    if (_peerConnectionCompletionHandlers.length > 1) {
      // The PeerConnection is already being setup, just wait for
      // it to be ready.
      return;
    }

    var pcConstraints = {
      optional: [
        // This should be unnecessary, but the plugin has issues if we remove it. This needs
        // to be investigated.
        { DtlsSrtpKeyAgreement: true },

        // https://jira.tokbox.com/browse/OPENTOK-21989
        { googIPv6: false }
      ]
    };

    logging.debug('Creating peer connection config "' + JSON.stringify(config) + '".');

    if (!config.iceServers || config.iceServers.length === 0) {
      // This should never happen unless something is misconfigured
      logging.error('No ice servers present');
      analytics.logEvent({
        action: 'Error',
        variation: 'noIceServers'
      });
    }

    PeerConnection.createPeerConnection(config, pcConstraints, localWebRtcStream,
                         attachEventsToPeerConnection);
  };

  // An auxiliary function to internalCreatePeerConnection. This binds the various event
  // callbacks once the peer connection is created.
  //
  // +err+ will be non-null if an err occured while creating the PeerConnection
  // +pc+ will be the PeerConnection object itself.
  //
  var attachEventsToPeerConnection = function(err, pc) {
    if (err) {
      triggerError('Failed to create PeerConnection, exception: ' +
          err.toString(), 'NewPeerConnection');

      _peerConnectionCompletionHandlers = [];
      return;
    }

    logging.debug('OT attachEventsToPeerConnection');
    _peerConnection = pc;
    _stateLogger = connectionStateLogger(_peerConnection);
    _channels = new PeerConnectionChannels(_peerConnection);
    if (config.channels) { _channels.addMany(config.channels); }

    _peerConnection.addEventListener(
      'icecandidate',
      iceCandidateForwarder(delegateMessage),
      false
    );

    _peerConnection.addEventListener('addstream', onRemoteStreamAdded, false);
    _peerConnection.addEventListener('removestream', onRemoteStreamRemoved, false);
    _peerConnection.addEventListener('signalingstatechange', routeStateChanged, false);

    if (_peerConnection.oniceconnectionstatechange !== void 0) {
      var failedStateTimer;
      _peerConnection.addEventListener('iceconnectionstatechange', function(event) {
        api.trigger('iceConnectionStateChange', event.target.iceConnectionState);

        if (event.target.iceConnectionState === 'failed') {
          if (failedStateTimer) {
            clearTimeout(failedStateTimer);
          }

          // We wait 5 seconds and make sure that it's still in the failed state
          // before we trigger the error. This is because we sometimes see
          // 'failed' and then 'connected' afterwards.
          failedStateTimer = setTimeout(function() {
            if (event.target.iceConnectionState === 'failed') {
              triggerError('The stream was unable to connect due to a network error.' +
               ' Make sure your connection isn\'t blocked by a firewall.', 'ICEWorkflow');
            }
          }, 5000);
        } else {
          analytics.logEvent({
            action: 'attachEventsToPeerConnection',
            variation: 'iceconnectionstatechange',
            payload: event.target.iceConnectionState
          });
        }
      }, false);
    }

    triggerPeerConnectionCompletion(null);
  };

  var triggerPeerConnectionCompletion = function() {
    while (_peerConnectionCompletionHandlers.length) {
      _peerConnectionCompletionHandlers.shift().call(null);
    }
  };

  // Clean up the Peer Connection and trigger the close event.
  // This function can be called safely multiple times, it will
  // only trigger the close event once (per PeerConnection object)
  var tearDownPeerConnection = function() {
    // Our connection is dead, stop processing ICE candidates
    if (_iceProcessor) { _iceProcessor.setPeerConnection(null); }
    if (_stateLogger) { _stateLogger.stop(); }

    qos.stopCollecting();

    if (_peerConnection !== null) {
      if (_peerConnection.destroy) {
        // OTPlugin defines a destroy method on PCs. This allows
        // the plugin to release any resources that it's holding.
        _peerConnection.destroy();
      }

      _peerConnection = null;
      api.trigger('close');
    }
  };

  var routeStateChanged = function() {
    var newState = _peerConnection.signalingState;

    if (newState && newState !== _state) {
      _state = newState;
      logging.debug('PeerConnection.stateChange: ' + _state);

      switch (_state) {
        case 'closed':
          tearDownPeerConnection();
          break;
        default:
      }
    }
  };

  var qosCallback = function(parsedStats) {
    parsedStats.dataChannels = _channels.sampleQos();
    api.trigger('qos', parsedStats);
  };

  var getRemoteStreams = function() {
    var streams;

    if (_peerConnection.getRemoteStreams) {
      streams = _peerConnection.getRemoteStreams();
    } else if (_peerConnection.remoteStreams) {
      streams = _peerConnection.remoteStreams;
    } else {
      throw new Error('Invalid Peer Connection object implements no ' +
        'method for retrieving remote streams');
    }

    // Force streams to be an Array, rather than a 'Sequence' object,
    // which is browser dependent and does not behaviour like an Array
    // in every case.
    return Array.prototype.slice.call(streams);
  };

  /// PeerConnection signaling
  var onRemoteStreamAdded = function(event) {
    api.trigger('streamAdded', event.stream);
  };

  var onRemoteStreamRemoved = function(event) {
    api.trigger('streamRemoved', event.stream);
  };

  // ICE Negotiation messages

  // Relays a SDP payload (+sdp+), that is part of a message of type +messageType+
  // via the registered message delegators
  var relaySDP = function(messageType, sdp, uri) {
    delegateMessage(messageType, sdp, uri);
  };

  // Process an offer that
  var processOffer = function(message) {
    var offer = new NativeRTCSessionDescription({ type: 'offer', sdp: message.content.sdp });

    // Relays +answer+ Answer
    var relayAnswer = function(answer) {
      _iceProcessor.setPeerConnection(_peerConnection);
      _iceProcessor.processPending();
      relaySDP(RaptorConstants.Actions.ANSWER, answer);

      qos.startCollecting(_peerConnection);
    };

    var reportError = function(message, errorReason, prefix) {
      triggerError('PeerConnection.offerProcessor ' + message + ': ' +
        errorReason, prefix);
    };

    internalCreatePeerConnection(function() {
      offerProcessor(
        _peerConnection,
        config.numberOfSimulcastStreams,
        offer,
        relayAnswer,
        reportError
      );
    });
  };

  var processAnswer = function(message) {
    if (!message.content.sdp) {
      logging.error('PeerConnection.processMessage: Weird answer message, no SDP.');
      return;
    }

    _answer = new NativeRTCSessionDescription({ type: 'answer', sdp: message.content.sdp });

    _peerConnection.setRemoteDescription(_answer,
        function() {
          logging.debug('PeerConnection.processAnswer: setRemoteDescription Success');
        }, function(errorReason) {
          triggerError('Error while setting RemoteDescription ' + errorReason,
            'SetRemoteDescription');
        });

    _iceProcessor.setPeerConnection(_peerConnection);
    _iceProcessor.processPending();

    qos.startCollecting(_peerConnection);
  };

  var processSubscribe = function(message) {
    logging.debug('PeerConnection.processSubscribe: Sending offer to subscriber.');

    if (!_peerConnection) {
      // TODO(rolly) I need to examine whether this can
      // actually happen. If it does happen in the short
      // term, I want it to be noisy.
      throw new Error('PeerConnection broke!');
    }

    internalCreatePeerConnection(function() {
      subscribeProcessor(
        _peerConnection,
        config.numberOfSimulcastStreams,

        // Success: Relay Offer
        function(offer) {
          _offer = offer;
          relaySDP(RaptorConstants.Actions.OFFER, _offer, message.uri);
        },

        // Failure
        function(message, errorReason, prefix) {
          triggerError('subscribeProcessor ' + message + ': ' +
            errorReason, prefix);
        }
      );
    });
  };

  var triggerError = function(errorReason, prefix) {
    logging.error(errorReason);
    api.trigger('error', errorReason, prefix);
  };

  api.addLocalStream = function(webRTCStream) {
    internalCreatePeerConnection(function() {
      _peerConnection.addStream(webRTCStream);
    }, webRTCStream);
  };

  api.getSenders = function() {
    return _peerConnection.getSenders();
  };

  api.disconnect = function() {
    _iceProcessor = null;

    if (_peerConnection &&
        _peerConnection.signalingState &&
        _peerConnection.signalingState.toLowerCase() !== 'closed') {

      _peerConnection.close();

      if (OTHelpers.env.name === 'Firefox') {
        // FF seems to never go into the closed signalingState when the close
        // method is called on a PeerConnection. This means that we need to call
        // our cleanup code manually.
        //
        // * https://bugzilla.mozilla.org/show_bug.cgi?id=989936
        //
        OTHelpers.callAsync(tearDownPeerConnection);
      }
    }

    api.off();
  };

  api.createOfferWithIceRestart = function(subscriberUri) {
    processSubscribe({
      uri: subscriberUri
    });
  };

  api.processMessage = function(type, message) {
    logging.debug('PeerConnection.processMessage: Received ' +
      type + ' from ' + message.fromAddress);

    logging.debug(message);

    switch (type) {
      case 'generateoffer':
        processSubscribe(message);
        break;

      case 'offer':
        processOffer(message);
        break;

      case 'answer':
      case 'pranswer':
        processAnswer(message);
        break;

      case 'candidate':
        _iceProcessor.process(message);
        break;

      default:
        logging.debug('PeerConnection.processMessage: Received an unexpected message of type ' +
          type + ' from ' + message.fromAddress + ': ' + JSON.stringify(message));
    }

    return api;
  };

  api.setIceServers = function(iceServers) {
    if (iceServers) {
      config.iceServers = iceServers;
    }
  };

  api.registerMessageDelegate = function(delegateFn) {
    return _messageDelegates.push(delegateFn);
  };

  api.unregisterMessageDelegate = function(delegateFn) {
    var index = _messageDelegates.indexOf(delegateFn);

    if (index !== -1) {
      _messageDelegates.splice(index, 1);
    }
    return _messageDelegates.length;
  };

  api.remoteStreams = function() {
    return _peerConnection ? getRemoteStreams() : [];
  };

  api.getStats = function(callback) {
    if (!_peerConnection) {
      callback(new OTHelpers.Error('Cannot call getStats before there is a connection.',
      'NotConnectedError', {
        code: 1015
      }));
      return;
    }
    _getStatsAdapter(_peerConnection, callback);
  };

  var waitForChannel = function waitForChannel(timesToWait, label, options, completion) {
    var err;
    var channel = _channels.get(label, options);

    if (!channel) {
      if (timesToWait > 0) {
        setTimeout(
          waitForChannel.bind(null, timesToWait - 1, label, options, completion),
          200
        );

        return;
      }

      err = new OTHelpers.Error('A channel with that label and options could not be found. ' +
                            'Label:' + label + '. Options: ' + JSON.stringify(options));
    }

    completion(err, channel);
  };

  api.getDataChannel = function(label, options, completion) {
    if (!_peerConnection) {
      completion(new OTHelpers.Error('Cannot create a DataChannel before there is a connection.'));
      return;
    }
    // Wait up to 20 sec for the channel to appear, then fail
    waitForChannel(100, label, options, completion);
  };

  api.iceConnectionStateIsConnected = function() {
    return _peerConnection.iceConnectionState === 'connected' ||
      _peerConnection.iceConnectionState === 'completed';
  };

  var qos = new Qos(qosCallback);

  return api;
};

PeerConnection.createPeerConnection = createPeerConnection;

module.exports = PeerConnection;
