'use strict';

var Events = require('./events.js');
var OTPlugin = require('@opentok/otplugin.js');
var OTHelpers = require('@opentok/ot-helpers');
var logging = require('./logging.js');

// Helper to synchronise several startup tasks and then dispatch a unified
// 'envLoaded' event.

function EnvironmentLoader() {
  var environmentLoader = this;
  OTHelpers.eventing(environmentLoader);

  // If the plugin is installed, then we should wait for it to be ready as well.
  var _pluginSupported = OTPlugin.isSupported();
  var _pluginLoadAttemptComplete = _pluginSupported ? OTPlugin.isReady() : true;

  var isReady = function() {
    return !OTHelpers.isDOMUnloaded() &&
              OTHelpers.isReady() &&
              _pluginLoadAttemptComplete;
  };

  var onLoaded = function() {
    if (isReady()) {
      environmentLoader.dispatchEvent(
        new Events.EnvLoadedEvent(Events.Event.names.ENV_LOADED)
      );
    }
  };

  var onDomReady = function() {
    OTHelpers.onDOMUnload(onDomUnload);

    onLoaded();
  };

  var onDomUnload = function() {
    environmentLoader.dispatchEvent(
      new Events.EnvLoadedEvent(Events.Event.names.ENV_UNLOADED)
    );
  };

  var onPluginReady = function(err) {
    // We mark the plugin as ready so as not to stall the environment
    // loader. In this case though, OTPlugin is not supported.
    _pluginLoadAttemptComplete = true;

    if (err) {
      logging.debug('TB Plugin failed to load or was not installed');
    }

    onLoaded();
  };

  OTHelpers.onDOMLoad(onDomReady);

  // If the plugin should work on this platform then
  // see if it loads.
  if (_pluginSupported) { OTPlugin.ready(onPluginReady); }

  this.onLoad = function(cb, context) {
    if (isReady()) {
      cb.call(context);
      return;
    }

    environmentLoader.on(Events.Event.names.ENV_LOADED, cb, context);
  };

  this.onUnload = function(cb, context) {
    if (this.isUnloaded()) {
      cb.call(context);
      return;
    }

    environmentLoader.on(Events.Event.names.ENV_UNLOADED, cb, context);
  };

  this.isUnloaded = function() {
    return OTHelpers.isDOMUnloaded();
  };
}

module.exports = new EnvironmentLoader();
