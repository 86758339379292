'use strict';

// TODO: this is not unit tested

var analytics = require('../ot/analytics.js');
var OTHelpers = require('@opentok/ot-helpers');

module.exports = function ConnectivityAttemptPinger(options) {
  var _state = 'Initial';
  var states = ['Initial', 'Attempt',  'Success', 'Failure'];
  var PING_INTERVAL = 5000;
  var PING_COUNT_TOTAL = 6;

  var _previousState,
      pingTimer,               // Timer for the Attempting ping;
      pingCount;

  //// Private API
  var stateChanged = function(newState) {
    _state = newState;
    var invalidSequence = false;
    switch (_state) {
      case 'Attempt':
        if (_previousState !== 'Initial') {
          invalidSequence = true;
        }
        startAttemptPings();
        break;
      case 'Success':
        if (_previousState !== 'Attempt') {
          invalidSequence = true;
        }
        stopAttemptPings();
        break;
      case 'Failure':
        if (_previousState !== 'Attempt') {
          invalidSequence = true;
        }
        stopAttemptPings();
        break;
      default:
    }
    if (invalidSequence) {
      var data = options ? OTHelpers.clone(options) : {};
      data.action = 'Internal Error';
      data.variation = 'Non-fatal';
      data.payload = {
        debug: 'Invalid sequence: ' + options.action + ' ' +
          _previousState + ' -> ' + _state
      };
      analytics.logEvent(data);
    }
  };

  var setState = OTHelpers.statable(this, states, 'Failure', stateChanged);

  var startAttemptPings = function() {
    pingCount = 0;
    pingTimer = setInterval(function() {
      if (pingCount < PING_COUNT_TOTAL) {
        var data = OTHelpers.extend(options, { variation: 'Attempting' });
        analytics.logEvent(data);
      } else {
        stopAttemptPings();
      }
      pingCount++;
    }, PING_INTERVAL);
  };

  var stopAttemptPings = function() {
    clearInterval(pingTimer);
  };

  this.setVariation = function(variation) {
    _previousState = _state;
    setState(variation);

    // We could change the ConnectivityAttemptPinger to a ConnectivityAttemptLogger
    // that also logs events in addition to logging the ping ('Attempting') events.
    //
    // var payload = OTHelpers.extend(options, {variation:variation});
    // analytics.logEvent(payload);
  };

  this.stop = function() {
    stopAttemptPings();
  };
};
