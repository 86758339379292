'use strict';

var OTHelpers = require('@opentok/ot-helpers');

if (OTHelpers.env.name === 'Node') {
  module.exports = { value: '' };
} else {
  // Script embed
  var scriptSrc = (function() {
    var s = document.getElementsByTagName('script');
    s = s[s.length - 1];
    s = s.getAttribute('src') || s.src;
    return s;
  })();

  var m = scriptSrc.match(/[\?\&]apikey=([^&]+)/i);

  // TODO: The indirection here is due to the need to set APIKEY in testing. We should find a better
  // solution.
  module.exports = { value: m ? m[1] : '' };
}
