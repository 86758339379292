'use strict';

var logging = require('../ot/logging.js');
var properties = require('./properties.js');
var OTPlugin = require('@opentok/otplugin.js');
var OTHelpers = require('@opentok/ot-helpers');

///
// Capabilities
//
// Support functions to query browser/client Media capabilities.
//

// Indicates whether this client supports the getUserMedia
// API.
//
OTHelpers.registerCapability('getUserMedia', function() {
  if (OTHelpers.env.name === 'Node') { return false; }
  return !!(global.navigator.webkitGetUserMedia ||
            global.navigator.mozGetUserMedia ||
            (global.navigator.mediaDevices && global.navigator.mediaDevices.getUserMedia) ||
            OTPlugin.isInstalled());
});

// TODO Remove all PeerConnection stuff, that belongs to the messaging layer not the Media layer.
// Indicates whether this client supports the PeerConnection
// API.
//
// Chrome Issues:
// * The explicit prototype.addStream check is because webkitRTCPeerConnection was
// partially implemented, but not functional, in Chrome 22.
//
// Firefox Issues:
// * No real support before Firefox 19
// * Firefox 19 has issues with generating Offers.
// * Firefox 20 doesn't interoperate with Chrome.
//
OTHelpers.registerCapability('PeerConnection', function() {
  if (OTHelpers.env === 'Node') {
    return false;
  } else if (typeof global.webkitRTCPeerConnection === 'function' &&
                    !!global.webkitRTCPeerConnection.prototype.addStream) {
    return true;
  } else if (typeof global.RTCPeerConnection === 'function') {
    return true;
  } else if (typeof global.mozRTCPeerConnection === 'function' && OTHelpers.env.version > 20.0) {
    return true;
  }

  return OTPlugin.isInstalled();
});

// Indicates whether this client supports WebRTC
//
// This is defined as: getUserMedia + PeerConnection + exceeds min browser version
//
OTHelpers.registerCapability('webrtc', function() {
  if (properties) {
    var minimumVersions = properties.minimumVersion || {};
    var minimumVersion = minimumVersions[OTHelpers.env.name.toLowerCase()];

    if (minimumVersion && OTHelpers.env.versionGreaterThan(minimumVersion)) {
      logging.debug('Support for', OTHelpers.env.name, 'is disabled because we require',
        minimumVersion, 'but this is', OTHelpers.env.version);
      return false;
    }
  }

  if (OTHelpers.env === 'Node') {
    // Node works, even though it doesn't have getUserMedia
    return true;
  }

  return OTHelpers.hasCapabilities('getUserMedia', 'PeerConnection');
});

// TODO Remove all transport stuff, that belongs to the messaging layer not the Media layer.
// Indicates if the browser supports bundle
//
// Broadly:
// * Firefox doesn't support bundle
// * Chrome support bundle
// * OT Plugin supports bundle
// * We assume NodeJs supports bundle (e.g. 'you're on your own' mode)
//
OTHelpers.registerCapability('bundle', function() {
  return OTHelpers.hasCapabilities('webrtc') &&
            (OTHelpers.env.name === 'Chrome' ||
              OTHelpers.env.name === 'Node' ||
              OTPlugin.isInstalled());
});

// Indicates if the browser supports RTCP Mux
//
// Broadly:
// * Older versions of Firefox (<= 25) don't support RTCP Mux
// * Older versions of Firefox (>= 26) support RTCP Mux (not tested yet)
// * Chrome support RTCP Mux
// * OT Plugin supports RTCP Mux
// * We assume NodeJs supports RTCP Mux (e.g. 'you're on your own' mode)
//
OTHelpers.registerCapability('RTCPMux', function() {
  return OTHelpers.hasCapabilities('webrtc') &&
              (OTHelpers.env.name === 'Chrome' ||
                OTHelpers.env.name === 'Node' ||
                OTPlugin.isInstalled());
});

OTHelpers.registerCapability('audioOutputLevelStat', function() {
  return ['Chrome', 'IE', 'Opera'].indexOf(OTHelpers.env.name) > -1;
});

OTHelpers.registerCapability('webAudioCapableRemoteStream', function() {
  return OTHelpers.env.name === 'Firefox';
});

OTHelpers.registerCapability('webAudio', function() {
  return 'AudioContext' in global;
});
