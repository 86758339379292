'use strict';

var OTHelpers = require('@opentok/ot-helpers');

module.exports = function curryCallAsync(fn) {
  return function() {
    var args = Array.prototype.slice.call(arguments);
    args.unshift(fn);
    OTHelpers.callAsync.apply(OTHelpers, args);
  };
};
