'use strict';

var OTHelpers = require('@opentok/ot-helpers');
var VideoContainer = require('./video_container.js');

var MediaStreamTrack = function(mediaStreamId, options, plugin) {
  var Proto = function MediaStreamTrack() {},
      api = new Proto();

  api.id = options.id;
  api.kind = options.kind;
  api.label = options.label;
  api.enabled = OTHelpers.castToBoolean(options.enabled);
  api.streamId = mediaStreamId;

  api.setEnabled = function(enabled) {
    api.enabled = OTHelpers.castToBoolean(enabled);

    if (api.enabled) {
      plugin._.enableMediaStreamTrack(mediaStreamId, api.id);
    } else {
      plugin._.disableMediaStreamTrack(mediaStreamId, api.id);
    }
  };

  return api;
};

var MediaStream = function(options, plugin) {
  var Proto = function MediaStream() {},
      api = new Proto(),
      audioTracks = [],
      videoTracks = [];

  api.id = options.id;
  plugin.addRef(api);

  // TODO
  // api.ended =
  // api.onended =

  if (options.videoTracks) {
    options.videoTracks.map(function(track) {
      videoTracks.push(new MediaStreamTrack(options.id, track, plugin));
    });
  }

  if (options.audioTracks) {
    options.audioTracks.map(function(track) {
      audioTracks.push(new MediaStreamTrack(options.id, track, plugin));
    });
  }

  var hasTracksOfType = function(type) {
    var tracks = type === 'video' ? videoTracks : audioTracks;

    return tracks.some(function(track) {
      return track.enabled;
    });
  };

  api.getVideoTracks = function() { return videoTracks; };
  api.getAudioTracks = function() { return audioTracks; };

  api.getTrackById = function(id) {
    videoTracks.concat(audioTracks).forEach(function(track) {
      if (track.id === id) return track;
    });

    return null;
  };

  api.hasVideo = function() {
    return hasTracksOfType('video');
  };

  api.hasAudio = function() {
    return hasTracksOfType('audio');
  };

  api.addTrack = function(/* MediaStreamTrack */) {
    // TODO
  };

  api.removeTrack = function(/* MediaStreamTrack */) {
    // TODO
  };

  api.stop = function() {
    plugin._.stopMediaStream(api.id);
    plugin.removeRef(api);
  };

  api.destroy = function() {
    api.stop();
  };

  // Private MediaStream API
  api._ = {
    plugin: plugin,

    // Get a VideoContainer to render the stream in.
    render: function() {
      return new VideoContainer(plugin, api);
    }
  };

  return api;
};

MediaStream.fromJson = function(json, plugin) {
  if (!json) return null;
  return new MediaStream(JSON.parse(json), plugin);
};

module.exports = MediaStream;
