'use strict';

var generateSimpleStateMachine = require('../generate_simple_state_machine.js');

// Models a Publisher's publishing State
//
// Valid States:
//    NotPublishing
//    GetUserMedia
//    BindingMedia
//    MediaBound
//    PublishingToSession
//    Publishing
//    Failed
//    Destroyed
//
//
// Valid Transitions:
//    NotPublishing ->
//        GetUserMedia
//
//    GetUserMedia ->
//        BindingMedia
//      | Failed                     (Failure Reasons -> stream error, constraints,
//                                   (permission denied
//      | NotPublishing              (destroy()
//
//
//    BindingMedia ->
//        MediaBound
//      | Failed                     (Failure Reasons -> Anything to do with the media
//                                   (being invalid, the media never plays
//      | NotPublishing              (destroy()
//
//    MediaBound ->
//        PublishingToSession        (MediaBound could transition to PublishingToSession
//                                   (if a stand-alone publish is bound to a session
//      | Failed                     (Failure Reasons -> media issues with a stand-alone publisher
//      | NotPublishing              (destroy()
//
//    PublishingToSession
//        Publishing
//      | Failed                     (Failure Reasons -> timeout while waiting for ack of
//                                   (stream registered. We do not do this right now
//      | NotPublishing              (destroy()
//
//
//    Publishing ->
//        NotPublishing              (Unpublish
//      | Failed                     (Failure Reasons -> loss of network, media error, anything
//                                   (that causes *all* Peer Connections to fail (less than all
//                                   (failing is just an error, all is failure)
//      | NotPublishing              (destroy()
//
//    Failed ->
//       Destroyed
//
//    Destroyed ->                   (Terminal state
//
//

var validStates = [
  'NotPublishing', 'GetUserMedia', 'BindingMedia', 'MediaBound',
  'PublishingToSession', 'Publishing', 'Failed',
  'Destroyed'
];

var validTransitions = {
  NotPublishing: ['NotPublishing', 'GetUserMedia', 'Destroyed'],
  GetUserMedia: ['BindingMedia', 'Failed', 'NotPublishing', 'Destroyed'],
  BindingMedia: ['MediaBound', 'Failed', 'NotPublishing', 'Destroyed'],
  MediaBound: ['NotPublishing', 'PublishingToSession', 'Failed', 'Destroyed'],
  PublishingToSession: ['NotPublishing', 'Publishing', 'Failed', 'Destroyed'],
  Publishing: ['NotPublishing', 'MediaBound', 'Failed', 'Destroyed'],
  Failed: ['Destroyed'],
  Destroyed: []
};

var initialState = 'NotPublishing';

var PublishingState = generateSimpleStateMachine(initialState, validStates, validTransitions);
module.exports = PublishingState;

PublishingState.prototype.isDestroyed = function() {
  return this.current === 'Destroyed';
};

PublishingState.prototype.isAttemptingToPublish = function() {
  return [
    'GetUserMedia',
    'BindingMedia',
    'MediaBound',
    'PublishingToSession'
  ].indexOf(this.current) !== -1;
};

PublishingState.prototype.isPublishing = function() {
  return this.current === 'Publishing';
};
