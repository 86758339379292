'use strict';

var OTHelpers = require('@opentok/ot-helpers');

/**
 * A class defining properties of the <code>capabilities</code> property of a
 * Session object. See <a href="Session.html#properties">Session.capabilities</a>.
 * <p>
 * All Capabilities properties are undefined until you have connected to a session
 * and the Session object has dispatched the <code>sessionConnected</code> event.
 * <p>
 * For more information on token roles, see the
 * <a href="https://tokbox.com/developer/guides/create-token/">Token Creation Overview</a>.
 *
 * @class Capabilities
 *
 * @property {Number} forceDisconnect Specifies whether you can call
 * the <code>Session.forceDisconnect()</code> method (1) or not (0). To call the
 * <code>Session.forceDisconnect()</code> method,
 * the user must have a token that is assigned the role of moderator.
 * @property {Number} forceUnpublish Specifies whether you can call
 * the <code>Session.forceUnpublish()</code> method (1) or not (0). To call the
 * <code>Session.forceUnpublish()</code> method, the user must have a token that
 * is assigned the role of moderator.
 * @property {Number} publish Specifies whether you can publish to the session (1) or not (0).
 * The ability to publish is based on a few factors. To publish, the user must have a token that
 * is assigned a role that supports publishing. There must be a connected camera and microphone.
 * @property {Number} subscribe Specifies whether you can subscribe to streams
 * in the session (1) or not (0). Currently, this capability is available for all users on all
 * platforms.
 */
module.exports = function Capabilities(permissions) {
  this.publish = permissions.indexOf('publish') !== -1 ? 1 : 0;
  this.subscribe = permissions.indexOf('subscribe') !== -1 ? 1 : 0;
  this.forceUnpublish = permissions.indexOf('forceunpublish') !== -1 ? 1 : 0;
  this.forceDisconnect = permissions.indexOf('forcedisconnect') !== -1 ? 1 : 0;
  this.supportsWebRTC = OTHelpers.hasCapabilities('webrtc') ? 1 : 0;

  this.permittedTo = function(action) {
    return this.hasOwnProperty(action) && this[action] === 1;
  };
};
