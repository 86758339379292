'use strict';

var uuid =                            require('uuid');
var analytics =                       require('../analytics.js');
var audioContext =                    require('../../helpers/audio_context.js');
var GetstatsAudioOutputLevelSampler = require('../../helpers/audio_level_samplers/getstats_audio_output_level_sampler');
var WebaudioAudioLevelSampler =       require('../../helpers/audio_level_samplers/webaudio_audio_level_sampler');
var AudioLevelTransformer =           require('../audio_level_transformer');
var AudioLevelMeter =                 require('../chrome/audio_level_meter.js');
var BackingBar =                      require('../chrome/backing_bar.js');
var Chrome =                          require('../chrome/chrome.js');
var ConnectivityAttemptPinger =       require('../../helpers/connectivity_attempt_pinger.js');
var Events =                          require('../events.js');
var ExceptionCodes =                  require('../exception_codes.js');
var getStatsHelpers =                 require('../peer_connection/get_stats_helpers.js');
var IntervalRunner =                  require('../interval_runner.js');
var logging =                         require('../logging.js');
var Message =                         require('../messaging/raptor/message.js');
var MuteButton =                      require('../chrome/mute_button.js');
var NamePanel =                       require('../chrome/name_panel.js');
var OTError =                         require('../ot_error.js');
var OTHelpers =                       require('@opentok/ot-helpers');
var OTPlugin =                        require('@opentok/otplugin.js');
var properties =                      require('../../helpers/properties.js');
var SubscriberPeerConnection =        require('../peer_connection/subscriber_peer_connection.js');
var SubscribingState =                require('./state.js');
var StylableComponent =               require('../stylable_component.js');
var VideoDisabledIndicator =          require('../chrome/video_disabled_indicator.js');
var WidgetView =                      require('../../helpers/widget_view.js');

var BIND_VIDEO_DELAY_MAX = 15000;

/**
 * The Subscriber object is a representation of the local video element that is playing back
 * a remote stream. The Subscriber object includes methods that let you disable and enable
 * local audio playback for the subscribed stream. The <code>subscribe()</code> method of the
 * {@link Session} object returns a Subscriber object.
 *
 * @property {Element} element The HTML DOM element containing the Subscriber.
 * @property {String} id The DOM ID of the Subscriber.
 * @property {Stream} stream The stream to which you are subscribing.
 *
 * @class Subscriber
 * @augments EventDispatcher
 */
var Subscriber = function(targetElement, options, completionHandler) {
  var _container,
      _streamContainer,
      _chrome,
      _audioLevelMeter,
      _fromConnectionId,
      _peerConnection,
      _subscribeStartTime,
      _startConnectingTime,
      _state,
      _audioLevelSampler,
      _audioLevelRunner,
      _isLocalStream,
      _lastSubscribeToVideoReason,
      _connectivityAttemptPinger;
  var _widgetId = uuid();
  var _domId = targetElement || _widgetId;
  var _session = options.session;
  var _stream = options.stream;
  var _audioVolume = 100;
  var _audioLevelCapable = OTHelpers.hasCapabilities('audioOutputLevelStat') ||
                           OTHelpers.hasCapabilities('webAudioCapableRemoteStream');
  var _frameRateRestricted = false;
  var _lastIceConnectionState = Events.Event.names.SUBSCRIBER_DISCONNECTED;
  var _subscriber = this;

  var _properties = OTHelpers.defaults({}, options, {
    showControls: true,
    testNetwork: false,
    fitMode: _stream.defaultFitMode || 'cover'
  });

  if (typeof completionHandler !== 'function') {
    completionHandler = function() {};
  }

  this.id = _domId;
  this.widgetId = _widgetId;
  this.session = _session;
  this.stream = _stream = _properties.stream;
  this.streamId = _stream.id;

  if (!_session) {
    OTError.handleJsException('OT.Subscriber must be passed a session option', 2000, {
      session: _session,
      target: this
    });

    return null;
  }

  OTHelpers.eventing(this, false);

  // make sure we trigger an error if we are not getting any "data" after a reasonable
  // amount of time
  var bindVideoGuardingTo = setTimeout(function bindVideoGuardingToFunction() {
    onPeerConnectionFailure(null, 'OT.Subscriber failed to subscribe to a stream in a reasonable' +
      ' amount of time', _peerConnection);
  }, BIND_VIDEO_DELAY_MAX);

  _subscriber.once('subscribeComplete', function clearVideoGuardTimeout() {
    clearTimeout(bindVideoGuardingTo);
  });

  _subscriber.once('subscribeComplete', completionHandler);

  if (_audioLevelCapable) {
    this.on({
      'audioLevelUpdated:added': function(count) {
        if (count === 1 && _audioLevelRunner) {
          _audioLevelRunner.start();
        }
      },
      'audioLevelUpdated:removed': function(count) {
        if (count === 0 && _audioLevelRunner) {
          _audioLevelRunner.stop();
        }
      }
    });
  }

  var logAnalyticsEvent = function(action, variation, payload, throttle) {
    var args = [{
      action: action,
      variation: variation,
      payload: payload,
      streamId: _stream ? _stream.id : null,
      sessionId: _session ? _session.sessionId : null,
      connectionId: _session && _session.isConnected() ?
        _session.connection.connectionId : null,
      partnerId: _session && _session.isConnected() ? _session.sessionInfo.partnerId : null,
      subscriberId: _widgetId
    }];
    if (throttle) { args.push(throttle); }
    analytics.logEvent.apply(analytics, args);
  };

  var logConnectivityEvent = function(variation, payload) {
    if (variation === 'Attempt' || !_connectivityAttemptPinger) {
      _connectivityAttemptPinger = new ConnectivityAttemptPinger({
        action: 'Subscribe',
        sessionId: _session ? _session.sessionId : null,
        connectionId: _session && _session.isConnected() ?
          _session.connection.connectionId : null,
        partnerId: _session.isConnected() ? _session.sessionInfo.partnerId : null,
        streamId: _stream ? _stream.id : null
      });
    }
    _connectivityAttemptPinger.setVariation(variation);
    logAnalyticsEvent('Subscribe', variation, payload);
  };

  var logResubscribe = function(variation, payload) {
    logAnalyticsEvent('ICERestart', variation, payload);
  };

  var recordQOS = function(parsedStats) {
    var QoSBlob = {
      streamType: 'WebRTC',
      width: (_container ?
        Number(OTHelpers.width(_container.domElement).replace('px', '')) :
        null
      ),
      height: (
        _container ?
        Number(OTHelpers.height(_container.domElement).replace('px', '')) :
        null
      ),
      sessionId: _session ? _session.sessionId : null,
      connectionId: _session ? _session.connection.connectionId : null,
      mediaServerName: _session ? _session.sessionInfo.messagingServer : null,
      p2pFlag: _session ? _session.sessionInfo.p2pEnabled : false,
      partnerId: _session ? _session.apiKey : null,
      streamId: _stream.id,
      subscriberId: _widgetId,
      version: properties.version,
      duration: parseInt(OTHelpers.now() - _subscribeStartTime, 10),
      remoteConnectionId: _stream.connection.connectionId
    };

    analytics.logQOS(OTHelpers.extend(QoSBlob, parsedStats));
    this.trigger('qos', parsedStats);
  }.bind(this);

  var stateChangeFailed = function(changeFailed) {
    logging.error('OT.Subscriber State Change Failed: ', changeFailed.message);
    logging.debug(changeFailed);
  };

  var onLoaded = function() {
    if (_state.isSubscribing() || !_streamContainer) { return; }

    logging.debug('OT.Subscriber.onLoaded');

    _state.set('Subscribing');
    _subscribeStartTime = OTHelpers.now();

    var payload = {
      pcc: parseInt(_subscribeStartTime - _startConnectingTime, 10),
      hasRelayCandidates: _peerConnection && _peerConnection.hasRelayCandidates()
    };
    logAnalyticsEvent('createPeerConnection', 'Success', payload);

    _container.loading(false);
    _chrome.showAfterLoading();

    if (_frameRateRestricted) {
      _stream.setRestrictFrameRate(true);
    }

    if (_audioLevelMeter && _subscriber.getStyle('audioLevelDisplayMode') === 'auto') {
      _audioLevelMeter[_container.audioOnly() ? 'show' : 'hide']();
    }

    this.trigger('subscribeComplete', null, this);
    this.trigger('loaded', this);

    logConnectivityEvent('Success', { streamId: _stream.id });
  };

  var onDisconnected = function() {
    logging.debug('OT.Subscriber has been disconnected from the Publisher\'s PeerConnection');

    if (_state.isAttemptingToSubscribe()) {
      // subscribing error
      _state.set('Failed');
      this.trigger('subscribeComplete', new OTError(null, 'ClientDisconnected'), this);

    } else if (_state.isSubscribing()) {
      _state.set('Failed');

      // we were disconnected after we were already subscribing
      // probably do nothing?
    }

    this.disconnect();
  };

  var onPeerConnectionFailure = function(code, reason, peerConnection, prefix) {
    var payload, errorCode;
    if (_state.isAttemptingToSubscribe()) {
      // We weren't subscribing yet so this was a failure in setting
      // up the PeerConnection or receiving the initial stream.
      payload = {
        reason: prefix ? prefix : 'PeerConnectionError',
        message: 'OT.Subscriber PeerConnection Error: ' + reason,
        hasRelayCandidates: _peerConnection && _peerConnection.hasRelayCandidates()
      };
      logAnalyticsEvent('createPeerConnection', 'Failure', payload);

      _state.set('Failed');
      this.trigger('subscribeComplete', new OTError(null, reason), this);

    } else if (_state.isSubscribing()) {
      // we were disconnected after we were already subscribing
      _state.set('Failed');
      this.trigger('error', reason);
    }

    this.disconnect();

    if (prefix === 'ICEWorkflow') {
      errorCode = ExceptionCodes.SUBSCRIBER_ICE_WORKFLOW_FAILED;
    } else {
      errorCode = ExceptionCodes.P2P_CONNECTION_FAILED;
    }
    payload = {
      reason: prefix ? prefix : 'PeerConnectionError',
      message: 'OT.Subscriber PeerConnection Error: ' + reason,
      code: errorCode
    };
    logConnectivityEvent('Failure', payload);

    OTError.handleJsException('OT.Subscriber PeerConnection Error: ' + reason,
      errorCode, {
        session: _session,
        target: this
      }
    );
    _showError.call(this, reason);
  }.bind(this);

  var onRemoteStreamAdded = function(webRTCStream) {
    logging.debug('OT.Subscriber.onRemoteStreamAdded');

    _state.set('BindingRemoteStream');

    // Disable the audio/video, if needed
    this.subscribeToAudio(_properties.subscribeToAudio);

    _lastSubscribeToVideoReason = 'loading';
    this.subscribeToVideo(_properties.subscribeToVideo, 'loading');

    // setting resolution and frame rate doesn't work in P2P
    if (!_session.sessionInfo.p2pEnabled) {
      this.setPreferredResolution(_properties.preferredResolution);
      this.setPreferredFrameRate(_properties.preferredFrameRate);
    }

    var videoContainerOptions = {
      error: onPeerConnectionFailure,
      audioVolume: _audioVolume
    };

    // This is a workaround for a bug in Chrome where a track disabled on
    // the remote end doesn't fire loadedmetadata causing the subscriber to timeout
    // https://jira.tokbox.com/browse/OPENTOK-15605
    // Also https://jira.tokbox.com/browse/OPENTOK-16425
    //
    // Workaround for an IE issue https://jira.tokbox.com/browse/OPENTOK-18824
    // We still need to investigate further.
    //
    var tracks = webRTCStream.getVideoTracks();
    if (tracks.length > 0) {
      tracks.forEach(function(track) {
        track.setEnabled(_stream.hasVideo && _properties.subscribeToVideo);
      });
    }

    _streamContainer = _container.bindVideo(webRTCStream, videoContainerOptions,
      function(err) {

        if (err) {
          onPeerConnectionFailure(null, err.message || err, _peerConnection, 'VideoElement');
          return;
        }
        if (_streamContainer) {
          _streamContainer.orientation({
            width: _stream.videoDimensions.width,
            height: _stream.videoDimensions.height,
            videoOrientation: _stream.videoDimensions.orientation
          });
        }

        onLoaded.call(this, null);
      }.bind(this));

    // if the audioLevelSampler implementation requires a stream we need to set it now
    if (_audioLevelSampler && 'webRTCStream' in _audioLevelSampler
      && webRTCStream.getAudioTracks().length > 0) {
      _audioLevelSampler.webRTCStream(webRTCStream);
    }

    logAnalyticsEvent('createPeerConnection', 'StreamAdded');
    this.trigger('streamAdded', this);
  };

  var onRemoteStreamRemoved = function(webRTCStream) {
    logging.debug('OT.Subscriber.onStreamRemoved');

    if (_streamContainer.stream === webRTCStream) {
      _streamContainer.destroy();
      _streamContainer = null;
    }

    this.trigger('streamRemoved', this);
  };

  var connectionStateMap = {
    new: Events.Event.names.SUBSCRIBER_DISCONNECTED,
    checking: Events.Event.names.SUBSCRIBER_DISCONNECTED,
    connected: Events.Event.names.SUBSCRIBER_CONNECTED,
    completed: Events.Event.names.SUBSCRIBER_CONNECTED,
    disconnected: Events.Event.names.SUBSCRIBER_DISCONNECTED
  };

  var onIceConnectionStateChange = function(state) {
    var mappedState = connectionStateMap[state];
    if (mappedState && mappedState !== _lastIceConnectionState) {
      _lastIceConnectionState = mappedState;
      logging.debug('OT.Subscriber.connectionStateChanged to ' + state);
      this.dispatchEvent(new Events.ConnectionStateChangedEvent(mappedState, this));
    }
  };

  var onIceRestartSuccess = function() {
    logResubscribe('Success');
  };

  var onIceRestartFailure = function() {
    logResubscribe('Failure', {
      reason: 'ICEWorkflow',
      message: 'OT.Subscriber PeerConnection Error: ' +
       'The stream was unable to connect due to a network error.' +
       ' Make sure your connection isn\'t blocked by a firewall.'
    });
  };

  var streamDestroyed = function() {
    this.disconnect();
  };

  var streamUpdated = function(event) {

    switch (event.changedProperty) {
      case 'videoDimensions':
        if (!_streamContainer) {
          // Ignore videoEmension updates before streamContainer is created OPENTOK-17253
          break;
        }
        _streamContainer.orientation({
          width: event.newValue.width,
          height: event.newValue.height,
          videoOrientation: event.newValue.orientation
        });

        this.dispatchEvent(new Events.VideoDimensionsChangedEvent(
          this, event.oldValue, event.newValue
        ));

        break;

      case 'videoDisableWarning':
        _chrome.videoDisabledIndicator.setWarning(event.newValue);
        this.dispatchEvent(new Events.VideoDisableWarningEvent(
          event.newValue ? 'videoDisableWarning' : 'videoDisableWarningLifted'
        ));
        break;

      case 'hasVideo':

        setAudioOnly(!(_stream.hasVideo && _properties.subscribeToVideo));

        this.dispatchEvent(new Events.VideoEnabledChangedEvent(
          _stream.hasVideo ? 'videoEnabled' : 'videoDisabled',
          {
            reason: 'publishVideo'
          }
        ));
        break;

      case 'hasAudio':
        var muteStyle = this.getStyle('showMuteButton') ? this.getStyle('showMuteButton') : 'auto';
        _chrome.muteButton.setDisplayMode(event.newValue ? muteStyle : 'auto');
        break;

      default:
    }
  };

  /// Chrome

  // If mode is false, then that is the mode. If mode is true then we'll
  // definitely display  the button, but we'll defer the model to the
  // Publishers buttonDisplayMode style property.
  var chromeButtonMode = function(mode) {
    if (mode === false) { return 'off'; }

    var defaultMode = this.getStyle('buttonDisplayMode');

    // The default model is false, but it's overridden by +mode+ being true
    if (defaultMode === false) { return 'on'; }

    // defaultMode is either true or auto.
    return defaultMode;
  };

  var updateChromeForStyleChange = function(key, value/*, oldValue*/) {
    if (!_chrome) { return; }

    switch (key) {
      case 'nameDisplayMode':
        _chrome.name.setDisplayMode(value);
        _chrome.backingBar.setNameMode(value);
        break;

      case 'videoDisabledDisplayMode':
        _chrome.videoDisabledIndicator.setDisplayMode(value);
        break;

      case 'showArchiveStatus':
        _chrome.archive.setShowArchiveStatus(value);
        break;

      case 'buttonDisplayMode':
        _chrome.muteButton.setDisplayMode(value);
        _chrome.backingBar.setMuteMode(value);
        break;

      case 'audioLevelDisplayMode':
        _chrome.audioLevel.setDisplayMode(value);
        break;

      case 'bugDisplayMode':
        // bugDisplayMode can't be updated but is used by some partners
        break;

      case 'backgroundImageURI':
        _container.setBackgroundImageURI(value);
        break;

      default:
    }
  };

  var _createChrome = function() {

    var widgets = {
      backingBar: new BackingBar({
        nameMode: !_properties.name ? 'off' : this.getStyle('nameDisplayMode'),
        muteMode: chromeButtonMode.call(this, this.getStyle('showMuteButton'))
      }),

      name: new NamePanel({
        name: _properties.name,
        mode: this.getStyle('nameDisplayMode')
      }),

      muteButton: new MuteButton({
        muted: _properties.muted,
        mode: _stream.hasAudio ? chromeButtonMode.call(this, this.getStyle('showMuteButton'))
          : 'off'
      })
    };

    if (_audioLevelCapable) {
      var audioLevelTransformer = new AudioLevelTransformer();

      var audioLevelUpdatedHandler = function(evt) {
        _audioLevelMeter.setValue(audioLevelTransformer.transform(evt.audioLevel));
      };

      _audioLevelMeter = new AudioLevelMeter({
        mode: this.getStyle('audioLevelDisplayMode'),
        onActivate: function() {
          _subscriber.on('audioLevelUpdated', audioLevelUpdatedHandler);
        },
        onPassivate: function() {
          _subscriber.off('audioLevelUpdated', audioLevelUpdatedHandler);
        }
      });

      widgets.audioLevel = _audioLevelMeter;
    }

    widgets.videoDisabledIndicator = new VideoDisabledIndicator({
      mode: this.getStyle('videoDisabledDisplayMode')
    });

    _chrome = new Chrome({
      parent: _container.domElement
    }).set(widgets).on({
      muted: function() {
        muteAudio.call(this, true);
      },

      unmuted: function() {
        muteAudio.call(this, false);
      }
    }, this);

    // Hide the chrome until we explicitly show it
    _chrome.hideWhileLoading();
  };

  var _showError = function() {
    // Display the error message inside the container, assuming it's
    // been created by now.
    if (_container) {
      _container.addError(
        'The stream was unable to connect due to a network error.',
        'Make sure your connection isn\'t blocked by a firewall.'
      );
    }
  };

  StylableComponent(this, {
    nameDisplayMode: 'auto',
    buttonDisplayMode: 'auto',
    audioLevelDisplayMode: 'auto',
    videoDisabledDisplayMode: 'auto',
    backgroundImageURI: null,
    showArchiveStatus: true,
    showMicButton: true
  }, _properties.showControls, function(payload) {
    logAnalyticsEvent('SetStyle', 'Subscriber', payload, 0.1);
  });

  var setAudioOnly = function(audioOnly) {
    if (_peerConnection) {
      _peerConnection.subscribeToVideo(!audioOnly);
    }

    if (_container) {
      _container.audioOnly(audioOnly);
      _container.showPoster(audioOnly);
    }

    if (_audioLevelMeter && _subscriber.getStyle('audioLevelDisplayMode') === 'auto') {
      _audioLevelMeter[audioOnly ? 'show' : 'hide']();
    }
  };

  // logs an analytics event for getStats on the first call
  var notifyGetStatsCalled = (function() {
    var callCount = 0;
    return function throttlingNotifyGetStatsCalled() {
      if (callCount === 0) {
        logAnalyticsEvent('GetStats', 'Called');
      }
      callCount++;
    };
  })();

  this.destroy = function(reason, quiet) {
    if (_state.isDestroyed()) { return this; }

    if (_state.isAttemptingToSubscribe()) {
      if (reason === 'streamDestroyed') {
        // We weren't subscribing yet so the stream was destroyed before we setup
        // the PeerConnection or receiving the initial stream.
        this.trigger('subscribeComplete', new OTError(null, 'InvalidStreamID'), this);
      } else {
        logConnectivityEvent('Canceled', { streamId: _stream.id });
        _connectivityAttemptPinger.stop();
      }
    }

    _state.set('Destroyed');

    if (_audioLevelRunner) {
      _audioLevelRunner.stop();
    }

    this.disconnect();

    if (_chrome) {
      _chrome.destroy();
      _chrome = null;
    }

    if (_container) {
      _container.destroy();
      _container = null;
      this.element = null;
    }

    if (_stream && !_stream.destroyed) {
      logAnalyticsEvent('unsubscribe', null, { streamId: _stream.id });
    }

    this.id = _domId = null;
    this.stream = _stream = null;
    this.streamId = null;

    this.session = _session = null;
    _properties = null;

    if (quiet !== true) {
      this.dispatchEvent(
        new Events.DestroyedEvent(
          Events.Event.names.SUBSCRIBER_DESTROYED,
          this,
          reason
        ),
        this.off.bind(this)
      );
    }

    return this;
  };

  this.disconnect = function() {
    if (!_state.isDestroyed() && !_state.isFailed()) {
      // If we are already in the destroyed state then disconnect
      // has been called after (or from within) destroy.
      _state.set('NotSubscribing');
    }

    if (_streamContainer) {
      _streamContainer.destroy();
      _streamContainer = null;
    }

    if (_peerConnection) {
      _peerConnection.destroy();
      _peerConnection = null;

      logAnalyticsEvent('disconnect', 'PeerConnection', { streamId: _stream.id });
    }
  };

  this.processMessage = function(type, fromConnection, message) {
    logging.debug('OT.Subscriber.processMessage: Received ' + type + ' message from ' +
      fromConnection.id);
    logging.debug(message);

    if (_fromConnectionId !== fromConnection.id) {
      _fromConnectionId = fromConnection.id;
    }

    if (_peerConnection) {
      _peerConnection.processMessage(type, message);
    }
  };

  this.disableVideo = function(active) {
    if (!active) {
      logging.warn('Due to high packet loss and low bandwidth, video has been disabled');
    } else if (_lastSubscribeToVideoReason === 'auto') {
      logging.info('Video has been re-enabled');
      _chrome.videoDisabledIndicator.disableVideo(false);
    } else {
      logging.info('Video was not re-enabled because it was manually disabled');
      return;
    }
    this.subscribeToVideo(active, 'auto');
    if (!active) {
      _chrome.videoDisabledIndicator.disableVideo(true);
    }
    var payload = active ? { videoEnabled: true } : { videoDisabled: true };
    logAnalyticsEvent('updateQuality', 'video', payload);
  };

  /**
   * Returns the base-64-encoded string of PNG data representing the Subscriber video.
   *
   *  <p>You can use the string as the value for a data URL scheme passed to the src parameter of
   *  an image file, as in the following:</p>
   *
   *  <pre>
   *  var imgData = subscriber.getImgData();
   *
   *  var img = document.createElement("img");
   *  img.setAttribute("src", "data:image/png;base64," + imgData);
   *  var imgWin = window.open("about:blank", "Screenshot");
   *  imgWin.document.write("&lt;body&gt;&lt;/body&gt;");
   *  imgWin.document.body.appendChild(img);
   *  </pre>
   * @method #getImgData
   * @memberOf Subscriber
   * @return {String} The base-64 encoded string. Returns an empty string if there is no video.
   */
  this.getImgData = function() {
    if (!this.isSubscribing()) {
      logging.error('OT.Subscriber.getImgData: Cannot getImgData before the Subscriber ' +
        'is subscribing.');
      return null;
    }

    return _streamContainer.imgData();
  };

  this.getStats = function(callback) {
    if (!_peerConnection) {
      callback(new OTHelpers.Error('OT.Subscriber is not connected cannot getStats',
      'NotConnectedError', {
        code: 1015
      }));
      return;
    }

    notifyGetStatsCalled();

    _peerConnection.getStats(function(error, stats) {
      if (error) {
        callback(error);
        return;
      }

      var otStats = {
        timestamp: 0
      };

      stats.forEach(function(stat) {
        if (getStatsHelpers.isInboundStat(stat)) {
          var video = getStatsHelpers.isVideoStat(stat);
          var audio = getStatsHelpers.isAudioStat(stat);

          // it is safe to override the timestamp of one by another
          // if they are from the same getStats "batch" video and audio ts have the same value
          if (audio || video) {
            otStats.timestamp = getStatsHelpers.normalizeTimestamp(stat.timestamp);
          }
          if (video) {
            otStats.video = getStatsHelpers.parseStatCategory(stat);
          } else if (audio) {
            otStats.audio = getStatsHelpers.parseStatCategory(stat);
          }
        }
      });

      callback(null, otStats);
    });
  };

  /**
   * Sets the audio volume, between 0 and 100, of the Subscriber.
   *
   * <p>You can set the initial volume when you call the <code>Session.subscribe()</code>
   * method. Pass a <code>audioVolume</code> property of the <code>properties</code> parameter
   * of the method.</p>
   *
   * @param {Number} value The audio volume, between 0 and 100.
   *
   * @return {Subscriber} The Subscriber object. This lets you chain method calls, as in the
   * following:
   *
   * <pre>mySubscriber.setAudioVolume(50).setStyle(newStyle);</pre>
   *
   * @see <a href="#getAudioVolume">getAudioVolume()</a>
   * @see <a href="Session.html#subscribe">Session.subscribe()</a>
   * @method #setAudioVolume
   * @memberOf Subscriber
   */
  this.setAudioVolume = function(value) {
    value = parseInt(value, 10);
    if (isNaN(value)) {
      logging.error('OT.Subscriber.setAudioVolume: value should be an integer between 0 and 100');
      return this;
    }
    _audioVolume = Math.max(0, Math.min(100, value));
    if (_audioVolume !== value) {
      logging.warn('OT.Subscriber.setAudioVolume: value should be an integer between 0 and 100');
    }
    if (_properties.muted && _audioVolume > 0) {
      _properties.premuteVolume = value;
      muteAudio.call(this, false);
    }
    if (_streamContainer) {
      _streamContainer.setAudioVolume(_audioVolume);
    }
    return this;
  };

  /**
  * Returns the audio volume, between 0 and 100, of the Subscriber.
  *
  * <p>Generally you use this method in conjunction with the <code>setAudioVolume()</code>
  * method.</p>
  *
  * @return {Number} The audio volume, between 0 and 100, of the Subscriber.
  * @see <a href="#setAudioVolume">setAudioVolume()</a>
  * @method #getAudioVolume
  * @memberOf Subscriber
  */
  this.getAudioVolume = function() {
    if (_properties.muted) {
      return 0;
    }
    if (_streamContainer) {
      return _streamContainer.getAudioVolume();
    }
    return _audioVolume;
  };

  /**
  * Toggles audio on and off. Starts subscribing to audio (if it is available and currently
  * not being subscribed to) when the <code>value</code> is <code>true</code>; stops
  * subscribing to audio (if it is currently being subscribed to) when the <code>value</code>
  * is <code>false</code>.
  * <p>
  * <i>Note:</i> This method only affects the local playback of audio. It has no impact on the
  * audio for other connections subscribing to the same stream. If the Publsher is not
  * publishing audio, enabling the Subscriber audio will have no practical effect.
  * </p>
  *
  * @param {Boolean} value Whether to start subscribing to audio (<code>true</code>) or not
  * (<code>false</code>).
  *
  * @return {Subscriber} The Subscriber object. This lets you chain method calls, as in the
  * following:
  *
  * <pre>mySubscriber.subscribeToAudio(true).subscribeToVideo(false);</pre>
  *
  * @see <a href="#subscribeToVideo">subscribeToVideo()</a>
  * @see <a href="Session.html#subscribe">Session.subscribe()</a>
  * @see <a href="StreamPropertyChangedEvent.html">StreamPropertyChangedEvent</a>
  *
  * @method #subscribeToAudio
  * @memberOf Subscriber
  */
  this.subscribeToAudio = function(pValue) {
    var value = OTHelpers.castToBoolean(pValue, true);

    if (_peerConnection) {
      _peerConnection.subscribeToAudio(value && !_properties.subscribeMute);

      if (_session && _stream && value !== _properties.subscribeToAudio) {
        _stream.setChannelActiveState('audio', value && !_properties.subscribeMute);
      }
    }

    _properties.subscribeToAudio = value;

    return this;
  };

  var muteAudio = function(_mute) {
    _chrome.muteButton.muted(_mute);

    if (_mute === _properties.mute) {
      return;
    }
    if (OTHelpers.env.name === 'Chrome' || OTPlugin.isInstalled()) {
      _properties.subscribeMute = _properties.muted = _mute;
      this.subscribeToAudio(_properties.subscribeToAudio);
    } else if (_mute) {
      _properties.premuteVolume = this.getAudioVolume();
      _properties.muted = true;
      this.setAudioVolume(0);
    } else if (_properties.premuteVolume || _properties.audioVolume) {
      _properties.muted = false;
      this.setAudioVolume(_properties.premuteVolume || _properties.audioVolume);
    }
    _properties.mute = _properties.mute;
  };

  var reasonMap = {
    auto: 'quality',
    publishVideo: 'publishVideo',
    subscribeToVideo: 'subscribeToVideo'
  };

  /**
  * Toggles video on and off. Starts subscribing to video (if it is available and
  * currently not being subscribed to) when the <code>value</code> is <code>true</code>;
  * stops subscribing to video (if it is currently being subscribed to) when the
  * <code>value</code> is <code>false</code>.
  * <p>
  * <i>Note:</i> This method only affects the local playback of video. It has no impact on
  * the video for other connections subscribing to the same stream. If the Publsher is not
  * publishing video, enabling the Subscriber video will have no practical video.
  * </p>
  *
  * @param {Boolean} value Whether to start subscribing to video (<code>true</code>) or not
  * (<code>false</code>).
  *
  * @return {Subscriber} The Subscriber object. This lets you chain method calls, as in the
  * following:
  *
  * <pre>mySubscriber.subscribeToVideo(true).subscribeToAudio(false);</pre>
  *
  * @see <a href="#subscribeToAudio">subscribeToAudio()</a>
  * @see <a href="Session.html#subscribe">Session.subscribe()</a>
  * @see <a href="StreamPropertyChangedEvent.html">StreamPropertyChangedEvent</a>
  *
  * @method #subscribeToVideo
  * @memberOf Subscriber
  */
  this.subscribeToVideo = function(pValue, reason) {
    var value = OTHelpers.castToBoolean(pValue, true);

    setAudioOnly(!(value && _stream.hasVideo));

    if (value && _container && _container.video() && _stream.hasVideo) {
      _container.loading(value);
      _container.video().whenTimeIncrements(function() {
        _container.loading(false);
      }, this);
    }

    if (_chrome && _chrome.videoDisabledIndicator) {
      _chrome.videoDisabledIndicator.disableVideo(false);
    }

    if (_peerConnection) {
      if (_session && _stream && (value !== _properties.subscribeToVideo ||
          reason !== _lastSubscribeToVideoReason)) {
        _stream.setChannelActiveState('video', value, reason);
      }
    }

    _properties.subscribeToVideo = value;
    _lastSubscribeToVideoReason = reason;

    if (reason !== 'loading') {
      this.dispatchEvent(new Events.VideoEnabledChangedEvent(
        value ? 'videoEnabled' : 'videoDisabled',
        {
          reason: reasonMap[reason] || 'subscribeToVideo'
        }
      ));
    }

    return this;
  };

  this.setPreferredResolution = function(preferredResolution) {
    if (_state.isDestroyed() || !_peerConnection) {
      logging.warn('Cannot set the max Resolution when not subscribing to a publisher');
      return;
    }

    _properties.preferredResolution = preferredResolution;

    if (_session.sessionInfo.p2pEnabled) {
      logging.warn('OT.Subscriber.setPreferredResolution will not work in a P2P Session');
      return;
    }

    var curMaxResolution = _stream.getPreferredResolution();

    var isUnchanged = (curMaxResolution && preferredResolution &&
                        curMaxResolution.width ===  preferredResolution.width &&
                        curMaxResolution.height ===  preferredResolution.height) ||
                      (!curMaxResolution && !preferredResolution);

    if (isUnchanged) {
      return;
    }

    _stream.setPreferredResolution(preferredResolution);
  };

  this.setPreferredFrameRate = function(preferredFrameRate) {
    if (_state.isDestroyed() || !_peerConnection) {
      logging.warn('Cannot set the max frameRate when not subscribing to a publisher');
      return;
    }

    _properties.preferredFrameRate = preferredFrameRate;

    if (_session.sessionInfo.p2pEnabled) {
      logging.warn('OT.Subscriber.setPreferredFrameRate will not work in a P2P Session');
      return;
    }

    /* jshint -W116 */
    if (_stream.getPreferredFrameRate() === preferredFrameRate) {
      return;
    }
    /* jshint +W116 */

    _stream.setPreferredFrameRate(preferredFrameRate);
  };

  this.isSubscribing = function() {
    return _state.isSubscribing();
  };

  this.isWebRTC = true;

  this.isLoading = function() {
    return _container && _container.loading();
  };

  this.videoElement = function() {
    return _streamContainer.domElement();
  };

  /**
  * Returns the width, in pixels, of the Subscriber video.
  *
  * @method #videoWidth
  * @memberOf Subscriber
  * @return {Number} the width, in pixels, of the Subscriber video.
  */
  this.videoWidth = function() {
    return _streamContainer.videoWidth();
  };

  /**
  * Returns the height, in pixels, of the Subscriber video.
  *
  * @method #videoHeight
  * @memberOf Subscriber
  * @return {Number} the width, in pixels, of the Subscriber video.
  */
  this.videoHeight = function() {
    return _streamContainer.videoHeight();
  };

  /**
  * Restricts the frame rate of the Subscriber's video stream, when you pass in
  * <code>true</code>. When you pass in <code>false</code>, the frame rate of the video stream
  * is not restricted.
  * <p>
  * When the frame rate is restricted, the Subscriber video frame will update once or less per
  * second.
  * <p>
  * This feature is only available in sessions that use the OpenTok Media Router (sessions with
  * the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">media mode</a>
  * set to routed), not in sessions with the media mode set to relayed. In relayed sessions,
  * calling this method has no effect.
  * <p>
  * Restricting the subscriber frame rate has the following benefits:
  * <ul>
  *    <li>It reduces CPU usage.</li>
  *    <li>It reduces the network bandwidth consumed.</li>
  *    <li>It lets you subscribe to more streams simultaneously.</li>
  * </ul>
  * <p>
  * Reducing a subscriber's frame rate has no effect on the frame rate of the video in
  * other clients.
  *
  * @param {Boolean} value Whether to restrict the Subscriber's video frame rate
  * (<code>true</code>) or not (<code>false</code>).
  *
  * @return {Subscriber} The Subscriber object. This lets you chain method calls, as in the
  * following:
  *
  * <pre>mySubscriber.restrictFrameRate(false).subscribeToAudio(true);</pre>
  *
  * @method #restrictFrameRate
  * @memberOf Subscriber
  */
  this.restrictFrameRate = function(val) {
    logging.debug('OT.Subscriber.restrictFrameRate(' + val + ')');

    logAnalyticsEvent('restrictFrameRate', val.toString(), { streamId: _stream.id });

    if (_session.sessionInfo.p2pEnabled) {
      logging.warn('OT.Subscriber.restrictFrameRate: Cannot restrictFrameRate on a P2P session');
    }

    if (typeof val !== 'boolean') {
      logging.error(
        'OT.Subscriber.restrictFrameRate: expected a boolean value got a ' + typeof val
      );
    } else {
      _frameRateRestricted = val;
      _stream.setRestrictFrameRate(val);
    }
    return this;
  };

  this.on('styleValueChanged', updateChromeForStyleChange, this);

  this._ = {
    getDataChannel: function(label, options, completion) {
      // @fixme this will fail if it's called before we have a SubscriberPeerConnection.
      // I.e. before we have a publisher connection.
      if (!_peerConnection) {
        completion(
          new OTHelpers.Error('Cannot create a DataChannel before there is a publisher connection.')
        );

        return;
      }

      _peerConnection.getDataChannel(label, options, completion);
    },

    iceRestart: function() {
      if (!_peerConnection) {
        logging.debug('Subscriber: Skipping ice restart, we have no peer connection');
      } else if (!_peerConnection.iceConnectionStateIsConnected()) {
        logResubscribe('Attempt');
        _peerConnection.createOfferWithIceRestart();
      } else {
        logging.debug('Subscriber: Skipping ice restart, we are connected.');
      }
    }
  };

  _state = new SubscribingState(stateChangeFailed);

  logging.debug('OT.Subscriber: subscribe to ' + _stream.id);

  _state.set('Init');

  if (!_stream) {
    // @todo error
    logging.error('OT.Subscriber: No stream parameter.');
    return false;
  }

  _stream.on({
    updated: streamUpdated,
    destroyed: streamDestroyed
  }, this);

  _fromConnectionId = _stream.connection.id;
  _properties.name = _properties.name || _stream.name;
  _properties.classNames = 'OT_root OT_subscriber';

  if (_properties.style) {
    this.setStyle(_properties.style, null, true);
  }
  if (_properties.audioVolume) {
    this.setAudioVolume(_properties.audioVolume);
  }

  _properties.subscribeToAudio = OTHelpers.castToBoolean(_properties.subscribeToAudio, true);
  _properties.subscribeToVideo = OTHelpers.castToBoolean(_properties.subscribeToVideo, true);

  _container = new Subscriber.WidgetView(targetElement, _properties);
  this.id = _domId = _container.domId();
  this.element = _container.domElement;

  _createChrome.call(this);

  _startConnectingTime = OTHelpers.now();

  logAnalyticsEvent('createPeerConnection', 'Attempt', '');

  _isLocalStream = _stream.connection.id === _session.connection.id;

  if (!_properties.testNetwork && _isLocalStream) {
    // Subscribe to yourself edge-case
    var publisher = _session.getPublisherForStream(_stream);
    if (!(publisher && publisher._.webRtcStream())) {
      this.trigger('subscribeComplete', new OTError(null, 'InvalidStreamID'), this);
      return this;
    }

    onRemoteStreamAdded.call(this, publisher._.webRtcStream());
  } else {
    if (_properties.testNetwork) {
      this.setAudioVolume(0);
    }

    _state.set('ConnectingToPeer');

    var uri = Message.subscribers.uri(_session.apiKey, _session.sessionId, _stream.id,
      this.widgetId);

    _peerConnection = new Subscriber.SubscriberPeerConnection(_stream.connection, _session,
      _stream, this, uri, _properties);

    _peerConnection.on({
      disconnected: onDisconnected,
      error: onPeerConnectionFailure,
      remoteStreamAdded: onRemoteStreamAdded,
      remoteStreamRemoved: onRemoteStreamRemoved,
      qos: recordQOS,
      iceConnectionStateChange: onIceConnectionStateChange,
      iceRestartSuccess: onIceRestartSuccess,
      iceRestartFailure: onIceRestartFailure
    }, this);

    // initialize the peer connection AFTER we've added the event listeners
    _peerConnection.init(function(err) {
      if (err) {
        throw err;
      }
    });

    if (OTHelpers.hasCapabilities('audioOutputLevelStat')) {
      _audioLevelSampler = new GetstatsAudioOutputLevelSampler(_peerConnection.getStats);
    } else if (OTHelpers.hasCapabilities('webAudioCapableRemoteStream')) {
      _audioLevelSampler = new WebaudioAudioLevelSampler(audioContext());
    }

    if (_audioLevelSampler) {
      var subscriber = this;
      // sample with interval to minimise disturbance on animation loop but dispatch the
      // event with RAF since the main purpose is animation of a meter
      _audioLevelRunner = new IntervalRunner(function() {
        _audioLevelSampler.sample(function(audioOutputLevel) {
          if (audioOutputLevel !== null) {
            OTHelpers.requestAnimationFrame(function() {
              subscriber.dispatchEvent(
                new Events.AudioLevelUpdatedEvent(audioOutputLevel));
            });
          }
        });
      }, 60);
    }

  }

  logConnectivityEvent('Attempt', { streamId: _stream.id });

  /**
  * Dispatched periodically to indicate the subscriber's audio level. The event is dispatched
  * up to 60 times per second, depending on the browser. The <code>audioLevel</code> property
  * of the event is audio level, from 0 to 1.0. See {@link AudioLevelUpdatedEvent} for more
  * information.
  * <p>
  * The following example adjusts the value of a meter element that shows volume of the
  * subscriber. Note that the audio level is adjusted logarithmically and a moving average
  * is applied:
  * <pre>
  * var movingAvg = null;
  * subscriber.on('audioLevelUpdated', function(event) {
  *   if (movingAvg === null || movingAvg <= event.audioLevel) {
  *     movingAvg = event.audioLevel;
  *   } else {
  *     movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
  *   }
  *
  *   // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
  *   var logLevel = (Math.log(movingAvg) / Math.LN10) / 1.5 + 1;
  *   logLevel = Math.min(Math.max(logLevel, 0), 1);
  *   document.getElementById('subscriberMeter').value = logLevel;
  * });
  * </pre>
  * <p>This example shows the algorithm used by the default audio level indicator displayed
  * in an audio-only Subscriber.
  *
  * @name audioLevelUpdated
  * @event
  * @memberof Subscriber
  * @see AudioLevelUpdatedEvent
  */

  /**
  * Dispatched when the video for the subscriber is disabled.
  * <p>
  * The <code>reason</code> property defines the reason the video was disabled. This can be set to
  * one of the following values:
  * <p>
  *
  * <ul>
  *
  *   <li><code>"publishVideo"</code> &mdash; The publisher stopped publishing video by calling
  *   <code>publishVideo(false)</code>.</li>
  *
  *   <li><code>"quality"</code> &mdash; The OpenTok Media Router stopped sending video
  *   to the subscriber based on stream quality changes. This feature of the OpenTok Media
  *   Router has a subscriber drop the video stream when connectivity degrades. (The subscriber
  *   continues to receive the audio stream, if there is one.)
  *   <p>
  *   Before sending this event, when the Subscriber's stream quality deteriorates to a level
  *   that is low enough that the video stream is at risk of being disabled, the Subscriber
  *   dispatches a <code>videoDisableWarning</code> event.
  *   <p>
  *   If connectivity improves to support video again, the Subscriber object dispatches
  *   a <code>videoEnabled</code> event, and the Subscriber resumes receiving video.
  *   <p>
  *   By default, the Subscriber displays a video disabled indicator when a
  *   <code>videoDisabled</code> event with this reason is dispatched and removes the indicator
  *   when the <code>videoDisabled</code> event with this reason is dispatched. You can control
  *   the display of this icon by calling the <code>setStyle()</code> method of the Subscriber,
  *   setting the <code>videoDisabledDisplayMode</code> property(or you can set the style when
  *   calling the <code>Session.subscribe()</code> method, setting the <code>style</code> property
  *   of the <code>properties</code> parameter).
  *   <p>
  *   This feature is only available in sessions that use the OpenTok Media Router (sessions with
  *   the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">media mode</a>
  *   set to routed), not in sessions with the media mode set to relayed.
  *   <p>
  *   You can disable this audio-only fallback feature, by setting the
  *   <code>audioFallbackEnabled</code> property to <code>false</code> in the options you pass
  *   into the <code>OT.initPublisher()</code> method on the publishing client. (See
  *   <a href="OT.html#initPublisher">OT.initPublisher()</a>.)
  *   </li>
  *
  *   <li><code>"subscribeToVideo"</code> &mdash; The subscriber started or stopped subscribing to
  *   video, by calling <code>subscribeToVideo(false)</code>.
  *   </li>
  *
  * </ul>
  *
  * @see VideoEnabledChangedEvent
  * @see event:videoDisableWarning
  * @see event:videoEnabled
  * @name videoDisabled
  * @event
  * @memberof Subscriber
  */

  /**
  * Dispatched when the OpenTok Media Router determines that the stream quality has degraded
  * and the video will be disabled if the quality degrades more. If the quality degrades further,
  * the Subscriber disables the video and dispatches a <code>videoDisabled</code> event.
  * <p>
  * By default, the Subscriber displays a video disabled warning indicator when this event
  * is dispatched (and the video is disabled). You can control the display of this icon by
  * calling the <code>setStyle()</code> method and setting the
  * <code>videoDisabledDisplayMode</code> property (or you can set the style when calling
  * the <code>Session.subscribe()</code> method and setting the <code>style</code> property
  * of the <code>properties</code> parameter).
  * <p>
  * This feature is only available in sessions that use the OpenTok Media Router (sessions with
  * the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">media mode</a>
  * set to routed), not in sessions with the media mode set to relayed.
  *
  * @see Event
  * @see event:videoDisabled
  * @see event:videoDisableWarningLifted
  * @name videoDisableWarning
  * @event
  * @memberof Subscriber
  */

  /**
  * Dispatched when the OpenTok Media Router determines that the stream quality has improved
  * to the point at which the video being disabled is not an immediate risk. This event is
  * dispatched after the Subscriber object dispatches a <code>videoDisableWarning</code> event.
  * <p>
  * This feature is only available in sessions that use the OpenTok Media Router (sessions with
  * the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">media mode</a>
  * set to routed), not in sessions with the media mode set to relayed.
  *
  * @see Event
  * @see event:videoDisabled
  * @see event:videoDisableWarning
  * @name videoDisableWarningLifted
  * @event
  * @memberof Subscriber
  */

  /**
  * Dispatched when the OpenTok Media Router resumes sending video to the subscriber
  * after video was previously disabled.
  * <p>
  * The <code>reason</code> property defines the reason the video was enabled. This can be set to
  * one of the following values:
  * <p>
  *
  * <ul>
  *
  *   <li><code>"publishVideo"</code> &mdash; The publisher started publishing video by calling
  *   <code>publishVideo(true)</code>.</li>
  *
  *   <li><code>"quality"</code> &mdash; The OpenTok Media Router resumed sending video
  *   to the subscriber based on stream quality changes. This feature of the OpenTok Media
  *   Router has a subscriber drop the video stream when connectivity degrades and then resume
  *   the video stream if the stream quality improves.
  *   <p>
  *   This feature is only available in sessions that use the OpenTok Media Router (sessions with
  *   the <a href="http://tokbox.com/opentok/tutorials/create-session/#media-mode">media mode</a>
  *   set to routed), not in sessions with the media mode set to relayed.
  *   </li>
  *
  *   <li><code>"subscribeToVideo"</code> &mdash; The subscriber started or stopped subscribing to
  *   video, by calling <code>subscribeToVideo(false)</code>.
  *   </li>
  *
  * </ul>
  *
  * <p>
  * To prevent video from resuming, in the <code>videoEnabled</code> event listener,
  * call <code>subscribeToVideo(false)</code> on the Subscriber object.
  *
  * @see VideoEnabledChangedEvent
  * @see event:videoDisabled
  * @name videoEnabled
  * @event
  * @memberof Subscriber
  */

  /**
  * Dispatched when the Subscriber element is removed from the HTML DOM. When this event is
  * dispatched, you may choose to adjust or remove HTML DOM elements related to the subscriber.
  * @see Event
  * @name destroyed
  * @event
  * @memberof Subscriber
  */

  /**
  * Dispatched when the video dimensions of the video change. This can occur when the
  * <code>stream.videoType</code> property is set to <code>"screen"</code> (for a screen-sharing
  * video stream), when the user resizes the window being captured. It can also occur if the video
  * is being published by a mobile device and the user rotates the device (causing the camera
  * orientation to change). This event object has a <code>newValue</code> property and an
  * <code>oldValue</code> property, representing the new and old dimensions of the video.
  * Each of these has a <code>height</code> property and a <code>width</code> property,
  * representing the height and width, in pixels.
  * @name videoDimensionsChanged
  * @event
  * @memberof Subscriber
  * @see VideoDimensionsChangedEvent
  */
};

Subscriber.WidgetView = WidgetView;
Subscriber.SubscriberPeerConnection = SubscriberPeerConnection;

module.exports = Subscriber;
