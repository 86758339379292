'use strict';

/* global ActiveXObject */

var OTHelpers = require('@opentok/ot-helpers');
var opentokPlugin = require('../../opentok-plugin');
var versionGreaterThan = require('./version_greater_than.js');

var meta = {};
module.exports = meta;

meta.mimeType = 'application/x-opentokplugin,version=' + opentokPlugin.version;
meta.activeXName = 'TokBox.OpenTokPlugin.' + opentokPlugin.version;

var updaterMimeType,        // <- cached version, use getInstallerMimeType instead
    installedVersion = -1,  // <- cached version, use getInstallerMimeType instead
    waitingForInstallerPath = [],
    installerPath;

// Work out the full mimeType (including the currently installed version)
// of the installer.
var findMimeTypeAndVersion = function() {

  if (updaterMimeType !== void 0) {
    return updaterMimeType;
  }

  var activeXControlId = 'TokBox.OpenTokPluginInstaller',
      installPluginName = 'OpenTokPluginInstaller',
      unversionedMimeType = 'application/x-opentokplugininstaller',
      plugin = navigator.plugins[activeXControlId] || navigator.plugins[installPluginName];

  installedVersion = -1;

  if (plugin) {
    // Look through the supported mime-types for the version
    // There should only be one mime-type in our use case, and
    // if there's more than one they should all have the same
    // version.
    var numMimeTypes = plugin.length,
        extractVersion = new RegExp(unversionedMimeType.replace('-', '\\-') +
                                                    ',version=([0-9a-zA-Z-_.]+)', 'i'),
        mimeType,
        bits;

    for (var i = 0; i < numMimeTypes; ++i) {
      mimeType = plugin[i];

      // Look through the supported mimeTypes and find
      // the newest one.
      if (mimeType && mimeType.enabledPlugin &&
          (mimeType.enabledPlugin.name === plugin.name) &&
          mimeType.type.indexOf(unversionedMimeType) !== -1) {

        bits = extractVersion.exec(mimeType.type);

        if (bits !== null && versionGreaterThan(bits[1], installedVersion)) {
          installedVersion = bits[1];
        }
      }
    }
  } else if (OTHelpers.env.name === 'IE') {
    // This may mean that the installer plugin is not installed.
    // Although it could also mean that we're on IE 9 and below,
    // which does not support navigator.plugins. Fallback to
    // using 'ActiveXObject' instead.
    try {
      plugin = new ActiveXObject(activeXControlId);
      installedVersion = plugin.getMasterVersion();
    } catch (e) {}
  }

  updaterMimeType = installedVersion !== -1 ?
                            unversionedMimeType + ',version=' + installedVersion :
                            null;
};

meta.getInstallerMimeType = function() {
  if (updaterMimeType === void 0) {
    findMimeTypeAndVersion();
  }

  return updaterMimeType;
};

meta.version = function() {
  return opentokPlugin.version;
};

meta.installedVersion = function() {
  if (installedVersion === void 0) {
    findMimeTypeAndVersion();
  }

  return installedVersion;
};

// Version 0.4.0.4 autoupdate was broken. We want to prompt
// for install on 0.4.0.4 or earlier. We're also including
// earlier versions just in case. Version 0.4.0.10 also
// had a broken updater, we'll treat that version the same
// way.
meta.hasBrokenUpdater = function() {
  var _broken = meta.installedVersion() === '0.4.0.9' ||
                !versionGreaterThan(meta.installedVersion(), '0.4.0.4');

  meta.hasBrokenUpdater = function() { return _broken; };
  return _broken;
};

meta.setPathToInstaller = function(newInstallerPath) {
  installerPath = newInstallerPath + 'OpenTokPluginMain.msi';
  waitingForInstallerPath.forEach(function(waiting) {
    waiting(installerPath);
  });
  waitingForInstallerPath = [];
};

// Returns a URI to the OTPlugin installer that is paired with
// this version of OTPlugin.js.
meta.pathToInstaller = function(callback) {
  if (installerPath) {
    // Give it to them straight up
    callback(installerPath);
  } else {
  // queue up to give it to them later
    waitingForInstallerPath.push(callback);
  }
};
