'use strict';

var capabilities = require('./capabilities');

function isWebSocketSupported() {
  return 'WebSocket' in global && global.WebSocket !== void 0;
}

module.exports = isWebSocketSupported;

// Indicates if the client supports WebSockets.
capabilities.registerCapability('websockets', isWebSocketSupported);
