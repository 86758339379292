'use strict';

var DataChannel = require('./data_channel.js');
var OTHelpers = require('@opentok/ot-helpers');

// Contains a collection of DataChannels for a particular RTCPeerConnection
//
// @param [RTCPeerConnection] pc A native peer connection object
//
// @constructor
// @private
//
module.exports = function PeerConnectionChannels(pc) {
  /// Private Data
  var channels = [];
  var api = {};

  var lastQos = {
    sentMessages: 0,
    recvMessages: 0
  };

  /// Private API

  var remove = function remove(channel) {
    channels.filter(function(c) {
      return channel !== c;
    });
  };

  var add = function add(nativeChannel) {
    var channel = new DataChannel(nativeChannel);
    channels.push(channel);

    channel.on('close', function() {
      remove(channel);
    });

    return channel;
  };

  /// Public API

  api.add = function(label, options) {
    return add(pc.createDataChannel(label, options));
  };

  api.addMany = function(newChannels) {
    for (var label in newChannels) {
      if (newChannels.hasOwnProperty(label)) {
        api.add(label, newChannels[label]);
      }
    }
  };

  api.get = function(label, options) {
    return OTHelpers.find(channels, function(channel) {
      return channel.equals(label, options);
    });
  };

  api.getOrAdd = function(label, options) {
    var channel = api.get(label, options);
    if (!channel) {
      channel = api.add(label, options);
    }

    return channel;
  };

  api.getQosData = function() {
    var qosData = {
      sentMessages: 0,
      recvMessages: 0
    };

    channels.forEach(function(channel) {
      qosData.sentMessages += channel.getQosData().sentMessages;
      qosData.recvMessages += channel.getQosData().recvMessages;
    });

    return qosData;
  };

  api.sampleQos = function() {
    var newQos = api.getQosData();

    var sampleQos = {
      sentMessages: newQos.sentMessages - lastQos.sentMessages,
      recvMessages: newQos.recvMessages - lastQos.recvMessages
    };

    lastQos = newQos;

    return sampleQos;
  };

  api.destroy = function() {
    channels.forEach(function(channel) {
      channel.close();
    });

    channels = [];
  };

  /// Init

  pc.addEventListener('datachannel', function(event) {
    add(event.channel);
  }, false);

  return api;
};
