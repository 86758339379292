'use strict';

var uuid = require('uuid');

/* global chrome */

module.exports = {
  isSupportedInThisBrowser: !!navigator.webkitGetUserMedia && typeof chrome !== 'undefined',
  autoRegisters: false,
  extensionRequired: true,
  getConstraintsShowsPermissionUI: true,
  sources: {
    screen: true,
    application: false,
    window: false,
    browser: false
  },

  register: function(extensionID, version) {
    if (version === 2) {
      return this.registerVersion2(extensionID);
    }
    return this.registerVersion1(extensionID);
  },

  registerVersion1: function(extensionID) {
    if (!extensionID) {
      throw new Error('initChromeScreenSharingExtensionHelper: extensionID is required.');
    }

    var isChrome = !!navigator.webkitGetUserMedia && typeof chrome !== 'undefined';
    var callbackRegistry = {};
    var isInstalled = void 0;

    var prefix = 'com.tokbox.screenSharing.' + extensionID;
    var request = function(method, payload) {
      var res = { payload: payload, from: 'jsapi' };
      res[prefix] = method;
      return res;
    };

    var addCallback = function(fn, timeToWait) {
      var timeout;
      var requestId = uuid();

      callbackRegistry[requestId] = function() {
        clearTimeout(timeout);
        timeout = null;
        fn.apply(null, arguments);
      };
      if (timeToWait) {
        timeout = setTimeout(function() {
          delete callbackRegistry[requestId];
          fn(new Error('Timeout waiting for response to request.'));
        }, timeToWait);
      }
      return requestId;
    };

    var isAvailable = function(callback) {
      if (!callback) {
        throw new Error('isAvailable: callback is required.');
      }

      if (!isChrome) {
        setTimeout(callback.bind(null, false));
        return;
      }

      if (isInstalled !== void 0) {
        setTimeout(callback.bind(null, isInstalled));
      } else {
        var requestId = addCallback(function(event) {
          if (isInstalled !== true) {
            isInstalled = (event === 'extensionLoaded');
          }
          callback(isInstalled);
        }, 2000);
        var post = request('isExtensionInstalled', { requestId: requestId });
        global.postMessage(post, '*');
      }
    };

    var getConstraints = function(source, constraints, callback) {
      if (!callback) {
        throw new Error('getSourceId: callback is required');
      }
      isAvailable(function(isInstalled) {
        if (!isInstalled) {
          return callback(new Error('Extension is not installed'));
        }

        var requestId = addCallback(function(event, payload) {
          if (event === 'permissionDenied') {
            callback(new Error('PermissionDeniedError'));
          } else {
            if (!constraints.video) {
              constraints.video = {};
            }
            if (!constraints.video.mandatory) {
              constraints.video.mandatory = {};
            }
            constraints.video.mandatory.chromeMediaSource = 'desktop';
            constraints.video.mandatory.chromeMediaSourceId = payload.sourceId;
            callback(void 0, constraints);
          }
        });
        global.postMessage(request('getSourceId', { requestId: requestId, source: source }), '*');
      });
    };

    global.addEventListener('message', function(event) {

      if (event.origin !== global.location.origin) {
        return;
      }

      if (!(event.data != null && typeof event.data === 'object')) {
        return;
      }

      if (event.data.from !== 'extension') {
        return;
      }

      var method = event.data[prefix];
      var payload = event.data.payload;

      if (payload && payload.requestId) {
        var callback = callbackRegistry[payload.requestId];
        delete callbackRegistry[payload.requestId];
        if (callback) {
          callback(method, payload);
        }
      }

      if (method === 'extensionLoaded') {
        isInstalled = true;
      }
    });

    return {
      extensionAPIVersion: 1,
      extensionID: extensionID,
      isInstalled: isAvailable,
      getConstraints: getConstraints
    };
  },

  registerVersion2: function(extensionID) {
    var isChrome = typeof chrome !== 'undefined' && typeof chrome.runtime !== 'undefined';

    var isInstalled = function(callback) {
      if (!callback) {
        throw new Error('isAvailable: callback is required.');
      }

      if (!isChrome) {
        setTimeout(callback.bind(null, false));
        return;
      }

      chrome.runtime.sendMessage(extensionID, {
        type: 'isInstalled'
      }, null, function(response) {
        setTimeout(callback.bind(null, !!response));
      });
    };

    var getConstraints = function(source, constraints, callback) {
      if (!callback) {
        throw new Error('getSourceId: callback is required');
      }
      isInstalled(function(installed) {
        if (!installed) {
          return callback(new Error('Extension is not installed'));
        }

        chrome.runtime.sendMessage(extensionID, {
          type: 'getSourceId',
          source: source
        }, null, function(data) {
          if (data.error === 'permissionDenied') {
            callback(new Error('PermissionDeniedError'));
          } else if (data.error) {
            callback(new Error('UnexpectError: ' + data.error));
          } else {
            if (!constraints.video) {
              constraints.video = {};
            }
            if (!constraints.video.mandatory) {
              constraints.video.mandatory = {};
            }
            constraints.video.mandatory.chromeMediaSource = 'desktop';
            constraints.video.mandatory.chromeMediaSourceId = data.sourceId;
            callback(void 0, constraints);

          }
        });
      });
    };

    return {
      extensionAPIVersion: 2,
      extensionID: extensionID,
      isInstalled: isInstalled,
      getConstraints: getConstraints
    };

  }
};
