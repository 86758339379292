'use strict';

var OTError = require('../../ot_error.js');
var OTHelpers = require('@opentok/ot-helpers');
var logging = require('../../logging.js');
var RumorMessageTypes = require('../rumor/rumor_message_types.js');
var unboxFromRumorMessage = require('./unbox_from_rumor_message.js');

var Dispatcher = function() {
  OTHelpers.eventing(this, true);
  this.callbacks = {};
};

module.exports = Dispatcher;

Dispatcher.prototype.registerCallback = function(transactionId, completion) {
  this.callbacks[transactionId] = completion;
};

Dispatcher.prototype.triggerCallback = function(transactionId) {
  /*, arg1, arg2, argN-1, argN*/
  if (!transactionId) { return; }

  var completion = this.callbacks[transactionId];

  if (completion && OTHelpers.isFunction(completion)) {
    var args = Array.prototype.slice.call(arguments);
    args.shift();

    completion.apply(null, args);
  }

  delete this.callbacks[transactionId];
};

Dispatcher.prototype.onClose = function(reason) {
  this.emit('close', reason);
};

Dispatcher.prototype.onReconnected = function() {
  this.emit('reconnected');
};

Dispatcher.prototype.onReconnecting = function() {
  this.emit('reconnecting');
};

Dispatcher.prototype.dispatch = function(rumorMessage) {
  // The special casing of STATUS messages is ugly. Need to think about
  // how to better integrate this.

  if (rumorMessage.type === RumorMessageTypes.STATUS) {
    logging.debug('OT.Raptor.dispatch: STATUS');
    logging.debug(rumorMessage);

    var error;

    if (rumorMessage.isError) {
      error = new OTError(rumorMessage.status);
    }

    this.triggerCallback(rumorMessage.transactionId, error, rumorMessage);

    return;
  }

  var message = unboxFromRumorMessage(rumorMessage);
  logging.debug('OT.Raptor.dispatch ' + message.signature);
  logging.debug(JSON.stringify(message, null, 2));

  switch (message.resource) {
    case 'session':
      this.dispatchSession(message);
      break;

    case 'connection':
      this.dispatchConnection(message);
      break;

    case 'stream':
      this.dispatchStream(message);
      break;

    case 'stream_channel':
      this.dispatchStreamChannel(message);
      break;

    case 'subscriber':
      this.dispatchSubscriber(message);
      break;

    case 'subscriber_channel':
      this.dispatchSubscriberChannel(message);
      break;

    case 'signal':
      this.dispatchSignal(message);
      break;

    case 'archive':
      this.dispatchArchive(message);
      break;

    default:
      logging.warn(
        'OT.Raptor.dispatch: Type ' + message.resource + ' is not currently implemented'
      );
  }
};

Dispatcher.prototype.dispatchSession = function(message) {
  switch (message.method) {
    case 'read':
      this.emit('session#read', message.content, message.transactionId);
      break;

    default:
      logging.warn('OT.Raptor.dispatch: ' + message.signature + ' is not currently implemented');
  }
};

Dispatcher.prototype.dispatchConnection = function(message) {

  switch (message.method) {
    case 'created':
      this.emit('connection#created', message.content);
      break;

    case 'deleted':
      this.emit('connection#deleted', message.params.connection, message.reason);
      break;

    default:
      logging.warn('OT.Raptor.dispatch: ' + message.signature + ' is not currently implemented');
  }
};

Dispatcher.prototype.dispatchStream = function(message) {

  switch (message.method) {
    case 'created':
      this.emit('stream#created', message.content, message.transactionId);
      break;

    case 'deleted':
      this.emit('stream#deleted', message.params.stream,
        message.reason);
      break;

    case 'updated':
      this.emit('stream#updated', message.params.stream,
        message.content);
      break;

    // The JSEP process
    case 'generateoffer':
    case 'answer':
    case 'pranswer':
    case 'offer':
    case 'candidate':
      this.dispatchJsep(message.method, message);
      break;

    default:
      logging.warn('OT.Raptor.dispatch: ' + message.signature + ' is not currently implemented');
  }
};

Dispatcher.prototype.dispatchStreamChannel = function(message) {
  switch (message.method) {
    case 'updated':
      this.emit('streamChannel#updated', message.params.stream,
        message.params.channel, message.content);
      break;

    default:
      logging.warn('OT.Raptor.dispatch: ' + message.signature + ' is not currently implemented');
  }
};

// Dispatch JSEP messages
//
// generateoffer:
// Request to generate a offer for another Peer (or Prism). This kicks
// off the JSEP process.
//
// answer:
// generate a response to another peers offer, this contains our constraints
// and requirements.
//
// pranswer:
// a provisional answer, i.e. not the final one.
//
// candidate
//
//
Dispatcher.prototype.dispatchJsep = function(method, message) {
  this.emit('jsep#' + method, message.params.stream, message.fromAddress, message);
};

Dispatcher.prototype.dispatchSubscriberChannel = function(message) {
  switch (message.method) {
    case 'updated':
      this.emit('subscriberChannel#updated', message.params.stream,
        message.params.channel, message.content);
      break;

    case 'update': // subscriberId, streamId, content
      this.emit('subscriberChannel#update', message.params.subscriber,
        message.params.stream, message.content);
      break;

    default:
      logging.warn('OT.Raptor.dispatch: ' + message.signature + ' is not currently implemented');
  }
};

Dispatcher.prototype.dispatchSubscriber = function(message) {
  switch (message.method) {
    case 'created':
      this.emit('subscriber#created', message.params.stream, message.fromAddress,
        message.content.id);
      break;

    case 'deleted':
      this.dispatchJsep('unsubscribe', message);
      this.emit('subscriber#deleted', message.params.stream,
        message.fromAddress);
      break;

    // The JSEP process
    case 'generateoffer':
    case 'answer':
    case 'pranswer':
    case 'offer':
    case 'candidate':
      this.dispatchJsep(message.method, message);
      break;

    default:
      logging.warn('OT.Raptor.dispatch: ' + message.signature + ' is not currently implemented');
  }
};

Dispatcher.prototype.dispatchSignal = function(message) {
  if (message.method !== 'signal') {
    logging.warn('OT.Raptor.dispatch: ' + message.signature + ' is not currently implemented');
    return;
  }
  this.emit('signal', message.fromAddress, message.content.type,
    message.content.data);
};

Dispatcher.prototype.dispatchArchive = function(message) {
  switch (message.method) {
    case 'created':
      this.emit('archive#created', message.content);
      break;

    case 'updated':
      this.emit('archive#updated', message.params.archive, message.content);
      break;

    default:
  }
};
