'use strict';

var $ = require('./elementCollection/shorthandSelector');
var makeEverythingAttachToOTHelpers = require('./makeEverythingAttachToOTHelpers');

var domExtras = {};
module.exports = domExtras;

domExtras.isElementNode = function(node) {
  return node && typeof node === 'object' && node.nodeType === 1;
};

domExtras.createElement = function(nodeName, attributes, children, doc) {
  var element = (doc || document).createElement(nodeName);

  if (attributes) {
    for (var name in attributes) {
      if (typeof(attributes[name]) === 'object') {
        if (!element[name]) element[name] = {};

        var subAttrs = attributes[name];
        for (var n in subAttrs) {
          element[name][n] = subAttrs[n];
        }
      }
      else if (name === 'className') {
        element.className = attributes[name];
      }
      else {
        element.setAttribute(name, attributes[name]);
      }
    }
  }

  var setChildren = function(child) {
    if(typeof child === 'string') {
      element.innerHTML = element.innerHTML + child;
    } else {
      element.appendChild(child);
    }
  };

  if (Array.isArray(children)) {
    children.forEach(setChildren);
  } else if(children) {
    setChildren(children);
  }

  return element;
};

domExtras.createButton = function(innerHTML, attributes, events) {
  var button = domExtras.createElement('button', attributes, innerHTML);

  if (events) {
    for (var name in events) {
      if (events.hasOwnProperty(name)) {
        $(button).on(name, events[name]);
      }
    }

    button._boundEvents = events;
  }

  return button;
};

makeEverythingAttachToOTHelpers(domExtras);
