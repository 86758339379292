'use strict';

var OTHelpers = require('@opentok/ot-helpers');

// Returns a String representing the supported WebRTC crypto scheme. The possible
// values are SDES_SRTP, DTLS_SRTP, and NONE;
//
// Broadly:
// * Firefox only supports DTLS
// * Older versions of Chrome (<= 24) only support SDES
// * Newer versions of Chrome (>= 25) support DTLS and SDES
//
module.exports = function supportedCryptoScheme() {
  var chromeBefore25 = (
    OTHelpers.env.name === 'Chrome' &&
    OTHelpers.env.version < 25
  );

  return (chromeBefore25 ? 'SDES_SRTP' : 'DTLS_SRTP');
};
