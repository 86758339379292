'use strict';

/* jshint browser:true, smarttabs:true */

var $ = require('../elementCollection/shorthandSelector');
var env = require('../env');
var makeEverythingAttachToOTHelpers = require('../makeEverythingAttachToOTHelpers');
var util = require('../util');

var browserAjax = {};
module.exports = browserAjax;

function formatPostData(data) { //, contentType
  // If it's a string, we assume it's properly encoded
  if (typeof(data) === 'string') return data;

  var queryString = [];

  for (var key in data) {
    queryString.push(
      encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
    );
  }

  return queryString.join('&').replace(/\+/g, '%20');
}

if (env.name !== 'Node') {
  browserAjax.xdomainRequest = function(url, options, callback) {
    /* global XDomainRequest */
    // TODO: this is deprecated: https://developer.mozilla.org/en-US/docs/Web/API/XDomainRequest
    var xdr = new XDomainRequest(),
        _options = options || {},
        _method = _options.method.toLowerCase();

    if(!_method) {
      callback(new Error('No HTTP method specified in options'));
      return;
    }

    _method = _method.toUpperCase();

    if(!(_method === 'GET' || _method === 'POST')) {
      callback(new Error('HTTP method can only be '));
      return;
    }

    function done(err, event) {
      xdr.onload = xdr.onerror = xdr.ontimeout = function() {};
      xdr = void 0;
      callback(err, event);
    }


    xdr.onload = function() {
      done(null, {
        target: {
          responseText: xdr.responseText,
          headers: {
            'content-type': xdr.contentType
          }
        }
      });
    };

    xdr.onerror = function() {
      done(new Error('XDomainRequest of ' + url + ' failed'));
    };

    xdr.ontimeout = function() {
      done(new Error('XDomainRequest of ' + url + ' timed out'));
    };

    xdr.open(_method, url);
    xdr.send(options.body && formatPostData(options.body));

  };

  browserAjax.request = function(url, options, callback) {
    var request = new XMLHttpRequest(),
        _options = options || {},
        _method = _options.method;

    if(!_method) {
      callback(new Error('No HTTP method specified in options'));
      return;
    }

    if (options.overrideMimeType) {
      if (request.overrideMimeType) {
        request.overrideMimeType(options.overrideMimeType);
      }
      delete options.overrideMimeType;
    }

    // Setup callbacks to correctly respond to success and error callbacks. This includes
    // interpreting the responses HTTP status, which XmlHttpRequest seems to ignore
    // by default.
    if (callback) {
      $(request).on('load', function(event) {
        var status = event.target.status;

        // We need to detect things that XMLHttpRequest considers a success,
        // but we consider to be failures.
        if ( status >= 200 && (status < 300 || status === 304) ) {
          callback(null, event);
        } else {
          callback(event);
        }
      });

      $(request).on('error', callback);
    }

    request.open(options.method, url, true);

    if (!_options.headers) _options.headers = {};

    for (var name in _options.headers) {
      if (!Object.prototype.hasOwnProperty.call(_options.headers, name)) {
        continue;
      }
      request.setRequestHeader(name, _options.headers[name]);
    }

    request.send(options.body && formatPostData(options.body));
  };

  browserAjax.get = function(url, options, callback) {
    var _options = util.extend(options || {}, {
      method: 'GET'
    });
    browserAjax.request(url, _options, callback);
  };

  browserAjax.post = function(url, options, callback) {
    var _options = util.extend(options || {}, {
      method: 'POST'
    });

    if(_options.xdomainrequest) {
      browserAjax.xdomainRequest(url, _options, callback);
    } else {
      browserAjax.request(url, _options, callback);
    }
  };

  browserAjax.getJSON = function(url, options, callback) {
    options = options || {};

    var done = function(error, event) {
      if(error) {
        callback(error, event && event.target && event.target.responseText);
      } else {
        var response;

        try {
          response = JSON.parse(event.target.responseText);
        } catch(e) {
          // Badly formed JSON
          callback(e, event && event.target && event.target.responseText);
          return;
        }

        callback(null, response, event);
      }
    };

    if(options.xdomainrequest) {
      browserAjax.xdomainRequest(url, { method: 'GET' }, done);
    } else {
      var extendedHeaders = util.extend({
        'Accept': 'application/json'
      }, options.headers || {});

      browserAjax.get(url, util.extend(options || {}, {
        headers: extendedHeaders
      }), done);
    }

  };

  makeEverythingAttachToOTHelpers(browserAjax);
}
