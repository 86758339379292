'use strict';

var logging = require('../logging.js');
var SDPHelpers = require('./sdp_helpers.js');

// Attempt to completely process a subscribe message. This will:
// * create an Offer
// * set the new offer as the location description
//
// If there are no issues, the +success+ callback will be executed on completion.
// Errors during any step will result in the +failure+ callback being executed.
//
module.exports = function subscribeProcessor(
  peerConnection,
  numberOfSimulcastStreams,
  success,
  failure
) {
  var generateErrorCallback, setLocalDescription;

  generateErrorCallback = function(message, prefix) {
    return function(errorReason) {
      logging.error(message);
      logging.error(errorReason);

      if (failure) { failure(message, errorReason, prefix); }
    };
  };

  setLocalDescription = function(offer) {
    offer.sdp = SDPHelpers.removeComfortNoise(offer.sdp);
    offer.sdp = SDPHelpers.removeVideoCodec(offer.sdp, 'ulpfec');
    offer.sdp = SDPHelpers.removeVideoCodec(offer.sdp, 'red');

    if (peerConnection.getLocalStreams().length === 0) {
      offer.sdp = SDPHelpers.fixChromeBug528089(offer.sdp);
    }

    if (numberOfSimulcastStreams > 1) {
      offer.sdp = SDPHelpers.enableSimulcast(offer.sdp, numberOfSimulcastStreams);
    }

    peerConnection.setLocalDescription(
      offer,

      // Success
      function() {
        success(offer);
      },

      // Failure
      generateErrorCallback('Error while setting LocalDescription', 'SetLocalDescription')
    );
  };

  peerConnection.createOffer(
    // Success
    setLocalDescription,

    // Failure
    generateErrorCallback('Error while creating Offer', 'CreateOffer'),

    // Constraints
    {
      iceRestart: true
    }
  );
};
