'use strict';

var analytics = require('./analytics.js');
var APIKEY = require('./api_key.js');
var Dialogs = require('../helpers/dialogs.js');
var EnvironmentLoader = require('../ot/environment_loader.js');
var OTPlugin = require('@opentok/otplugin.js');
var OTHelpers = require('@opentok/ot-helpers');
var logging = require('./logging.js');
var properties = require('../helpers/properties.js');

var systemRequirements = {};
module.exports = systemRequirements;

// Global parameters used by upgradeSystemRequirements
var _intervalId;
var _lastHash = document.location.hash;

var HAS_REQUIREMENTS = 1;
var NOT_HAS_REQUIREMENTS = 0;

/**
 * Checks if the system supports OpenTok for WebRTC. Note that this method is called
 * automatically when you call <code>OT.initPublisher()</code> or <code>OT.initSession()</code>,
 * and if the system doesn't support OpenTok, the OpenTok.js library displays a message to
 * the user. Call the <code>OT.checkSystemRequirements()</code> method before calling
 * <code>OT.initPublisher()</code> or <code>OT.initSession()</code> if you do not want the
 * library to display that message.
 *
 * @return {Number} Whether the system supports OpenTok for WebRTC (1) or not (0).
 * @see <a href="#upgradeSystemRequirements">OT.upgradeSystemRequirements()</a>
 * @method OT.checkSystemRequirements
 * @memberof OT
 */
systemRequirements.check = function() {
  logging.debug('OT.checkSystemRequirements()');

  // Try native support first, then OTPlugin...
  var systemRequirementsMet = OTHelpers.hasCapabilities('websockets', 'webrtc') ||
                                    OTPlugin.isInstalled();

  systemRequirementsMet = systemRequirementsMet ?
                                    HAS_REQUIREMENTS : NOT_HAS_REQUIREMENTS;

  systemRequirements.check = function() {
    logging.debug('OT.checkSystemRequirements()');
    return systemRequirementsMet;
  };

  if (systemRequirementsMet === NOT_HAS_REQUIREMENTS) {
    analytics.logEvent({
      action: 'checkSystemRequirements',
      variation: 'notHasRequirements',
      partnerId: APIKEY.value,
      payload: { userAgent: OTHelpers.env.userAgent }
    });
  }

  return systemRequirementsMet;
};

/**
 * Displays information about system requirments for OpenTok for WebRTC. This
 * information is displayed in an iframe element that fills the browser window.
 * <p>
 * <i>Note:</i> this information is displayed automatically when you call the
 * <code>OT.initSession()</code> or the <code>OT.initPublisher()</code> method
 * if the client does not support OpenTok for WebRTC.
 * </p>
 * @see <a href="#checkSystemRequirements">OT.checkSystemRequirements()</a>
 * @method OT.upgradeSystemRequirements
 * @memberof OT
 */
systemRequirements.upgrade = function() {
  // trigger after the OT environment has loaded
  EnvironmentLoader.onLoad(function() {

    if (OTPlugin.isSupported()) {
      Dialogs.Plugin.promptToInstall().on({
        download: function() {
          OTPlugin.pathToInstaller(function(pathToInstaller) {
            global.location = pathToInstaller;
          });
        },
        refresh: function() {
          location.reload();
        },
        closed: function() {}
      });
      return;
    }

    var id = '_upgradeFlash';

    // Load the iframe over the whole page.
    document.body.appendChild((function() {
      var d = document.createElement('iframe');
      d.id = id;
      d.style.position = 'absolute';
      d.style.position = 'fixed';
      d.style.height = '100%';
      d.style.width = '100%';
      d.style.top = '0px';
      d.style.left = '0px';
      d.style.right = '0px';
      d.style.bottom = '0px';
      d.style.zIndex = 1000;
      d.style.backgroundColor = 'rgba(0,0,0,0.2)';
      d.setAttribute('frameBorder', '0');
      d.frameBorder = '0';
      d.scrolling = 'no';
      d.setAttribute('scrolling', 'no');

      var minimumBrowserVersion = properties.minimumVersion[OTHelpers.env.name.toLowerCase()];
      var isSupportedButOld =  minimumBrowserVersion > OTHelpers.env.version;
      d.src = properties.assetURL + '/html/upgrade.html#' +
                        encodeURIComponent(isSupportedButOld ? 'true' : 'false') + ',' +
                        encodeURIComponent(JSON.stringify(properties.minimumVersion)) + '|' +
                        encodeURIComponent(document.location.href);

      return d;
    })());

    // Now we need to listen to the event handler if the user closes this dialog.
    // Since this is from an IFRAME within another domain we are going to listen to hash
    // changes. The best cross browser solution is to poll for a change in the hashtag.
    if (_intervalId) { clearInterval(_intervalId); }
    _intervalId = setInterval(function() {
      var hash = document.location.hash;
      var re = /^#?\d+&/;
      if (hash !== _lastHash && re.test(hash)) {
        _lastHash = hash;
        if (hash.replace(re, '') === 'close_window') {
          document.body.removeChild(document.getElementById(id));
          document.location.hash = '';
        }
      }
    }, 100);
  });
};

module.exports = systemRequirements;
