'use strict';

var Message = require('./rumor_message.js');

var BUFFER_DRAIN_INTERVAL = 100;

// The total number of times to retest the websocket's send buffer
var BUFFER_DRAIN_MAX_RETRIES = 10;

module.exports = function NativeSocket(TheWebSocket, messagingURL, events) {

  var webSocket,
      disconnectWhenSendBufferIsDrained,
      bufferDrainTimeout,           // Timer to poll whether th send buffer has been drained
      close;

  webSocket = new TheWebSocket(messagingURL);
  webSocket.binaryType = 'arraybuffer';

  webSocket.onopen = events.onOpen;
  webSocket.onclose = events.onClose;
  webSocket.onerror = events.onError;

  webSocket.onmessage = function(message) {
    if (!Message) {
      // In IE 10/11, This can apparently be called after
      // the page is unloaded and OT is garbage-collected

      // TODO: To avoid a circular dependency, I've replaced !OT with !Message for the condition. If
      // OT is garbage collected, surely Message would be too. We should try to figure out what's
      // actually going on here, I find it hard to believe this is really what was happening. And
      // even if it did, the result should be throwing an exception after the page unloads anyway,
      // and that shouldn't matter.

      return;
    }

    var msg = Message.deserialize(message.data);
    events.onMessage(msg);
  };

  // Ensure that the WebSocket send buffer is fully drained before disconnecting
  // the socket. If the buffer doesn't drain after a certain length of time
  // we give up and close it anyway.
  disconnectWhenSendBufferIsDrained = function disconnectWhenSendBufferIsDrained(bufferDrainRetries) {
    if (!webSocket) { return; }

    if (bufferDrainRetries === void 0) { bufferDrainRetries = 0; }
    if (bufferDrainTimeout) { clearTimeout(bufferDrainTimeout); }

    if (webSocket.bufferedAmount > 0 &&
      (bufferDrainRetries + 1) <= BUFFER_DRAIN_MAX_RETRIES) {
      bufferDrainTimeout = setTimeout(disconnectWhenSendBufferIsDrained,
        BUFFER_DRAIN_INTERVAL, bufferDrainRetries + 1);

    } else {
      close();
    }
  };

  close = function close() {
    webSocket.close();
  };

  this.close = function(drainBuffer, removeEventHandlers) {
    if (removeEventHandlers) {
      var empty = function() {};
      webSocket.onopen = empty;
      webSocket.onclose = empty;
      webSocket.onerror = empty;
      webSocket.onmessage = empty;
    }
    if (drainBuffer) {
      disconnectWhenSendBufferIsDrained();
    } else {
      close();
    }
  };

  this.send = function(msg) {
    webSocket.send(msg.serialize());
  };

  this.isClosed = function() {
    return webSocket.readyState === 3;
  };
  
};
