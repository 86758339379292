'use strict';

var extensions = {
  attributes: require('./extensions/attributes'),
  css: require('./extensions/css'),
  classNames: require('./extensions/classNames'),
  observers: require('./extensions/observers')
};

var env = require('../env');
var util = require('../util');

// A helper for converting a NodeList to a JS Array
var nodeListToArray = function nodeListToArray(nodes) {
  if (env.name !== 'IE' || env.version > 9) {
    return Array.prototype.slice.call(nodes);
  }

  // IE 9 and below call use Array.prototype.slice.call against
  // a NodeList, hence the following
  var array = [];

  for (var i = 0, num = nodes.length; i < num; ++i) {
    array.push(nodes[i]);
  }

  return array;
};

var selectorToElementArray = function(selector, context) {
  var elements;

  if (typeof selector === 'undefined') {
    return [];
  }

  if (typeof selector === 'string') {
    elements = [];

    var idSelector = util.detectIdSelectors.exec(selector);
    context = context || document;

    if (idSelector && idSelector[1]) {
      var element = context.getElementById(idSelector[1]);
      if (element) {
        elements.push(element);
      }
    }
    else {
      elements = context.querySelectorAll(selector);
    }
  }
  else if (
    selector &&
    (
      selector.nodeType ||
      (global.XMLHttpRequest && selector instanceof XMLHttpRequest) ||
      selector === global
    )
  ) {
    // allow OTHelpers(DOMNode) and OTHelpers(xmlHttpRequest)
    elements = [selector];
    context = selector;
  }
  else if (Array.isArray(selector)) {
    elements = selector.slice();
    context  = null;
  }
  else {
    elements = nodeListToArray(elements);
  }

  return elements;
};

// ElementCollection contains the result of calling OTHelpers.
//
// It has the following properties:
//   length
//   first
//   last
//
// It also has a get method that can be used to access elements in the collection
//
//   var videos = OTHelpers('video');
//   var firstElement = videos.get(0);               // identical to videos.first
//   var lastElement = videos.get(videos.length-1);  // identical to videos.last
//   var allVideos = videos.get();
//
//
// The collection also implements the following helper methods:
//   some, forEach, map, filter, find,
//   appendTo, after, before, remove, empty,
//   attr, center, width, height,
//   addClass, removeClass, hasClass, toggleClass,
//   on, off, once,
//   observeStyleChanges, observeNodeOrChildNodeRemoval
//
// Mostly the usage should be obvious. When in doubt, assume it functions like
// the jQuery equivalent.
//
var ElementCollection = function ElementCollection(selector, context) {
  var elements = selectorToElementArray(selector, context);
  this.context = context;
  this.toArray = function() { return elements; };

  this.length = elements.length;
  this.first = elements[0];
  this.last = elements[elements.length-1];

  this.get = function(index) {
    if (index === void 0) return elements;
    return elements[index];
  };
};

module.exports = ElementCollection;

ElementCollection._attachToOTHelpers = {};

// @remove
ElementCollection._attachToOTHelpers.removeElementById = function(elementId) {
  return new ElementCollection('#'+elementId).remove();
};

// @remove
ElementCollection._attachToOTHelpers.removeElement = function(element) {
  return new ElementCollection(element).remove();
};

// @remove
ElementCollection._attachToOTHelpers.removeElementsByType = function(parentElem, type) {
  return new ElementCollection(type, parentElem).remove();
};

ElementCollection.prototype.getAsArray = function() {
  var _collection = this.get();

  if (!util.isFunction(_collection.forEach)) {
    // It's possibly something Array-ish that isn't quite an
    // Array. Something like arguments or a NodeList
    _collection = nodeListToArray(_collection);
  }

  return _collection;
};

ElementCollection.prototype.some = function (iter, context) {
  return this.getAsArray().some(iter, context);
};

ElementCollection.prototype.forEach = function(fn, context) {
  this.getAsArray().forEach(fn, context);
  return this;
};

ElementCollection.prototype.map = function(fn, context) {
  return new ElementCollection(this.getAsArray().map(fn, context), this.context);
};

ElementCollection.prototype.filter = function(fn, context) {
  return new ElementCollection(this.getAsArray().filter(fn, context), this.context);
};

ElementCollection.prototype.find = function(selector) {
  return new ElementCollection(selector, this.first);
};

// Helper function for adding event listeners to dom elements.
// WARNING: This doesn't preserve event types, your handler could
// be getting all kinds of different parameters depending on the browser.
// You also may have different scopes depending on the browser and bubbling
// and cancelable are not supported.
ElementCollection.prototype.on = function (eventName, handler) {
  return this.forEach(function(element) {
    element.addEventListener(eventName, handler, false);
  });
};

// Helper function for removing event listeners from dom elements.
ElementCollection.prototype.off = function (eventName, handler) {
  return this.forEach(function(element) {
    element.removeEventListener (eventName, handler,false);
  });
};

ElementCollection.prototype.once = function (eventName, handler) {
  var removeAfterTrigger = function() {
    this.off(eventName, removeAfterTrigger);
    handler.apply(null, arguments);
  }.bind(this);

  return this.on(eventName, removeAfterTrigger);
};

ElementCollection.prototype.appendTo = function(parentElement) {
  if (!parentElement) throw new Error('appendTo requires a DOMElement to append to.');

  return this.forEach(function(child) {
    parentElement.appendChild(child);
  });
};

ElementCollection.prototype.append = function() {
  var parentElement = this.first;
  if (!parentElement) return this;

  Array.prototype.forEach.call(arguments, function(child) {
    parentElement.appendChild(child);
  });

  return this;
};

ElementCollection.prototype.prepend = function() {
  if (arguments.length === 0) return this;

  var parentElement = this.first,
      elementsToPrepend;

  if (!parentElement) return this;

  elementsToPrepend = Array.prototype.slice.call(arguments);

  if (!parentElement.firstElementChild) {
    parentElement.appendChild(elementsToPrepend.shift());
  }

  elementsToPrepend.forEach(function(element) {
    parentElement.insertBefore(element, parentElement.firstElementChild);
  });

  return this;
};

ElementCollection.prototype.after = function(prevElement) {
  if (!prevElement) {
    throw new Error('after requires a DOMElement to insert after');
  }

  return this.forEach(function(element) {
    if (element.parentElement) {
      if (prevElement !== element.parentNode.lastChild) {
        element.parentElement.insertBefore(element, prevElement);
      }
      else {
        element.parentElement.appendChild(element);
      }
    }
  });
};

ElementCollection.prototype.before = function(nextElement) {
  if (!nextElement) {
    throw new Error('before requires a DOMElement to insert before');
  }

  return this.forEach(function(element) {
    if (element.parentElement) {
      element.parentElement.insertBefore(element, nextElement);
    }
  });
};

ElementCollection.prototype.remove = function () {
  return this.forEach(function(element) {
    if (element.parentNode) {
      element.parentNode.removeChild(element);
    }
  });
};

ElementCollection.prototype.empty = function () {
  return this.forEach(function(element) {
    // elements is a "live" NodesList collection. Meaning that the collection
    // itself will be mutated as we remove elements from the DOM. This means
    // that "while there are still elements" is safer than "iterate over each
    // element" as the collection length and the elements indices will be modified
    // with each iteration.
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  });
};

// Detects when an element is not part of the document flow because
// it or one of it's ancesters has display:none.
ElementCollection.prototype.isDisplayNone = function() {
  return this.some(function(element) {
    if ( (element.offsetWidth === 0 || element.offsetHeight === 0) &&
                new ElementCollection(element).css('display') === 'none') {
      return true;
    }

    if (element.parentNode && element.parentNode.style) {
      return new ElementCollection(element.parentNode).isDisplayNone();
    }
  });
};

var findElementWithDisplayNone = function(element) {
  if ( (element.offsetWidth === 0 || element.offsetHeight === 0) &&
            new ElementCollection(element).css('display') === 'none') {
    return element;
  }

  if (element.parentNode && element.parentNode.style) {
    return findElementWithDisplayNone(element.parentNode);
  }

  return null;
};

// TODO: I am preserving the behaviour that came before me, but it seems incorrect.
ElementCollection.prototype.findElementWithDisplayNone = findElementWithDisplayNone;

ElementCollection._attachToOTHelpers.findElementWithDisplayNone = findElementWithDisplayNone;

// @remove
ElementCollection._attachToOTHelpers.emptyElement = function(element) {
  return new ElementCollection(element).empty();
};

extensions.css(ElementCollection, findElementWithDisplayNone);
extensions.attributes(ElementCollection);
extensions.classNames(ElementCollection);
extensions.observers(ElementCollection);

// TODO: Deprecation logging?
// @remove
[
  'on',
  'off',
  'once',
  'isDisplayNone',
  'show',
  'hide',
  'css',
  'applyCSS',
  'makeVisibleAndYield',
  'addClass',
  'removeClass'
].forEach(function(methodName) {
  ElementCollection._attachToOTHelpers[methodName] = function(/* arguments */) {
    var args = Array.prototype.slice.apply(arguments);
    var element = args.shift();

    var collection = new ElementCollection(element);

    return ElementCollection.prototype[methodName].apply(collection, args);
  };
});
