'use strict';

var curryCallAsync = require('./curry_call_async.js');
var empiricDelay = require('./empiric_delay.js');
var logging = require('./logging.js');
var OTHelpers = require('@opentok/ot-helpers');
var TaskQueue = require('./task_queue.js');

module.exports = function pluginEventingBehaviour(api) {
  var eventHandlers = {},
      pendingTasks = new TaskQueue(),
      isReady = false; // TODO: is this just a duplicate of readiness.isReady?

  var onCustomEvent = function() {
    var args = Array.prototype.slice.call(arguments);
    api.emit(args.shift(), args);
  };

  var devicesChanged = function() {
    var args = Array.prototype.slice.call(arguments);
    logging.debug(args);
    api.emit('devicesChanged', args);
  };

  api.on = function(name, callback, context) {
    if (!eventHandlers.hasOwnProperty(name)) {
      eventHandlers[name] = [];
    }

    eventHandlers[name].push([callback, context]);
    return api;
  };

  api.off = function(name, callback, context) {
    if (!eventHandlers.hasOwnProperty(name) ||
        eventHandlers[name].length === 0) {
      return;
    }

    eventHandlers[name] = eventHandlers[name].filter(function(listener) {
      return listener[0] !== callback && listener[1] !== context;
    });

    return api;
  };

  api.once = function(name, callback, context) {
    var fn = function() {
      api.off(name, fn);
      return callback.apply(context, arguments);
    };

    api.on(name, fn);
    return api;
  };

  api.emit = function(name, args) {
    OTHelpers.callAsync(function() {
      if (!eventHandlers.hasOwnProperty(name) || eventHandlers[name].length === 0) {
        return;
      }

      eventHandlers[name].forEach(function(handler) {
        handler[0].apply(handler[1], args);
      });
    });

    return api;
  };

  // Calling this will bind a listener to the devicesChanged events that
  // the plugin emits and then rebroadcast them.
  api.listenForDeviceChanges = function() {
    if (!isReady) {
      pendingTasks.add(api.listenForDeviceChanges, api);
      return;
    }

    setTimeout(function() {
      api._.registerXCallback('devicesChanged', devicesChanged);
    }, empiricDelay);
  };

  var onReady = function onReady(readyCallback) {
    if (api._.on) {
      // If the plugin supports custom events we'll use them
      api._.on(-1, {
        customEvent: curryCallAsync(onCustomEvent)
      });
    }

    var internalReadyCallback = function() {
      // It's not safe to do most plugin operations until the plugin
      // is ready for us to do so. We use isReady as a guard in
      isReady = true;

      pendingTasks.runAll();
      readyCallback.call(api);
    };

    // Only the main plugin has an initialise method
    if (api._.initialise) {
      api.on('ready', curryCallAsync(internalReadyCallback));
      api._.initialise();
    } else {
      internalReadyCallback.call(api);
    }
  };

  return function(completion) {
    onReady(function(err) {
      if (err) {
        logging.error('Error while starting up plugin ' + api.uuid + ': ' + err);
        completion(err);
        return;
      }

      logging.debug('Plugin ' + api.id + ' is loaded');
      completion(void 0, api);
    });
  };
};
