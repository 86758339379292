'use strict';

// This is here because capabilities are all about global state. TODO: That's why they need to go.
require('./web_rtc_polyfills.js');

// Web OT Helpers
var OTPlugin = require('@opentok/otplugin.js');
var OTHelpers = require('@opentok/ot-helpers');

var deviceHelpers = {};
module.exports = deviceHelpers;

///
// Device Helpers
//
// Support functions to enumerating and guerying device info
//

var deviceKindsMap = {
  audio: 'audioInput',
  video: 'videoInput',
  audioinput: 'audioInput',
  videoinput: 'videoInput',
  audioInput: 'audioInput',
  videoInput: 'videoInput'
};

var getNativeEnumerateDevices = function() {
  if (global.navigator.mediaDevices) {
    return global.navigator.mediaDevices.enumerateDevices;
  } else if (OTHelpers.hasCapabilities('otplugin')) {
    return OTPlugin.mediaDevices.enumerateDevices;
  } else if (global.MediaStreamTrack && global.MediaStreamTrack.getSources) {
    return global.MediaStreamTrack.getSources;
  }
};

var enumerateDevices = function(completion) {
  var fn = getNativeEnumerateDevices();

  if (global.navigator.mediaDevices && OTHelpers.isFunction(fn)) {
    // De-promisify the newer style APIs. We aren't ready for Promises yet...
    fn = function(completion) {
      global.navigator.mediaDevices.enumerateDevices().then(function(devices) {
        completion(void 0, devices);
      })['catch'](function(err) {
        completion(err);
      });
    };
  } else if (global.MediaStreamTrack && global.MediaStreamTrack.getSources) {
    fn = function(completion) {
      global.MediaStreamTrack.getSources(function(devices) {
        completion(void 0, devices);
      });
    };
  }

  return fn(completion);
};

var fakeShouldAskForDevices = function fakeShouldAskForDevices(callback) {
  setTimeout(callback.bind(null, { video: true, audio: true }));
};

// Indicates whether this browser supports the enumerateDevices (getSources) API.
//
OTHelpers.registerCapability('enumerateDevices', function() {
  return OTHelpers.isFunction(getNativeEnumerateDevices());
});

deviceHelpers.getMediaDevices = function(completion, customGetDevices) {
  if (!OTHelpers.hasCapabilities('enumerateDevices')) {
    completion(new Error('This browser does not support enumerateDevices APIs'));
    return;
  }

  var getDevices = OTHelpers.isFunction(customGetDevices) ? customGetDevices
                                                     : enumerateDevices;

  getDevices(function(err, devices) {
    if (err) {
      completion(err);
      return;
    }

    // Normalise the device kinds
    var filteredDevices = OTHelpers(devices).map(function(device) {
      return {
        deviceId: device.deviceId || device.id,
        label: device.label,
        kind: deviceKindsMap[device.kind]
      };
    }).filter(function(device) {
      return device.kind === 'audioInput' || device.kind === 'videoInput';
    });

    completion(void 0, filteredDevices.get());
  });
};

deviceHelpers.shouldAskForDevices = function(callback) {
  if (OTHelpers.hasCapabilities('enumerateDevices')) {
    deviceHelpers.getMediaDevices(function(err, devices) {
      if (err) {
        // There was an error. It may be temporally. Just assume
        // all devices exist for now.
        fakeShouldAskForDevices(callback);
        return;
      }

      var hasAudio = devices.some(function(device) {
        return device.kind === 'audioInput';
      });

      var hasVideo = devices.some(function(device) {
        return device.kind === 'videoInput';
      });

      callback.call(null, { video: hasVideo, audio: hasAudio });
    });

  } else {
    // This environment can't enumerate devices anyway, so we'll memorise this result.
    // TODO: Is memoization worth it here?
    deviceHelpers.shouldAskForDevices = fakeShouldAskForDevices;
    deviceHelpers.shouldAskForDevices(callback);
  }
};
