module.exports = {
  "version": "@@version",
  "build": "@@build_revision",
  "buildTime": "@@build_time",
  "debug": "@@chef_start @debug @@chef_end",
  "websiteURL": "@@chef_start @website_url @@chef_end",
  "cdnURL": "@@chef_start @cdn_url @@chef_end",
  "loggingURL": "@@chef_start @logging_url @@chef_end",
  "apiURL": "@@chef_start @api_url @@chef_end",
  "messagingProtocol": "wss",
  "messagingPort": 443,
  "supportSSL": "@@chef_start @support_ssl @@chef_end",
  "cdnURLSSL": "@@chef_start @ssl_widget_url @@chef_end",
  "loggingURLSSL": "@@chef_start @logging_url_ssl @@chef_end",
  "apiURLSSL": "@@chef_start @api_url_ssl @@chef_end",
  "minimumVersion": {
    "firefox": null,
    "chrome": null
  }
}