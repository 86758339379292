'use strict';

var OTHelpers = require('@opentok/ot-helpers');
var logging = require('../../ot/logging.js');
var Promise = require('bluebird');

var canBeOrientatedMixin = require('./can_be_oriented_mixin.js');
var videoElementErrorCodeToStr = require('./video_element_error_code_to_str.js');
var videoContentResizesMixin = require('../video_content_resizes_mixin.js');
var audioContextProvider = require('../audio_context');
var WebaudioAudioLevelSampler = require('../audio_level_samplers/webaudio_audio_level_sampler');

function createNativeVideoElement(fallbackText, muted) {
  var videoElement = document.createElement('video');
  videoElement.setAttribute('autoplay', '');
  videoElement.innerHTML = fallbackText;

  if (muted === true) {
    videoElement.muted = 'true';
  }

  return videoElement;
}

function unbindNativeStream(videoElement) {
  videoElement.onended = null;

  if (videoElement.srcObject !== void 0) {
    videoElement.srcObject = null;
  } else if (videoElement.mozSrcObject !== void 0) {
    videoElement.mozSrcObject = null;
  } else {
    global.URL.revokeObjectURL(videoElement.src);
  }
}

function bindStreamToNativeVideoElement(videoElement, webRtcStream, completion) {
  var timeout;

  var cleanup = function cleanup() {
    clearTimeout(timeout);
    videoElement.removeEventListener('loadedmetadata', onLoad, false);
    videoElement.removeEventListener('error', onError, false);
    webRtcStream.onended = null;
    videoElement.onended = null;
  };

  var onLoad = function onLoad() {
    cleanup();
    completion(null);
  };

  var onError = function onError(event) {
    cleanup();
    unbindNativeStream(videoElement);
    completion('There was an unexpected problem with the Video Stream: ' +
      videoElementErrorCodeToStr(event.target.error.code));
  };

  var onStoppedLoading = function onStoppedLoading() {
    // The stream ended before we fully bound it. Maybe the other end called
    // stop on it or something else went wrong.
    cleanup();
    unbindNativeStream(videoElement);
    completion('Stream ended while trying to bind it to a video element.');
  };

  // The official spec way is 'srcObject', we are slowly converging there.
  if (videoElement.srcObject !== void 0) {
    videoElement.srcObject = webRtcStream;
  } else if (videoElement.mozSrcObject !== void 0) {
    videoElement.mozSrcObject = webRtcStream;
  } else {
    videoElement.src = global.URL.createObjectURL(webRtcStream);
  }

  videoElement.addEventListener('loadedmetadata', onLoad, false);
  videoElement.addEventListener('error', onError, false);
  webRtcStream.onended = onStoppedLoading;
  videoElement.onended = onStoppedLoading;
}

module.exports = function NativeVideoElementWrapper(
  options,
  errorHandler,
  orientationChangedHandler,
  defaultAudioVolume
) {
  var _domElement, _audioLevelSampler;
  var _videoElementMovedWarning = false;

  OTHelpers.eventing(this);

  /// Private API
  var _onVideoError = function(event) {
    var reason = 'There was an unexpected problem with the Video Stream: ' +
                  videoElementErrorCodeToStr(event.target.error.code);
    errorHandler(reason, this, 'VideoElement');
  }.bind(this);

  // The video element pauses itself when it's reparented, this is
  // unfortunate. This function plays the video again and is triggered
  // on the pause event.
  var _playVideoOnPause = function() {
    if (!_videoElementMovedWarning) {
      logging.warn('Video element paused, auto-resuming. If you intended to do this, ' +
                'use publishVideo(false) or subscribeToVideo(false) instead.');

      _videoElementMovedWarning = true;
    }

    _domElement.play();
  };

  _domElement = createNativeVideoElement(options.fallbackText, options.muted);

  // dirty but it is the only way right now to get the aspect ratio in FF
  // any other event is triggered too early
  var ratioAvailableListeners = [];
  _domElement.addEventListener('timeupdate', function timeupdateHandler() {
    if (!_domElement) {
      event.target.removeEventListener('timeupdate', timeupdateHandler);
      return;
    }
    var aspectRatio = _domElement.videoWidth / _domElement.videoHeight;
    if (!isNaN(aspectRatio)) {
      _domElement.removeEventListener('timeupdate', timeupdateHandler);
      var listener;
      while ((listener = ratioAvailableListeners.shift())) {
        listener();
      }
    }
  });

  _domElement.addEventListener('pause', _playVideoOnPause);

  videoContentResizesMixin(this, _domElement);

  canBeOrientatedMixin(this, function() { return _domElement; }, orientationChangedHandler);

  /// Public methods

  this.domElement = function() {
    return _domElement;
  };

  this.videoWidth = function() {
    return _domElement ? _domElement.videoWidth : 0;
  };

  this.videoHeight = function() {
    return _domElement ? _domElement.videoHeight : 0;
  };

  this.imgData = function() {
    var canvas = OTHelpers.createElement('canvas', {
      width: _domElement.videoWidth,
      height: _domElement.videoHeight,
      style: { display: 'none' }
    });

    document.body.appendChild(canvas);
    try {
      canvas.getContext('2d').drawImage(_domElement, 0, 0, canvas.width, canvas.height);
    } catch (err) {
      logging.warn('Cannot get image data yet');
      return null;
    }
    var imgData = canvas.toDataURL('image/png');

    OTHelpers.removeElement(canvas);

    return imgData.replace('data:image/png;base64,', '').trim();
  };

  // Append the Video DOM element to a parent node
  this.appendTo = function(parentDomElement) {
    parentDomElement.appendChild(_domElement);
    return this;
  };

  // Bind a stream to the video element.
  this.bindToStream = function(webRtcStream, completion) {
    var _this = this;

    bindStreamToNativeVideoElement(_domElement, webRtcStream, function(err) {
      if (err) {
        completion(err);
        return;
      }

      if (!_domElement) {
        completion('Can\'t bind because _domElement no longer exists');
        return;
      }

      _this.startObservingSize();

      function handleEnded() {
        webRtcStream.onended = null;

        if (_domElement) {
          _domElement.onended = null;
        }

        _this.trigger('mediaStopped', _this);
      }

      // OPENTOK-22428: Firefox doesn't emit the ended event on the webRtcStream when the user
      // stops sharing their camera, but we do get the ended event on the video element.
      webRtcStream.onended = handleEnded;
      _domElement.onended = handleEnded;

      _domElement.addEventListener('error', _onVideoError, false);

      if (webRtcStream.getAudioTracks().length > 0) {
        _audioLevelSampler = new WebaudioAudioLevelSampler(audioContextProvider());
        _audioLevelSampler.webRTCStream(webRtcStream);
      }

      completion(null);
    });

    return this;
  };

  // Unbind the currently bound stream from the video element.
  this.unbindStream = function() {
    if (_domElement) {
      unbindNativeStream(_domElement);
    }

    this.stopObservingSize();

    _audioLevelSampler = null;

    return this;
  };

  this.setAudioVolume = function(value) {
    if (_domElement) { _domElement.volume = value; }
  };

  this.getAudioVolume = function() {
    // Return the actual volume of the DOM element
    if (_domElement) { return _domElement.volume; }
    return defaultAudioVolume;
  };

  // see https://wiki.mozilla.org/WebAPI/AudioChannels
  // The audioChannelType is currently only available in Firefox. This property returns
  // "unknown" in other browser. The related HTML tag attribute is "mozaudiochannel"
  this.audioChannelType = function(type) {
    if (type !== void 0) {
      _domElement.mozAudioChannelType = type;
    }

    if ('mozAudioChannelType' in _domElement) {
      return _domElement.mozAudioChannelType;
    }

    return 'unknown';
  };

  this.getAudioInputLevel = function() {
    return new Promise(function(resolve) {
      if (_audioLevelSampler) {
        _audioLevelSampler.sample(resolve);
      } else {
        // if we can't sample a value we still resolve but with a null value
        // it is up to the caller to figure out what to do with it
        resolve(null);
      }
    });
  };

  this.whenTimeIncrements = function(callback, context) {
    if (_domElement) {
      var lastTime, handler;
      handler = function() {
        if (_domElement) {
          if (!lastTime || lastTime >= _domElement.currentTime) {
            lastTime = _domElement.currentTime;
          } else {
            _domElement.removeEventListener('timeupdate', handler, false);
            callback.call(context, this);
          }
        }
      }.bind(this);
      _domElement.addEventListener('timeupdate', handler, false);
    }
  };

  this.destroy = function() {
    this.unbindStream();

    if (_domElement) {
      // Unbind this first, otherwise it will trigger when the
      // video element is removed from the DOM.
      _domElement.removeEventListener('pause', _playVideoOnPause);

      OTHelpers.removeElement(_domElement);
      _domElement = null;
    }

    return void 0;
  };
};
