'use strict';

module.exports = function refCountBehaviour(api) {
  var _liveObjects = [];

  api.addRef = function(ref) {
    _liveObjects.push(ref);
    return api;
  };

  api.removeRef = function(ref) {
    if (_liveObjects.length === 0) return;

    var index = _liveObjects.indexOf(ref);
    if (index !== -1) {
      _liveObjects.splice(index, 1);
    }

    if (_liveObjects.length === 0) {
      api.destroy();
    }

    return api;
  };

  api.removeAllRefs = function() {
    while (_liveObjects.length) {
      _liveObjects.shift().destroy();
    }
  };
};
