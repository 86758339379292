'use strict';

var OTHelpers = require('@opentok/ot-helpers');

var OTPlugin = {};
module.exports = OTPlugin;
global.OTPlugin = OTPlugin;

// Establish the environment that we're running in
// Note: we don't currently support 64bit IE
OTPlugin.isSupported = function() {
  return (
    OTHelpers.env.name === 'IE' &&
    OTHelpers.env.version >= 9 &&
    OTHelpers.env.userAgent.indexOf('x64') === -1
  );
};

OTHelpers.registerCapability('otplugin', function() {
  return OTPlugin.isInstalled();
});

// If this client isn't supported we still make sure that OTPlugin is defined
// and the basic API (isSupported() and isInstalled()) is created.
// TODO: ^^^ Is this really needed? It would be nice to not monkey around with this early exit
// thingy.
if (!OTPlugin.isSupported()) {
  OTPlugin.isInstalled = function isInstalled() { return false; };
  OTPlugin.setPathToInstaller = function setPathToInstaller() {};
  return;
}

var AutoUpdater = require('./auto_updater.js');
var logging = require('./logging.js');
var MediaConstraints = require('./media_constraints.js');
var MediaDevices = require('./media_devices.js');
var MediaStream = require('./media_stream.js');
var meta = require('./meta.js');
var PeerConnection = require('./peer_connection/peer_connection.js');
var PluginProxies = require('./plugin_proxies.js');
var readiness = require('./readiness.js');
var RTCIceCandidate = require('./rtc_ice_candidate.js');
var RTCSessionDescription = require('./rtc_session_description.js');
var RumorSocket = require('./rumor_socket.js');

// TODO: Can we remove this? It's only here for possible external use.
OTPlugin.isReady = readiness.isReady;

OTPlugin.meta = meta;

OTPlugin.isInstalled = function isInstalled() {
  if (!this.isSupported()) return false;
  return AutoUpdater.isinstalled();
};

OTPlugin.setPathToInstaller = function setPathToInstaller(installerPath) {
  meta.setPathToInstaller(installerPath);
};

OTPlugin.version = meta.version;
OTPlugin.installedVersion = meta.installedVersion;

// Returns a URI to the OTPlugin installer that is paired with
// this version of OTPlugin.js.
OTPlugin.pathToInstaller = meta.pathToInstaller;

// Trigger +callback+ when the plugin is ready
//
// Most of the public API cannot be called until
// the plugin is ready.
OTPlugin.ready = function ready(callback) {
  readiness.listen(callback.bind(OTPlugin));
};

// Helper function for OTPlugin.getUserMedia
var _getUserMedia = function _getUserMedia(mediaConstraints, success, error) {
  PluginProxies.createMediaPeer(function(err, plugin) {
    if (err) {
      error.call(OTPlugin, err);
      return;
    }

    plugin._.getUserMedia(mediaConstraints.toHash(), function(streamJson) {
      success.call(OTPlugin, MediaStream.fromJson(streamJson, plugin));
    }, error);
  });
};

// Equivalent to: window.getUserMedia(constraints, success, error);
//
// Except that the constraints won't be identical
OTPlugin.getUserMedia = function getUserMedia(userConstraints, success, error) {
  var constraints = new MediaConstraints(userConstraints);

  if (constraints.screenSharing) {
    _getUserMedia(constraints, success, error);
  } else {
    var sources = [];
    if (constraints.hasVideo) sources.push('video');
    if (constraints.hasAudio) sources.push('audio');

    PluginProxies.mediaCapturer.selectSources(sources, function(captureDevices) {
      for (var key in captureDevices) {
        if (captureDevices.hasOwnProperty(key)) {
          logging.debug(key + ' Capture Device: ' + captureDevices[key]);
        }
      }

      // Use the sources to acquire the hardware and start rendering
      constraints.setVideoSource(captureDevices.video);
      constraints.setAudioSource(captureDevices.audio);

      _getUserMedia(constraints, success, error);
    }, error);
  }
};

OTPlugin.enumerateDevices = function(completion) {
  readiness.listen(function(error) {
    if (error) {
      completion(error);
    } else {
      PluginProxies.mediaCapturer.enumerateDevices(completion);
    }
  });
};

OTPlugin.initRumorSocket = function(messagingURL, completion) {
  readiness.listen(function(error) {
    if (error) {
      completion(error);
    } else {
      completion(null, new RumorSocket(PluginProxies.mediaCapturer, messagingURL));
    }
  });
};

// Equivalent to: var pc = new window.RTCPeerConnection(iceServers, options);
//
// Except that it is async and takes a completion handler
OTPlugin.initPeerConnection = function initPeerConnection(
  iceServers,
  options,
  localStream,
  completion
) {
  var gotPeerObject = function(err, plugin) {
    if (err) {
      completion.call(OTPlugin, err);
      return;
    }

    logging.debug('Got PeerConnection for ' + plugin.id);

    PeerConnection.create(iceServers, options, plugin, function(err, peerConnection) {
      if (err) {
        completion.call(OTPlugin, err);
        return;
      }

      completion.call(OTPlugin, null, peerConnection);
    });
  };

  // @fixme this is nasty and brittle. We need some way to use the same Object
  // for the PeerConnection that was used for the getUserMedia call (in the case
  // of publishers). We don't really have a way of implicitly associating them though.
  // Hence, publishers will have to pass through their localStream (if they have one)
  // and we will look up the original Object and use that. Otherwise we generate
  // a new one.
  if (localStream && localStream._.plugin) {
    gotPeerObject(null, localStream._.plugin);
  } else {
    PluginProxies.createMediaPeer(gotPeerObject);
  }
};

OTPlugin.RTCSessionDescription = RTCSessionDescription;
OTPlugin.RTCIceCandidate = RTCIceCandidate;

OTPlugin.mediaDevices = new MediaDevices();

module.exports = OTPlugin;
