'use strict';

var Archive = require('../../archive.js');
var Connection = require('../../connection.js');
var DelayedEventQueue = require('./delayed_event_queue.js');
var Dispatcher = require('./dispatcher.js');
var logging = require('../../logging.js');
var OTHelpers = require('@opentok/ot-helpers');
var sessionObjects = require('../../session/objects.js');
var Stream = require('../../stream.js');
var StreamChannel = require('../../stream_channel.js');

function parseStream(dict, session) {
  var channel = dict.channel.map(function(channel) {
    return new StreamChannel(channel);
  });

  var connectionId = dict.connectionId ? dict.connectionId : dict.connection.id;

  return new Stream(dict.id,
                    dict.name,
                    dict.creationTime,
                    session.connections.get(connectionId),
                    session,
                    channel);
}

function parseAndAddStreamToSession(dict, session) {
  if (session.streams.has(dict.id)) { return undefined; }

  var stream = parseStream(dict, session);
  session.streams.add(stream);

  return stream;
}

function parseArchive(dict) {
  return new Archive(dict.id,
                     dict.name,
                     dict.status);
}

function parseAndAddArchiveToSession(dict, session) {
  if (session.archives.has(dict.id)) { return undefined; }

  var archive = parseArchive(dict);
  session.archives.add(archive);

  return archive;
}

var DelayedSessionEvents = function(dispatcher) {
  var eventQueues = {};

  this.enqueue = function enqueue(/* key, arg1, arg2, ..., argN */) {
    var key = arguments[0];
    var eventArgs = Array.prototype.slice.call(arguments, 1);
    if (!eventQueues[key]) {
      eventQueues[key] = new DelayedEventQueue(dispatcher);
    }
    eventQueues[key].enqueue.apply(eventQueues[key], eventArgs);
  };

  this.triggerConnectionCreated = function triggerConnectionCreated(connection) {
    if (eventQueues['connectionCreated' + connection.id]) {
      eventQueues['connectionCreated' + connection.id].triggerAll();
    }
  };

  this.triggerSessionConnected = function triggerSessionConnected(connections) {
    if (eventQueues.sessionConnected) {
      eventQueues.sessionConnected.triggerAll();
    }

    connections.forEach(function(connection) {
      this.triggerConnectionCreated(connection);
    }, this);
  };
};

var unconnectedStreams = {};

module.exports = function SessionDispatcher(session) {

  var dispatcher = new Dispatcher();
  var sessionStateReceived = false;
  var delayedSessionEvents = new DelayedSessionEvents(dispatcher);

  dispatcher.on('reconnecting', function() {
    session._.reconnecting();
  });

  dispatcher.on('reconnected', function() {
    session._.reconnected();
  });

  dispatcher.on('close', function(reason) {

    var connection = session.connection;

    if (!connection) {
      return;
    }

    if (connection.destroyedReason()) {
      logging.debug('OT.Raptor.Socket: Socket was closed but the connection had already ' +
        'been destroyed. Reason: ' + connection.destroyedReason());
      return;
    }

    connection.destroy(reason);
  });

  // This method adds connections to the session both on a connection#created and
  // on a session#read. In the case of session#read sessionRead is set to true and
  // we include our own connection.
  var addConnection = function(connection, sessionRead) {
    connection = Connection.fromHash(connection);
    if (sessionRead || session.connection && connection.id !== session.connection.id) {
      session.connections.add(connection);
      delayedSessionEvents.triggerConnectionCreated(connection);
    }

    Object.keys(unconnectedStreams).forEach(function(streamId) {
      var stream = unconnectedStreams[streamId];
      if (stream && connection.id === stream.connection.id) {
        // dispatch streamCreated event now that the connectionCreated has been dispatched
        parseAndAddStreamToSession(stream, session);
        delete unconnectedStreams[stream.id];

        var payload = {
          debug: sessionRead ? 'connection came in session#read' :
            'connection came in connection#created',
          streamId: stream.id,
          connectionId: connection.id
        };
        session.logEvent('streamCreated', 'warning', payload);
      }
    });

    return connection;
  };

  dispatcher.on('session#read', function(content, transactionId) {
    var connection;
    var state = {};

    state.streams = [];
    state.connections = [];
    state.archives = [];

    content.connection.forEach(function(connectionParams) {
      connection = addConnection(connectionParams, true);
      state.connections.push(connection);
    });

    content.stream.forEach(function(streamParams) {
      state.streams.push(parseAndAddStreamToSession(streamParams, session));
    });

    (content.archive || content.archives).forEach(function(archiveParams) {
      state.archives.push(parseAndAddArchiveToSession(archiveParams, session));
    });

    session._.subscriberMap = {};

    dispatcher.triggerCallback(transactionId, null, state);

    sessionStateReceived = true;
    delayedSessionEvents.triggerSessionConnected(session.connections);
  });

  dispatcher.on('connection#created', function(connection) {
    addConnection(connection);
  });

  dispatcher.on('connection#deleted', function(connection, reason) {
    connection = session.connections.get(connection);
    connection.destroy(reason);
  });

  dispatcher.on('stream#created', function(stream, transactionId) {
    var connectionId = stream.connectionId ? stream.connectionId : stream.connection.id;
    if (session.connections.has(connectionId)) {
      stream = parseAndAddStreamToSession(stream, session);
    } else {
      unconnectedStreams[stream.id] = stream;

      var payload = {
        debug: 'eventOrderError -- streamCreated event before connectionCreated',
        streamId: stream.id
      };
      session.logEvent('streamCreated', 'warning', payload);
    }

    if (stream.publisher) {
      stream.publisher.setStream(stream);
    }

    dispatcher.triggerCallback(transactionId, null, stream);
  });

  dispatcher.on('stream#deleted', function(streamId, reason) {
    var stream = session.streams.get(streamId);

    if (!stream) {
      logging.error('OT.Raptor.dispatch: A stream does not exist with the id of ' +
        streamId + ', for stream#deleted message!');
      // @todo error
      return;
    }

    stream.destroy(reason);
  });

  dispatcher.on('stream#updated', function(streamId, content) {
    var stream = session.streams.get(streamId);

    if (!stream) {
      logging.error('OT.Raptor.dispatch: A stream does not exist with the id of ' +
        streamId + ', for stream#updated message!');
      // @todo error
      return;
    }

    stream._.update(content);

  });

  dispatcher.on('streamChannel#updated', function(streamId, channelId, content) {
    var stream;
    if (!(streamId && (stream = session.streams.get(streamId)))) {
      logging.error('OT.Raptor.dispatch: Unable to determine streamId, or the stream does not ' +
        'exist, for streamChannel message!');
      // @todo error
      return;
    }
    stream._.updateChannel(channelId, content);
  });

  // Dispatch JSEP messages
  //
  // generateoffer:
  // Request to generate a offer for another Peer (or Prism). This kicks
  // off the JSEP process.
  //
  // answer:
  // generate a response to another peers offer, this contains our constraints
  // and requirements.
  //
  // pranswer:
  // a provisional answer, i.e. not the final one.
  //
  // candidate
  //
  //


  // This function takes an array of collections and a matching function
  // If it finds a match in the first collection it returns that item otherwise
  // it returns a match in the second collection. The return value is an array of
  // 1 item or an empty array if there is no match.
  var findItems = function(fromCollections, whereClause) {
    return fromCollections.reduce(function(returnVal, collection) {
      if (returnVal.length === 0) {
        var item = collection.find(whereClause);
        return item ? [item] : [];
      }
      return returnVal;
    }, []);
  };

  var jsepHandler = function(method, streamId, fromAddress, message) {

    var whereClause = { streamId: streamId };
    var subscribers = sessionObjects.subscribers;
    var publishers = sessionObjects.publishers;
    var fromConnection, actors;

    // Determine which subscriber/publisher objects should receive this message.
    switch (method) {
      case 'offer':
        actors = findItems([subscribers, publishers], whereClause);
        break;

      case 'answer':
      case 'pranswer':
        actors = findItems([publishers, subscribers], whereClause);
        break;

      case 'generateoffer':
      case 'unsubscribe':
        actors = findItems([publishers], whereClause);
        break;

      case 'candidate':
        actors = subscribers.where(whereClause).concat(publishers.where(whereClause));
        break;

      default:
        logging.warn('OT.Raptor.dispatch: jsep#' + method +
          ' is not currently implemented');
        return;
    }

    if (actors.length === 0) { return; }

    fromConnection = session.connections.get(fromAddress);
    if (!fromConnection && fromAddress.match(/^symphony\./)) {
      fromConnection = Connection.fromHash({
        id: fromAddress,
        creationTime: Math.floor(OTHelpers.now())
      });

      session.connections.add(fromConnection);
    } else if (!fromConnection) {
      logging.warn('OT.Raptor.dispatch: Messsage comes from a connection (' +
        fromAddress + ') that we do not know about. The message was ignored.');
      return;
    }

    actors.forEach(function(actor) {
      actor.processMessage(method, fromConnection, message);
    });
  };

  dispatcher.on('jsep#offer', jsepHandler.bind(null, 'offer'));
  dispatcher.on('jsep#answer', jsepHandler.bind(null, 'answer'));
  dispatcher.on('jsep#pranswer', jsepHandler.bind(null, 'pranswer'));
  dispatcher.on('jsep#generateoffer', jsepHandler.bind(null, 'generateoffer'));
  dispatcher.on('jsep#unsubscribe', jsepHandler.bind(null, 'unsubscribe'));
  dispatcher.on('jsep#candidate', jsepHandler.bind(null, 'candidate'));

  dispatcher.on('subscriberChannel#updated', function(streamId, channelId, content) {

    if (!streamId || !session.streams.has(streamId)) {
      logging.error('OT.Raptor.dispatch: Unable to determine streamId, or the stream does not ' +
        'exist, for subscriberChannel#updated message!');
      // @todo error
      return;
    }

    session.streams.get(streamId)._
      .updateChannel(channelId, content);

  });

  dispatcher.on('subscriberChannel#update', function(subscriberId, streamId, content) {

    if (!streamId || !session.streams.has(streamId)) {
      logging.error('OT.Raptor.dispatch: Unable to determine streamId, or the stream does not ' +
        'exist, for subscriberChannel#update message!');
      // @todo error
      return;
    }

    // Hint to update for congestion control from the Media Server
    if (!sessionObjects.subscribers.has(subscriberId)) {
      logging.error('OT.Raptor.dispatch: Unable to determine subscriberId, or the subscriber ' +
        'does not exist, for subscriberChannel#update message!');
      // @todo error
      return;
    }

    // We assume that an update on a Subscriber channel is to disableVideo
    // we may need to be more specific in the future
    sessionObjects.subscribers.get(subscriberId).disableVideo(content.active);

  });

  dispatcher.on('subscriber#created', function(streamId, fromAddress, subscriberId) {

    var stream = streamId ? session.streams.get(streamId) : null;

    if (!stream) {
      logging.error('OT.Raptor.dispatch: Unable to determine streamId, or the stream does ' +
        'not exist, for subscriber#created message!');
      // @todo error
      return;
    }

    session._.subscriberMap[fromAddress + '_' + stream.id] = subscriberId;
  });

  dispatcher.on('subscriber#deleted', function(streamId, fromAddress) {
    var stream = streamId ? session.streams.get(streamId) : null;

    if (!stream) {
      logging.error('OT.Raptor.dispatch: Unable to determine streamId, or the stream does ' +
        'not exist, for subscriber#created message!');
      // @todo error
      return;
    }

    delete session._.subscriberMap[fromAddress + '_' + stream.id];
  });

  dispatcher.on('signal', function(fromAddress, signalType, data) {
    var fromConnection = session.connections.get(fromAddress);
    if (session.connection && fromAddress === session.connection.connectionId) {
      if (sessionStateReceived) {
        session._.dispatchSignal(fromConnection, signalType, data);
      } else {
        delayedSessionEvents.enqueue('sessionConnected',
          'signal', fromAddress, signalType, data);
      }
    } else if (session.connections.get(fromAddress)) {
      session._.dispatchSignal(fromConnection, signalType, data);
    } else {
      delayedSessionEvents.enqueue('connectionCreated' + fromAddress,
        'signal', fromAddress, signalType, data);
    }
  });

  dispatcher.on('archive#created', function(archive) {
    parseAndAddArchiveToSession(archive, session);
  });

  dispatcher.on('archive#updated', function(archiveId, update) {
    var archive = session.archives.get(archiveId);

    if (!archive) {
      logging.error('OT.Raptor.dispatch: An archive does not exist with the id of ' +
        archiveId + ', for archive#updated message!');
      // @todo error
      return;
    }

    archive._.update(update);
  });

  return dispatcher;
};
