'use strict';

var Widget = require('./behaviour/widget.js');
var OTHelpers = require('@opentok/ot-helpers');

module.exports = function MuteButton(options) {
  var _onClickCb;
  var _muted = options.muted || false;

  var updateClasses = function() {
    if (_muted) {
      OTHelpers.addClass(this.domElement, 'OT_active');
    } else {
      OTHelpers.removeClass(this.domElement, 'OT_active ');
    }
  }.bind(this);

  // Private Event Callbacks
  var attachEvents = function(elem) {
    _onClickCb = onClick.bind(this);
    OTHelpers.on(elem, 'click', _onClickCb);
  };

  var detachEvents = function(elem) {
    _onClickCb = null;
    OTHelpers.off(elem, 'click', _onClickCb);
  };

  var onClick = function() {
    _muted = !_muted;

    updateClasses();

    if (_muted) {
      this.parent.trigger('muted', this);
    } else {
      this.parent.trigger('unmuted', this);
    }

    return false;
  };

  OTHelpers.defineProperties(this, {
    muted: {
      get: function() { return _muted; },
      set: function(muted) {
        _muted = muted;
        updateClasses();
      }
    }
  });

  // Mixin common widget behaviour
  var classNames = _muted ? 'OT_edge-bar-item OT_mute OT_active' : 'OT_edge-bar-item OT_mute';
  Widget(this, {
    mode: options.mode,
    nodeName: 'button',
    htmlContent: 'Mute',
    htmlAttributes: {
      className: classNames
    },
    onCreate: attachEvents.bind(this),
    onDestroy: detachEvents.bind(this)
  });
};
