'use strict';

var logging = require('./logging');
var makeEverythingAttachToOTHelpers = require('./makeEverythingAttachToOTHelpers');
var util = require('./util');

var capabilities = {};
module.exports = capabilities;

var capabilityRegistry = {};

var memoriseCapabilityTest;

// Registers a new capability type and a function that will indicate
// whether this client has that capability.
//
//   OTHelpers.registerCapability('bundle', function() {
//     return OTHelpers.hasCapabilities('webrtc') &&
//                (OTHelpers.env.name === 'Chrome' || TBPlugin.isInstalled());
//   });
//
capabilities.registerCapability = function(name, callback) {
  var _name = name.toLowerCase();

  if (capabilityRegistry.hasOwnProperty(_name)) {
    logging.error('Attempted to register', name, 'capability more than once');
    return;
  }

  if (!util.isFunction(callback)) {
    logging.error('Attempted to register', name,
                            'capability with a callback that isn\' a function');
    return;
  }

  memoriseCapabilityTest(_name, callback);
};

// Wrap up a capability test in a function that memorises the
// result.
memoriseCapabilityTest = function (name, callback) {
  capabilityRegistry[name] = function() {
    var result = callback();
    capabilityRegistry[name] = function() {
      return result;
    };

    return result;
  };
};

var testCapability = function (name) {
  return capabilityRegistry[name]();
};

/* test-code */
capabilities.__testOnly =  {};

capabilities.__testOnly.disableCapabilityMemorisation = false;

var rawCapabilityRegistry = {},
    defaultMemoriseCapabilityTest = memoriseCapabilityTest;

// Intercept the memorise call and store the raw callbacks in test mode
memoriseCapabilityTest = function (name, callback) {
  rawCapabilityRegistry[name.toLowerCase()] = callback;
  return defaultMemoriseCapabilityTest(name, callback);
};

// Override this call in test mode
testCapability = function (name) {
  var cap;

  if (capabilities.__testOnly.disableCapabilityMemorisation === true) {
    cap = rawCapabilityRegistry[name];
  }
  else {
    cap = capabilityRegistry[name];
  }

  return cap();
};

capabilities.__testOnly.resetCapabilities = function() {
  rawCapabilityRegistry = {};
  capabilityRegistry = {};
};

capabilities.__testOnly.unmemoriseCapability = function(name) {
  memoriseCapabilityTest(name, rawCapabilityRegistry[name]);
};

capabilities.__testOnly.removeCapabilities = function() {
  var names = Array.prototype.slice.call(arguments),
      num = names.length;

  for (var i = 0; i < num; ++i) {
    delete rawCapabilityRegistry[names[i]];
    delete capabilityRegistry[names[i]];
  }
};
/* end-test-code */

// Returns true if all of the capability names passed in
// exist and are met.
//
//  OTHelpers.hasCapabilities('bundle', 'rtcpMux')
//
capabilities.hasCapabilities = function(/* capability1, capability2, ..., capabilityN  */) {
  var capNames = Array.prototype.slice.call(arguments),
      name;

  for (var i = 0; i < capNames.length; ++i) {
    name = capNames[i].toLowerCase();

    if (!capabilityRegistry.hasOwnProperty(name)) {
      logging.error('hasCapabilities was called with an unknown capability: ' + name);
      return false;
    }
    else if (testCapability(name) === false) {
      return false;
    }
  }

  return true;
};

makeEverythingAttachToOTHelpers(capabilities);
