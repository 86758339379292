'use strict';

var guidStorage = require('./guid_storage.js');
var logging = require('../ot/logging.js');
var OTHelpers = require('@opentok/ot-helpers');
var properties = require('./properties.js');

module.exports = function Analytics(loggingUrl) {
  var LOG_VERSION = '1';
  var _analytics = new OTHelpers.Analytics(loggingUrl, logging.debug, guidStorage.get);

  this.logError = function(code, type, message, details, options) {
    if (!options) { options = {}; }
    var partnerId = options.partnerId;

    guidStorage.get(function(error, guid) {
      if (error) {
        // @todo
        return;
      }
      var data = OTHelpers.extend({
        // TODO: properties.version only gives '2.2', not '2.2.9.3'.
        clientVersion: 'js-' + properties.version.replace('v', ''),
        guid: guid,
        partnerId: partnerId,
        source: global.location.href,
        logVersion: LOG_VERSION,
        clientSystemTime: new Date().getTime()
      }, options);
      _analytics.logError(code, type, message, details, data);
    });

  };

  this.logEvent = function(options, throttle, completionHandler) {
    var partnerId = options.partnerId;

    if (!options) { options = {}; }

    guidStorage.get(function(error, guid) {
      if (error) {
        // @todo
        return;
      }

      // Set a bunch of defaults
      var data = OTHelpers.extend({
        // TODO: properties.version only gives '2.2', not '2.2.9.3'.
        clientVersion: 'js-' + properties.version.replace('v', ''),
        guid: guid,
        partnerId: partnerId,
        source: global.location.href,
        logVersion: LOG_VERSION,
        clientSystemTime: new Date().getTime()
      }, options);
      _analytics.logEvent(data, false, throttle, completionHandler);
    });
  };

  this.logQOS = function(options) {
    var partnerId = options.partnerId;

    if (!options) { options = {}; }

    guidStorage.get(function(error, guid) {
      if (error) {
        // @todo
        return;
      }

      // Set a bunch of defaults
      var data = OTHelpers.extend({
        // TODO: properties.version only gives '2.2', not '2.2.9.3'.
        clientVersion: 'js-' + properties.version.replace('v', ''),
        guid: guid,
        partnerId: partnerId,
        source: global.location.href,
        logVersion: LOG_VERSION,
        clientSystemTime: new Date().getTime(),
        duration: 0 //in milliseconds
      }, options);

      _analytics.logQOS(data);
    });
  };
};
