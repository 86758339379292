'use strict';

var chromeExtensionHelper = require('./chrome_extension_helper.js');
var firefoxExtensionHelper = require('./firefox_extension_helper.js');
var ieExtensionHelper = require('./ie_extension_helper.js');
var OTHelpers = require('@opentok/ot-helpers');

var screenSharing = {};
module.exports = screenSharing;

screenSharing.extensionByKind = {};
screenSharing.extensionClasses = {};

screenSharing.registerExtensionHelper = function(kind, helper) {
  screenSharing.extensionClasses[kind] = helper;
  if (helper.autoRegisters && helper.isSupportedInThisBrowser) {
    screenSharing.registerExtension(kind);
  }
};

/**
 * Register a Chrome extension for screen-sharing support.
 * <p>
 * Use the <code>OT.checkScreenSharingCapability()</code> method to check if an extension is
 * required, registered, and installed.
 * <p>
 * The OpenTok
 * <a href="https://github.com/opentok/screensharing-extensions">screensharing-extensions</a>
 * sample includes code for creating an extension for screen-sharing support.
 *
 * @param {String} kind Set this parameter to <code>"chrome"</code>. Currently, you can only
 * register a screen-sharing extension for Chrome.
 *
 * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
 * @see <a href="OT.html#checkScreenSharingCapability">OT.checkScreenSharingCapability()</a>
 * @method OT.registerScreenSharingExtension
 * @memberof OT
 */

screenSharing.registerExtension = function(kind) {
  var initArgs = Array.prototype.slice.call(arguments, 1);

  if (screenSharing.extensionClasses[kind] == null) {
    throw new Error('Unsupported kind passed to OT.registerScreenSharingExtension');
  }

  var x = screenSharing.extensionClasses[kind]
          .register.apply(screenSharing.extensionClasses[kind], initArgs);
  screenSharing.extensionByKind[kind] = x;
};

var screenSharingPickHelper = function() {

  var foundClass = OTHelpers.find(Object.keys(screenSharing.extensionClasses), function(cls) {
    return screenSharing.extensionClasses[cls].isSupportedInThisBrowser;
  });

  if (foundClass === void 0) {
    return {};
  }

  return {
    name: foundClass,
    proto: screenSharing.extensionClasses[foundClass],
    instance: screenSharing.extensionByKind[foundClass]
  };

};

screenSharing.pickHelper = function() {
  return screenSharingPickHelper();
};

/**
 * Checks for support for publishing screen-sharing streams on the client browser. The object
 * passed to the callback function defines whether screen sharing is supported, as well as whether
 * an extension is required, installed, and registered (if needed).
 * <p>
 * <pre>
 * OT.checkScreenSharingCapability(function(response) {
 *   if (!response.supported || response.extensionRegistered === false) {
 *     // This browser does not support screen sharing
 *   } else if (response.extensionInstalled === false) {
 *     // Prompt to install the extension
 *   } else {
 *     // Screen sharing is available.
 *   }
 * });
 * </pre>
 * <p>
 * To publish a screen-sharing video in Chrome or Firefox, the client adds an extension
 * that enables publishing a screen-sharing video stream on your domain. The OpenTok plugin for
 * Internet Explorer has screen-sharing capabilities built-in. The OpenTok
 * <a href="https://github.com/opentok/screensharing-extensions">screensharing-extensions</a>
 * sample includes code for creating Chrome and Firefox extensions for screen-sharing support.
 *
 * @param {function} callback The callback invoked with the support options object passed as
 * the parameter. This object has the following properties that indicate support for publishing
 * screen-sharing streams in the client:
 * <p>
 * <ul>
 *   <li>
 *     <code>supported</code> (Boolean) &mdash; Set to true if screen sharing is supported in the
 *     browser. Check the <code>extensionRequired</code> property to see if the browser requires
 *     an extension for screen sharing.
 *   </li>
 *   <li>
 *     <code>supportedSources</code> (Object) &mdash; An object with the following properties:
 *     <code>application</code>, <code>screen</code>, and <code>window</code>. Each property is
 *     a Boolean value indicating support. In Firefox, each of these properties is set to
 *     <code>true</code>. Currently in Chrome, only the <code>screen</code> property is
 *     set to <code>true</code>. In Internet Explorer (using the OpenTok plugin), only
 *     <code>screen</code> and <code>window</code> are set to <code>true</code>.
 *   </li>
 * </ul>
 * <p> The options parameter also includes the following properties, which apply to screen-sharing
 * support in Chrome:
 * <ul>
 *   <li>
 *     <code>extensionRequired</code> (String) &mdash; Set to <code>"chrome"</code> in Chrome,
 *     which requires a screen sharing extension to be installed. Otherwise, this property is
 *     undefined.
 *   </li>
 *   <li>
 *     <code>extensionRegistered</code> (Boolean) &mdash; In Chrome, this property is set to
 *     <code>true</code> if a screen-sharing extension is registered; otherwise it is set to
 *     <code>false</code>. If the extension type does not require registration (for example,
 *     in Firefox), this property is set to <code>true</code>. In other browsers (which do not
 *     require an extension), this property is undefined. Use the
 *     <code>OT.registerScreenSharingExtension()</code> method to register an extension in Chrome.
 *   </li>
 *   <li>
 *     <code>extensionInstalled</code> (Boolean) &mdash; If an extension is required, this is set
 *     to <code>true</code> if the extension installed (and registered, if needed); otherwise it
 *     is set to <code>false</code>. If an extension is not required (for example in Firefox),
 *     this property is undefined.
 *   </li>
 * </ul>
 *
 * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
 * @see <a href="OT.html#registerScreenSharingExtension">OT.registerScreenSharingExtension()</a>
 * @method OT.checkScreenSharingCapability
 * @memberof OT
 */
screenSharing.checkCapability = function(callback) {

  var response = {
    supported: false,
    extensionRequired: void 0,
    extensionRegistered: void 0,
    extensionInstalled: void 0,
    supportedSources: {}
  };

  // find a supported browser

  var helper = screenSharingPickHelper();

  if (helper.name === void 0) {
    setTimeout(callback.bind(null, response));
    return;
  }

  response.supported = true;
  response.extensionRequired = helper.proto.extensionRequired ? helper.name : void 0;

  response.supportedSources = {
    screen: helper.proto.sources.screen,
    application: helper.proto.sources.application,
    window: helper.proto.sources.window,
    browser: helper.proto.sources.browser
  };

  if (!helper.instance) {
    response.extensionRegistered = false;
    if (response.extensionRequired) {
      response.extensionInstalled = false;
    }
    setTimeout(callback.bind(null, response));
    return;
  }

  response.extensionRegistered = response.extensionRequired ? true : void 0;
  helper.instance.isInstalled(function(installed) {
    response.extensionInstalled = response.extensionRequired ? installed : void 0;
    callback(response);
  });
};

screenSharing.registerExtensionHelper('chrome', chromeExtensionHelper);
screenSharing.registerExtensionHelper('firefox', firefoxExtensionHelper);
screenSharing.registerExtensionHelper('OpenTokPlugin', ieExtensionHelper);
