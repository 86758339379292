'use strict';

var env = require('../../env');

if (env.name === 'Node') {
  var EventEmitter = require('events').EventEmitter,
      nodeUtil = require('util');

  // container for the EventEmitter behaviour. This prevents tight coupling
  // caused by accidentally bleeding implementation details and API into whatever
  // objects nodeEventing is applied to.
  var NodeEventable = function NodeEventable() {
    EventEmitter.call(this);

    this.events = {};
  };
  nodeUtil.inherits(NodeEventable, EventEmitter);

  module.exports = function nodeEventing(/* self */) {
    var api = new NodeEventable();

    api.addListeners = function (eventNames, handler, context, closure) {
      var listener = {handler: handler};
      if (context) listener.context = context;
      if (closure) listener.closure = closure;

      eventNames.forEach(function(name) {
        if (!api.events[name]) api.events[name] = [];
        api.events[name].push(listener);

        api.on(name, handler);

        var addedListener = name + ':added';
        if (api.events[addedListener]) {
          api.emit(addedListener, api.events[name].length);
        }
      });
    };

    api.removeAllListenersNamed = function (eventNames) {
      var _eventNames = eventNames.split(' ');
      api.removeAllListeners(_eventNames);

      _eventNames.forEach(function(name) {
        if (api.events[name]) delete api.events[name];
      });
    };

    api.removeListeners = function (eventNames, handler, closure) {
      function filterHandlers(listener) {
        return !(listener.handler === handler && listener.closure === closure);
      }

      eventNames.split(' ').forEach(function(name) {
        if (api.events[name]) {
          api.off(name, handler);
          api.events[name] = api.events[name].filter(filterHandlers);
          if (api.events[name].length === 0) delete api.events[name];

          var removedListener = name + ':removed';
          if (api.events[removedListener]) {
            api.emit(removedListener, api.events[name] ? api.events[name].length : 0);
          }
        }
      });
    };

    api.removeAllListeners = function () {
      api.events = {};
      api.removeAllListeners();
    };

    api.dispatchEvent = function(event, defaultAction) {
      this.emit(event.type, event);

      if (defaultAction) {
        defaultAction.call(null, event);
      }
    };

    api.trigger = api.emit.bind(api);

    /* test-code */

    /*global jasmine:true */

    // When this is being used inside Jasmime we create and expose spies for
    // several private methods. These are used for verification in our test
    // suites.


    if (global.jasmine && global.jasmine.getEnv()) {
      api.addListeners = jasmine.createSpy('addListeners')
                          .and.callFake(api.addListeners);

      api.removeListeners = jasmine.createSpy('removeListeners')
                            .and.callFake(api.removeListeners);

      api.removeAllListenersNamed = jasmine.createSpy('removeAllListenersNamed')
                                      .and.callFake(api.removeAllListenersNamed);
    }
    /* end-test-code */

    return api;
  };
}
else {
  module.exports = undefined;
}
