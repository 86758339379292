'use strict';

var uuid = require('uuid');
var analytics = require('../../analytics.js');
var ExceptionCodes = require('../../exception_codes.js');
var Dispatcher = require('./dispatcher.js');
var logging = require('../../logging.js');
var Message = require('./message.js');
var OTError = require('../../ot_error.js');
var OTHelpers = require('@opentok/ot-helpers');
var Signal = require('./signal.js');
var RumorSocket = require('../rumor/rumor_socket.js');

function SignalError(code, message) {
  this.code = code;
  this.message = message;

  // Undocumented. Left in for backwards compatibility:
  this.reason = message;
}

// The Dispatcher bit is purely to make testing simpler, it defaults to a new Dispatcher so in
// normal operation you would omit it.
var RaptorSocket = function RaptorSocket(
  connectionId,
  widgetId,
  messagingSocketUrl,
  symphonyUrl,
  dispatcher
) {
  var _apiKey, _sessionId, _token, _rumor, _dispatcher, _completion;
  var _states = ['disconnected', 'connecting', 'connected', 'error', 'disconnecting'];

  //// Private API
  var setState = OTHelpers.statable(this, _states, 'disconnected');

  var onConnectComplete = function onConnectComplete(error) {
    if (error) {
      setState('error');
    } else {
      setState('connected');
    }

    _completion.apply(null, arguments);
  };

  var onClose = function onClose(err) {
    var reason = 'clientDisconnected';
    if (!this.is('disconnecting') && _rumor.is('error')) {
      reason = 'networkDisconnected';
    }
    if (err && err.code === 4001) {
      reason = 'networkTimedout';
    }

    setState('disconnected');

    _dispatcher.onClose(reason);
  }.bind(this);

  var onError = function onError(err) {
    logging.error('OT.Raptor.Socket error:', err);
  };
  // @todo what does having an error mean? Are they always fatal? Are we disconnected now?

  var onReconnecting = function onReconnecting() {
    _dispatcher.onReconnecting();
  };

  var onReconnected = function onReconnected() {
    var event = {
      action: 'Reconnect',
      variation: 'Success',
      payload: {
        retries: _rumor.reconnectRetriesCount(),
        messageQueueSize: _rumor.messageQueueSize(),
        socketId: _rumor.socketID()
      },
      sessionId: _sessionId,
      partnerId: _apiKey,
      connectionId: connectionId
    };
    analytics.logEvent(event);
    _dispatcher.onReconnected();
  };

  var onReconnectAttempt = function onReconnectAttempt() {
    var event = {
      action: 'Reconnect',
      variation: 'Attempt',
      payload: {
        retries: _rumor.reconnectRetriesCount(),
        messageQueueSize: _rumor.messageQueueSize(),
        socketId: _rumor.socketID()
      },
      sessionId: _sessionId,
      partnerId: _apiKey,
      connectionId: connectionId
    };
    analytics.logEvent(event);
  };

  var convertRumorConnectError = function convertRumorConnectError(error) {
    var errorCode, errorMessage;
    var knownErrorCodes = [400, 403, 409];

    if (error.code === ExceptionCodes.CONNECT_FAILED) {
      errorCode = error.code;
      errorMessage = OTError.getTitleByCode(error.code);
    } else if (error.code && knownErrorCodes.indexOf(error.code) > -1) {
      errorCode = ExceptionCodes.CONNECT_FAILED;
      errorMessage = 'Received error response to connection create message.';
    } else {
      errorCode = ExceptionCodes.UNEXPECTED_SERVER_RESPONSE;
      errorMessage = 'Unexpected server response. Try this operation again later.';
    }

    return {
      errorCode: errorCode,
      errorMessage: errorMessage
    };
  };

  var onReconnectFailure = function onReconnectFailure(error) {
    var converted = convertRumorConnectError(error);
    var event = {
      action: 'Reconnect',
      variation: 'Failure',
      payload: {
        reason: 'ConnectToSession',
        code: converted.errorCode,
        message: converted.errorMessage,
        messageQueueSize: _rumor.messageQueueSize(),
        socketId: _rumor.socketID()
      },
      sessionId: _sessionId,
      partnerId: _apiKey,
      connectionId: connectionId
    };
    analytics.logEvent(event);
  };

  //// Public API

  this.connect = function(token, sessionInfo, completion) {
    if (!this.is('disconnected', 'error')) {
      logging.warn('Cannot connect the Raptor Socket as it is currently connected. You should ' +
        'disconnect first.');
      return;
    }

    setState('connecting');
    _apiKey = sessionInfo.partnerId;
    _sessionId = sessionInfo.sessionId;
    _token = token;
    _completion = completion;

    var rumorChannel = '/v2/partner/' + _apiKey + '/session/' + _sessionId;

    _rumor = new RaptorSocket.RumorSocket({
      messagingURL: messagingSocketUrl,
      notifyDisconnectAddress: symphonyUrl,
      connectionId: connectionId,
      enableReconnection: sessionInfo.reconnection
    });

    _rumor.onClose(onClose);
    _rumor.onError(onError);
    _rumor.onReconnecting(onReconnecting);
    _rumor.onReconnectAttempt(onReconnectAttempt);
    _rumor.onReconnectFailure(onReconnectFailure);
    _rumor.onReconnected(onReconnected);
    _rumor.onMessage(_dispatcher.dispatch.bind(_dispatcher));

    _rumor.connect(function(error) {
      if (error) {
        onConnectComplete({
          reason: 'WebSocketConnection',
          code: error.code,
          message: error.message
        });
        return;
      }

      logging.debug('OT.Raptor.Socket connected. Subscribing to ' +
        rumorChannel + ' on ' + messagingSocketUrl);

      _rumor.subscribe([rumorChannel]);

      var capabilities = [];

      if (sessionInfo.renegotiation) {
        capabilities.push('renegotiation');
      }

      //connect to session
      var connectMessage = Message.connections.create(
        _apiKey,
        _sessionId,
        _rumor.id(),
        capabilities
      );
      this.publish(connectMessage, { 'X-TB-TOKEN-AUTH': _token }, true, function(error) {
        if (error) {
          var converted = convertRumorConnectError(error);
          var payload = {
            reason: 'ConnectToSession',
            code: converted.errorCode,
            message: converted.errorMessage,
            socketId: _rumor.socketID()
          };
          var event = {
            action: 'Connect',
            variation: 'Failure',
            payload: payload,
            sessionId: _sessionId,
            partnerId: _apiKey,
            connectionId: connectionId
          };
          analytics.logEvent(event);
          onConnectComplete(payload);
          return;
        }

        this.publish(Message.sessions.get(_apiKey, _sessionId), {}, true,
          function(error) {
          if (error) {
            var errorCode, errorMessage;
            var knownErrorCodes = [400, 403, 409];

            if (error.code && knownErrorCodes.indexOf(error.code) > -1) {
              errorCode = ExceptionCodes.CONNECT_FAILED;
              errorMessage = 'Received error response to session read';
            } else {
              errorCode = ExceptionCodes.UNEXPECTED_SERVER_RESPONSE;
              errorMessage = 'Unexpected server response. Try this operation again later.';
            }
            var payload = {
              reason: 'GetSessionState',
              code: errorCode,
              message: errorMessage,
              socketId: _rumor.socketID()
            };
            var event = {
              action: 'Connect',
              variation: 'Failure',
              payload: payload,
              sessionId: _sessionId,
              partnerId: _apiKey,
              connectionId: connectionId
            };
            analytics.logEvent(event);
            onConnectComplete(payload);
          } else {
            onConnectComplete.apply(null, arguments);
          }
        });
      }.bind(this));
    }.bind(this));
  };

  this.disconnect = function(drainSocketBuffer) {
    if (this.is('disconnected')) {
      return;
    }

    setState('disconnecting');
    _rumor.disconnect(drainSocketBuffer);
  };

  // Publishs +message+ to the Symphony app server.
  //
  // The completion handler is optional, as is the headers
  // dict, but if you provide the completion handler it must
  // be the last argument.
  //
  this.publish = function(message, headers, retryAfterReconnect, completion) {
    if (_rumor.isNot('connected', 'reconnecting')) {
      logging.error('OT.Raptor.Socket: cannot publish until the socket is connected.' + message);
      return undefined;
    }

    var _completion;
    var transactionId = uuid();

    // Work out if which of the optional arguments (completion)
    // have been provided.
    if (!_completion && completion && OTHelpers.isFunction(completion)) { _completion = completion; }

    if (_completion) { _dispatcher.registerCallback(transactionId, _completion); }

    logging.debug('OT.Raptor.Socket Publish (ID:' + transactionId + ')');
    logging.debug(message);


    _rumor.publish([symphonyUrl], message, OTHelpers.extend({}, headers, {
      'Content-Type': 'application/x-raptor+v2',
      'TRANSACTION-ID': transactionId,
      'X-TB-FROM-ADDRESS': _rumor.id()
    }), retryAfterReconnect);

    return transactionId;
  };

  // Register a new stream against _sessionId
  this.streamCreate = function(name, streamId, audioFallbackEnabled, channels, minBitrate,
    maxBitrate, completion) {
    var message = Message.streams.create(_apiKey,
                                         _sessionId,
                                         streamId,
                                         name,
                                         audioFallbackEnabled,
                                         channels,
                                         minBitrate,
                                         maxBitrate);

    this.publish(message, {}, true, function(error, message) {
      completion(error, streamId, message);
    });
  };

  this.streamDestroy = function(streamId) {
    this.publish(Message.streams.destroy(_apiKey, _sessionId, streamId), {}, true);
  };

  this.streamChannelUpdate = function(streamId, channelId, attributes) {
    this.publish(Message.streamChannels.update(_apiKey, _sessionId,
      streamId, channelId, attributes), {}, true);
  };

  this.subscriberCreate = function(streamId, subscriberId, channelsToSubscribeTo, completion) {
    this.publish(Message.subscribers.create(_apiKey, _sessionId,
      streamId, subscriberId, _rumor.id(), channelsToSubscribeTo), {}, true, completion);
  };

  this.subscriberDestroy = function(streamId, subscriberId) {
    this.publish(Message.subscribers.destroy(_apiKey, _sessionId,
      streamId, subscriberId), {}, true);
  };

  this.subscriberUpdate = function(streamId, subscriberId, attributes) {
    this.publish(Message.subscribers.update(_apiKey, _sessionId,
      streamId, subscriberId, attributes), {}, true);
  };

  this.subscriberChannelUpdate = function(streamId, subscriberId, channelId, attributes) {
    this.publish(Message.subscriberChannels.update(_apiKey, _sessionId,
      streamId, subscriberId, channelId, attributes), {}, true);
  };

  this.forceDisconnect = function(connectionIdToDisconnect, completion) {
    this.publish(Message.connections.destroy(_apiKey, _sessionId,
      connectionIdToDisconnect), {}, true, completion);
  };

  this.forceUnpublish = function(streamIdToUnpublish, completion) {
    this.publish(Message.streams.destroy(_apiKey, _sessionId,
      streamIdToUnpublish), {}, true, completion);
  };

  this.jsepCandidate = function(streamId, candidate) {
    this.publish(
      Message.streams.candidate(_apiKey, _sessionId, streamId, candidate), {}, true
    );
  };

  this.jsepCandidateP2p = function(streamId, subscriberId, candidate) {
    this.publish(
      Message.subscribers.candidate(_apiKey, _sessionId, streamId,
        subscriberId, candidate), {}, true
    );
  };

  this.jsepOffer = function(uri, offerSdp) {
    this.publish(Message.offer(uri, offerSdp), {}, true);
  };

  this.jsepAnswer = function(streamId, answerSdp) {
    this.publish(Message.streams.answer(_apiKey, _sessionId, streamId, answerSdp), {}, true);
  };

  this.jsepAnswerP2p = function(streamId, subscriberId, answerSdp) {
    this.publish(Message.subscribers.answer(_apiKey, _sessionId, streamId,
      subscriberId, answerSdp), {}, true);
  };

  this.signal = function(options, completion, logEventFn) {
    var signal = new Signal(_sessionId, _rumor.id(), options || {});

    if (!signal.valid) {
      if (completion && OTHelpers.isFunction(completion)) {
        completion(new SignalError(signal.error.code, signal.error.reason), signal.toHash());
      }

      return;
    }

    this.publish(signal.toRaptorMessage(), {}, signal.retryAfterReconnect, function(err) {
      var error, errorCode, errorMessage;
      var expectedErrorCodes = [400, 403, 404, 413];

      if (err) {
        if (err.code && expectedErrorCodes.indexOf(err.code) > -1) {
          errorCode = err.code;
          errorMessage = err.message;
        } else {
          errorCode = ExceptionCodes.UNEXPECTED_SERVER_RESPONSE;
          errorMessage = 'Unexpected server response. Try this operation again later.';
        }
        error = new SignalError(errorCode, errorMessage);
      } else {
        var typeStr = signal.data ? typeof (signal.data) : null;
        logEventFn('signal', 'send', { type: typeStr });
      }

      if (completion && OTHelpers.isFunction(completion)) { completion(error, signal.toHash()); }
    });
  };

  this.id = function() {
    return _rumor && _rumor.id();
  };

  if (dispatcher == null) {
    dispatcher = new Dispatcher();
  }
  _dispatcher = dispatcher;
};

RaptorSocket.RumorSocket = RumorSocket;

module.exports = RaptorSocket;
