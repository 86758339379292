'use strict';

// core modules
var assert = require('assert');

// community modules
var Promise = require('bluebird');

// Takes a function of the form function(input?) => promise (one optional argument and returns a
// promise) and returns a function of the same form which prevents concurrent requests by
// serializing them. That is, when there is a pending result and a new request is made, the
// underlying request function is not called until the pending result has been fulfilled.

module.exports = function(request) {
  var queue = [];
  var responsePromise;

  var tryNext;

  var serializedRequest = function(input) {
    assert(this === undefined);
    assert(arguments.length <= 1);

    var deferred = Promise.defer();

    queue.push({
      input: input,
      resolve: deferred.resolve.bind(deferred)
    });

    tryNext();

    return deferred.promise;
  };

  tryNext = function() {
    if (queue.length === 0 || responsePromise) {
      return;
    }

    var job = queue.shift();

    responsePromise = request(job.input).finally(function() {
      responsePromise = undefined;
      tryNext();
    });

    job.resolve(responsePromise);
  };

  return serializedRequest;
};
