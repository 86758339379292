'use strict';

var OTHelpers = require('@opentok/ot-helpers');
var properties = require('./properties.js');
var logging = require('../ot/logging.js');

var Dialogs = {};
module.exports = Dialogs;

var addCss = function(document, url, callback) {
  var head = document.head || document.getElementsByTagName('head')[0];
  var cssTag = OTHelpers.createElement('link', {
    type: 'text/css',
    media: 'screen',
    rel: 'stylesheet',
    href: url
  });
  head.appendChild(cssTag);
  OTHelpers.on(cssTag, 'error', function(error) {
    logging.error('Could not load CSS for dialog', url, error && error.message || error);
  });
  OTHelpers.on(cssTag, 'load', callback);
};

var addDialogCSS = function(document, urls, callback) {
  var allURLs = [
    '//fonts.googleapis.com/css?family=Didact+Gothic',
    properties.cssURL
  ].concat(urls);
  var remainingStylesheets = allURLs.length;
  allURLs.forEach(function(stylesheetUrl) {
    addCss(document, stylesheetUrl, function() {
      if (--remainingStylesheets <= 0) {
        callback();
      }
    });
  });

};

var templateElement = function(classes, children, tagName) {
  var el = OTHelpers.createElement(tagName || 'div', { class: classes }, children, this);
  el.on = OTHelpers.on.bind(OTHelpers, el);
  el.off = OTHelpers.off.bind(OTHelpers, el);
  return el;
};

var checkBoxElement = function(classes, nameAndId, onChange) {
  var checkbox = templateElement.call(this, '', null, 'input');
  checkbox = OTHelpers(checkbox).on('change', onChange);

  checkbox.attr({
    name: nameAndId,
    id: nameAndId,
    type: 'checkbox'
  });

  return checkbox.first;
};

var linkElement = function(children, href, classes) {
  var link = templateElement.call(this, classes || '', children, 'a');
  link.setAttribute('href', href);
  return link;
};

Dialogs.Plugin = {};

Dialogs.Plugin.promptToInstall = function() {
  var modal = new OTHelpers.Modal(function(global, document) {

    var el = templateElement.bind(document);
    var btn = function(children, size) {
      var classes = 'OT_dialog-button ' +
                    (size ? 'OT_dialog-button-' + size : 'OT_dialog-button-large');
      var b = el(classes, children);

      b.enable = function() {
        OTHelpers.removeClass(this, 'OT_dialog-button-disabled');
        return this;
      };

      b.disable = function() {
        OTHelpers.addClass(this, 'OT_dialog-button-disabled');
        return this;
      };

      return b;
    };
    var downloadButton = btn('Download plugin');
    var cancelButton = btn('cancel', 'small');
    var refreshButton = btn('Refresh browser');
    var acceptEULA, checkbox, close, root;

    OTHelpers.addClass(cancelButton, 'OT_dialog-no-natural-margin OT_dialog-button-block');
    OTHelpers.addClass(refreshButton, 'OT_dialog-no-natural-margin');

    function onDownload() {
      modal.trigger('download');
      setTimeout(function() {
        root.querySelector('.OT_dialog-messages-main').innerHTML
          = 'To enable audio & video chat on this website, run the installer and then refresh ' +
          'your browser window.';
        var sections = root.querySelectorAll('.OT_dialog-section');
        OTHelpers.addClass(sections[0], 'OT_dialog-hidden');
        OTHelpers.removeClass(sections[1], 'OT_dialog-hidden');
      }, 3000);
    }

    function onRefresh() {
      modal.trigger('refresh');
    }

    function onToggleEULA() {
      if (checkbox.checked) {
        enableButtons();
      } else {
        disableButtons();
      }
    }

    function enableButtons() {
      downloadButton.enable();
      downloadButton.on('click', onDownload);

      refreshButton.enable();
      refreshButton.on('click', onRefresh);
    }

    function disableButtons() {
      downloadButton.disable();
      downloadButton.off('click', onDownload);

      refreshButton.disable();
      refreshButton.off('click', onRefresh);
    }

    downloadButton.disable();
    refreshButton.disable();

    cancelButton.on('click', function() {
      modal.trigger('cancelButtonClicked');
      modal.close();
    });

    close = el('OT_closeButton', '&times;')
      .on('click', function() {
        modal.trigger('closeButtonClicked');
        modal.close();
      }).first;

    var protocol = (global.location.protocol.indexOf('https') >= 0 ? 'https' : 'http');
    acceptEULA = linkElement.call(document,
                                  'end-user license agreement',
                                  protocol + '://tokbox.com/support/ie-eula');

    checkbox = checkBoxElement.call(document, null, 'acceptEULA', onToggleEULA);

    root = el('OT_dialog-centering', [
      el('OT_dialog-centering-child', [
        el('OT_root OT_dialog OT_dialog-plugin-prompt', [
          close,
          el('OT_dialog-messages', [
            el('OT_dialog-messages-main', 'This app requires real-time communication')
          ]),
          el('OT_dialog-section', [
            el('OT_dialog-single-button-with-title', [
              el('OT_dialog-button-title', [
                checkbox,
                (function() {
                  var x = el('', 'accept', 'label');
                  x.setAttribute('for', checkbox.id);
                  x.style.margin = '0 5px';
                  return x;
                })(),
                acceptEULA
              ]),
              el('OT_dialog-actions-card', [
                downloadButton,
                cancelButton
              ])
            ])
          ]),
          el('OT_dialog-section OT_dialog-hidden', [
            el('OT_dialog-button-title', [
              'You will then be able to continue using this application in Internet Explorer.'
            ]),
            refreshButton
          ])
        ])
      ])
    ]);

    addDialogCSS(document, [], function() {
      document.body.appendChild(root);
    });

  });
  return modal;
};

Dialogs.Plugin.promptToReinstall = function() {
  var modal = new OTHelpers.Modal(function(global, document) {

    var el = templateElement.bind(document);
    var close, okayButton, root;

    close = el('OT_closeButton', '&times;')
              .on('click', function() {
                modal.trigger('closeButtonClicked');
                modal.close();
              }).first;

    okayButton =
      el('OT_dialog-button OT_dialog-button-large OT_dialog-no-natural-margin', 'Okay')
        .on('click', function() {
          modal.trigger('okay');
        }).first;

    root = el('OT_dialog-centering', [
      el('OT_dialog-centering-child', [
        el('OT_ROOT OT_dialog OT_dialog-plugin-reinstall', [
          close,
          el('OT_dialog-messages', [
            el('OT_dialog-messages-main', 'Reinstall Opentok Plugin'),
            el('OT_dialog-messages-minor', 'Uh oh! Try reinstalling the OpenTok plugin again ' +
              'to enable real-time video communication for Internet Explorer.')
          ]),
          el('OT_dialog-section', [
            el('OT_dialog-single-button', okayButton)
          ])
        ])
      ])
    ]);

    addDialogCSS(document, [], function() {
      document.body.appendChild(root);
    });

  });

  return modal;
};

Dialogs.Plugin.updateInProgress = function() {

  var progressValue = 0;
  var progressBar, progressText;

  var modal = new OTHelpers.Modal(function(global, document) {

    var el = templateElement.bind(document);
    var root;

    progressText = el('OT_dialog-plugin-upgrade-percentage', '0%', 'strong');

    progressBar = el('OT_dialog-progress-bar-fill');

    root = el('OT_dialog-centering', [
      el('OT_dialog-centering-child', [
        el('OT_ROOT OT_dialog OT_dialog-plugin-upgrading', [
          el('OT_dialog-messages', [
            el('OT_dialog-messages-main', [
              'One moment please... ',
              progressText
            ]),
            el('OT_dialog-progress-bar', progressBar),
            el('OT_dialog-messages-minor OT_dialog-no-natural-margin',
              'Please wait while the OpenTok plugin is updated')
          ])
        ])
      ])
    ]);

    addDialogCSS(document, [], function() {
      document.body.appendChild(root);
      if (progressValue != null) {
        modal.setUpdateProgress(progressValue);
      }
    });
  });

  modal.setUpdateProgress = function(newProgress) {
    if (progressBar && progressText) {
      if (newProgress > 99) {
        OTHelpers.css(progressBar, 'width', '');
        progressText.innerHTML = '100%';
      } else if (newProgress < 1) {
        OTHelpers.css(progressBar, 'width', '0%');
        progressText.innerHTML = '0%';
      } else {
        OTHelpers.css(progressBar, 'width', newProgress + '%');
        progressText.innerHTML = newProgress + '%';
      }
    } else {
      progressValue = newProgress;
    }
  };

  return modal;
};

Dialogs.Plugin.updateComplete = function(error) {
  var modal = new OTHelpers.Modal(function(global, document) {
    var el = templateElement.bind(document);
    var reloadButton, root, msgs;

    reloadButton =
      el('OT_dialog-button OT_dialog-button-large OT_dialog-no-natural-margin', 'Reload')
        .on('click', function() {
          modal.trigger('reload');
        }).first;

    if (error) {
      msgs = ['Update Failed.', error + '' || 'NO ERROR'];
    } else {
      msgs = ['Update Complete.',
        'The OpenTok plugin has been succesfully updated. ' +
        'Please reload your browser.'];
    }

    root = el('OT_dialog-centering', [
      el('OT_dialog-centering-child', [
        el('OT_root OT_dialog OT_dialog-plugin-upgraded', [
          el('OT_dialog-messages', [
            el('OT_dialog-messages-main', msgs[0]),
            el('OT_dialog-messages-minor', msgs[1])
          ]),
          el('OT_dialog-single-button', reloadButton)
        ])
      ])
    ]);

    addDialogCSS(document, [], function() {
      document.body.appendChild(root);
    });

  });

  return modal;
};
