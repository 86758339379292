'use strict';

var isReady = require('./readiness.js').isReady;
var PluginProxies = require('./plugin_proxies.js');
var registerReadyListner = require('./readiness.js').listen;

// Exposes a enumerateDevices method and emits a devicechange event
//
// http://w3c.github.io/mediacapture-main/#idl-def-MediaDevices
//
module.exports = function MediaDevices() {
  var Proto = function MediaDevices() {},
      api = new Proto();

  api.enumerateDevices = function enumerateDevices(completion) {
    registerReadyListner(function(error) {
      if (error) {
        completion(error);
      } else {
        PluginProxies.mediaCapturer.enumerateDevices(completion);
      }
    });
  };

  api.addListener = function addListener(fn, context) {
    registerReadyListner(function(error) {
      if (error) {
        // No error message here, ready failing would have
        // created a bunch elsewhere
        return;
      }

      PluginProxies.mediaCapturer.on('devicesChanged', fn, context);
    });
  };

  api.removeListener = function removeListener(fn, context) {
    if (isReady()) {
      PluginProxies.mediaCapturer.off('devicesChanged', fn, context);
    }
  };

  return api;
};
