'use strict';

var util = require('../../util');

var specialDomProperties = {
  'for': 'htmlFor',
  'class': 'className'
};

module.exports = function(ElementCollection) {
  // Gets or sets the attribute called +name+ for the first element in the collection
  ElementCollection.prototype.attr = function (name, value) {
    if (util.isObject(name)) {
      var actualName;

      for (var key in name) {
        actualName = specialDomProperties[key] || key;
        this.first.setAttribute(actualName, name[key]);
      }
    }
    else if (value === void 0) {
      return this.first.getAttribute(specialDomProperties[name] || name);
    }
    else {
      this.first.setAttribute(specialDomProperties[name] || name, value);
    }

    return this;
  };


  // Removes an attribute called +name+ for the every element in the collection.
  ElementCollection.prototype.removeAttr = function (name) {
    var actualName = specialDomProperties[name] || name;

    this.forEach(function(element) {
      element.removeAttribute(actualName);
    });

    return this;
  };


  // Gets, and optionally sets, the html body of the first element
  // in the collection. If the +html+ is provided then the first
  // element's html body will be replaced with it.
  //
  ElementCollection.prototype.html = function (html) {
    if (html !== void 0) {
      this.first.innerHTML = html;
    }

    return this.first.innerHTML;
  };


  // Centers +element+ within the global. You can pass through the width and height
  // if you know it, if you don't they will be calculated for you.
  ElementCollection.prototype.center = function (width, height) {
    var $element;

    this.forEach(function(element) {
      $element = new ElementCollection(element);
      if (!width) width = parseInt($element.width(), 10);
      if (!height) height = parseInt($element.height(), 10);

      var marginLeft = -0.5 * width + 'px';
      var marginTop = -0.5 * height + 'px';

      $element.css('margin', marginTop + ' 0 0 ' + marginLeft)
              .addClass('OT_centered');
    });

    return this;
  };

  // @remove
  // Centers +element+ within the global. You can pass through the width and height
  // if you know it, if you don't they will be calculated for you.
  ElementCollection._attachToOTHelpers.centerElement = function(element, width, height) {
    return new ElementCollection(element).center(width, height);
  };

  /**
   * Methods to calculate element widths and heights.
   */
  var _width = function(element) {
        if (element.offsetWidth > 0) {
          return element.offsetWidth + 'px';
        }

        return new ElementCollection(element).css('width');
      },

      _height = function(element) {
        if (element.offsetHeight > 0) {
          return element.offsetHeight + 'px';
        }

        return new ElementCollection(element).css('height');
      };

  ElementCollection.prototype.width = function (newWidth) {
    if (newWidth) {
      this.css('width', newWidth);
      return this;
    }
    else {
      if (this.isDisplayNone()) {
        return this.makeVisibleAndYield(function(element) {
          return _width(element);
        })[0];
      }
      else {
        return _width(this.get(0));
      }
    }
  };

  // @remove
  ElementCollection._attachToOTHelpers.width = function(element, newWidth) {
    var ret = new ElementCollection(element).width(newWidth);
    return newWidth ? ElementCollection._attachToOTHelpers : ret;
  };

  ElementCollection.prototype.height = function (newHeight) {
    if (newHeight) {
      this.css('height', newHeight);
      return this;
    }
    else {
      if (this.isDisplayNone()) {
        // We can't get the height, probably since the element is hidden.
        return this.makeVisibleAndYield(function(element) {
          return _height(element);
        })[0];
      }
      else {
        return _height(this.get(0));
      }
    }
  };

  // @remove
  ElementCollection._attachToOTHelpers.height = function(element, newHeight) {
    var ret = new ElementCollection(element).height(newHeight);
    return newHeight ? ElementCollection._attachToOTHelpers : ret;
  };
};
