'use strict';

var meta = require('./meta.js');
var OTHelpers = require('@opentok/ot-helpers');
var proxyExtras = require('./proxy_extras.js');

var Proto = function PluginProxies() {},
    api = new Proto(),
    proxies = {};

/// Private API

// This is called whenever a Proxy's destroy event fires.
var cleanupProxyOnDestroy = function cleanupProxyOnDestroy(object) {
  if (api.mediaCapturer && api.mediaCapturer.id === object.id) {
    api.mediaCapturer = null;
  } else if (proxies.hasOwnProperty(object.id)) {
    delete proxies[object.id];
  }

  if (object.OTHelpers) {
    object.OTHelpers.remove();
  }
};

/// Public API

// Public accessor for the MediaCapturer
api.mediaCapturer = null;

api.removeAll = function removeAll() {
  for (var id in proxies) {
    if (proxies.hasOwnProperty(id)) {
      proxies[id].destroy();
    }
  }

  if (api.mediaCapturer) api.mediaCapturer.destroy();
};

api.create = function create(options, completion) {
  var proxy = proxyExtras.createPluginProxy(options, completion);

  proxies[proxy.uuid] = proxy;

  // Clean up after this Proxy when it's destroyed.
  proxy.on('destroy', function() {
    cleanupProxyOnDestroy(proxy);
  });

  return proxy;
};

api.createMediaPeer = function createMediaPeer(options, completion) {
  if (OTHelpers.isFunction(options)) {
    completion = options;
    options = {};
  }

  var mediaPeer =  api.create(OTHelpers.extend(options || {}, {
    mimeType: meta.mimeType,
    isVisible: true,
    windowless: true
  }), function(err) {
    if (err) {
      completion.call(void 0, err);
      return;
    }

    proxies[mediaPeer.id] = mediaPeer;
    completion.call(void 0, void 0, mediaPeer);
  });

  proxyExtras.makeMediaPeerProxy(mediaPeer);
};

api.createMediaCapturer = function createMediaCapturer(completion) {
  if (api.mediaCapturer) {
    completion.call(void 0, void 0, api.mediaCapturer);
    return api;
  }

  api.mediaCapturer = api.create({
    mimeType: meta.mimeType,
    isVisible: false,
    windowless: false
  }, function(err) {
    completion.call(void 0, err, api.mediaCapturer);
  });

  proxyExtras.makeMediaCapturerProxy(api.mediaCapturer);
};

module.exports = api;
