'use strict';

var OTHelpers = require('@opentok/ot-helpers');

module.exports = {
  isSupportedInThisBrowser: OTHelpers.env.name === 'Firefox',
  autoRegisters: true,
  extensionRequired: false,
  getConstraintsShowsPermissionUI: false,
  sources: {
    screen: true,
    application: OTHelpers.env.name === 'Firefox' && OTHelpers.env.version >= 34,
    window: OTHelpers.env.name === 'Firefox' && OTHelpers.env.version >= 34,
    browser: OTHelpers.env.name === 'Firefox' && OTHelpers.env.version >= 38
  },
  register: function() {
    return {
      isInstalled: function(callback) {
        callback(true);
      },
      getConstraints: function(source, constraints, callback) {
        constraints.video = {
          mediaSource: source
        };

        // copy constraints under the video object and removed them from the root constraint object
        if (constraints.browserWindow) {
          constraints.video.browserWindow = constraints.browserWindow;
          delete constraints.browserWindow;
        }
        if (typeof constraints.scrollWithPage !== 'undefined') {
          constraints.video.scrollWithPage = constraints.scrollWithPage;
          delete constraints.scrollWithPage;
        }

        callback(void 0, constraints);
      }
    };
  }
};
