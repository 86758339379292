'use strict';

require('./web_rtc_polyfills.js');

var OTPlugin = require('@opentok/otplugin.js');

var NativeRTCPeerConnection = (global.webkitRTCPeerConnection ||
                               global.RTCPeerConnection ||
                               global.mozRTCPeerConnection);

module.exports =  function createPeerConnection(
  config,
  options,
  publishersWebRtcStream,
  completion
) {
  if (OTPlugin.isInstalled()) {
    OTPlugin.initPeerConnection(config, options,
                                publishersWebRtcStream, completion);
  } else {
    var pc;

    try {
      pc = new NativeRTCPeerConnection(config, options);
    } catch (e) {
      completion(e.message);
      return;
    }

    completion(null, pc);
  }
};
