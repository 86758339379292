'use strict';

var uuid = require('uuid');
var OTHelpers = require('@opentok/ot-helpers');
var logging = require('../ot/logging.js');

var guidStorage = {};
module.exports = guidStorage;

var currentGuidStorage,
    currentGuid;

var isInvalidStorage = function isInvalidStorage(storageInterface) {
  return !(
    OTHelpers.isFunction(storageInterface.get) &&
    OTHelpers.isFunction(storageInterface.set)
  );
};

var getClientGuid = function getClientGuid(completion) {
  if (currentGuid) {
    completion(null, currentGuid);
    return;
  }

  // It's the first time that getClientGuid has been called
  // in this page lifetime. Attempt to load any existing Guid
  // from the storage
  currentGuidStorage.get(completion);
};

/*
* Sets the methods for storing and retrieving client GUIDs persistently
* across sessions. By default, OpenTok.js attempts to use browser cookies to
* store GUIDs.
* <p>
* Pass in an object that has a <code>get()</code> method and
* a <code>set()</code> method.
* <p>
* The <code>get()</code> method must take one parameter: the callback
* method to invoke. The callback method is passed two parameters &mdash;
* the first parameter is an error object or null if the call is successful;
* and the second parameter is the GUID (a string) if successful.
* <p>
* The <code>set()</code> method must include two parameters: the GUID to set
* (a string) and the callback method to invoke. The callback method is
* passed an error object on error, or it is passed no parameter if the call is
* successful.
* <p>
* Here is an example:
* <p>
* <pre>
* var ComplexStorage = function() {
*   this.set = function(guid, completion) {
*     AwesomeBackendService.set(guid, function(response) {
*       completion(response.error || null);
*     });
*   };
*   this.get = function(completion) {
*     AwesomeBackendService.get(function(response, guid) {
*       completion(response.error || null, guid);
*     });
*   };
* };
*
* OT.overrideGuidStorage(new ComplexStorage());
* </pre>
*/
guidStorage.override = function(storageInterface) {
  if (isInvalidStorage(storageInterface)) {
    throw new Error('The storageInterface argument does not seem to be valid, ' +
                                      'it must implement get and set methods');
  }

  if (currentGuidStorage === storageInterface) {
    return;
  }

  currentGuidStorage = storageInterface;

  // If a client Guid has already been assigned to this client then
  // let the new storage know about it so that it's in sync.
  if (currentGuid) {
    currentGuidStorage.set(currentGuid, function(error) {
      if (error) {
        logging.error('Failed to send initial Guid value (' + currentGuid +
                              ') to the newly assigned Guid Storage. The error was: ' + error);
        // @todo error
      }
    });
  }
};

guidStorage.get = function(completion) {
  getClientGuid(function(error, guid) {
    if (error) {
      completion(error);
      return;
    }

    if (!guid) {
      // Nothing came back, this client is entirely new.
      // generate a new Guid and persist it
      guid = uuid();
      currentGuidStorage.set(guid, function(error) {
        if (error) {
          completion(error);
          return;
        }

        currentGuid = guid;
      });
    } else if (!currentGuid) {
      currentGuid = guid;
    }

    completion(null, currentGuid);
  });
};

// Implement our default storage mechanism, which sets/gets a cookie
// called 'opentok_client_id'
guidStorage.override({
  get: function(completion) {
    completion(null, OTHelpers.getCookie('opentok_client_id'));
  },

  set: function(guid, completion) {
    OTHelpers.setCookie('opentok_client_id', guid);
    completion(null);
  }
});

// Test only
guidStorage.set = function(guid) {
  currentGuid = guid;
};
